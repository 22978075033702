// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import _ from 'lodash';
import { MuiPaginationContext } from './MuiPaginationContext';
import { MuiInput } from '../MuiForms/Inputs';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { Tr } from '@lsoft/translate';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	MuiPaginationFilter: {
		padding: [[1.2, 0]], // Todo - Find a solution, which works responsive and imitates the height from MuiPaginationSort
		margin: 0,
	},
}));

const MuiPaginationFilter = ({ theme, label, filter }) => {
	const {
		initialized,
		loadCurrentPageWithNewFilter,
		activeFilter,
	} = useContext(MuiPaginationContext);
	const classes = useStyles({ theme });

	if (_.isNil(initialized) || !initialized) {
		return null;
	}
	const a = Object.keys(_.get(filter, 'match.fields', {}));
	const b = !_.isNil(activeFilter) ? Object.keys(activeFilter) : {};

	const value = a.every((v) => b.includes(v));
	return (
		<Grid container alignItems={'center'} spacing={1}>
			<Grid item>
				<Typography variant={'body2'} component={'span'} color={'inherit'}>
					<Tr tr={label} />
				</Typography>
			</Grid>
			<Grid item>
				<MuiInput
					className={clsx(classes.MuiPaginationFilter)}
					color={'primary'}
					value={value}
					variant={'checkbox'}
					onChange={(val) => {
						loadCurrentPageWithNewFilter(filter, val);
					}}
					labelPlacement={'start'}
				/>
			</Grid>
		</Grid>
	);
};
MuiPaginationFilter.propTypes = {
	label: PropTypes.object,
	filter: PropTypes.object,
	name: PropTypes.string,
	value: PropTypes.string,
};
MuiPaginationFilter.defaultProps = {
	label: {},
	filter: {},
};

export { MuiPaginationFilter };
