import React, { useContext } from 'react';
import { MuiPaginationContext } from './MuiPaginationContext';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import _ from 'lodash';

const MuiPaginationError = (props) => {
	const { error } = useContext(MuiPaginationContext);
	if (_.isNil(error)) return null;
	return props.renderError(error);
};

MuiPaginationError.propTypes = {
	renderError: PropTypes.func,
};

MuiPaginationError.defaultProps = {
	// eslint-disable-next-line react/display-name
	renderError: (error) => {
		return <Alert severity="error">{error.ERROR}</Alert>;
	},
};

export { MuiPaginationError };
