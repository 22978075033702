// eslint-disable-next-line no-unused-vars
export const MuiGlobalStyles = (theme) => ({
	'@global': {
		html: {
			height: '100%',
		},
		body: {
			height: '100%',
			// border: '3px solid blue'
		},
		'#app': {
			height: '100%',
		},
	},
});
