import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { generateSig } from '@lsoft/shared/functions/generateSig';

const cookieObj = new Cookies();

export const Context = React.createContext({});
Context.displayName = 'MuiCookie.Context';

// const allowAllCookies
const parseCookiesState = (cookies) => {
	const _webapi_ca = _.get(cookies, '_webapi_ca');
	const _webapi_client_id = _.get(cookies, '_webapi_client_id');
	const _am_auth_token = _.get(cookies, '_am_auth_token');

	return {
		technicalCookies: !_.isNil(_webapi_ca) && _webapi_ca !== '0',
		marketingCookies: !_.isNil(_webapi_ca) && _webapi_ca === '2',
		amAuthToken: !_.isNil(_am_auth_token),
		_am_auth_token,
		_webapi_ca,
		_webapi_client_id,
	};
};

const makeCookie = (name, value, options) => {
	return {
		[name]: {
			name,
			value,
			options: _.assign(
				{},
				{
					maxAge: 86400 * 1461, // 4 years in seconds
					path: '/',
					sameSite: 'lax',
				},
				options
			),
		},
	};
};

export const Provider = ({ children, cookies }) => {
	const [cookiesState, setCookieState] = useState(cookies);
	const parsedCookies = parseCookiesState(cookiesState);
	const { technicalCookies, marketingCookies, _webapi_client_id } =
		parsedCookies;

	const setCookies = useCallback(
		async (...cookies) => {
			// console.log('cookies', cookies);
			// console.log('cookiesstate', cookiesState);

			const cookieDict = _.assign({}, ...cookies);
			const cookieMap = Object.values(cookieDict).reduce((ret, c) => {
				cookieObj.set(c.name, c.value, c.options);
				ret[c.name] = c.value;
				return ret;
			}, {});
			setCookieState(_.assign({}, cookiesState, cookieMap));
		},
		[cookieObj, cookiesState]
	);
	const deleteCookie = useCallback(
		(name, options) => {
			// console.log('remove Cookie but its ', name);
			cookieObj.remove(name, _.assign({}, { path: '/' }, options));
			const newCookieState = _.assign({}, cookiesState, { [name]: value });
			delete newCookieState[name];
			setCookieState(newCookieState);
		},
		[cookieObj, cookiesState]
	);
	const allowCookiesWithClientId = useCallback(
		async (_webapi_ca) => {
			await setCookies(
				makeCookie('_webapi_ca', _webapi_ca),
				_.isNil(_webapi_client_id)
					? makeCookie('_webapi_client_id', generateSig('CID'))
					: null
			);
		},
		[_webapi_client_id]
	);

	const allowTechnicalCookies = useCallback(async () => {
		if (!technicalCookies) {
			await allowCookiesWithClientId('1');
		}
	}, [technicalCookies, _webapi_client_id]);

	const allowAllCookies = useCallback(async () => {
		if (!marketingCookies) {
			await allowCookiesWithClientId('2');
		}
	}, [marketingCookies]);

	const value = {
		...parsedCookies,
		allowAllCookies,
		allowTechnicalCookies,
		setCookie: (name, value, options) =>
			setCookies(makeCookie(name, value, options)),
		deleteCookie,
		makeCookie,
	};
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
Provider.propTypes = { cookies: PropTypes.object };

export const MuiCookie = {
	Context,
	Container: Provider,
};
