import { makeOptionsProvider, MuiWebapiContainer } from '../MuiWebapi';
import _ from 'lodash';

// eslint-disable-next-line no-unused-vars
const selector = (state, options) => _.get(state.webapi.data, 'PRINTERS', null);

const Printers = MuiWebapiContainer(
	{
		endpointPath: 'print.printers_get',
		dataPaths: { data: ['PRINTERS'] },
	},
	{
		selector: selector,
	}
);

makeOptionsProvider(Printers, {
	makeOption: (entry) => ({
		value: entry.NAME,
		label: { ALL: `${entry.NAME} ${entry.LOCATION}` },
	}),
	// filterEntries: (dataList) => {
	// 	return dataList.filter((entry) => {
	// 		return entry.PRINTER_TYPE === 'PDF' || entry.PRINTER_TYPE === 'DEFAULT';
	// 	});
	// },
});

export { Printers };
