import React, { Fragment, useContext } from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { Cart } from '../../container/shop/Cart';
import { HeaderCartContent } from './HeaderCartContent';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { HeaderIconPortalButton } from './HeaderIconPortalButton';
import { grey } from '@material-ui/core/colors';
import { MuiCookieTooltip } from '../../../libs/mui/MuiCookieTooltip';
import { ShopModeContext } from '../ShopMode/ShopMode';

const useStyles = makeStyles((theme) => ({
	IconButton: {
		background: grey[100],
		'&:hover': {
			background: grey[200],
		},
	},
	Badge: {
		transform: 'scale(0.85) translate(40%,0%)',
	},
}));

const HeaderCart = ({ theme }) => {
	const classes = useStyles({ theme });
	const { buttonRef, isOpen, onButtonClick } = useContext(
		HeaderIconPortalButton.Context
	);
	const { disable_customer_order } = useContext(ShopModeContext);

	const { total_item_count } = useContext(Cart.Context);

	return (
		<Fragment>
			<MuiCookieTooltip>
				<IconButton
					ref={buttonRef}
					color={'inherit'}
					onClick={onButtonClick}
					className={clsx(classes.IconButton)}
					disabled={disable_customer_order}
				>
					<Badge
						badgeContent={total_item_count}
						color="primary"
						overlap={'rectangular'}
						classes={{ anchorOriginTopRightRectangular: clsx(classes.Badge) }}
					>
						<ShoppingCartIcon />
					</Badge>
				</IconButton>
			</MuiCookieTooltip>
			{isOpen && total_item_count !== 0 && <HeaderCartContent />}
		</Fragment>
	);
};

HeaderCart.propTypes = {};
HeaderCart.defaultProps = {};

export { HeaderCart };
