'use client';

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import { getTranslation } from './Tr';

export const TrContext = React.createContext({});
TrContext.displayName = 'TrContext';

export const TrProvider = (props) => {
	const [tr, setTr] = useState({});
	const [activeLanguage, setActiveLanguage] = useState(props.activeLanguage);
	const [availableLanguages, setAvailableLanguages] = useState(
		props.availableLanguages,
	);

	useEffect(() => {
		if (props.activeLanguage !== activeLanguage) {
			setActiveLanguage(props.activeLanguage);
		}
	}, [props.activeLanguage]);

	const registerTrms = (trms) => {
		trms.map((trm) => {
			const pathsToCheck = _.concat(
				_.get(trm, 'pathesToCheck', []),
				_.get(trm, 'pathsToCheck', []),
			);
			const exists = _.min(pathsToCheck.map((path) => _.has(tr, path)));
			if (!exists) {
				setTr(_.merge(tr, trm.tr));
			}
		});
	};

	const getTranslationFromData = (data) => {
		return getTranslation(data, activeLanguage, availableLanguages);
	};

	const getContextValues = () => {
		return {
			tr,
			activeLanguage,
			availableLanguages,
			setActiveLanguage,
			setAvailableLanguages,
			registerTrms,
			getTranslationFromData,
		};
	};
	return (
		<TrContext.Provider value={getContextValues()}>
			{props.children}
		</TrContext.Provider>
	);
};

TrProvider.propTypes = {
	activeLanguage: PropTypes.string,
	availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
};
