import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Tr } from '@lsoft/translate';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import _ from 'lodash';
import { MuiLoadableOnClick } from '../MuiLoadable';

const MuiConfirmDialog = ({
														theme,
														size,
														title,
														body,
														cancelText,
														disableConfirmButton,
														onConfirm,
														confirmText,
														open,
														setOpen,
														contained,
														...props
													}) => {
	let additionalProps = contained
		? {
			disablePortal: true,
			style: {
				position: 'absolute'
			}
		}
		: {};
	additionalProps = _.assign({}, additionalProps, props);

	return (
		<Dialog
			open={open}
			maxWidth={size}
			aria-labelledby='confirmation-dialog-title'
			fullScreen={false}
			fullWidth={false}
			disablePortal
			{...additionalProps}
		>
			<DialogTitle id='confirmation-dialog-title'>{title}</DialogTitle>
			<DialogContent>{body}</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
					}}
					color='primary'
				>
					{cancelText}
				</Button>
				<MuiLoadableOnClick>
					<Button
						disabled={disableConfirmButton}
						onClick={async () => {
							await onConfirm();
							return setOpen(false);
						}}
						color='primary'
					>
						{confirmText}
					</Button>
				</MuiLoadableOnClick>
			</DialogActions>
		</Dialog>
	);
};

MuiConfirmDialog.propTypes = {
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	confirmText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	cancelText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	size: PropTypes.string,
	style: PropTypes.any,
	disableConfirmButton: PropTypes.bool,
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	onConfirm: PropTypes.func
};

MuiConfirmDialog.defaultProps = {
	open: false,
	onConfirm: () => {
		console.log('defaultProp onConfirm');
	},
	setOpen: () => {
		console.log('defaultProp set open');
	},
	title: (
		<Tr
			tr={{
				EN: 'Confirmation',
				DE: 'Bestätigung'
			}}
		/>
	),
	body: (
		<DialogContentText>
			<Tr
				tr={{
					EN: 'Are you sure?',
					DE: 'Möchten Sie das wirklich tun?'
				}}
			/>
		</DialogContentText>
	),
	confirmText: (
		<Typography>
			<Tr
				tr={{
					EN: 'Confirm',
					DE: 'Bestätigen'
				}}
			/>
		</Typography>
	),
	cancelText: (
		<Typography>
			<Tr
				tr={{
					EN: 'Cancel',
					DE: 'Abbrechen'
				}}
			/>
		</Typography>
	),
	size: 'xs',
	disableConfirmButton: false
};

const MuiConfirm = ({ children, ...props }) => {
	const [open, setOpen] = useState(false);

	const realButton = React.Children.only(children);
	const newButton = React.cloneElement(
		realButton,
		{
			onClick: () => setOpen(true),
			style: props.style
		},
		realButton.props.children
	);
	return (
		<Fragment>
			{newButton}
			<MuiConfirmDialog
				open={open}
				setOpen={setOpen}
				onConfirm={realButton.props.onClick}
				{...props}
			/>
		</Fragment>
	);
};

export { MuiConfirm, MuiConfirmDialog };
