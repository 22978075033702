import { useSnackbar } from 'notistack';
import { Session } from '../container/shop/Session';
import { useContext } from 'react';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { getTranslation } from '@lsoft/translate';
import * as Sentry from '@sentry/react';
import { parse_error_to_js_error } from '../utils/errors';

const debug_log = false;

export const useShopSnackbar = () => {
	// noinspection JSCheckFunctionSignatures
	const session = useContext(Session.Context);
	const { locale } = useRouter();
	const snackbar = useSnackbar();

	const enrich_sentry_data_with_exception_data = (scope, exception) => {
		// Return scope if exception has no ERROR_CLASS
		if (!_.has(exception, 'ERROR_CLASS')) return scope;
		// Set new context with exception data @scope
		scope.setContext('exception_data', exception);
		// Return scope
		return scope;
	};

	const enrich_sentry_data_with_session = (scope) => {
		// Return scope if session is null
		if (_.isNil(session)) return scope;
		// Extract data from session and set as tag @scope
		const account_id = _.get(session, ['ACCOUNT_ID'], null);
		if (!_.isNil(account_id)) scope.setTag('account_id', account_id);
		scope.setTag('session_id', _.get(session, ['SESSION_ID']));
		scope.setTag('client_ids', _.get(session, ['CLIENT_IDS'], []).join());
		// Return scope
		return scope;
	};

	const enrich_sentry_data_with_meta_data = (scope, meta_data) => {
		// Return scope if meta_data is null
		if (_.isNil(meta_data)) return scope;
		// Run through meta_data and set a new context @scope
		scope.setContext('meta_data', meta_data);
		// Return scope
		return scope;
	};

	return (options) => {
		// Default options
		const default_options = {
			msg: getTranslation({ ALL: 'Info' }, locale),
			exception: null,
			meta_data: null,
			send_to_sentry: true,
			variant: 'info',
		};
		// Merge options into default options
		const { msg, exception, meta_data, send_to_sentry, variant } = _.merge(
			{},
			default_options,
			options
		);
		// Log options if debug is true
		if (debug_log) {
			console.log('msg', msg);
			console.log('exception', exception);
			console.log('parsed_exception', parse_error_to_js_error(exception));
			console.log('meta_data', meta_data);
			console.log('session', session);
			console.log('generated stack', new Error().stack);
		}
		// Throw snackbar
		if (_.isFunction(snackbar.enqueueSnackbar)) {
			snackbar.enqueueSnackbar(msg, {
				variant: variant,
			});
		}
		// Sentry error handling
		if (!_.isNil(exception) && send_to_sentry) {
			// Parse exception to instance of Error
			const parsed_exception = parse_error_to_js_error(exception);
			// Enrich scope
			let scope = new Sentry.Scope();
			scope = enrich_sentry_data_with_exception_data(scope, exception);
			scope = enrich_sentry_data_with_session(scope);
			scope = enrich_sentry_data_with_meta_data(scope, meta_data);
			// Log scope if debug is true
			if (debug_log) console.log('scope', scope);
			// Send exception and scope to sentry
			Sentry.captureException(parsed_exception, scope);
			// Clear scope
			scope.clear();
			// Log cleared scope if debug is true
			if (debug_log) console.log('cleared scope', scope);
		}
	};
};
