export const trPageNotFound = {
	DE: 'Seite nicht gefunden',
	EN: 'Page not found',
	FR: 'Page non trouvée',
};
export const trSorryThePageYouRequestedCouldNotBeFound = {
	DE:
		'Die von Ihnen angeforderte Seite konnte leider nicht gefunden werden. Bitte wechseln Sie zur Startseite um fortzufahren.',
	EN:
		'Sorry, the page you requested could not be found. Please go to the homepage to continue.',
	// prettier-ignore
	FR:
    'Désolé, la page que vous avez demandée n\'a pas pu être trouvée. Veuillez vous rendre sur la page d\'accueil pour continuer.',
};
