import React, {
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useDispatch } from 'react-redux';
import { useWebapi } from '@lsoft/mui';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { throttle } from '@lsoft/shared/functions/throttle';
import { MuiPaginationContext } from '../MuiPagination';

const MuiSearchContext = React.createContext(null);
MuiSearchContext.displayName = 'MuiSearchContext';

const searchReducer = (state, action) => {
	const newState = _.cloneDeep(state);
	switch (action.type) {
		case 'setAutocompleteResults':
			return _.assign({}, newState, action.payload);

		case 'setSearchStr':
			return _.assign({}, newState, action.payload);

		default:
			throw new Error(`no reducer for action '${action.type}'`);
	}
};
const initialState = {
	selectedAutoCompleteItem: null,
	autocompleteResults: [],
	searchStr: '',
};

const MuiSearch = ({ children, plugin, minimumLength, throttleDelay }) => {
	const [loading, setLoading] = useState(false);
	const [state, dispatch] = useReducer(searchReducer, initialState, (x) => x);
	const reduxDispatch = useDispatch();
	const webapi = useWebapi();
	const router = useRouter();
	const snackbar = useSnackbar();

	const setSearchStr = useCallback(
		async (search) => {
			dispatch({
				type: 'setSearchStr',
				payload: { searchStr: search },
			});
		},
		[state, dispatch],
	);
	const setAutocompleteResults = useCallback(
		(result) => {
			dispatch({
				type: 'setAutocompleteResults',
				payload: { autocompleteResults: result },
			});
		},
		[state, dispatch],
	);

	const selectNextAutoComplete = () => {
		console.log('next');
	};

	const contextValues = {
		setSearchStr,
		selectNextAutoComplete,
		router,
		snackbar,
		dispatch: reduxDispatch,
		webapi,
		loading,
		setLoading,
		type: 'PRODUCT',
		...state,
	};

	const callAutoComplete = useCallback(
		async (search) => {
			const realSearch = search.trim();

			if (_.isNil(realSearch.length) || realSearch.length < minimumLength) {
				setAutocompleteResults({});
				return;
			}
			const r = await plugin.autocomplete(contextValues, realSearch);
			setAutocompleteResults(r);
			// console.log(timestamp(), search);
		},
		[plugin, setAutocompleteResults],
	);

	const throttledAutoComplete = useCallback(
		throttle(throttleDelay, false, (search) => callAutoComplete(search)),
		[plugin, throttleDelay],
	);
	const search = useCallback(async () => {
		//dispatch und webapi needed!
		// router.push(`/product/${state.searchStr}`);

		plugin.search(contextValues, state.searchStr);
	}, [plugin, state.searchStr, contextValues]);

	useEffect(() => {
		throttledAutoComplete(state.searchStr);
	}, [state.searchStr]);

	return (
		<MuiSearchContext.Provider value={{ ...contextValues, search }}>
			{children}
		</MuiSearchContext.Provider>
	);
};

MuiSearch.propTypes = {
	plugin: PropTypes.object.isRequired,
	minimumLength: PropTypes.number,
	throttleDelay: PropTypes.number,
};
MuiSearch.defaultProps = {
	minimumLength: 3,
	throttleDelay: 500,
};

export { MuiSearch, MuiSearchContext };
