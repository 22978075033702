// eslint-disable-next-line no-unused-vars
const MuiInputStyles = (theme) => ({
	MuiInput: {
		'& .MuiFormHelperText-root': {
			fontSize: '0.6rem',
		},
		'& .MuiFormHelperText-contained': {
			marginLeft: theme.spacing(0.5),
			marginRight: theme.spacing(0.5),
		},
	},
	MuiFormHelperText: {
		fontSize: '0.6rem',
	},
	NumberInputAlignRight: {
		'& input': {
			textAlign: 'right',
		},
	},
	MuiInputTableCellInput: {
		position: 'unset !important',
		'& .MuiInputBase-root': {
			fontSize: '0.875rem',
			paddingTop: 2,
			position: 'static',
		},
		'& .MuiFilledInput-root': {
			background: 'transparent',
			paddingTop: 2,
		},
		'& .MuiFilledInput-adornedEnd': {
			paddingRight: 0,
		},
		'& .MuiFilledInput-underline:before': {
			borderBottom: 0,
		},
		'& .MuiFilledInput-underline.Mui-error:after': {
			// marginRight: 1,
			borderBottomWidth: 2,
			background: 'rgba(255,0,0,0.15)',
			position: 'absolute',
			top: 0,
			bottom: 0,
		},
		'& .MuiFilledInput-input': {
			border: 0,
			padding: 0,
		},
	},
	MuiInputTableCell: {
		position: 'relative',
		padding: 0,
	},
	MuiInputTableCellHeader: {
		padding: '0 !important',
	},
});

export { MuiInputStyles };
