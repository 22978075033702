import React, { useContext } from 'react';
// noinspection ES6UnusedImports
import PropTypes from 'prop-types'; // eslint-disable-line no-unused-vars
// noinspection ES6UnusedImports
import clsx from 'clsx'; // eslint-disable-line no-unused-vars
import { MuiInfoAreaContext } from './MuiInfoAreaContext';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
	createStyles({
		MuiInfoAreaButtons: {
			borderLeft: [[1, 'solid', theme.palette.divider]],
			height: '100vh',
			minWidth: 56,
			width: 56,
		},
		actionButtons: {
			padding: theme.spacing(0.5),
			position: 'fixed',
			width: 56,
		},
		actionButtonSelected: {
			position: 'relative',
			'&:after': {
				content: '""',
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: 0,
				width: 2,
				background: theme.palette.secondary.main,
			},
		},
	})
);
//Todo: change from default to normal export
const MuiInfoAreaButtons = (props) => {
	const classes = useStyles(props);
	const muiInfoArea = useContext(MuiInfoAreaContext);
	const entries = Object.entries(muiInfoArea.items);

	const icons = entries.map(([id, item]) => {
		return (
			<IconButton
				key={id}
				aria-label={id}
				onClick={() => {
					muiInfoArea.clickItem(id);
				}}
				className={clsx(
					muiInfoArea.selectedId === id ? classes.actionButtonSelected : null
				)}
			>
				{item.props.icon}
			</IconButton>
		);
	});

	return (
		<div className={clsx(classes.MuiInfoAreaButtons)}>
			{/*	ActionButtons - Starts */}
			<div className={clsx(classes.actionButtons)}>{icons}</div>
			{/* ActionButtons - Ends */}
		</div>
	);
};

MuiInfoAreaButtons.propTypes = {
	icon: PropTypes.any,
	muiInfoArea: PropTypes.any,
};

export default MuiInfoAreaButtons;
