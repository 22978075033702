import React, { Fragment, useCallback, useContext } from 'react';
import { makePick, pickDefault } from './input_components';
import PropTypes from 'prop-types';
import { funcWrap } from '../../../core';

const pick = makePick(['value'], pickDefault);

export const MuiInputRadioGroupContext = React.createContext(null);
MuiInputRadioGroupContext.displayName = 'MuiInputRadioGroupContext';

export const MuiInputRadioGroupItemContext = React.createContext(null);
MuiInputRadioGroupContext.displayName = 'MuiInputRadioGroupContext';

export const MuiRadioGroupItem = ({ children, value }) => {
	const { value: currentValue, setValue } = useContext(
		MuiInputRadioGroupContext
	);
	const selected = currentValue === value;
	const setSelected = useCallback(
		(checked) => {
			if (checked) setValue(value);
		},
		[setValue]
	);
	return (
		<MuiInputRadioGroupItemContext.Provider
			value={{ value, selected, setSelected }}
		>
			{children}
		</MuiInputRadioGroupItemContext.Provider>
	);
};

MuiRadioGroupItem.propTypes = { value: PropTypes.any };

const Component = ({ children, ...newProps }) => {
	const { value, onChange } = pick(newProps);
	return (
		<MuiInputRadioGroupContext.Provider value={{ value, setValue: onChange }}>
			{children}
		</MuiInputRadioGroupContext.Provider>
	);
};

Component.propTypes = {
	newProps: PropTypes.object
};

export const ic_radiogroup = {
	// eslint-disable-next-line react/display-name
	Component,
	getNewProps: (newProps) => {
		newProps.checked = newProps.value;
		// newProps.onChange = funcWrap(newProps.onChange, (onChange) => async (e) =>
		// 	onChange(e.target.checked)
		// );
		return newProps;
	}
};
