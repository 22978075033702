import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import NextLink from 'next/link';
import Link from '@material-ui/core/Link';
import ListItemText from '@material-ui/core/ListItemText';
import { Tr } from '@lsoft/translate';
import React, { Fragment, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import { createStyles, makeStyles } from '@material-ui/core';
import { MuiDrawerMenuItems } from './MuiDrawerMenu';

const useStyles = makeStyles((theme) =>
	createStyles({
		drawerMenuItemsNested: {
			paddingLeft: theme.spacing(1.5),
		},
		listItemButton: {
			padding: 0,
		},
		listItemLink: {
			color: 'inherit',
			display: 'block',
			height: '100%',
			padding: [
				[
					theme.spacing(1),
					theme.spacing(2),
					theme.spacing(1),
					theme.spacing(2),
				],
			],
			textDecoration: 'inherit',
			width: '100%',
			'&:hover': {
				color: 'inherit',
				textDecoration: 'inherit',
			},
		},
	})
);

const MuiDrawerMenuItem = (props) => {
	const { item } = props;

	switch (item.type) {
		case 'SUBMENU':
			return <MuiDrawerMenuItemSubMenu key={item.modulePath} item={item} />;
		case 'DIVIDER':
			return <Divider />;
		default:
			return <MuiDrawerMenuItemLink key={item.modulePath} item={item} />;
	}
};

MuiDrawerMenuItem.propTypes = {
	item: PropTypes.object,
};

const MuiDrawerMenuItemLink = (props) => {
	const { item } = props;
	const classes = useStyles(props);

	return (
		<ListItem className={clsx(classes.listItemButton)} button>
			<Link
				className={clsx(classes.listItemLink)}
				component={NextLink}
				href={item.linkTo}
				passHref
			>
				<ListItemText
					primary={<Tr tr={item.tr} />}
					primaryTypographyProps={{
						variant: 'body2',
					}}
				/>
			</Link>
		</ListItem>
	);
};

MuiDrawerMenuItemLink.propTypes = {
	item: PropTypes.object.isRequired,
};

const MuiDrawerMenuItemSubMenu = (props) => {
	const { item } = props;
	const classes = useStyles(props);
	const [open, setOpen] = useState(false);

	return (
		<Fragment>
			<ListItem button onClick={() => setOpen(!open)}>
				<ListItemText
					primary={<Tr tr={item.tr} />}
					primaryTypographyProps={{
						variant: 'subtitle2',
					}}
				/>
				<Fragment>{open ? <ExpandMoreIcon /> : <ChevronRightIcon />}</Fragment>
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List
					component={'div'}
					className={clsx(classes.drawerMenuItemsNested)}
					disablePadding
				>
					<MuiDrawerMenuItems menuObj={item} />
				</List>
			</Collapse>
		</Fragment>
	);
};

MuiDrawerMenuItemSubMenu.propTypes = {
	item: PropTypes.object.isRequired,
};

export { MuiDrawerMenuItem };
