import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { MuiSearchContext } from './MuiSearch';
import { includes } from 'lodash';
import { validateKeyEventCharacters } from '../core';
import clsx from 'clsx';

const handleOnKeyDown = (e, ctx, allowedCharacters, allowedShortCuts) => {
	if (!validateKeyEventCharacters(e, { allowedCharacters })) {
		e.preventDefault();
		return;
	}

	if (e.ctrlKey) {
		if (!includes(allowedShortCuts, e.keyCode)) {
			e.preventDefault();
			return;
		}
	}

	switch (e.key) {
		case 'Enter':
			e.preventDefault();
			ctx.search();
			ctx.setSearchStr('');
			break;
		case 'Tab':
			e.preventDefault();
			ctx.selectNextAutoComplete();
	}
};

const handlePaste = (e, ctx, allowedCharacters) => {
	e.preventDefault();
	e.persist();
	const text = e.clipboardData.getData('text');

	const reg = RegExp(allowedCharacters);

	for (let charIndex in text) {
		if (!reg.test(text[charIndex])) {
			return;
		}
	}
	const selectionStart = e.target.selectionStart;
	const selectionEnd = e.target.selectionEnd;
	const oldVal = ctx.searchStr;
	ctx.setSearchStr(
		oldVal.substring(0, selectionStart) +
			text +
			oldVal.substring(selectionEnd, oldVal.length),
	);
	return selectionStart + text.length;
};

const MuiSearchInput = ({
	allowedCharacters,
	allowedShortCuts,
	classes,
	...inputProps
}) => {
	const ctx = useContext(MuiSearchContext);
	return (
		<TextField
			{...inputProps}
			onFocus={(event) => {
				event.target.select();
			}}
			value={ctx.searchStr}
			onPaste={async (e) => {
				const newSelection = await handlePaste(e, ctx, allowedCharacters);
				e.target.setSelectionRange(newSelection, newSelection);
			}}
			onChange={(e) => {
				ctx.setSearchStr(e.target.value);
			}}
			onKeyDown={(e) => {
				return handleOnKeyDown(e, ctx, allowedCharacters, allowedShortCuts);
			}}
			color={'primary'}
			className={clsx(classes)}
		/>
	);
};

MuiSearchInput.propTypes = {
	allowedCharacters: PropTypes.string,
	allowedShortCuts: PropTypes.array,
};
// 65=a, 67=c, 76=l, 82=r, 86=v, 88=x
MuiSearchInput.defaultProps = {
	allowedCharacters: '[0-9A-Za-z_-\\s]',
	allowedShortCuts: [65, 67, 76, 82, 86, 88],
};

export { MuiSearchInput };
