import NextLink from 'next/link';

import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { MuiAppContext } from '../MuiApp';

const useStyles = makeStyles((theme) => ({
	fullWidth: {
		display: 'block',
		width: '100%',
	},
}));

const MuiLink = ({
	children,
	href,
	fullWidth,
	linkprops: realLinkProps,
	scrollToTop,
	...props
}) => {
	const classes = useStyles();
	const { scrollTop } = useContext(MuiAppContext);

	const onClick = () => {
		if (scrollToTop && _.isFunction(scrollTop)) {
			scrollTop();
		}
		const realLinkPropsOnClick = _.get(realLinkProps, 'onClick', () => {});
		realLinkPropsOnClick();
	};

	const linkprops = _.merge(
		{ href, color: 'primary', underline: 'none' },
		realLinkProps,
		{
			className: clsx(_.get(realLinkProps, 'className', null), {
				[classes.fullWidth]: fullWidth,
			}),
			onClick,
		}
	);

	// noinspection JSValidateTypes
	return (
		<Link component={NextLink} {...linkprops}>
			{children}
		</Link>
	);
};

MuiLink.propTypes = {
	fullWidth: PropTypes.bool,
	href: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	linkprops: PropTypes.object,
	scrollToTop: PropTypes.bool,
};
MuiLink.defaultProps = {
	fullWidth: false,
	scrollToTop: false,
};

export { MuiLink };
