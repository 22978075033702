// eslint-disable-next-line no-unused-vars
import React from 'react';

const autocomplete = async (ctx, searchStr) => {
	return [];
};

const search = async (ctx, searchStr) => {
	return searchStr;
};

export const makeSearchPlugin = () => {
	return {
		autocomplete,
		search,
	};
};
