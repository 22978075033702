export const controlKeys = [
	'Tab',
	'Shift',
	'Control',
	'ArrowLeft',
	'ArrowRight',
	'ArrowDown',
	'ArrowUp',
	'Backspace',
	'Enter',
	'Delete',
	'Insert',
	'Home',
	'End',
];
