export const MuiTableStyles = (theme) => ({
	MuiTable: {
		// tableLayout: 'fixed',
		borderCollapse: 'separate',
		'& thead': {
			zIndex: 10,
		},
		'& th': {
			background: theme.palette.grey[200],
			fontWeight: 700,

			borderTopColor: theme.palette.grey[400],
			borderTopWidth: 1,
			borterTopStyle: 'solid',

			borderBottomColor: theme.palette.grey[400],
			borderBottomWidth: 1,
			borterBottomStyle: 'solid',
		},
		'& tr:hover': {
			background: theme.palette.primary[50],
		},
		'& .error': {
			background: theme.palette.tableError.light,
			'&:hover': {
				background: theme.palette.tableError.dark,
			},
		},
		'& .disabled': {
			background: theme.palette.grey[300],
			opacity: 0.5,
			'&:hover': {
				background: theme.palette.grey[400],
			},
		},

		'& .success': {
			background: '#DFD',
			'&:hover': {
				background: '#BFB',
			},
		},

		'& .MuiTable-gridIcon svg ': {
			position: 'relative',
			top: 5,
		},
		'& th, td': {
			boxSizing: 'content-box',
			lineHeight: '30px',
			// paddingTop: theme.spacing(0.5),
			// paddingBottom: theme.spacing(0.5),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			whiteSpace: 'nowrap',
			// , border: '1px solid red'
			// minHeight: 43,
			// height: 43
		},
		'& tr td:first-child, th:first-child ': {
			paddingLeft: theme.spacing(1.5),
		},
		'& tr td:last-child, th:last-child': {
			paddingRight: theme.spacing(1.5),
		},
	},
	MuiTableMultiHeader: {
		'& th': {
			lineHeight: '20px',
		},
		'& thead tr:first-child th': {
			borderBottom: 0,
			marginBottom: 0,
			paddingBottom: 0,
		},
		'& thead tr:last-child th': {
			borderTop: 0,
			marginTop: 0,
			paddingTop: 0,
			paddingBottom: theme.spacing(1),
		},
	},
	MuiTableHeaderInfo: {
		'& th': {
			background: theme.palette.grey[200],
			borderBottom: 0,
			marginBottom: 0,
			paddingBottom: 0,
			paddingTop: theme.spacing(0.25),
			lineHeight: 1,
			// border: '1px solid blue'
		},
	},
	MuiTableStickyHeader: {
		'& th': {
			position: 'sticky',
			zIndex: 1300,
		},
	},
	MuiTableRowDisabled: {
		'& td': {
			background: theme.palette.grey[100],
			color: theme.palette.grey[500],
		},
	},
	MuiTableDenseColumns: {
		'& th, td': {
			paddingLeft: theme.spacing(0.25),
			paddingRight: theme.spacing(0.25),
		},
		'& tr td:first-child, th:first-child ': {
			paddingLeft: theme.spacing(1),
		},
		'& tr td:last-child, th:last-child': {
			paddingRight: theme.spacing(1),
		},
	},
});
