import React, { Fragment, useContext, useRef, useState } from 'react';
import { TextOrTr, WithTr, Tr } from '@lsoft/translate';
import { createStyles, makeStyles, TextField } from '@material-ui/core';
import { Keys } from 'react-keydown';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SyncIcon from '@material-ui/icons/Sync';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { trmLoginView } from '../translations/trmLoginView';
import PropTypes from 'prop-types';
import { MuiForm, MuiFormContext, MuiFormField } from '../MuiForms';
import { MuiAppContext } from './MuiAppContainer';
import { get } from 'lodash';
import { MuiGlobalComponent } from '../MuiGlobal';
import _ from 'lodash';
import Zoom from '@material-ui/core/Zoom';
import { Alert } from '@material-ui/lab';

const md5 = require('md5');

const LoginErrorContext = React.createContext({});
LoginErrorContext.displayName = 'LoginErrorContext';

const errorMapping = {
	UnknownError: {
		DE: 'Ein unerwarteter Fehler ist aufgetreten.',
		EN: 'An unexpected error has occurred.',
	},
	UsernameOrPasswordWrong: {
		DE: 'Das eingegebene aktuelle Passwort ist falsch.',
		EN: 'The current password entered is incorrect.',
		FR: 'Le mot de passe actuel saisi est incorrect.',
	},
};

const useStyles = makeStyles((theme) =>
	createStyles({
		loginView: {
			height: '100vh',
			width: '100%',
			textAlign: 'center',
		},
		rfidInput: {
			opacity: 0,
			height: 0,
			width: 0,
		},
		loginButton: {
			width: '100%',
		},
		textInput: {
			marginBottom: theme.spacing(2),
		},
		switchButton: {},
		divider: {
			margin: [[theme.spacing(2), theme.spacing(0)]],
		},
	})
);

const RfidLogin = (props) => {
	const { ENTER } = Keys;
	const classes = useStyles(props);
	const { handleSetError } = useContext(LoginErrorContext);

	const rfidLoginInput = useRef();
	return (
		<Fragment>
			<Typography variant={'h5'}>
				<TextOrTr>_tr:loginView.scanTag</TextOrTr>
			</Typography>
			<Box className={clsx(classes.rfidInput)}>
				<TextField
					autoFocus
					onKeyDown={async (e) => {
						if (e.keyCode === ENTER) {
							try {
								const ret = props.onLogin({
									RFID: e.target.value,
								});
								await ret;
							} catch (e) {
								handleSetError(e);
							}
							e.target.value = '';
						}
					}}
					onBlur={() => {
						// noinspection JSUnresolvedFunction
						rfidLoginInput.current.focus();
					}}
					placeholder={'RFID'}
					inputRef={rfidLoginInput}
				/>
			</Box>
		</Fragment>
	);
};
RfidLogin.propTypes = {
	onLogin: PropTypes.func,
};

const UsernameLogin = ({ theme, onLogin }) => {
	const classes = useStyles({ theme });
	const { handleSetError } = useContext(LoginErrorContext);

	const loginUserData = async (formData) => {
		try {
			const ret = await onLogin({
				USERNAME: formData.USERNAME,
				PASSWORD: md5(formData.PASSWORD),
			});
			return ret;
		} catch (e) {
			handleSetError(e);
		}
	};
	return (
		<MuiForm
			fieldGlobals={{
				inputProps: {
					size: 'small',
					styleVariant: 'outlined',
					shrink: false,
				},
			}}
			fields={{
				USERNAME: {
					default: '',
					variant: 'username',
					inputProps: {
						id: 'username',
						label: { EN: 'Username / E-Mail', DE: 'Nutzername / E-Mail' },
					},
				},
				PASSWORD: {
					default: '',
					variant: 'password_unvalidated',
					inputProps: {
						id: 'password',
						label: { EN: 'Password', DE: 'Passwort' },
					},
				},
			}}
		>
			<form>
				<Grid container direction={'column'} spacing={2}>
					<MuiFormContext.Consumer>
						{(context) => (
							<Fragment>
								<Grid item>
									<MuiFormField
										name={'USERNAME'}
										onEnter={() => {
											if (context.valid) {
												loginUserData(context.formData);
											}
										}}
									/>
								</Grid>
								<Grid item>
									<MuiFormField
										name={'PASSWORD'}
										onEnter={() => {
											if (context.valid) {
												loginUserData(context.formData);
											}
										}}
									/>
								</Grid>
								<Grid item>
									<Button
										className={clsx(classes.loginButton)}
										// disableElevation
										onClick={() => loginUserData(context.formData)}
										size={'large'}
										variant={'contained'}
										color={'primary'}
										disabled={!context.valid}
									>
										<TextOrTr>_tr:loginView.login</TextOrTr>
									</Button>
								</Grid>
							</Fragment>
						)}
					</MuiFormContext.Consumer>
				</Grid>
			</form>
		</MuiForm>
	);
};
UsernameLogin.propTypes = {
	onLogin: PropTypes.func,
};
UsernameLogin.defaultProps = {
	onLogin: (d) => {
		console.log('onLogin', d);
	},
};

const MuiAppDefaultLoginView = ({ theme }) => {
	const classes = useStyles({ theme });
	const muiApp = useContext(MuiAppContext);
	const rfidLogin = get(muiApp, 'loginInfo.mode', 'rfid') === 'rfid';
	// const rfidLogin = true;
	const buttonTr = rfidLogin
		? '_tr:loginView.loginPassword'
		: '_tr:loginView.loginRfid';

	const InnerComponent = rfidLogin ? RfidLogin : UsernameLogin;

	const [errorState, setErrorState] = useState(null);

	const handleSetError = (e) => {
		const errorClass = _.get(e, 'data.ERROR_CLASS', null);
		setErrorState(null);
		if (errorClass === 'AuthInvalidLogin') {
			setErrorState('UsernameOrPasswordWrong');
		} else {
			setErrorState('UnknownError');
			throw e;
		}
	};

	return (
		<LoginErrorContext.Provider value={{ handleSetError }}>
			<WithTr trms={[trmLoginView]}>
				<Grid
					container
					className={clsx(classes.loginView)}
					justifyContent={'center'}
					alignItems={'center'}
					direction={'column'}
					spacing={4}
				>
					<Grid item>
						<Typography component={'h1'} variant={'h3'}>
							{get(muiApp, 'appInfo.title', 'no title')}
						</Typography>
						<Divider className={clsx(classes.divider)} />
						<Typography variant={'body2'}>
							Version: {get(muiApp, 'appInfo.version', 'no version')}
						</Typography>
					</Grid>
					<Grid item>
						<InnerComponent onLogin={muiApp.login} />
					</Grid>
					<Grid item>
						<Button
							className={classes.switchButton}
							startIcon={<SyncIcon />}
							variant={'outlined'}
							onClick={() => {
								setErrorState(null);
								return muiApp.setLoginInfoMode(rfidLogin ? 'username' : 'rfid');
							}}
						>
							<Typography variant={'caption'}>
								<TextOrTr>{buttonTr}</TextOrTr>
							</Typography>
						</Button>
					</Grid>{' '}
					<Grid item style={{ height: 56 }}>
						{!_.isNil(errorState) && (
							<Zoom in={true}>
								<Typography align={'center'} color={'error'}>
									<Tr tr={errorMapping[errorState]} />
								</Typography>
							</Zoom>
						)}
					</Grid>
				</Grid>
			</WithTr>
		</LoginErrorContext.Provider>
	);
};

export const MuiAppLoginView = MuiGlobalComponent(
	'_loginview',
	MuiAppDefaultLoginView
);
