import React, { useContext } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import { MuiSearchBarContext } from './MuiSearchBarContainer';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { getTranslation } from '@lsoft/translate';
import { useRouter } from 'next/router';
import { validateKeyEventCharacters } from '@lsoft/mui';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';

const useStyles = makeStyles((theme) => ({
	MuiSearchInput: {
		alignItems: 'center',
		boxShadow: 'none',
		background: grey[100],
		display: 'flex',
		maxWidth: '100%',
		[theme.breakpoints.up('md')]: {
			width: 600,
			margin: 0,
		},
		[theme.breakpoints.up('lg')]: {
			width: 800,
		},
		'&.autocompleteOpen': {
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
		},
	},
	autocompleteIsOpen: {
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
	input: {
		fontSize: '0.875rem',
		marginLeft: theme.spacing(1),
		flex: 1,
		background: 'transparent',
		border: 'none',
	},
	IconButton: {
		background: grey[100],
		'&:hover': {
			background: grey[200],
		},
	},
}));

const MuiSearchInput = ({ placeholder }) => {
	const classes = useStyles();
	const { locale } = useRouter();
	const throwSnackbar = useShopSnackbar();
	const {
		autocomplete,
		search,
		search_string,
		setSearchString,
		focusAutocomplete,
		onFocus,
	} = useContext(MuiSearchBarContext);

	const autocomplete_is_open = _.isEmpty(autocomplete);
	const allowed_characters = '[0-9A-Za-z\\s+!(){}[\\]^\'"~*?:\\\\/-]';
	const onSubmit = (e) => {
		e.preventDefault();
		search();
	};

	const onChange = (e) => {
		setSearchString(e.target.value);
	};

	return (
		<Paper
			component={'form'}
			className={clsx(classes.MuiSearchInput, {
				[classes.autocompleteIsOpen]: autocomplete_is_open,
			})}
		>
			<input
				className={classes.input}
				id={'searchBar'}
				placeholder={getTranslation(placeholder, locale)}
				value={search_string}
				onFocus={onFocus}
				onChange={onChange}
				onKeyDown={(e) => {
					if (
						!validateKeyEventCharacters(e, {
							allowedCharacters: allowed_characters,
						})
					) {
						e.preventDefault();
						throwSnackbar({
							msg: getTranslation(
								{
									DE: 'Bitte geben Sie nur alphanummerische Zeichen ein (A-Z, a-z, 0-9).',
									EN: 'Only alphanumeric characters (A-Z, a-z, 0-9) allowed.',
									FR: 'Veuillez saisir uniquement des caractères alphanumériques (A-Z, a-z, 0-9).',
								},
								locale,
							),
							variant: 'warning',
						});
						return;
					}

					if (e.key === 'Tab' || e.key === 'ArrowDown') {
						e.preventDefault();
						e.stopPropagation();
						focusAutocomplete();
					}
				}}
				spellCheck={'false'}
				onSubmit={onSubmit}
			/>
			<IconButton type={'submit'} onClick={onSubmit}>
				<SearchIcon />
			</IconButton>
		</Paper>
	);
};

MuiSearchInput.propTypes = {
	placeholder: PropTypes.object,
};
MuiSearchInput.defaultProps = {
	placeholder: {
		DE: 'Suchbegriff eingeben',
		EN: 'Enter search term',
		FR: 'Entrez un terme de recherche',
	},
};

export { MuiSearchInput };
