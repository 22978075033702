import _ from 'lodash';

/**
 getBasePath
 Returns the base path by path
 */
const getBasePath = (pathStr) => {
	const sp = _.toPath(pathStr);
	const basePath = _.slice(sp, 0, sp.length - 1).join('.');
	return basePath;
};

/**
 prepareItems
 Add bathPath to all items
 Add items=[] if item is type===SUBMENU
 */
const prepareItems = (items) => {
	return items.map((i) => {
		i.basePath = i.type === 'DIVIDER' ? i.path : getBasePath(i.path);
		if (i.type === 'SUBMENU') i.items = [];
		return i;
	});
};

/**
 getLinkItems
 Returns items which are not type=SUBMENU
 */
const getLinkItems = (items) => {
	return items.filter((i) => i.type !== 'SUBMENU');
};

/**
 reduceItemsActive
 Returns only the items which are active
 */
const reduceItemsActive = (items, listActive) => {
	return items.filter((item) => {
		if (item.type === 'DIVIDER') return true;
		return !(_.indexOf(listActive, item.path) === -1);
	});
};

/**
 reduceItemsRestricted
 Returns only the items which are not resticted
 */
const reduceItemsRestricted = (items, scopes) => {
	return items.filter((item) => {
		//No scopes mentioned? return this item!
		if (_.get(item, 'scopes', []).length === 0) return true;
		//some brainfuck, but works, it looks for not matching scopes/modes and returns otherwise fulfilled
		return !_.some(item.scopes, (scope) => {
			if (!scope.includes(':')) scope = scope + ':r';
			//for example "amAdmin:RW" ->scope=ar, mode=RW
			const [ar, mode] = scope.split(':');
			//for example scopes={amAdmin:"RW"}
			const scopeMatch = !(ar in scopes);
			if (scopeMatch) return true;
			const modeMatch = !scopes[ar].startsWith(mode);
			if (modeMatch) return true;
			return false;
		});
	});
};

/**
 getCombinedItems
 Combines activeItems and allItems to a list
 */
const getCombinedItems = (activeItems, allItems) => {
	let activePaths = {};
	let combinedItems = [];
	activeItems.map((i) => {
		let path = [];
		_.toPath(i.path).map((node) => {
			path.push(node);
			activePaths[_.join(path, '.')] = true;
		});
	});
	allItems.map((i) => {
		if (_.has(activePaths, i.path)) combinedItems.push(i);
	});
	return combinedItems;
};

/**
 removeRedundantStartEndDivider
 Remove type=DIVIDER which are at the start or end
 */
const removeRedundantStartEndDivider = (items) => {
	let changed = false;
	if (items.length !== 0) {
		if (items[0].type === 'DIVIDER') {
			items.shift();
			changed = true;
		}
		if (items[items.length - 1].type === 'DIVIDER') {
			items.pop();
			changed = true;
		}
	}
	return changed;
};

/**
 removeDuplicatedDivider
 Remove duplicate entries for type=DIVIDER
 */
const removeDuplicatedDivider = (items) => {
	let ret = [];
	let dividerFound = false;
	items.map((i) => {
		if (!(dividerFound && i.type === 'DIVIDER')) {
			ret.push(i);
			dividerFound = i.type === 'DIVIDER';
		}
	});
	return ret;
};

/**
 removeRedundantDividers
 Calls removeRedundantStartEndDivider and removeDuplicatedDivider
 Remove redundant dividers
 */
const removeRedundantDividers = (items) => {
	while (removeRedundantStartEndDivider(items)) {
		removeRedundantStartEndDivider(items);
	}
	return removeDuplicatedDivider(items);
};

/**
 generateMenuTreeItemsRecursive
 Builds the menu tree
 */
const generateMenuTreeItemsRecursive = (root, combinedItems) => {
	const filteredItems = combinedItems.filter((i) => i.basePath === root);
	const subItems = filteredItems.map((i) => {
		if (i.type === 'DIVIDER') return i;
		if (i.type !== 'SUBMENU') return i;
		i.items = generateMenuTreeItemsRecursive(i.path, combinedItems);
		return i;
	});
	return removeRedundantDividers(subItems);
};

/**
 muiGetMenuTree
 Builds and returns the cleaned tree for rendering
 */
const getMuiDrawerMenuTree = (menuEntries, listActive, scopes) => {
	// 1. Prepare menu entries
	const preparedMenuEntries = prepareItems(menuEntries);
	// 2. Get menu entries
	let linkItems = getLinkItems(preparedMenuEntries);
	// 3. Reduce menu entries -> active / restriced
	linkItems = reduceItemsActive(linkItems, listActive);
	linkItems = reduceItemsRestricted(linkItems, scopes);
	// 3. Generate combined items
	const combinedItems = getCombinedItems(linkItems, preparedMenuEntries);
	// 4. Generate and return menuTree
	return _.get(generateMenuTreeItemsRecursive('', combinedItems), '[0]', {
		items: [],
	});
};

const getMuiDrawerMenuApplicationModules = (menuEntries) => {
	if (_.isNil(menuEntries)) return [];
	// 1. Prepare menu entries
	const preparedMenuEntries = prepareItems(menuEntries);
	// 2. Get menu entries
	const application_modules = getLinkItems(preparedMenuEntries)
		.filter((i) => i.type !== 'DIVIDER')
		.map((i) => i.path);
	return application_modules;
};

export {
	prepareItems,
	getLinkItems,
	reduceItemsActive,
	reduceItemsRestricted,
	getCombinedItems,
	removeRedundantDividers,
	generateMenuTreeItemsRecursive,
	getMuiDrawerMenuTree,
	getMuiDrawerMenuApplicationModules,
};
