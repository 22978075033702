import _ from 'lodash';
import { shop_customer_contact_verify } from '@lsoft/shared/backend/shop/shop';
import { base64_decode } from '@lsoft/shared/functions/base64';

const debug_log = false;

const makeError = (ERROR_CLASS, ERROR) => {
	const ret = {
		successful: false,
		error: {
			ERROR_CLASS,
			ERROR,
		},
		appProps: null,
	};
	if (debug_log) console.log('error', ret);
	return ret;
};

export const decodeVerificationHash = (hash) => {
	let ret = {
		EMAIL: null,
		ACCOUNT_VERIFICATION_CODE: null,
	};
	if (_.isNil(hash)) return ret;

	const hash_split = base64_decode(hash).split(['|']);
	if (hash_split.length !== 2) return ret;

	const email = hash_split[0].trim();
	const avc = hash_split[1].trim();
	ret = { EMAIL: email, ACCOUNT_VERIFICATION_CODE: avc };

	return ret;
};

export const customerContactVerify = async (
	webapi,
	appName,
	EMAIL,
	ACCOUNT_VERIFICATION_CODE
) => {
	if (_.isNil(EMAIL) || _.isNil(ACCOUNT_VERIFICATION_CODE)) {
		return makeError(
			'INVALID_EMAIL_OR_ACCOUNT_VERIFICATION_CODE',
			'Invalid EMAIL or ACCOUNT_VERIFICATION_CODE'
		);
	}

	try {
		const { session, am_auth_token } = await shop_customer_contact_verify({
			app_name: appName,
			account_verification_code: ACCOUNT_VERIFICATION_CODE,
			email: EMAIL,
		});

		if (_.isNil(session) || _.isNil(am_auth_token))
			return makeError('SESSION_OR_TOKEN_NULL', 'Session or token null');

		return {
			successful: true,
			error: null,
			appProps: {
				session,
				am_auth_token,
				cookies: { _am_auth_token: am_auth_token },
			},
		};
	} catch (e) {
		const error_class = _.get(e, 'ERROR_CLASS', 'UNKNOWN_ERROR');
		const error_message = _.get(e, 'ERROR', 'No message.');

		return makeError(error_class, error_message);
	}
};
