import React, { Fragment, useContext } from 'react';
// noinspection ES6UnusedImports
import PropTypes from 'prop-types'; // eslint-disable-line no-unused-vars
// noinspection ES6UnusedImports
import clsx from 'clsx'; // eslint-disable-line no-unused-vars
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import LanguageIcon from '@material-ui/icons/Language';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import Menu from '@material-ui/core/Menu';
import { Tr, WithTr } from '@lsoft/translate';
import { MuiSearchWidget } from './MuiSearchWidget';
import { createStyles, makeStyles } from '@material-ui/core';
import NextLink from 'next/link';
import Link from '@material-ui/core/Link';
import _ from 'lodash';
import Cookies from 'universal-cookie';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { trmLanguages } from '../../translations';
import { MuiAppContext } from '../MuiAppContainer';
import { MuiGlobalComponent } from '../../MuiGlobal';
import { useRouter } from 'next/router';
import { AmContext } from '@lsoft/shared/components/am/AmContext';

const useStyles = makeStyles((theme) =>
	createStyles({
		appBar: {
			// zIndex: theme.zIndex.drawer + 1,
			border: 'solid red 1',
		},
		projectLink: {
			color: 'inherit',
			display: 'block',
			height: '100%',
			// padding: [
			// 	[theme.spacing(1), theme.spacing(2), theme.spacing(1), theme.spacing(2)]
			// ],
			textDecoration: 'inherit',
			width: '100%',
			'&:hover': {
				color: 'inherit',
				textDecoration: 'inherit',
			},
		},
		projectId: {
			display: 'inline-block',
			width: theme.sizes.drawerWidth,
		},
		projectName: {
			display: 'inline-block',
			marginRight: theme.spacing(1),
		},
		accountAvatarIcon: {
			padding: theme.spacing(1),
		},
		accountAvatar: {
			fontSize: '1rem',
			height: theme.spacing(4),
			width: theme.spacing(4),
		},
		translateIcon: {
			height: theme.spacing(3),
			width: theme.spacing(3),
		},
	}),
);

export const MuiDefaultAppBarTranslation = ({ theme }) => {
	const { locale, locales, asPath, push } = useRouter();
	const classes = useStyles({ theme });
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const getOptions = () => {
		const tr = _.get(trmLanguages, 'tr.language');
		return locales
			.filter((lc) => lc !== locale)
			.map((lc) => {
				return (
					<MenuItem
						key={lc}
						onClick={() => {
							const cookie = new Cookies();
							let expires = new Date();
							expires.setDate(new Date().getDate() + 1825);
							cookie.set('lang', lc, {
								expires,
								path: '/',
								sameSite: 'strict',
							});
							push(asPath, asPath, { locale: lc }).then(() => {
								handleClose();
							});
						}}
					>
						<Typography>
							<Tr tr={tr.Languages[lc.toUpperCase()]} />
						</Typography>
					</MenuItem>
				);
			});
	};

	return (
		<Fragment>
			<WithTr trms={[trmLanguages]}>
				<IconButton color={'inherit'} onClick={handleClick}>
					<LanguageIcon className={classes.translateIcon} />
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					getContentAnchorEl={null}
				>
					<MenuItem disabled>
						<Typography variant={'caption'} align={'center'}>
							Select a language
						</Typography>{' '}
					</MenuItem>
					{getOptions()}
				</Menu>
			</WithTr>
		</Fragment>
	);
};

export const MuiDefaultAppBarAccount = (props) => {
	const classes = useStyles(props);
	const amContext = useContext(AmContext);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const getInitials = () => {
		const currentUser = _.get(amContext, 'currentUser', '');
		if (!_.isNull(currentUser)) {
			const nameParts = currentUser.split(' ');
			const initials = nameParts.map((part) => part.charAt(0));
			return initials.join('');
		} else {
			return '';
		}
	};
	const muiApp = useContext(MuiAppContext);

	return (
		<Fragment>
			<IconButton
				className={clsx(classes.accountAvatarIcon)}
				onClick={handleClick}
			>
				<Avatar
					className={clsx(classes.accountAvatar)}
					alt={amContext.currentUser}
				>
					{getInitials()}
				</Avatar>
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				getContentAnchorEl={null}
			>
				<MenuItem disabled>
					<Typography variant={'caption'} align={'center'}>
						Hello {amContext.currentUser}
					</Typography>{' '}
				</MenuItem>
				<MenuItem onClick={() => muiApp.logout()}>
					<ListItemIcon>
						<MeetingRoomIcon />
					</ListItemIcon>
					<Typography>Logout</Typography>
				</MenuItem>
			</Menu>
		</Fragment>
	);
};

const MuiDefaultAppBar = (props) => {
	const muiApp = useContext(MuiAppContext);
	const classes = useStyles(props);
	const renderDocumentName = () => {
		if (props.documentName === null) return null;
		return ` - ${props.documentName}`;
	};
	// noinspection JSValidateTypes
	return (
		<AppBar className={clsx(classes.appBar, 'z-appbar')} position={'static'}>
			<Toolbar>
				<Grid
					container
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Grid item>
						<Grid container>
							<Grid item>
								<div className={clsx(classes.projectId)}>
									<Link
										component={NextLink}
										className={clsx(classes.projectLink)}
										href={'/'}
										passHref
									>
										<Typography
											component={'h1'}
											variant={'h6'}
											color={'inherit'}
											className={clsx(classes.projectName)}
										>
											{muiApp.appInfo.title}
										</Typography>
										<Typography
											component={'span'}
											variant={'caption'}
											color={'inherit'}
										>
											{muiApp.appInfo.version}
										</Typography>
									</Link>
								</div>
							</Grid>
							<Grid item>
								<Typography component="h1" variant="h6" color="inherit" noWrap>
									<Tr tr={props.title} />
									{renderDocumentName()}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid
							alignItems={'center'}
							container
							direction={'row'}
							justifyContent={'flex-end'}
						>
							<Grid item>
								<MuiSearchWidget />
							</Grid>
							<Grid item>
								<MuiDefaultAppBarTranslation />
							</Grid>
							<Grid item>
								<MuiDefaultAppBarAccount />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

MuiDefaultAppBar.propTypes = {
	title: PropTypes.object,
	documentName: PropTypes.any,
};

export const MuiAppBar = MuiGlobalComponent('_appbar', MuiDefaultAppBar);
