// noinspection DuplicatedCode

import { lsfastapi } from '@lsoft/lsfastapi-client';
import { payment_api } from '@lsoft/payment-api-client';
import { elasticsearch_api } from '@lsoft/elasticsearch-api-client';
import { tax_rate_api } from '@lsoft/tax-rate-api-client';
import { google_maps_api } from '@lsoft/google-maps-api-client';
import { erp_api } from '@lsoft/erp-api-client';
import { global_api } from '@lsoft/global-api-client';
import { marketing_api } from '@lsoft/marketing-api-client';
import { shop_api } from '@lsoft/shop-api-client';
import { pim_api } from '@lsoft/pim-api-client';
import { useIsServer } from '@lsoft/shared/hooks/useIsServer';
import { render_api } from '@lsoft/render-api-client';
import { application_modules_api } from '@lsoft/application-modules-api-client';

// eslint-disable-next-line react-hooks/rules-of-hooks
const isServer = useIsServer();

const api_baseurl = isServer ? process.env.NEXT_PUBLIC_API_BASEURL : '';

lsfastapi.add_api(pim_api, {
	baseurl: api_baseurl + '/api/v2/pim',
});

lsfastapi.add_api(render_api, {
	baseurl: api_baseurl + '/api/v2/render',
});
lsfastapi.add_api(shop_api, {
	baseurl: api_baseurl + '/api/v2/shop',
});

lsfastapi.add_api(elasticsearch_api, {
	baseurl: api_baseurl + '/api/v2/elasticsearch',
});

lsfastapi.add_api(tax_rate_api, {
	baseurl: api_baseurl + '/api/v2/tax_rate',
});

lsfastapi.add_api(payment_api, {
	baseurl: api_baseurl + '/api/v2/payment',
});

lsfastapi.add_api(google_maps_api, {
	baseurl: api_baseurl + '/api/v2/google_maps',
});
//url must be one url of the erp-api-ingress doesnt matter which
lsfastapi.add_api(erp_api, {
	baseurl: api_baseurl + '/api/v2/order',
});

lsfastapi.add_api(marketing_api, {
	baseurl: api_baseurl + '/api/v2/marketing',
});

lsfastapi.add_api(global_api, {
	baseurl: api_baseurl + '/api/v2/global',
});

lsfastapi.add_api(application_modules_api, {
	baseurl: api_baseurl + '/api/v2/application_modules',
});

// lsfastapi.add_api(product_info_api, {
// 	baseurl: api_baseurl + '/api/v2/product_info',
// });

export const webapi = { lsfastapi };
