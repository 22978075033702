import { MuiGlobalComponent } from '../MuiGlobal';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tr } from '@lsoft/translate';

const MuiDefaultModuleNotEnabledError = ({ paths }) => {
	return (
		<Fragment>
			<h2>
				<Tr tr={{ EN: 'Module not enabled', DE: 'Modul nicht aktiviert' }} />
			</h2>
			<div>
				<Tr tr={{ EN: 'Module', DE: 'Modul' }} />
			</div>
			<ul>
				{paths.map((p) => (
					<li key={p}>{p}</li>
				))}
			</ul>
		</Fragment>
	);
};
MuiDefaultModuleNotEnabledError.propTypes = {
	paths: PropTypes.array,
};

MuiDefaultModuleNotEnabledError.defaultProps = {
	paths: [],
};

export const MuiModuleNotEnabledError = MuiGlobalComponent(
	'_modulenotenablederror',
	MuiDefaultModuleNotEnabledError
);
