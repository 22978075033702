import React, { useContext } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import { Tr } from '@lsoft/translate';
import { ShopModeContext } from './ShopMode';
import { Container, makeStyles, Paper, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
	AlertRoot: {
		borderRadius: 0,
		'& a': {
			color: 'rgb(102, 60, 0)',
			fontWeight: 'bold',
		},
	},
}));

const tr_online_shop_in_catalog_mode = {
	DE: 'Der Shop befindet sich momentan im Katalogmodus. Es ist nicht möglich Ware in den Warenkorb zu legen oder Bestellungen abzuschließen. Sie können alle Produkte anschauen und in unserem Sortiment stöbern.',
	EN: 'The online store is currently in catalog mode. It is not possible to add goods to the shopping cart or complete orders. You can view all products and browse our assortment.',
	FR: "La boutique est actuellement en mode catalogue. Il n'est pas possible possible d'ajouter des marchandises au panier ou de passer des commandes. Vous pouvez voir tous les produits et parcourir notre assortiment.",
};
const tr_important_mode = {
	DE: 'Wichtiger Hinweis',
	EN: 'Important note',
	FR: 'Note importante',
};
const tr_we_apologise_for_the_inconvenience = {
	DE: 'Wir entschuldigen uns für die Unannehmlichkeiten und bemühen uns, Ihnen die gewohnten Funktionen so schnell wie möglich wieder zur Verfügung zu stellen.',
	EN: 'We apologise for the inconvenience and will endeavour to restore the usual functions to you as soon as possible.',
	FR: 'Nous nous excusons pour la gêne occasionnée et nous nous efforcerons de rétablir les fonctions habituelles dès que possible.',
};
const tr_in_the_meantime = {
	DE: 'In der Zwischenzeit können Sie über unser <a target="_blank" rel="noopener" href="https://letzshop.lu/de/vendors/ltoys">Profil bei letzshop.lu</a> oder auch in dem Onlineshop unseres Partners <a target="_blank" rel="noopener" href="https://www.electronic-shop.lu/category/1.2.9028568">electronic-shop.lu</a> einkaufen.',
	EN: 'In the meantime, you can shop via our <a target="_blank" rel="noopener" href="https://letzshop.lu/de/vendors/ltoys">profile at letzshop.lu</a> or you can use the the online shop of our partner <a target="_blank" rel="noopener" href="https://www.electronic-shop.lu/category/1.2.9028568">electronic-shop.lu</a>.',
	FR: 'En attendant, vous pouvez faire vos achats via notre <a target="_blank" rel="noopener" href="https://letzshop.lu/de/vendors/ltoys">profil sur letzshop.lu</a> ou utiliser la boutique en ligne de notre partenaire <a target="_blank" rel="noopener" href="https://www.electronic-shop.lu/category/1.2.9028568">electronic-shop.lu</a>.',
};

export const OrdersDisabledAlert = () => {
	const classes = useStyles();
	const { disable_customer_order } = useContext(ShopModeContext);
	const disableGutters = useMediaQuery((theme) =>
		theme.breakpoints.down(theme.breakpoints.CSSContainerDisableGutters)
	);
	if (!disable_customer_order) return null;
	return (
		<Container disableGutters={disableGutters}>
			<Paper>
				<Alert
					variant={'standard'}
					severity={'warning'}
					className={clsx(classes.AlertRoot)}
				>
					<AlertTitle>
						<Tr tr={tr_important_mode} />
					</AlertTitle>
					<Typography gutterBottom variant={'body2'}>
						<Tr tr={tr_online_shop_in_catalog_mode} />
						&nbsp;
						<Tr tr={tr_we_apologise_for_the_inconvenience} />
					</Typography>
					<Typography gutterBottom variant={'body2'}>
						<Tr tr={tr_in_the_meantime} html />
					</Typography>
				</Alert>
			</Paper>
		</Container>
	);
};
