// export const generateId = (
// 	prefix = null,
// 	length = 10,
// 	alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
// ) => {
// 	const numid = () => {
// 		const date = new Date().getTime();
// 		return (
// 			Math.floor(parseInt(date) / 1000) * 10000 +
// 			Math.floor(Math.random() * 10000)
// 		);
// 	};
//
// 	const hashids = new Hashids('', length, alphabet);
//
// 	if (prefix != null) {
// 		return prefix + '-' + hashids.encode(numid());
// 	}
//
// 	return hashids.encode(numid());
// };
import _ from 'lodash';

export const numTime = () => {
	const date = new Date().getTime();
	return Math.floor(parseInt(date) / 1000);
};

export const hexTime = () => pad(numTime(), 16, 8);

export const hexTimeBubble = () => {
	const t = hexTime();
	return [7, 1, 5, 3, 4, 2, 6, 0].map((i) => t.charAt(i)).join('');
};

export const b32randomtime = () => {
	let rnd = Math.floor(Math.random() * 256);
	const ret = hexTime()
		.split('')
		.map((c) => {
			const r = (rnd & 1) * 16 + parseInt(c, 16);
			rnd = rnd >> 1;
			return r.toString(32).toUpperCase();
		})
		.join('');
	return ret;
};

export const numid = () => {
	return numTime() * 10000 + Math.floor(Math.random() * 10000);
};

const pad = (num, radix, size) => {
	num = num.toString(radix);
	while (num.length < size) num = '0' + num;
	return num.toUpperCase();
};

export const b32random = (length) => {
	let r = null;
	let ret = '';
	while (length > 0) {
		r = pad(Math.floor(Math.random() * 1048575), 32, 5);
		ret += length >= 5 ? r : r.substring(r.length - length);
		length -= 5;
	}
	return ret.toUpperCase();
};

const pop = (a) => {
	if (a.length === 0) return null;
	return _.pullAt(a, [0])[0];
};

export const b32id = (length) => {
	const t = b32randomtime().split('');
	const r = b32random(length - t.length).split('');
	let toggle = true;
	const ret = _.range(length).map(() => {
		if (toggle) {
			if (t.length !== 0) toggle = !toggle;
			return pop(r);
		}
		if (!toggle) {
			if (r.length !== 0) toggle = !toggle;
			return pop(t);
		}
	});
	return ret.join('');
};

export const idBlocks = (id, length = 4) => {
	let r = '';
	let i = 0;
	while (i < id.length) {
		if (i % length === 0 && i !== 0) r += '-';
		r += id.charAt(i);

		i++;
	}
	return r;
};

export const generateId = (prefix = null, length, blocks = true) => {
	const id = b32id(length);
	const hashed = blocks ? idBlocks(id, 4) : id;
	return prefix === null ? hashed : `${prefix}-${hashed}`;
};
