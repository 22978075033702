import _ from 'lodash';
import React, { useEffect, useState } from 'react';

export const DataContainer = ({ children, data }) => {
	const [newData, setNewData] = useState(data);

	useEffect(() => {
		// if data is a function
		if (_.isFunction(newData) || newData instanceof Promise) {
			const as = async () => {
				const result = await data();
				setNewData(result);
			};
			as();
		}
	}, [data]);

	if (_.isNil(data)) return null;
	if (!_.isObject(children)) return null;
	if (newData instanceof Promise) return null;
	return React.cloneElement(children, {
		data: newData,
		setDataContainerData: setNewData,
	});
};
