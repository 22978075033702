import _ from 'lodash';
import { generateSig } from './generateSig';
import Cookies from 'cookies';

export const getSetCookieStr = (name, value, options) => {
	const { expires, path, sameSite, maxAge } = options;
	let cookieStr = `${name}=${value};`;
	if (!_.isNil(path)) cookieStr += ` Path=${path};`;
	if (!_.isNil(sameSite)) cookieStr += ` SameSite=${sameSite};`;
	if (!_.isNil(expires)) cookieStr += ` Expires=${expires.toUTCString()}`;
	if (!_.isNil(maxAge)) cookieStr += ` Max-Age=${maxAge}`;
	return cookieStr;
};

export const setResponseCookie = (res, req, name, value, options) => {
	const cookies = new Cookies(req, res);
	cookies.set(name, value, options);
	// res.setHeader('Set-Cookie', getSetCookieStr(name, value, options));
};

export const generateAndSetClientIdIfMissing = (res, req, existing_cookies) => {
	const { _webapi_client_id } = existing_cookies;

	// if (_.isNil(_webapi_ca)) return existing_cookies; // Removed to prevent session/client id bug #207
	const newCookies = _.assign({}, existing_cookies, {
		_webapi_client_id: generateSig('CID'),
	});
	if (!_.isNil(_webapi_client_id)) {
		newCookies['_webapi_client_id'] = _webapi_client_id;
	}

	setResponseCookie(
		res,
		req,
		'_webapi_client_id',
		newCookies._webapi_client_id,
		{
			maxAge: Date.now() + 86400 * 1461,
			sameSite: 'lax',
			path: '/',
			httpOnly: false,
		},
	);
	return newCookies;
};

export const setWebapiCookieAcceptDefaultIfMissing = (
	res,
	req,
	existing_cookies,
) => {
	const { _webapi_ca } = existing_cookies;
	const newCookies = _.assign({}, existing_cookies, {
		_webapi_ca: '0',
	});
	if (!_.isNil(_webapi_ca)) {
		newCookies['_webapi_ca'] = _webapi_ca;
	}
	setResponseCookie(res, req, '_webapi_ca', newCookies._webapi_ca, {
		maxAge: Date.now() + 86400 * 1461,
		sameSite: 'lax',
		path: '/',
		httpOnly: false,
	});
	return newCookies;
};
