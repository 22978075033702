// eslint-disable-next-line no-unused-vars
export const MuiAccordionStyles = (theme) => ({
	MuiAccordion: {
		'& .MuiAccordionSummary-root': {
			height: theme.spacing(5),
			minHeight: theme.spacing(5),
		},
		'& .MuiAccordionSummary-content': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		'& .MuiAccordionDetails-root': {
			paddingLeft: theme.spacing(6),
			paddingTop: theme.spacing(0.5),
			paddingBottom: theme.spacing(0.5),
		},
	},
	MuiAccordionSummaryIcon: {
		height: theme.spacing(3),
		width: theme.spacing(4),
	},
});
