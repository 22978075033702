const MuiSnackbarProps = {
	autoHideDuration: 3000,
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left',
	},
	dense: true,
};

export { MuiSnackbarProps };
