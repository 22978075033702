import React, { useContext } from 'react';
import { MuiPaginationContext } from './MuiPaginationContext';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { Tr } from '@lsoft/translate';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) =>
	createStyles({
		button: {
			width: 200,
		},
		buttonProgress: {
			color: theme.palette.primary[500],
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	})
);

const MuiPaginationLoadMore = (props) => {
	const classes = useStyles(props);
	const { view, loading, error, loadMore, isLastPage } = useContext(
		MuiPaginationContext
	);
	if (_.isNil(loading) || _.isNil(view) || isLastPage || !_.isNil(error)) {
		return null;
	}

	const renderIcon = () => {
		if (!loading) return null;
		return (
			<CircularProgress size={24} className={clsx(classes.buttonProgress)} />
		);
	};

	return (
		<Button
			variant={'contained'}
			color={'primary'}
			className={clsx(classes.button, props.className)}
			disabled={loading}
			onClick={() => loadMore()}
		>
			{renderIcon()}
			{props.buttonText}
		</Button>
	);
};
MuiPaginationLoadMore.propTypes = {
	buttonText: PropTypes.any,
	className: PropTypes.any,
};

MuiPaginationLoadMore.defaultProps = {
	buttonText: <Tr tr={{ EN: 'Load more', DE: 'Mehr laden' }} />,
	className: null,
};

export { MuiPaginationLoadMore };
