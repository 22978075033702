import React, { useContext } from 'react';
import AccountIcon from '@material-ui/icons/Person';
import { MuiAppContext, MuiLink } from '@lsoft/mui';
import { HeaderIconPortal, HeaderIconPortalPaper } from './HeaderIconPortal';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { getTranslation, Tr } from '@lsoft/translate';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import ListSubheader from '@material-ui/core/ListSubheader';
import _ from 'lodash';
import { HeaderIconPortalButton } from './HeaderIconPortalButton';
import {
	trAccount,
	trCreateAccount,
	trMyAccount,
	trSignIn,
	trSignOut,
	trYouHaveSuccessfullyLoggedOut,
} from './Header.tr';
import { useRouter } from 'next/router';
import { MuiCookieTooltip } from '../../../libs/mui/MuiCookieTooltip';
import { grey } from '@material-ui/core/colors';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import { NotLoggedIn } from '@lsoft/shared/components/am/NotLoggedIn';
import { LoggedIn } from '@lsoft/shared/components/am/LoggedIn';

const useStyles = makeStyles((theme) => ({
	headerIconPortalPaper: {
		'& .MuiMenuItem-root': {
			minHeight: theme.spacing(5),
		},
	},
	IconButton: {
		background: grey[100],
		'&:hover': {
			background: grey[200],
		},
	},
	AccountAvatar: {
		cursor: 'pointer',
		fontSize: '0.8rem',
		height: theme.spacing(4),
		width: theme.spacing(4),
		padding: [
			[theme.spacing(1), theme.spacing(1), theme.spacing(1), theme.spacing(1)],
		],
		color: 'white',
		backgroundColor: 'black',
	},
	Dot: {
		'& .MuiBadge-anchorOriginBottomRightCircle': {
			border: '1px solid white',
			right: '15%',
			bottom: '12%',
			transform: 'scale(1) translate(50%, 50%)',
			transformOrigin: '100% 100%',
		},
		'& .MuiBadge-dot': {
			height: 10,
			padding: 0,
			minWidth: 10,
			borderRadius: 5,
		},
	},
	MenuList: {
		focus: 'unset',
	},
	IconButtonFaker: {
		height: 48,
		width: 48,
		background: grey[100],
		borderRadius: '50%',
		padding: theme.spacing(1),
		'&:hover': {
			background: grey[200],
		},
	},
}));

const getNameInitials = (data) => {
	let nameStr = data;

	if (_.isArray(nameStr)) {
		nameStr = nameStr.join(' ');
	} else if (_.isObject(data)) {
		nameStr = Object.values(nameStr).join(' ');
	}

	const nameParts = nameStr.split(' ');
	const initials = nameParts.map((part) => part.charAt(0));
	return initials.join('');
};

const HeaderAccount = ({ theme }) => {
	const { locale } = useRouter();
	const { buttonRef, close, isOpen, onButtonClick } = useContext(
		HeaderIconPortalButton.Context,
	);

	const classes = useStyles(theme);
	const throwSnackbar = useShopSnackbar();

	const { loginInfo, userInfo, logout } = useContext(MuiAppContext);
	const { setRenderLogin } = loginInfo;

	const handleLogin = () => {
		setRenderLogin(true);
	};

	const handleLogout = async () => {
		await logout();

		throwSnackbar({
			msg: getTranslation(trYouHaveSuccessfullyLoggedOut, locale),
			variant: 'success',
		});

		close();
	};

	return (
		<>
			<NotLoggedIn>
				<MuiCookieTooltip>
					<IconButton
						ref={buttonRef}
						color={'inherit'}
						onClick={onButtonClick}
						className={clsx(classes.IconButton)}
					>
						<AccountIcon />
					</IconButton>
				</MuiCookieTooltip>
			</NotLoggedIn>
			<LoggedIn>
				<Box className={clsx(classes.IconButtonFaker)}>
					<Badge
						showZero={true}
						variant={'dot'}
						overlap={'circular'}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						color={'primary'}
						className={clsx(classes.Dot)}
					>
						<Avatar
							ref={buttonRef}
							className={clsx(classes.AccountAvatar)}
							onClick={onButtonClick}
						>
							{getNameInitials(userInfo)}
						</Avatar>
					</Badge>
				</Box>
			</LoggedIn>
			{isOpen && (
				<HeaderIconPortal>
					<HeaderIconPortalPaper
						className={clsx(classes.headerIconPortalPaper)}
					>
						<MenuList
							className={clsx(classes.MenuList)}
							subheader={
								<ListSubheader color={'primary'} disableSticky>
									<Tr tr={trAccount} />
								</ListSubheader>
							}
						>
							<LoggedIn>
								<MenuItem>
									<MuiLink
										fullWidth
										href={'/account/profile'}
										linkprops={{
											onClick: close,
											color: 'textPrimary',
										}}
									>
										<Typography>
											<Tr tr={trMyAccount} />
										</Typography>
									</MuiLink>
								</MenuItem>

								<MenuItem onClick={handleLogout}>
									<Typography>
										<Tr tr={trSignOut} />
									</Typography>
								</MenuItem>
							</LoggedIn>
							<NotLoggedIn>
								<MenuItem onClick={handleLogin}>
									<Typography>
										<Tr tr={trSignIn} />
									</Typography>
								</MenuItem>
								<MenuItem>
									<MuiLink
										fullWidth
										href={'/register/sign_up'}
										linkprops={{
											onClick: close,
											color: 'textPrimary',
										}}
									>
										<Typography>
											<Tr tr={trCreateAccount} />
										</Typography>
									</MuiLink>
								</MenuItem>
							</NotLoggedIn>
						</MenuList>
					</HeaderIconPortalPaper>
				</HeaderIconPortal>
			)}
		</>
	);
};

HeaderAccount.propTypes = {};
HeaderAccount.defaultProps = {};

export { HeaderAccount };
