import { MuiGlobalStyles } from './MuiGlobalStyles.styles';
import { MuiTableStyles } from './MuiTableStyles.styles';
import { MuiAccordionStyles } from './MuiAccordion.styles';
import { MuiInputStyles } from '../MuiForms/Inputs';

export const MuiStyles = (theme) => ({
	...MuiGlobalStyles(theme),
	...MuiTableStyles(theme),
	...MuiAccordionStyles(theme),
	...MuiInputStyles(theme),
});
