import { Fade, makeStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	Snackbar: {
		top: theme.sizes.appBarHeight,
		// border: '1px solid red',
	},
}));

export const ShopSnackbarProvider = ({ theme, children }) => {
	const classes = useStyles({ theme });
	const SnackbarProps = {
		autoHideDuration: 1500,
		anchorOrigin: {
			vertical: 'top',
			horizontal: 'center',
		},
		dense: true,
		maxSnack: 1,
		TransitionComponent: Fade,
		transitionDuration: { enter: 50, exit: 200 },
	};
	return (
		<SnackbarProvider
			{...SnackbarProps}
			classes={{ anchorOriginTopCenter: clsx(classes.Snackbar) }}
		>
			{children}
		</SnackbarProvider>
	);
};
