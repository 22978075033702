import _ from 'lodash';

export const flatten_dict = (d, prefix = []) =>
	Object.entries(d).reduce((ret, [k, v]) => {
		const prefix_path = [...prefix, k];
		if (_.isObject(v)) {
			const fd2 = flatten_dict(v, prefix_path);
			ret = _.merge({}, ret, fd2);
		} else {
			ret[prefix_path.join('.')] = v;
		}
		return ret;
	}, {});

export const deflat_dict = (d) =>
	Object.entries(d).reduce((ret, [k, v]) => {
		_.set(ret, k, v);
		return ret;
	}, {});

export const dict_filter_paths = (d, paths) => {
	return deflat_dict(
		Object.entries(flatten_dict(d))
			.filter(([k]) => {
				return paths.indexOf(k) !== -1;
			})
			.reduce((ret, [k, v]) => {
				ret[k] = v;
				return ret;
			}, {})
	);
};

export const dict_to_list = (dict) => {
	return Object.values(dict).map((item) => {
		return item;
	});
};

export const dict_keys_to_lowercase = (dict) => {
	return Object.keys(dict).reduce((new_dict, k) => {
		new_dict[k.toLowerCase()] = dict[k];
		return new_dict;
	}, {});
};

export const dict_keys_to_uppercase = (dict) => {
	return Object.keys(dict).reduce((new_dict, k) => {
		new_dict[k.toUpperCase()] = dict[k];
		return new_dict;
	}, {});
};
export const remove_empty_keys = (obj) => {
	return _.omitBy(obj, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
};
