import React from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import { MuiPageContent } from './MuiPageContent';
import { MuiPageContentContainer } from './MuiPageContentContainer';

const MuiPageRenderOnRestriction = (props) => {
	const missingScopes = _.get(props, 'missingScopes', []);

	return (
		<MuiPageContent>
			<MuiPageContentContainer>
				<h2>Restricted Content.</h2>
				<div>Missing Scopes: {missingScopes}</div>
			</MuiPageContentContainer>
		</MuiPageContent>
	);
};

MuiPageRenderOnRestriction.propTypes = {
	missingScopes: PropTypes.array,
};

MuiPageRenderOnRestriction.defaultProps = {
	missingScopes: [],
};

export { MuiPageRenderOnRestriction };
