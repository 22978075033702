import React, { useContext } from 'react';
// eslint-disable-next-line no-unused-vars
import { makeStyles, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { MuiCookie, MuiLink, MuiTopCenterBottom } from '@lsoft/mui';

import { Tr } from '@lsoft/translate';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
	trAcceptAllCookies,
	trAcceptNecessaryCookies,
	trCookiePolicy,
	trPrivacyPolicy,
	trToContinueAcceptCookies,
	trWeUseCookiesAndTechnology,
	trWhichCookieSettingsProceed,
	trYourCookieSettings,
} from './CookieNotification.tr';
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	CookieNotification: {
		minHeight: '20vh',
		position: 'absolute',
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: theme.zIndexes.CookieNotification,
	},
	CookieNotificationContent: {
		maxHeight: '40vh',
	},
}));

export const CookieNotification = ({ theme }) => {
	const ctx = useContext(MuiCookie.Context);

	const classes = useStyles({ theme });
	const fullWidth = useMediaQuery((theme) => theme.breakpoints.down('xs'));

	if (ctx.technicalCookies) return null;

	return (
		<Card className={clsx(classes.CookieNotification)} elevation={24} square>
			<Container maxWidth={'lg'} disableGutters>
				<MuiTopCenterBottom.Container>
					<MuiTopCenterBottom.Center>
						<CardHeader title={<Tr tr={trYourCookieSettings} />} />
						<CardContent className={clsx(classes.CookieNotificationContent)}>
							<Typography gutterBottom>
								<Tr tr={trWeUseCookiesAndTechnology} />
							</Typography>
							<Typography gutterBottom>
								<Tr tr={trToContinueAcceptCookies} />
							</Typography>
							<Typography gutterBottom>
								<Tr tr={trWhichCookieSettingsProceed} />
							</Typography>
						</CardContent>
					</MuiTopCenterBottom.Center>
					<MuiTopCenterBottom.Bottom>
						<CardContent>
							<Grid
								container
								direction={'row'}
								justifyContent={'flex-end'}
								alignItems={'flex-start'}
								spacing={2}
							>
								<Grid item xs={fullWidth ? 12 : 'auto'}>
									<Button
										variant={'outlined'}
										fullWidth={fullWidth}
										onClick={ctx.allowTechnicalCookies}
									>
										<Tr tr={trAcceptNecessaryCookies} />
									</Button>
								</Grid>
								<Grid item xs={fullWidth ? 12 : 'auto'}>
									<Button
										variant={'contained'}
										color={'secondary'}
										fullWidth={fullWidth}
										onClick={ctx.allowAllCookies}
									>
										<Tr tr={trAcceptAllCookies} />
									</Button>
								</Grid>
							</Grid>
							<Typography gutterBottom variant={'caption'}>
								<MuiLink target={'_blank'} href={'/cookie_policy'}>
									<Tr tr={trCookiePolicy} />
								</MuiLink>
								&nbsp;/&nbsp;
								<MuiLink target={'_blank'} href={'/privacy_policy'}>
									<Tr tr={trPrivacyPolicy} />
								</MuiLink>
							</Typography>
						</CardContent>
					</MuiTopCenterBottom.Bottom>
				</MuiTopCenterBottom.Container>
			</Container>
		</Card>
	);
};
