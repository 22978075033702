import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
	createStyles({
		drawer: {
			flexShrink: 0,
			width: theme.sizes.drawerWidth,
		},
		drawerPaper: {
			height: 'calc(100vh - 64px)', // Todo: Is there a variable height for the toolbar?
			overflowY: 'overlay',
			top: 'auto',
			width: theme.sizes.drawerWidth,
		},
	})
);

const MuiDrawer = (props) => {
	const classes = useStyles(props);
	return (
		<Drawer
			anchor="left"
			classes={{
				paper: classes.drawerPaper,
			}}
			className={clsx(classes.drawer)}
			open={true}
			variant={'permanent'}
			elevation={4}
		>
			{props.children}
		</Drawer>
	);
};

export { MuiDrawer };
