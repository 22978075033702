import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	fullWidth: {
		width: '100%',
	},
	FormControlContainer: {
		position: 'relative',
		paddingTop: theme.spacing(1),
	},
	FormControlOutlined: {
		height: '100%',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.grey[400],
		borderRadius: 4,
		'&:hover': {
			borderColor: theme.palette.grey[800],
		},
		'&:focus-within': {
			borderColor: theme.palette.primary[600],
			borderWidth: 1,
			boxShadow: [[0, 0, 0, 1, theme.palette.primary[600]]],
		},
	},
	FormControl: {
		paddingLeft: theme.spacing(0),
		paddingRight: theme.spacing(0),
	},
	FormControlLabel: {
		fontSize: '1rem',
		transform: 'scale(0.75)',
		position: 'absolute',
		top: -theme.spacing(0.25),
		left: -theme.spacing(0.25),
		paddingLeft: theme.spacing(0.75),
		paddingRight: theme.spacing(0.75),
		background: theme.palette.background.paper,
		color: theme.palette.grey[600],
		paddingBottom: theme.spacing(0.25),
	},
	FormControlLabelFocus: {
		color: theme.palette.primary[500],
	},
	FormControlChildContainer: {
		overflowY: 'scroll',
		height: `calc(100% - ${theme.spacing(1)}px)`,
		width: '100%',
	},
	Spacer: {
		height: theme.spacing(1),
		width: '100%',
	},
}));

export const MuiFormControlOutline = ({
	children,
	className,
	noLabel,
	label,
	fullWidth,
	...props
}) => {
	const [hasFocus, setFocusState] = useState(false);
	const classes = useStyles();
	return (
		<Box
			className={clsx(
				classes.FormControlContainer,
				className,
				fullWidth ? classes.fullWidth : null
			)}
			onFocus={() => setFocusState(true)}
			onBlur={() => setFocusState(false)}
			{...props}
		>
			{!noLabel && (
				<label
					className={clsx(
						classes.FormControlLabel,
						hasFocus ? classes.FormControlLabelFocus : null
					)}
				>
					{label}
				</label>
			)}
			<Box className={clsx(classes.FormControl, classes.FormControlOutlined)}>
				<Box className={clsx(classes.Spacer)} />
				<Box className={clsx(classes.FormControlChildContainer)}>
					{children}
				</Box>
			</Box>
		</Box>
	);
};

MuiFormControlOutline.propTypes = {};
MuiFormControlOutline.defaultProps = {};
