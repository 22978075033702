import React, { memo, useCallback, useContext, useMemo } from 'react';
import { MuiFormFieldTypes } from './MuiFormFieldTypes';
import _ from 'lodash';
import { MuiFormContext } from './MuiFormContext';
import { MuiInput } from './Inputs';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import { MuiInputStyles } from './Inputs/MuiInputStyles';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

MuiFormFieldTypes.registerStandardTypes();

const MuiFormFieldContext = React.createContext(null);
MuiFormContext.displayName = 'MuiFormContext';

// eslint-disable-next-line react/display-name
const MuiFormFieldInner = (props) => {
	const context = useContext(MuiFormFieldContext);
	const { name, field } = context;
	// get converted value
	const value = context.getValue();
	const value2 = context.getValue2();

	const { errors, spec } = field;
	// console.log(spec)
	const { inputComponent: variant, inputProps } = spec;
	const onChange = useCallback(
		(val) => {
			context.setValue(val);
			if (!_.isNil(props.onChange)) {
				props.onChange(val);
			}
		},
		[context]
	);
	// // console.log('mff', field)
	//
	const muiInputProps = _.merge({}, inputProps, props, {
		variant,
		name,
		value,
		value2,
		onChange,
		errors,
	});
	// console.log(`render MuiFormFieldInner ${name}`);
	return useMemo(() => {
		// console.log(
		// 	`render useMemo ${muiInputProps.name} ${
		// 		muiInputProps.value
		// 	} ${JSON.stringify(muiInputProps.errors)}`
		// );
		return <MuiInput {...muiInputProps} />;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, field.propsSelector(muiInputProps));
};

// eslint-disable-next-line react/display-name
const MuiFormField = memo(({ name, ...props }) => {
	// console.log(`render MuiFormField ${name}`);

	const context = useContext(MuiFormContext);
	// // get field
	const field = _.get(context.fields, name, null);
	if (field === null)
		throw new Error(`No field of name '${name}' found in MuiForm.`);

	const getValue = useCallback(() => {
		return context.getValue(name);
	}, [context, name]);

	const getValue2 = useCallback(() => {
		const value2 = _.get(field, ['spec', 'value2'], null);
		if (value2 != null) {
			return context.getValue(value2);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context, name]);

	const setValue = useCallback(
		(val) => {
			context.setValue(name, val);
		},
		[context, name]
	);

	let value = {
		name,
		field,
		getValue,
		getValue2,
		setValue,
	};

	if (name === 'FILTER') {
		value = _.merge({}, value, {
			// eslint-disable-next-line react/prop-types
			field: { spec: { inputComponent: props.variant } },
		});
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	// const inner = () => useMemo(() => <MuiFormFieldInner {...props} />, []);
	return (
		<MuiFormFieldContext.Provider value={value}>
			<MuiFormFieldInner {...props} />
		</MuiFormFieldContext.Provider>
	);
});

MuiFormField.propTypes = {
	name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	value2: PropTypes.string,
};

const useStyles = makeStyles(MuiInputStyles);
const MuiFormFieldCell = ({ theme, classNameCell, ...props }) => {
	const classes = useStyles({ theme });
	return (
		<TableCell className={clsx(classNameCell, classes.MuiInputTableCell)}>
			<MuiFormField {...props} />
		</TableCell>
	);
};
MuiFormFieldCell.propTypes = {
	classNameCell: PropTypes.any,
};

export { MuiFormField, MuiFormFieldCell };
