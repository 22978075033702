export const trmLoginView = {
	pathesToCheck: ['loginView'],

	tr: {
		loginView: {
			scanTag: {
				DE: 'Scannen Sie Ihren TAG',
				EN: 'Please scan your RFID',
			},
			loginRfid: {
				DE: 'Login mit RFID',
				EN: 'Login with RFID',
			},
			loginPassword: {
				DE: 'Login mit Nutzerdaten',
				EN: 'Login with user data',
			},
			username: {
				DE: 'Nutzername / E-Mail Addresse',
				EN: 'Username / email address',
			},
			password: {
				DE: 'Passwort',
				EN: 'Password',
			},
			login: {
				DE: 'Anmelden',
				EN: 'Login',
			},
		},
	},
};
