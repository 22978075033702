import { MuiFormFieldTypes, MuiGlobal } from '@lsoft/mui';

export const registerShopFields = () => {
	const registered = MuiGlobal.get('MuiFormFieldTypes._shopRegistered', false);
	if (!registered) {
		const erpFields = require('./fields');
		MuiFormFieldTypes.set(erpFields);
		MuiGlobal.set('MuiFormFieldTypes._shopRegistered', true);
	}
};
