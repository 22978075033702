export const trGainedPoints = {
	DE: 'Insgesamt gesammelte Punkte',
	EN: 'Total points earned',
	FR: 'Total des points gagnés',
};
export const trSpendPoints = {
	DE: 'Ausgegebene Punkte',
	EN: 'Points spend',
	FR: 'Points dépensés',
};
export const tr_points = {
	DE: 'Punkte',
	EN: 'Points',
	FR: 'Points',
};
export const trAvailablePoints = {
	DE: 'Verfügbare Punkte',
	EN: 'Points available',
	FR: 'Points disponibles',
};
export const trConvertablePoints = {
	DE: 'Einlösbare Punkte',
	EN: 'Redeemable points',
	FR: 'Points convertibles',
};
export const trConvertablePointsValue = {
	DE: 'Wert der einlösbaren Punkte',
	EN: 'Redeemable points value',
	FR: 'Valeur des points convertibles',
};
export const trYouDontHaveACustomerCard = {
	DE: 'Sie scheinen noch nicht über eine Kundenkarte zu verfügen. Möchten Sie eine virtuelle Kundenkarte erstellen?',
	// prettier-ignore
	EN: 'You don\'t seem to have a customer card yet. Would you like to create a virtual customer card?',
	FR: 'Vous ne semblez pas encore avoir de carte de client. Souhaitez-vous créer une carte client virtuelle ?',
};

export const trCreateDigitalCustomerCard = {
	DE: 'Virtuelle Kundenkarte anfordern',
	EN: 'Sign up for a virtual customer card',
	FR: 'Demander une carte client virtuelle',
};

export const trUnfortunatelyYourProfileIsNotMatchingAllRequirements = {
	DE: 'Leider erfüllt ihr Profil noch nicht alle Anforderungen um eine Kundenkarte beantragen zu können.',
	EN: 'Unfortunately, your profile does not yet meet all the requirements to apply for a customer card.',
	FR: 'Malheureusement, votre profil ne remplit pas encore toutes les conditions requises pour demander une carte de fidélité.',
};

export const trMissingInformationInProfileAddress = {
	DE: 'Profil-Adresse: Bitte fügen Sie Ihrem Account eine Profiladresse hinzu.',
	EN: 'Profile address: Please add a profile address to your account.',
	FR: 'Adresse du profil : Veuillez ajouter une adresse de profil à votre compte.',
};

export const trPleaseVerifyYourEmailAddress = {
	DE: 'Aktivierung: Bitte verifizieren Sie Ihre Email-Adresse',
	EN: 'Activation: Please verify your email address',
	FR: 'Activation : Veuillez vérifier votre adresse électronique',
};
export const trActualYouHaveAmountPoints = {
	DE: 'Aktuell haben Sie ${POINTS_TOTAL_AVAILABLE} Punkte gesammelt.',
	EN: 'Currently you have collected ${POINTS_TOTAL_AVAILABLE} points.',
	FR: 'Actuellement, vous avez collecté ${POINTS_TOTAL_AVAILABLE} points.',
};
export const trYouCanSpendAmountPoints = {
	DE: 'Von Ihren verfügbaren Punkten sind ${CONVERTABLE_POINTS} Punkte einlösbar. Dies entspricht einem einmaligen Rabatt von ${convertablePointsValueString}.',
	EN: '${CONVERTABLE_POINTS} points of your available points are redeemable. This corresponds to a one-time discount of ${convertablePointsValueString}.',
	FR: '${CONVERTABLE_POINTS} points de vos points disponibles sont échangeables. Cela correspond à une réduction unique de ${convertablePointsValueString}.',
};
export const trNotEnoughPointsForSpend = {
	DE: 'Leider haben Sie noch nicht genug Punkte gesammelt, um diese einzulösen.',
	EN: 'Unfortunately you have not yet collected enough points to redeem them.',
	// prettier-ignore
	FR:
		'Malheureusement, vous n\'avez pas encore accumulé suffisamment de points pour les échanger.',
};
export const trCustomerCardGTCLink = {
	DE: 'Das Beantragen, Sammeln und Einlösen von Punkten unterliegt bestimmten [Bedingungen](/gtc_customer_card).',
	EN: 'Applying for, collecting and redeeming points is subject to certain [terms and conditions.](/gtc_customer_card)',
	// prettier-ignore
	FR: 'La demande, la collecte et l\'échange de points sont soumis à [certaines conditions.](/gtc_customer_card)',
};

export const trBySigningUpForCustomerCardYouAgreeTermsAndConditions = {
	DE: 'Mit dem Beantragen der Kundenkarte, stimmen Sie den [Nutzungsbedingungen](/gtc_customer_card) zu.',
	EN: 'By signing up for the customer card, you agree to the [terms and conditions of use](/gtc_customer_card).',
	// prettier-ignore
	FR: 'En vous inscrivant à la carte client, vous acceptez les [conditions d\'utilisation](/gtc_customer_card).',
};

export const trYourPoints = {
	DE: 'Ihre Punkte',
	EN: 'Your points',
	FR: 'Vos points',
};
export const trAmount = { DE: 'Anzahl', EN: 'Amount', FR: 'Montant' };
export const trCurrentPointsBalance = {
	DE: 'Aktueller Punktestand',
	EN: 'Current points balance',
	FR: 'Balance des points courants',
};
export const trRedeemablePoints = {
	DE: 'Einlösbare Punkte',
	EN: 'Redeemable points',
	FR: 'Points échangeables',
};
export const trTotalPointsAccumulated = {
	DE: 'Gesammelte Punkte, gesamt',
	EN: 'Total points accumulated',
	FR: 'Total des points accumulés',
};
export const trPointsAlreadyRedeemed = {
	DE: 'Bereits eingelöste Punkte',
	EN: 'Points already redeemed',
	FR: 'Points déjà échangés',
};
export const trYourDiscount = {
	DE: 'Ihr Rabatt',
	EN: 'Your discount',
	FR: 'Votre remise',
};
export const trMissingData = {
	DE: 'Fehlende Daten',
	EN: 'Missing data',
	FR: 'Données manquantes',
};
export const trSomethingWentWrongPleaseTryAgainLater = {
	DE: 'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
	EN: 'Something went wrong. Please try again later.',
};
export const trHereYouCanFindEverythingAboutTheCustomerCard = {
	DE: 'Hier finden Sie alles zur LToys-Kundenkarte und unserem Treuepunktesystem.',
	EN: 'Here you can find everything about the customer card and our loyalty points system.',
};
export const trWithOurCustomerCartYouCollectPoints = {
	DE: 'Mit unserer Kundenkarte sammeln Sie bei jedem Einkauf Punkte in Höhe von 5% des Einkaufwertes. Diese können Sie zu einem späteren Einkauf als Rabatt einlösen.',
	EN: 'With the customer card, you collect points amounting to 5% of the purchase value with every purchase. You can redeem these points as a discount on a later purchase.',
	// prettier-ignore
	FR: 'Avec notre carte client, vous accumulez des points à hauteur de 5% de la valeur d\'achat à chaque achat. Vous pouvez les échanger contre une réduction pour un achat ultérieur.',
};
export const trOurCustomerCard = {
	DE: 'Unsere Kundenkarte',
	EN: 'Our customer card',
	FR: 'Notre carte de client',
};
export const trVisitYourProfileToApplyForAVirtualCustomerCard = {
	DE: 'Wechseln Sie in Ihr Profil um jetzt eine virtuelle Kundenkarte zu beantragen.',
	EN: 'Go to your profile to register for a virtual loyalty card now.',
	FR: 'Allez dans votre profil pour demander une carte de fidélité virtuelle maintenant.',
};
export const trYourCustomerCardIsNotTypeCustomer = {
	DE: 'Bei Ihrer Kundenkarte handelt es sich um eine Karte des Typs <strong>${CART_CUSTOMER_TYPE}</strong>. Zur Zeit können Karten dieses Typs <u>nicht in unserem Online-Shop genutzt werden</u>.',
	EN: 'Your customer card is a <strong>${CART_CUSTOMER_TYPE}</strong> type card. At present, cards of this type <u>cannot be used in our online shop</u>.',
	FR: 'Votre carte client est une carte de type <strong>${CART_CUSTOMER_TYPE}</strong>. Actuellement, les cartes de ce type ne peuvent pas être utilisées dans notre boutique en ligne.',
};
export const trInTheFutureYourCardWillBeFullyUsable = {
	DE: 'In Zukunft wird Ihre Karte auch in unserem Online-Shop voll nutzbar sein und Ihnen die gewohnten Vorzüge bieten.',
	EN: 'In the future, your card will fully usable in our online shop and offer you the usual features.',
	// prettier-ignore
	FR: 'À l\'avenir, votre carte sera également entièrement utilisable dans notre boutique en ligne et vous offrira les fonctionnalités habituelles.',
};
export const trInOurStoreYouWillReceiveTheUsualDiscount = {
	DE: 'In unserem Laden erhalten Sie auch jetzt schon wie gewohnt <strong>${DISCOUNT_RATE}% Rabatt</strong> auf Ihren Einkauf.',
	EN: 'As usual, you will receive a <strong>${DISCOUNT_RATE}% discount</strong> on your purchase made our store.',
	// prettier-ignore
	FR: 'Comme d\'habitude, vous bénéficierez d\'une <strong>réduction de ${DISCOUNT_RATE} %</strong> sur vos achats dans notre boutique.',
};
