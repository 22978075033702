import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

const MuiCenteredVertical = ({ children, disabled }) => {
	if (disabled) return children;
	return (
		<Grid
			container
			direction={'column'}
			alignItems={'stretch'}
			justifyContent={'center'}
			style={{ height: '100%' }}
		>
			<Grid item>{children}</Grid>
		</Grid>
	);
};

MuiCenteredVertical.propTypes = {
	disabled: PropTypes.bool,
};

MuiCenteredVertical.defaultProps = {
	disabled: false,
};

const MuiCenteredHorizontal = ({ children, disabled }) => {
	if (disabled) return children;
	return (
		<Grid container direction={'row'} alignItems={'stretch'} justifyContent={'center'}>
			<Grid item>{children}</Grid>
		</Grid>
	);
};

MuiCenteredHorizontal.propTypes = {
	disabled: PropTypes.bool,
};

MuiCenteredHorizontal.defaultProps = {
	disabled: false,
};

const MuiCentered = ({ children, vertical, horizontal }) => {
	return (
		<MuiCenteredVertical disabled={!vertical}>
			<MuiCenteredHorizontal disabled={!horizontal}>
				{children}
			</MuiCenteredHorizontal>
		</MuiCenteredVertical>
	);
};

MuiCentered.propTypes = {
	horizontal: PropTypes.bool.isRequired,
	vertical: PropTypes.bool.isRequired,
};

MuiCentered.defaultProps = {
	horizontal: true,
	vertical: true,
};

export { MuiCentered, MuiCenteredHorizontal, MuiCenteredVertical };
