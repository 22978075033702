import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	Container: {
		display: 'flex',
	},
	ContainerRow: {
		height: '100%',
	},
	ContainerColumn: {},
	Split: {},
	SplitRow: {
		overflowY: 'hidden',
	},
	SplitRowScroll: {
		overflowY: 'overlay',
		fallbacks: {
			overflowY: 'auto',
		},
	},
	SplitColumn: {},
}));

const Context = React.createContext(null);
Context.displayName = 'MuiSplitter.Context';

const Container = ({ theme, children, direction }) => {
	const classes = useStyles({ theme });
	return (
		<Context.Provider value={{ direction }}>
			<Box
				className={clsx(
					classes.Container,
					direction === 'row' ? classes.ContainerRow : classes.ContainerColumn
				)}
			>
				{children}
			</Box>
		</Context.Provider>
	);
};
Container.propTypes = {
	direction: PropTypes.oneOf(['column', 'row']),
};
Container.defaultProps = {
	direction: 'row',
};

const Split = ({ theme, children, width, height, scroll, className }) => {
	const { direction } = useContext(Context);
	const classes = useStyles({ theme });
	const style = _.assign({}, direction === 'row' ? { width } : { height });
	const splitDirection =
		direction === 'row' ? classes.SplitRow : classes.SplitColumn;
	const scrollClass = !scroll
		? null
		: direction === 'row'
		? classes.SplitRowScroll
		: classes.SplitColumnScroll;
	return (
		<Box
			className={clsx(classes.Split, splitDirection, scrollClass, className)}
			style={style}
		>
			{children}
		</Box>
	);
};
Split.propTypes = {
	width: PropTypes.any,
	height: PropTypes.any,
	scroll: PropTypes.bool,
	className: PropTypes.any,
};

Split.defaultProps = {
	width: '50%',
	height: '50%',
};

export { Split };

const MuiSplitter = { Container, Split };
export { MuiSplitter };
