import React, { useContext } from 'react';

export const ContextDataIterator = ({
	context,
	data_key,
	map_key,
	children: real_children,
}) => {
	const ctx = useContext(context);
	const data = _.get(ctx, data_key);
	if (_.isNil(data)) return null;

	return Object.values(data).map((entry) => {
		if (typeof entry !== 'object') return null;
		const entry_id = _.get(entry, [map_key]);
		return React.cloneElement(
			real_children,
			{
				key: entry_id,
				[map_key]: entry_id,
			},
			real_children.props.children
		);
	});
};
