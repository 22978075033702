import React from 'react';
import { makePick, pickDefault } from './input_components';
import PropTypes from 'prop-types';

const pick = makePick(['checked'], pickDefault);

const Component = (newProps) => {
	// eslint-disable-next-line no-unused-vars
	const pickedProps = pick(newProps);
	return <div>filterchipps component</div>;
};

Component.propTypes = {
	newProps: PropTypes.object,
};

export const ic_filter_chips = {
	// eslint-disable-next-line react/display-name
	Component,
	getNewProps: (newProps) => {
		// newProps.checked = newProps.value;
		// newProps.onChange = funcWrap(newProps.onChange, (onChange) => async (e) =>
		// 	onChange(e.target.checked)
		// );

		return newProps;
	},
};
