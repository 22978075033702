import { LsDataContainer } from '../../LsContainer';
import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { check_now_in_timeframe } from '../../../functions/shop/promotions';
import { erp_promotion_get } from '../../../backend/erp/promotion';

export const PromotionContext = React.createContext(null);

const load = async (name) => {
	return await erp_promotion_get({
		name,
	});
};

export const PromotionContainer = ({ children, name, ...props }) => {
	const extendContext = useCallback(({ data, functions }) => {
		if (!_.isNil(data)) {
			data.only_stock = _.get(data, 'only_stock', true);
			data.start_date = _.get(data, 'start_date');
			data.end_date = _.get(data, 'end_date');
			data.available = check_now_in_timeframe(data.start_date, data.end_date);
		}
		if (!_.isNil(functions)) {
		}
		return {
			data,
			functions,
		};
	}, []);

	return (
		<LsDataContainer
			context={PromotionContext}
			id={name}
			key={name}
			extendContext={extendContext}
			loader={async () => await load(name)}
			{...props}
		>
			{children}
		</LsDataContainer>
	);
};

PromotionContainer.propTypes = {
	...LsDataContainer.propTypes,
	name: PropTypes.string,
};
