import { makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { MuiPageContext } from '../MuiPage/MuiPageContext';
import { MuiTopCenterBottomStyles } from './MuiTopCenterBottom.styles';
import _ from 'lodash';
import { MuiAppContext } from '../MuiApp';

const useStyles = makeStyles((theme) => ({
	...MuiTopCenterBottomStyles(theme),
	red: { border: '1px solid red' },
}));

const Context = React.createContext(null);
Context.displayName = 'MuiTopCenterBottom.Context';

const Container = ({
	theme,
	children,
	variant = 'static',
	id: uuid,
	className,
}) => {
	const classes = useStyles({ theme });
	const { noPageScroll } = useContext(MuiPageContext);
	// const uuid = `mtcb-${_.uniqueId()}`;

	const value = {
		uuid,
		uuidTop: _.isNil(uuid) ? null : uuid + '-top',
		uuidCenter: _.isNil(uuid) ? null : uuid + '-center',
		uuidBottom: _.isNil(uuid) ? null : uuid + '-bottom',
	};

	let ContainerProps = {
		id: uuid,
		className: clsx(className),
		// classes.red
	};

	let TopProps = {
		id: value.uuidTop,
		className: clsx(
			// classes.red,
			classes.Top
		),
	};

	let CenterProps = {
		id: value.uuidCenter,
		className: clsx(
			// classes.red,
			classes.Center
		),
	};

	let BottomProps = {
		id: value.uuidBottom,
		className: clsx(
			// classes.red,
			classes.Bottom
		),
	};

	switch (variant) {
		case 'static':
			ContainerProps.className = clsx(
				ContainerProps.className,
				classes.Container
			);
			break;

		default:
			ContainerProps.className = clsx(
				ContainerProps.className,
				classes.topCenterBottom,
				{
					[classes.topCenterBottomScroll]: noPageScroll,
				}
			);
	}

	value.ContainerProps = ContainerProps;
	value.TopProps = TopProps;
	value.CenterProps = CenterProps;
	value.BottomProps = BottomProps;

	return (
		<Context.Provider value={value}>
			<Box {...ContainerProps}>{children}</Box>
		</Context.Provider>
	);
};
Container.propTypes = {
	noScroll: PropTypes.bool,
	variant: PropTypes.string,
	id: PropTypes.string,
};

const Top = ({ children }) => {
	// return <MuiPortal id={uuidTop}>{children}</MuiPortal>;
	// const { uuidTop } = useContext(Context);
	const { TopProps } = useContext(Context);
	return <Box {...TopProps}>{children}</Box>;
};

const Center = ({ children, isScrollRef = false }) => {
	const centerRef = useRef();
	const { CenterProps } = useContext(Context);
	const { setScrollRef } = useContext(MuiAppContext);

	useEffect(() => {
		if (isScrollRef) {
			setScrollRef(centerRef);
		}
	}, [centerRef]);

	return (
		<Box {...CenterProps} ref={centerRef}>
			{children}
		</Box>
	);
};
Center.propTypes = {
	isScrollRef: PropTypes.bool,
};

const Bottom = ({ children }) => {
	// const { uuidBottom } = useContext(Context);
	// return <MuiPortal id={uuidBottom}>{children}</MuiPortal>;
	const { BottomProps } = useContext(Context);
	return <Box {...BottomProps}>{children}</Box>;
};

const MuiTopCenterBottomView = (
	props = {
		noPageScroll: false,
		hideTop: false,
		hideContent: false,
		hideBottom: false,
	}
) => {
	return (
		<Container>
			{!props.hideTop && <Top>{props.topContent()}</Top>}
			{!props.hideContent && <Center>{props.centerContent()}</Center>}
			{!props.hideBottom && <Bottom>{props.bottomContent()}</Bottom>}
		</Container>
	);
};

MuiTopCenterBottomView.propTypes = {
	topContent: PropTypes.func,
	centerContent: PropTypes.func,
	bottomContent: PropTypes.func,
	noPageScroll: PropTypes.bool,
	hideTop: PropTypes.bool,
	hideContent: PropTypes.bool,
	hideBottom: PropTypes.bool,
};

const MuiTopCenterBottom = { Container, Top, Center, Bottom, Context };

export { MuiTopCenterBottomView, MuiTopCenterBottom };
