import _ from 'lodash';
import { MuiGlobal } from '../MuiGlobal';

const MuiFormFieldTypes = {};

MuiFormFieldTypes.set = (defs) => {
	MuiGlobal.set(
		'MuiFormFieldTypes',
		_.assign({}, MuiGlobal.get('MuiFormFieldTypes', {}), defs)
	);
};

MuiFormFieldTypes.get = (variant) => {
	return MuiGlobal.get(`MuiFormFieldTypes.${variant}`, null);
};

MuiFormFieldTypes.registerStandardTypes = () => {
	const registered = MuiGlobal.get(
		'MuiFormFieldTypes._standardRegistered',
		false
	);
	if (!registered) {
		const standardTypes = require('./Fields');
		MuiFormFieldTypes.set(standardTypes);
		MuiGlobal.set('MuiFormFieldTypes._standardRegistered', true);
	}
};

export { MuiFormFieldTypes };
