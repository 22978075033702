import _ from 'lodash';
import { makeOptionsProvider, MuiWebapiContainer } from '../MuiWebapi';

// eslint-disable-next-line no-unused-vars
const selector = (state, options) => {
	return _.get(
		state.webapi.data,
		['TYPE_MAPPINGS', options.SET, 'OPTIONS'],
		null
	);
};

const TypeMapping = MuiWebapiContainer(
	{
		endpointPath: 'type_mapping.typeMapping_get',
		dataPaths: { data: ['TYPE_MAPPINGS'] },
	},
	{
		selector: selector,
	}
);

makeOptionsProvider(TypeMapping, {
	includeData: false,
	makeOption: (entry) => {
		return {
			value: entry.NAME,
			label: entry.TRANSLATIONS,
		};
	},
});

export { TypeMapping };
