import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
	placeholder: {
		background: theme.palette.warning.light,
		// margin: 'auto',
		textAlign: 'center',
		'& .MuiCardContent-root': {
			padding: theme.spacing(0.5),
		},
	},
}));

export const MuiPlaceholder = ({
	theme,
	height,
	width,
	componentName,
	componentDesc,
	renderChildren,
	children,
}) => {
	const classes = useStyles({ theme });

	return (
		<Card
			variant={'outlined'}
			square
			className={classes.placeholder}
			style={{
				height,
				width,
			}}
		>
			<CardContent>
				<Typography variant={'body1'} component={'div'}>
					{componentName}
				</Typography>
				{!_.isNil(componentDesc) && { componentDesc }}
				{renderChildren && children}
			</CardContent>
		</Card>
	);
};

MuiPlaceholder.propTypes = {
	componentName: PropTypes.string,
	componentDesc: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	renderChildren: PropTypes.bool,
};

MuiPlaceholder.defaultProps = {
	componentName: 'placeholder',
	height: '100%',
	width: 'auto',
	renderChildren: false,
};
