import React from 'react';
import { makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';

import TableRow from '@material-ui/core/TableRow';
import { MuiActionContainer } from './MuiActionContainer';

import PropTypes from 'prop-types';
import _ from 'lodash';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	colAction: {
		width: 30,
		minWidth: 30,
		// '& .PrivateSwitchBase-root': { border: '1px solid red' },
		'& .MuiFormControlLabel-root': { marginRight: 0 },
		'& .MuiButtonBase-root': {
			padding: 0,
		},
		'&:first-child': {
			paddingRight: [0, '!important'],
		},
		'&:last-child': {
			paddingLeft: [0, '!important'],
		},
		'&:not(:last-child):not(:first-child)': {
			paddingLeft: [0, '!important'],
			paddingRight: [0, '!important'],
		},
	},
}));

const Header = (props) => {
	const classes = useStyles(props);
	return <TableCell className={clsx(classes.colAction)} />;
};

const Row = (props) => {
	const classes = useStyles(props);
	return (
		<TableCell className={clsx(classes.colAction)}>{props.children}</TableCell>
	);
};

const MuiActionContainerTableCell = {
	Header,
	Row,
};

const InnerRow = ({ container, children }) => {
	return (
		<TableRow>
			<MuiActionContainer.RenderSections
				sections={['left']}
				container={container}
			/>
			{children}
			<MuiActionContainer.RenderSections
				sections={['right']}
				container={container}
			/>
		</TableRow>
	);
};

InnerRow.propTypes = {
	container: PropTypes.any.isRequired,
};

const MuiActionContainerTableRow = ({ children, variant, actionId, value }) => {
	const container =
		variant === 'header'
			? MuiActionContainer.TableCell.Header
			: MuiActionContainer.TableCell.Row;

	const renderActionContext = _.isNil(value) && _.isNil(actionId);
	if (renderActionContext)
		return <InnerRow {...{ container }}>{children}</InnerRow>;
	return (
		<MuiActionContainer.ActionContext.Provider {...{ value, actionId }}>
			<InnerRow {...{ container }}>{children}</InnerRow>
		</MuiActionContainer.ActionContext.Provider>
	);
};
MuiActionContainerTableRow.propTypes = {
	variant: PropTypes.string,
	value: PropTypes.any,
	actionId: PropTypes.any,
};
MuiActionContainerTableRow.defaultProps = {
	variant: 'row',
};

export { MuiActionContainerTableRow, MuiActionContainerTableCell };
