/* eslint-disable prettier/prettier */
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import React, { useContext } from 'react';
import { getTranslation, Tr } from '@lsoft/translate';
import { makeStyles } from '@material-ui/core';
import { fromValue, inputComponents, toValue } from './input_components';
import { funcWrap } from '../../core/funcWrap';
import clsx from 'clsx';
import { MuiFormFieldOptions } from '../MuiFormFieldOptions';
import { MuiInputStyles } from './MuiInputStyles';
import { useRouter } from 'next/router';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(MuiInputStyles);

const transformNewProps = {};
//needs to be called first
transformNewProps.getMuiFormProps = (newProps, muiForm) => {
	if (_.isNil(muiForm)) return;
	const muiFormField = _.get(newProps, 'muiFormField', null);
	if (muiFormField !== null) {
		const formMeta = muiForm.getFormMeta();
		const fieldMeta = _.get(formMeta, muiFormField, null);
		//overwrite label with MuiForm label for this field
		newProps.label = _.get(fieldMeta, 'label', newProps.label);
		//overwrite placeholder with MuiForm placeholder for this field
		newProps.placeholder = _.get(
			fieldMeta,
			'placeholder',
			newProps.placeholder,
		);
		//overwrite noLabel with MuiForm noLabel for this field
		if (_.get(fieldMeta, 'noLabel', null) !== null) {
			newProps.noLabel = fieldMeta.noLabel;
		}
		//set error flag with muiForm validation.valid for this field
		if (!newProps.error) {
			newProps.error = !_.get(fieldMeta, ['validation', 'valid'], true);
		}

		newProps.onChange = funcWrap(
			newProps.onChange,
			(onChange) =>
				async (...args) => {
					//no await before onChange! it leads to caretposition error in normal textfield
					onChange(...args);
					await muiForm.setValue(muiFormField, ...args);
				},
		);
	}
};
// handle options from MuiFormFieldOptions
transformNewProps.getMuiFormFieldOptions = (newProps, muiFormFieldOptions) => {
	if (_.isNil(muiFormFieldOptions)) return;
	newProps.options = muiFormFieldOptions.data;
};

//handle variant/styleVariant
transformNewProps.variants = (newProps) => {
	//save variant in _inputVariant. needed for checks
	newProps['_inputVariant'] = newProps.variant;
	//rewrite styleVariant in the MuiWay
	newProps['variant'] = newProps.styleVariant;
};

transformNewProps.className = (newProps, classes) => {
	if (newProps.styleVariant === 'tablecell')
		newProps.className = clsx(
			newProps.className,
			classes.MuiInput,
			classes.MuiInputTableCellInput,
		);
	newProps.sharedClasses = classes;
};

// handles noLable flag/labelShrink
transformNewProps.label = (newProps) => {
	if (_.get(newProps, 'noLabel', false)) {
		delete newProps.label;
	}
	//checks for labelShrink flag in newProps and rewrite it in the MuiWay
	if (_.get(newProps, 'shrink', false)) {
		newProps['InputLabelProps'] = _.assign({}, newProps['InputLabelProps'], {
			shrink: true,
		});
	}
};

//calls wraps onChange with ToValue (validation/applyConverter)
transformNewProps.toValue = (newProps) => {
	newProps.onChange = funcWrap(newProps.onChange, (onChange) => (val) => {
		onChange(toValue(val, newProps));
	});
};

transformNewProps.debounceOnChange = (newProps) => {
	newProps.onChange = funcWrap(newProps.onChange, (onChange) => (val) => {
		if (val !== newProps.value) onChange(val);
	});
};

//value <--Fromvalue
transformNewProps.value = (newProps) => {
	newProps.value = fromValue(newProps);
};

//transform label/placeholder Objects to Tr components if needed.
transformNewProps.trs = (newProps) => {
	if (typeof newProps.label === 'object') {
		newProps.trLabel = newProps.label;
		newProps.label = <Tr tr={newProps.label} />;
	}
	if (typeof newProps.placeholder === 'object') {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { locale } = useRouter();
		newProps.trPlaceholderString = getTranslation(newProps.placeholder, locale);
		newProps.trPlaceholder = newProps.placeholder;
		newProps.placeholder = <Tr tr={newProps.placeholder} />;
	}
};

transformNewProps.errors = (newProps) => {
	const joinList = (l, sep) => {
		let ret = [];
		const maxLength = l.length;
		l.map((i, idx) => {
			ret.push(i);
			if (idx + 1 < maxLength) ret.push(sep);
		});
		return ret;
	};
	newProps.errorMessages = newProps.noErrorDisplay
		? []
		: _.get(newProps, 'errors', []);
	newProps.error = newProps.errorMessages.length !== 0;

	const hideErrorsIfEmpty = _.get(newProps, 'hideErrorsIfEmpty', false);
	if (hideErrorsIfEmpty) {
		if (newProps.error && newProps.value === newProps.emptyValue) {
			newProps.error = false;
			newProps.helperText = [];
			newProps.errorMessages = [];
		}
	}

	const convertedErrorMessages = newProps.errorMessages.map((m, idx) => {
		if (_.isNil(m)) return null;
		if (typeof m === 'string') return m;
		if (typeof m === 'object') return <Tr key={idx} tr={m} />;
	});
	newProps.helperText = joinList(convertedErrorMessages, ' / ');
};

// hack for removing defaultProps of MuiInput
const MuiInputDefaults = {
	noErrorDisplay: false,
	allowedCharacters: null,
	disabled: false,
	disabledOn: false,
	disabledShortcuts: null,
	converter: [],
	color: 'primary',
	fullWidth: true,
	onChange: (...args) => args,
	onFocus: () => {},
	onBlur: () => {},
	validators: [],
	styleVariant: 'outlined',
	noLabel: false,
	labelShrink: false,
	optionalReturnEmptyNull: false,
	displayDisabled: true,
	displayUnknown: true,
	autoFocus: false,
};
/*
 * Base Input Component
 * Renders an input based on props (switch / number / text) */
export const MuiInput = React.memo((realProps) => {
	const props = _.assign({}, MuiInputDefaults, realProps);
	// Set up Hooks
	// const muiForm = useContext(MuiFormContext);
	const muiFormFieldOptions = useContext(MuiFormFieldOptions);
	const classes = useStyles(props);

	const newProps = _.assign({}, props);

	//checking if variant exists
	if (!_.has(inputComponents, props.variant)) {
		throw {
			name: 'InputComponentNotExisting',
			message: `InputComponent '${props.variant}' not existing.`,
			toString: () => `InputComponent '${props.variant}' not existing.`,
		};
	}

	//next line next props variant. because newProps.variant gets overwriten with styleVariant
	const { Component, getNewProps } = inputComponents[props.variant];

	// Needs to be called first. getMuiFormProps() getsInfo from MuiForm and applies it to newProps
	// transformNewProps.getMuiFormProps(newProps, muiForm);

	//handles options from MuiFormFieldOptions
	transformNewProps.getMuiFormFieldOptions(newProps, muiFormFieldOptions);

	//className transformation
	transformNewProps.className(newProps, classes);

	//only call onChange on real changes
	// transformNewProps.debounceOnChange(newProps);

	//handles variant/inputvariants
	transformNewProps.variants(newProps);

	//deletes label if NoLabel flag is set
	transformNewProps.label(newProps);

	//calls wraps onChange with ToValue (validation/applyConverter)
	transformNewProps.toValue(newProps);

	//value <--Fromvalue
	transformNewProps.value(newProps);

	//transform label/placeholder Objects to Tr components if needed.
	transformNewProps.trs(newProps);

	//errors
	transformNewProps.errors(newProps);

	// props transformation of input_component
	getNewProps(newProps);

	return <Component {...newProps} />;
});

MuiInput.propTypes = {
	autoFocus: PropTypes.bool,
	allowedCharacters: PropTypes.string,
	disabled: PropTypes.bool,
	disabledShortcuts: PropTypes.array,
	fullWidth: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
	color: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	styleVariant: PropTypes.string,
	converter: PropTypes.array,
	value: PropTypes.any,
	variant: PropTypes.string,
	id: PropTypes.string,
	muiFormField: PropTypes.string,
	validators: PropTypes.array,
	noLabel: PropTypes.bool,
	labelShrink: PropTypes.bool,
	optionalEmpty: PropTypes.any,
	optionalReturnEmptyNull: PropTypes.bool,
	name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	noErrorDisplay: PropTypes.bool,
	readOnly: PropTypes.bool,
	suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	password: PropTypes.bool,
	upperCase: PropTypes.bool,
	lowerCase: PropTypes.bool,
	decimals: PropTypes.number,
	decimalSeparator: PropTypes.string,
	textAlign: PropTypes.string,
	displayEmpty: PropTypes.bool,
	options: PropTypes.array,
	placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
	displayDisabled: PropTypes.bool,
	displayUnknown: PropTypes.bool,
	disabledOn: PropTypes.bool,
};
