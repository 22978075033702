import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { GTMCheckoutProviderContext } from '../container/shop/GTMCheckoutProvider';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { Tr } from '@lsoft/translate';
import { trProceedToCheckout } from './Header/Header.tr';
import PropTypes from 'prop-types';

export const ProceedToCheckoutButton = ({ onClick }) => {
	const { push } = useRouter();
	const gtmCheckoutProviderContext = useContext(GTMCheckoutProviderContext);

	const handleClick = async () => {
		if (!_.isNil(gtmCheckoutProviderContext)) {
			await gtmCheckoutProviderContext.checkout_custom_event('checkout_begin');
		}
		if (_.isFunction(onClick)) {
			await onClick();
		}
		await push('/checkout/order_information');
	};

	return (
		<Button color={'primary'} variant={'contained'} onClick={handleClick}>
			<Tr tr={trProceedToCheckout} />
		</Button>
	);
};

ProceedToCheckoutButton.propTypes = {
	onClick: PropTypes.any,
};

ProceedToCheckoutButton.defaultProps = {
	onClick: null,
};
