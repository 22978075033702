// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MuiPaginationContext } from './MuiPaginationContext';

const MuiPaginationIterator = (props) => {
	const { view, DATA, initialized } = useContext(MuiPaginationContext);
	const IDS = _.get(view, 'IDS', null);

	if (IDS === null || !initialized) return null;

	return IDS.map((ID) => props.renderRow(ID, _.get(DATA, ID, null)));
};
MuiPaginationIterator.propTypes = {
	renderRow: PropTypes.func.isRequired,
};

export { MuiPaginationIterator };
