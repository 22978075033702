import { Container, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

export const MuiCSSContainer = ({ children, ...props }) => {
	const theme = useTheme();
	const disableGutters = useMediaQuery((theme) =>
		theme.breakpoints.down(theme.breakpoints.CSSContainerDisableGutters)
	);
	return (
		<Container
			maxWidth={theme.breakpoints.CSSContainerMaxWidth}
			disableGutters={disableGutters}
			{...props}
		>
			{children}
		</Container>
	);
};
