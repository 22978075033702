import React from 'react';
import PropTypes from 'prop-types';
import {
	Chip,
	Divider,
	Grid,
	makeStyles,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { Tr } from '@lsoft/translate';
import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';
import _ from 'lodash';
import { trNew, trRare } from './ShopSearch.tr';

const useStyles = makeStyles((theme) => ({
	ResultName: {
		'text-overflow': 'ellipsis',
		overflow: 'hidden',
	},
	AutocompleteResult: {
		padding: [
			[
				theme.spacing(0.5),
				theme.spacing(2),
				theme.spacing(0.5),
				theme.spacing(2),
			],
		],
	},
	AutocompleteResultDivider: {
		width: '100%',
		backgroundColor: grey[100],
	},
	AutocompleteChip: {
		marginRight: theme.spacing(0.5),
		[theme.breakpoints.up('lg')]: {
			marginLeft: theme.spacing(0.5),
		},
		'&.MuiChip-root': {
			height: 'auto',
			borderRadius: theme.spacing(0.5),
		},
		'& .MuiChip-label': {
			padding: [[theme.spacing(0.25), theme.spacing(0.5)]],
			fontSize: '0.6rem',
		},
	},
}));

const AutocompleteChip = ({ ...props }) => {
	const classes = useStyles();
	return <Chip {...props} className={classes.AutocompleteChip} />;
};

export const ShopSearchAutocompleteResult = ({ data }) => {
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
	const classes = useStyles();
	return (
		<Grid container>
			<Grid item xs={12}>
				<Divider className={clsx(classes.AutocompleteResultDivider)} />
			</Grid>
			<Grid item xs={12}>
				<Grid
					container
					direction={'row'}
					alignItems={'center'}
					className={clsx(classes.AutocompleteResult)}
				>
					<Grid item xs={12} lg={8}>
						<Typography variant={'body2'} className={clsx(classes.ResultName)}>
							<Tr tr={data.name_text} />
						</Typography>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Grid container justifyContent={lgUp ? 'flex-end' : 'flex-start'}>
							<Grid item>
								{_.includes(data.group_tags, 'NEW') && (
									<AutocompleteChip
										label={<Tr tr={trNew} />}
										color={'secondary'}
									/>
								)}
								{_.includes(data.group_tags, 'RARE') && (
									<AutocompleteChip
										label={<Tr tr={trRare} />}
										color={'secondary'}
									/>
								)}
								<AutocompleteChip label={data.msku} />
								<AutocompleteChip label={data.mname} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

ShopSearchAutocompleteResult.propTypes = {
	data: PropTypes.object.isRequired,
};
ShopSearchAutocompleteResult.defaultProps = {};
