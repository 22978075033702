/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { makePick, pickDefault } from './input_components';
import { MuiFormControlOutline } from '../../MuiFormControlOutline';
import { funcWrap } from '../../../core';
import { makeStyles, MenuItem, MenuList } from '@material-ui/core';
import { StringOrTr } from '@lsoft/translate';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';

const pick = makePick(
	['fullWidth', 'className', 'label', 'noLabel', 'classes'],
	pickDefault
);

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	MenuList: {
		paddingLeft: theme.spacing(1),
		paddingTop: 0,
		'& .Mui-selected': {
			backgroundColor: theme.palette.primary[400],
			color: theme.palette.primary.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.primary[500],
			},
		},
	},
	Prefix: {
		width: 30,
		fontSize: 10,
		lineHeight: 2.1,
	},
}));

const Component = (newProps) => {
	const classes = useStyles();

	const menuListProps = _.assign(
		{},
		{
			dense: true,
			value: newProps.value,
			component: 'nav',
		},
		newProps.noLabel ? {} : {}
	);
	const picked_props = pick(newProps);
	return (
		<MuiFormControlOutline {...picked_props}>
			<MenuList {...menuListProps} className={clsx(classes.MenuList)}>
				{newProps.options.map((option) => {
					const prefix = _.get(option, 'prefix', null);

					return (
						<MenuItem
							key={option.value}
							selected={option.value === newProps.value}
							disabled={newProps.disabled}
							onClick={() => {
								newProps.onChange(option.value);
							}}
						>
							{!_.isNil(prefix) && (
								<span className={clsx(classes.Prefix)}>
									{option.prefix} &nbsp; &nbsp;
								</span>
							)}
							<StringOrTr value={option.label} />
						</MenuItem>
					);
				})}
			</MenuList>
		</MuiFormControlOutline>
	);
};

const getNewProps = (newProps) => {
	if (_.isNil(newProps.options))
		throw new Error(`Missing options for ic_select`);
	newProps.onChange = funcWrap(
		newProps.onChange,
		(onChange) => async (value) => {
			onChange(value);
		}
	);

	return newProps;
};

Component.propTypes = {
	newProps: PropTypes.object,
};

export const ic_list_select = {
	Component,
	getNewProps,
};
