import React, { Fragment, useContext } from 'react';
import { MuiPaginationContext } from './MuiPaginationContext';
import { Tr } from '@lsoft/translate';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';

// import { createStyles, makeStyles } from '@material-ui/core';
// import styles from '../../styles/styles';

// const useStyles = makeStyles((theme) =>
// 	createStyles({
// 		PaginationInfo: {
// 			...styles(theme).taCenter,
// 			padding: [
// 				[
// 					theme.spacing(0.25),
// 					theme.spacing(1),
// 					theme.spacing(0.25),
// 					theme.spacing(1),
// 				],
// 			],
// 			margin: theme.spacing(1),
// 			textTransform: 'unset',
// 			borderStyle: 'solid',
// 			borderWidth: 1,
// 			borderRadius: theme.spacing(1),
// 			borderColor: theme.palette.primary[500],
// 			minWidth: 170,
// 		},
// 	})
// );

const MuiPaginationInfo = () => {
	const { view, start, end, initialized } = useContext(MuiPaginationContext);
	const max = _.get(view, 'MAX_RESULTS', null);

	if (!initialized) return null;
	return (
		<Typography component={'span'} variant={'body2'}>
			<Tr
				tr={{
					DE: 'Artikel',
					EN: 'Items',
				}}
			/>
			&nbsp;{start}&nbsp;{'-'}&nbsp;{end}&nbsp;
			<Tr
				tr={{
					DE: 'von',
					EN: 'of',
				}}
			/>
			&nbsp;
			{max}
		</Typography>
	);
};

export { MuiPaginationInfo };
