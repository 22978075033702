import { makePick, pickDefault } from './input_components';
import React, { useContext, useEffect, useRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import _ from 'lodash';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { funcWrap } from '../../../core/funcWrap';
import { StringOrTr, Tr, TrContext } from '@lsoft/translate';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Input from '@material-ui/core/Input';

const pick = makePick(
	['fullWidth', 'value', 'displayEmpty', 'options', 'variant', 'error'],
	pickDefault
);
const defaultPlaceholder = {
	EN: 'Please select',
	DE: 'Bitte wählen',
	FR: 'Choisir une option',
};

const Component = (newProps) => {
	// if (newProps.options.length === 0) return null;

	const inputLabel = useRef(null);
	const [labelWidth, setLabelWidth] = React.useState(0);
	useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, [inputLabel]);
	return (
		<FormControl {..._.pick(newProps, ['fullWidth', 'size'])}>
			<InputLabel ref={inputLabel} variant={newProps.styleVariant} shrink>
				{newProps.label}
			</InputLabel>
			{newProps.options.length !== 0 && (
				<Select
					input={
						newProps.variant === 'outlined' ? (
							<OutlinedInput
								labelWidth={labelWidth}
								notched={Boolean(newProps.label)}
							/>
						) : (
							<Input id="select-multiple-chip" />
						)
					}
					renderValue={() => {
						if (
							['', null].includes(newProps.value) ||
							_.isUndefined(newProps.value)
						) {
							// Return placeholder
							return newProps.placeholder;
						} else {
							// Return label of current value
							const tr_filter = newProps.options.filter(
								(option) => option.value === newProps.value
							);
							const tr =
								tr_filter.length === 0
									? 'missing option label'
									: tr_filter[0].label;
							return <StringOrTr value={tr} />;
						}
					}}
					{...pick(newProps)}
				>
					{newProps.options.map((option) => {
						const disabled = _.get(option, 'disabled', false);
						return (
							<MenuItem
								key={option.value}
								value={option.value}
								disabled={disabled}
							>
								<StringOrTr value={option.label} />
							</MenuItem>
						);
					})}
				</Select>
			)}
		</FormControl>
	);
};

Component.propTypes = {
	newProps: PropTypes.object,
};

export const ic_select = {
	// eslint-disable-next-line react/display-name
	Component,
	getNewProps: (newProps) => {
		if (_.isNil(newProps.options))
			throw new Error(`Missing options for ic_select`);

		if (!_.isNil(newProps.value2)) {
			newProps.options = newProps.options.filter(
				(entries) => entries.value !== newProps.value2
			);
		}

		newProps.variant = newProps.styleVariant;
		newProps.onChange = funcWrap(
			newProps.onChange,
			(onChange) => async (e) => onChange(e.target.value)
		);

		if (newProps.displayEmpty && !_.has(newProps, 'placeholder')) {
			newProps.placeholder = <Tr tr={defaultPlaceholder} />;
		}
		if (_.get(newProps, 'selectableNull', false)) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const trProvider = useContext(TrContext);
			const trString = trProvider.getTranslationFromData(
				newProps.trPlaceholder
			);
			newProps.options = [{ label: trString, value: null }].concat(
				newProps.options
			);
			// newProps.options = [{ label: '', value: null }].concat(newProps.options);
		}
		if (_.get(newProps, 'value', null) === null) {
			newProps.value = '';
		}
		return newProps;

		//	übergebene props abändern
	},
};
