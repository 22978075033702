import React, { useContext, useEffect, useReducer } from 'react';
import _ from 'lodash';

const makeReducer = (options) => {
	return (state, action) => {
		const joinedReducers = _.assign(
			{},
			{
				// eslint-disable-next-line no-unused-vars
				replaceState: (state, action) => action.payload,
			},
			options.editorReducer
		);
		const reducerFunction = _.get(
			joinedReducers,
			action.type,
			// eslint-disable-next-line no-unused-vars
			(state, action) => state
		);
		return reducerFunction(state, action);
	};
};

const editorContextFunctions = {
	replaceState: (context, state) => {
		context.editorDispatch({ type: 'replaceState', payload: state });
	},
};
const getSortedList = () => {};

const getDataChanged = (options, editorData, parentData) => {
	const dataChangedListSortIdentifier = _.get(
		options,
		'dataChangedListSortIdentifier',
		null
	);
	if (_.isNil(dataChangedListSortIdentifier)) {
		const ret = !_.isEqual(editorData, parentData);
		return ret;
	}

	let copiedEditorData = _.assign({}, editorData);
	let copiedParentData = _.assign({}, parentData);

	dataChangedListSortIdentifier.map((path) => {
		const copiedEditorDataValueSorted = getSortedList(copiedEditorData, path);
		// const copiedParentDataValue = _.get(copiedParentData, path);
		// console.log(copiedEditorDataValue, '   ', copiedParentDataValue);
		// _.set(updateObject, _.join([path, '$set'], '.'), actionPathValue);
		// const copiedEditorDataValue = Array.of(_.get(copiedEditorData, path));
		// _.set(copiedEditorData, _.join([path, '$set'], '.'), copiedEditorDataValue);
		// const copiedParentDataValue = Array.of(_.get(copiedParentData, path));
		// _.set(copiedParentData, _.join([path, '$set'], '.'), copiedParentDataValue);
	});
	return !_.isEqual(copiedEditorData, copiedParentData);
};

const combineContext = (context, data) => {
	Object.entries(data).map(([key, value]) => {
		context[key] = _.assign({}, _.get(context, key, null), value);
	});
};

const applyContextFunctions = (context) => {
	Object.entries(context.contextFunctions).map(([name, func]) => {
		context[name] = (...args) => func(context, ...args);
	}, {});
};

const applyContextAdditionalData = (context) => {
	const additionalData = {};
	Object.entries(context.contextAdditionalData).map(([name, func]) => {
		additionalData[name] = func(context);
	});
	context.additionalData = additionalData;
};

const makeContainer = (options, Context) => {
	const newOptions = _.assign(
		{},
		{ editorFunctions: {}, editorReducer: {} },
		{ contextFunctions: {}, contextAdditionalData: {} },
		{ ...options }
	);
	// eslint-disable-next-line react/display-name
	return ({ children }) => {
		const parentContext = useContext(newOptions.parentContext);
		const reducer = makeReducer(newOptions);
		const [state, dispatch] = useReducer(reducer, parentContext.data);

		useEffect(() => {
			dispatch({ type: 'replaceState', payload: parentContext.data });
			// eslint-disable-next-line
		}, [parentContext.data]);

		// apply parent context functions without data
		const context = _.omit(parentContext, ['data']);
		// apply editor context state
		_.merge(context, { data: state });
		// editor context related data
		_.merge(context, { editorDispatch: dispatch });

		const dataChanged = getDataChanged(options, state, parentContext.data);
		_.merge(context, {
			dataChanged: dataChanged,
		});
		// _.merge(context, { ci: 'ProductEditor' });
		combineContext(context, {
			contextFunctions: editorContextFunctions,
		});
		// combine context
		combineContext(context, {
			contextFunctions: newOptions.contextFunctions,
			contextAdditionalData: newOptions.contextAdditionalData,
		});

		// apply context
		applyContextFunctions(context);
		applyContextAdditionalData(context);

		return <Context.Provider value={context}>{children}</Context.Provider>;
	};
};
const MuiEditorContainer = (containerOptions) => {
	const Context = React.createContext(null);
	Context.displayName = 'MuiEditorContainer.Context';
	const Container = makeContainer(containerOptions, Context);
	return {
		Container,
		Context,
	};
};

export { MuiEditorContainer };
