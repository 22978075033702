import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import clsx from 'clsx';
import { SimpleTranslationStyles } from './simple_translation.styles';
import { makePick, pickDefault } from './input_components';
import { funcWrap } from '../../../core/funcWrap';
import {
	MuiTranslationContainer,
	TranslationContainerContext,
} from '../../MuiTranslationContainer';

const useStyles = makeStyles((theme) => ({
	...SimpleTranslationStyles(theme),
}));

const TranslationSelect = ({
	theme,
	lc,
	availableLanguages,
	onChange,
	variant,
	className,
}) => {
	const classes = useStyles({ theme });
	const options = availableLanguages.map((i) => (
		<MenuItem key={i} value={i}>
			{i}
		</MenuItem>
	));
	return (
		<Select
			value={lc}
			color={'primary'}
			variant={variant}
			onChange={(e) => {
				onChange(lc, e.target.value);
			}}
			className={clsx(classes.Select, className)}
		>
			{options}
		</Select>
	);
};
TranslationSelect.propTypes = {
	lc: PropTypes.string,
	variant: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	availableLanguages: PropTypes.arrayOf(PropTypes.string),
	size: PropTypes.string,
};

const SimpleTranslationLine = ({ theme, lc, variant }) => {
	const context = useContext(TranslationContainerContext);
	const classes = useStyles({ theme });
	const text = context.getText(lc);
	return (
		<Box
			className={clsx(
				classes.FormControl,
				variant === 'outlined' ? classes.FormControlOutlined : null
			)}
		>
			<Grid
				container
				direction={'row'}
				className={clsx(classes.FormControlInner)}
				justifyContent={'space-between'}
				alignItems={'center'}
				spacing={0}
			>
				<Grid item className={clsx(classes.GridSelect)}>
					<TranslationSelect
						lc={lc}
						availableLanguages={context.selectableLanguages(lc)}
						onChange={(old_lc, new_lc) =>
							context.changeTranslationLanguage(old_lc, new_lc)
						}
						variant={'standard'}
					/>
				</Grid>
				<Grid item xs>
					<Input
						size={'small'}
						value={text}
						className={clsx(classes.Input)}
						onChange={(e) => context.changeTranslationText(lc, e.target.value)}
					/>
				</Grid>
				<Grid item className={clsx(classes.GridButton)}>
					<IconButton
						size={'small'}
						color={'primary'}
						onClick={() => context.deleteTranslation(lc)}
					>
						<RemoveIcon />
					</IconButton>
				</Grid>
			</Grid>
		</Box>
	);
};

SimpleTranslationLine.propTypes = {
	lc: PropTypes.string,
	variant: PropTypes.string,
};
SimpleTranslationLine.defaultProps = {
	variant: 'outlined',
};

const SimpleTranslationAddButton = ({ theme, variant }) => {
	const context = useContext(TranslationContainerContext);
	const [lc, setLc] = useState(context.addableLanguages[0]);
	const classes = useStyles({ theme });
	if (context.addableLanguages.length === 0) return null;
	if (!_.includes(context.addableLanguages, lc))
		setLc(context.addableLanguages[0]);
	const handleOnAdd = () => {
		context.changeTranslationText(lc, '');
	};
	return (
		<Box
			className={clsx(
				classes.FormControl,
				classes.AddButton,
				variant === 'outlined' ? classes.FormControlOutlined : null
			)}
		>
			<Grid
				container
				direction={'row'}
				className={clsx(classes.FormControlInner)}
				justifyContent={'space-between'}
				alignItems={'center'}
				spacing={0}
			>
				<Grid item className={clsx(classes.GridSelect)}>
					<TranslationSelect
						availableLanguages={context.addableLanguages}
						lc={lc}
						onChange={(old_lc, new_lc) => setLc(new_lc)}
						variant={'standard'}
					/>
				</Grid>
				<Grid item className={clsx(classes.GridButton)}>
					<IconButton size={'small'} color={'primary'} onClick={handleOnAdd}>
						<AddIcon />
					</IconButton>
				</Grid>
			</Grid>
		</Box>
	);
};

SimpleTranslationAddButton.propTypes = {
	variant: PropTypes.string,
};
SimpleTranslationAddButton.defaultProps = {
	variant: 'outlined',
};

const SimpleTranslationInner = () => {
	const { usedLanguages } = useContext(TranslationContainerContext);
	return (
		<Grid container direction={'row'} spacing={1}>
			{usedLanguages.sort().map((lc) => (
				<Grid key={lc} item xs={12}>
					<SimpleTranslationLine lc={lc} />
				</Grid>
			))}
			<Grid item key={'ADD'}>
				<SimpleTranslationAddButton />
			</Grid>
		</Grid>
	);
};

const SimpleTranslation = ({ value, availableLanguages, onChange }) => {
	return (
		<MuiTranslationContainer
			tr={value}
			availableLanguages={availableLanguages}
			onChange={onChange}
		>
			<SimpleTranslationInner />
		</MuiTranslationContainer>
	);
};

SimpleTranslation.propTypes = {
	value: PropTypes.object,
	availableLanguages: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func,
};
SimpleTranslation.defaultProps = {
	onChange: (value) => {
		console.log('SimpleTranslation onChange:', value);
	},
};

const pick = makePick(['availableLanguages', 'value', 'onChange'], pickDefault);
const Component = (newProps) => {
	return <SimpleTranslation {...pick(newProps)} />;
};

Component.propTypes = {
	availableLanguages: PropTypes.array,
};

Component.defaultProps = {
	availableLanguages: ['DE', 'EN', 'FR', 'IT', 'PT'],
};

const ic_simple_translation = {
	// eslint-disable-next-line react/display-name
	Component,
	getNewProps: (newProps) => {
		newProps.onChange = funcWrap(
			newProps.onChange,
			(onChange) => async (val) => {
				// console.log(val);
				onChange(val);
			}
		);
		return newProps;
	},
};

export {
	ic_simple_translation,
	SimpleTranslation,
	TranslationSelect,
	SimpleTranslationAddButton,
};
