export const tr_visit_our_letzshop_profile = {
	DE: 'Besuchen Sie unser Letzshop-Profil',
	EN: 'Visit our Letzshop profile',
	FR: 'Visitez notre profil sur letzshop.lu',
};

// export const tr_visit_our_letzshop_profile = {
// 	DE: 'Finden sie das ideale Geschenk',
// 	EN: 'Visit our Letzshop profile',
// 	FR: 'Visitez notre profil sur letzshop.lu',
// };
