import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import {
	Cart,
	convert_raw_product_data_to_session_product,
} from '@lsoft/shop-ltoys/src/container/shop/Cart';
import {
	product_check_discount_available,
	product_stock_info_variant,
} from '@lsoft/shared/functions/shop/product';
import { LsDataContainer } from '@lsoft/shared/components/LsContainer';
import {
	ProductContainer,
	ProductContext,
} from '@lsoft/shared/container/Product';
import { GTMProductListContext } from './GTMProductList';
import { MuiLink } from '@lsoft/mui';
import PropTypes from 'prop-types';

export const ShopProductContext = React.createContext(null);

const ShopProductChildContainer = ({ children, ...props }) => {
	// noinspection JSCheckFunctionSignatures
	const cart_context = useContext(Cart.Context);
	// noinspection JSCheckFunctionSignatures
	const gtm_product_list_context = useContext(GTMProductListContext);
	// noinspection JSCheckFunctionSignatures
	const { get_gtm_product } = useContext(GTMProductListContext);

	const extendContext = useCallback(({ data, functions }) => {
		if (!_.isNil(data)) {
			data.group_tags = _.defaultTo(_.get(data, 'group_tags'), []);

			// All things discount
			data.discount_start_date = _.get(data, 'discount_start_date');
			data.discount_end_date = _.get(data, 'discount_end_date');
			data.discount_only_stock = _.get(data, 'discount_only_stock', true);
			data.netto_dp = _.get(data, 'netto_dp');
			data.brutto_dp = _.get(data, 'brutto_dp');
			data.discount_available = product_check_discount_available(data);
			data.real_netto_sp = !data.discount_available
				? data.netto_sp
				: data.netto_dp;
			data.real_brutto_sp = !data.discount_available
				? data.brutto_sp
				: data.brutto_dp;
			data.discount = !data.discount_available
				? 0
				: Math.round(100 - (100 / data.brutto_sp) * data.brutto_dp);

			data.rare = data.group_tags.includes('RARE');
			data.gt_new = data.group_tags.includes('NEW');

			data.stock_info_variant = product_stock_info_variant(data);

			data.product_impression_data = () => {
				if (_.isNil(data) && _.isNil(gtm_product_list_context)) return null;
				const list = gtm_product_list_context.list;
				return get_gtm_product(data, list);
			};
		}

		if (!_.isNil(functions)) {
			functions.product_add_to_cart = async (amount) => {
				if (_.isNil(cart_context) || _.isEmpty(cart_context)) return null;
				const productData = convert_raw_product_data_to_session_product(
					data.sku,
					amount,
					data
				);
				await cart_context.position_add(productData);
				const new_product_impression_data = _.merge(
					{},
					data.product_impression_data,
					{
						quantity: amount,
					}
				);
				await gtm_product_list_context.product_add_to_cart(
					new_product_impression_data
				);
			};

			functions.product_remove_from_cart = async () => {
				if (_.isNil(cart_context)) return null;
				await gtm_product_list_context.product_remove_from_cart(
					data.product_impression_data
				);
				await cart_context.position_remove(data.sku);
			};

			functions.product_increment_cart_position = async (amount) => {
				if (_.isNil(cart_context)) return null;
				const productData = convert_raw_product_data_to_session_product(
					data.sku,
					amount + 1,
					data
				);
				const new_product_impression_data = _.merge(
					{},
					data.product_impression_data,
					{
						quantity: 1,
					}
				);
				await cart_context.position_adjust(productData);
				await gtm_product_list_context.product_increment_cart_position(
					new_product_impression_data
				);
			};

			functions.product_decrement_cart_position = async (amount) => {
				if (_.isNil(cart_context)) return null;
				const productData = convert_raw_product_data_to_session_product(
					data.sku,
					amount - 1,
					data
				);
				const new_product_impression_data = _.merge(
					{},
					data.product_impression_data,
					{
						quantity: 1,
					}
				);
				await cart_context.position_adjust(productData);
				await gtm_product_list_context.product_decrement_cart_position(
					new_product_impression_data
				);
			};

			functions.product_click = async () => {
				if (_.isNil(data.product_impression_data)) return null;
				await gtm_product_list_context.product_click(
					data.product_impression_data
				);
			};
		}
		return { data, functions };
	}, []);

	const childLoader = (ctx) => {
		return ctx.data;
	};

	return (
		<LsDataContainer
			context={ShopProductContext}
			parentContext={ProductContext}
			childLoader={childLoader}
			extendContext={extendContext}
			child
			{...props}
		>
			{children}
		</LsDataContainer>
	);
};

export const ShopProductContainer = ({ children, ...props }) => {
	return (
		<ProductContainer {...props}>
			<ShopProductChildContainer>{children}</ShopProductChildContainer>
		</ProductContainer>
	);
};

ShopProductContainer.propTypes = {};
ShopProductContainer.defaultProps = {};

export const ShopProductLink = ({ children, onClick, linkprops, ...props }) => {
	const {
		data: { openProductsLink },
		functions: { product_click },
	} = useContext(ShopProductContext);

	const handleOnClick = async (e) => {
		product_click();
		if (_.isFunction(onClick)) {
			await onClick(e);
		}
	};

	const new_linkprops = _.merge({}, linkprops, {
		onClick: handleOnClick,
	});

	return (
		<MuiLink linkprops={new_linkprops} href={openProductsLink} {...props}>
			{children}
		</MuiLink>
	);
};

ShopProductLink.propTypes = {
	onClick: PropTypes.func,
	linkprops: PropTypes.object,
};
