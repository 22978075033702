import { MuiDrawer, MuiDrawerMenu } from '../MuiDrawer';
import React from 'react';
import { MuiGlobalComponent } from '../MuiGlobal';

const MuiDefaultAppMenu = (props) => {
	return (
		<MuiDrawer>
			<MuiDrawerMenu />
		</MuiDrawer>
	);
};

MuiDefaultAppMenu.propTypes = {};

export const MuiAppMenu = MuiGlobalComponent('_appmenu', MuiDefaultAppMenu);
