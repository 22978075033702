import { useSelector, useStore } from 'react-redux';
import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MuiWebapi } from './MuiWebapiGlobal';
import { MuiFormFieldOptions, MuiFormFieldOptionsContainer } from '../MuiForms';

export const applyContextFunctions = (context) => {
	Object.entries(context.contextFunctions).map(([name, func]) => {
		context[name] = (...args) => func(context, ...args);
	}, {});
};

export const applyContextAdditionalData = (context) => {
	const additionalData = {};
	Object.entries(context.contextAdditionalData).map(([name, func]) => {
		additionalData[name] = func(context);
	});
	context.additionalData = additionalData;
};

export const combineContext = (context, data) => {
	Object.entries(data).map(([key, value]) => {
		context[key] = _.assign({}, _.get(context, key, null), value);
	});
};

export const MuiWebapiBaseContainer = (
	load,
	action,
	Context,
	contextAdditionalData,
	selector,
	contextFunctions,
	contextTransform
) => {
	const WrappedContainer = (props) => {
		const store = useStore();
		const { getState, dispatch } = store;
		const data = useSelector((state) => selector(state, props.options));
		useEffect(() => {
			load(props.options, getState, dispatch);
			// eslint-disable-next-line
		}, [_.isNil(data), props.options]);

		if (_.isNil(data)) return null;

		const context = { data };
		// Apply base functions
		_.merge(context, {
			applyContextFunctions,
			applyContextAdditionalData,
			combineContext,
		});
		// Apply redux functions
		_.merge(context, { dispatch, getState });
		// Apply filtered props
		_.merge(context, _.omit(props, ['children', 'classes', 'context']));

		// combine context
		context.combineContext(context, {
			contextFunctions: contextFunctions,
			contextAdditionalData: contextAdditionalData,
		});

		// apply context functions
		context.applyContextFunctions(context);
		// transform context
		contextTransform(context);
		// apply context additional data
		context.applyContextAdditionalData(context);

		return (
			<Context.Provider value={context}>{props.children}</Context.Provider>
		);
	};
	WrappedContainer.propTypes = { options: PropTypes.object };
	return WrappedContainer;
};

const makeAction = (webapiOptions) => (options) => {
	return MuiWebapi.get().action(
		_.assign({}, webapiOptions, { options: options })
	);
};

const makeAsyncSelector = (selector) => async (state, options) => {
	return selector(state, options);
};

const makeLoad =
	(action, asyncSelector, onError) => async (options, getState, dispatch) => {
		let ret = await asyncSelector(getState(), options);
		if (ret === null) {
			try {
				await dispatch(action(options));
			} catch (e) {
				onError(e);
			}
			ret = await asyncSelector(getState(), options);
		}
		return ret;
	};

const makeWrappedActionComponent = (component) => {
	return component;
};

export const makeAddActionComponent =
	(ActionComponentsCollection) => (name, variant, component) => {
		const accName = _.get(ActionComponentsCollection, name, {});
		accName[variant] = makeWrappedActionComponent(component);
		ActionComponentsCollection[name] = accName;
	};

export const makeActionComponent = (ActionComponentsCollection) => (props) => {
	const ActionComponent = _.get(
		ActionComponentsCollection,
		[props.name, props.variant],
		null
	);
	const actionProps = _.omit(props, ['name', 'variant']);
	return <ActionComponent {...actionProps} />;
};

export const MuiWebapiContainer = (WebapiOptions, ContainerOptions) => {
	const modContainerOptions = _.assign(
		{},
		{
			contextAdditionalData: {},
			contextFunctions: {},
			contextTransform: () => {},
			loadOnce: true,
			shouldLoad: (data) => data === null,
			selector: () => null,
			onError: (e) => {
				throw e;
			},
		},
		ContainerOptions
	);
	const { contextAdditionalData, contextFunctions, contextTransform, onError } =
		modContainerOptions;

	const modWebapiOptions = _.assign({}, WebapiOptions);

	const Context = React.createContext(null);
	// const ReduxSelector = eswebapi.createSelector(modWebapiOptions);
	const selector = modContainerOptions.selector;
	const asyncSelector = !_.has(ContainerOptions, 'selector')
		? async () => null
		: makeAsyncSelector(selector);
	const action = makeAction(modWebapiOptions);

	const load = makeLoad(action, asyncSelector, onError);

	const Container = MuiWebapiBaseContainer(
		load,
		action,
		Context,
		contextAdditionalData,
		selector,
		contextFunctions,
		contextTransform
	);

	const name = _.get(ContainerOptions, 'name', null);
	if (!_.isNil(name)) {
		Container.displayName = `${name}.Container`;
		Context.displayName = `${name}.Context`;
	}

	const ActionComponentCollection = {};
	const addActionComponent = makeAddActionComponent(ActionComponentCollection);
	const ActionComponent = makeActionComponent(ActionComponentCollection);
	// const Container = props => props.children;

	return {
		ActionComponentCollection,
		addActionComponent,
		ActionComponent,
		load,
		asyncSelector,
		selector,
		action,
		webapiOptions: modWebapiOptions,
		Container,
		Context,
	};
};

export const makeOptionsProvider = (container, options) => {
	const realOptions = _.assign(
		{},
		{ name: 'Options', includeData: true },
		options
	);
	// console.log(_.get(realOptions, 'options', null));
	if (_.get(realOptions, 'options', null) === null) {
		// eslint-disable-next-line react/display-name
		container[realOptions.name] = ({ children }) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const ctx = useContext(container.Context);

			if (_.isNil(_.get(ctx, 'data'))) return null;
			let values = Object.values(ctx.data);
			if (!_.isNil(_.get(realOptions, 'filterEntries', null))) {
				values = realOptions.filterEntries(values);
			}
			const data = values.map((e) =>
				_.assign(
					{},
					realOptions.includeData ? { data: e } : {},
					realOptions.makeOption(e)
				)
			);

			return (
				<MuiFormFieldOptionsContainer options={data}>
					{children}
				</MuiFormFieldOptionsContainer>
			);
		};
	} else {
		// eslint-disable-next-line react/display-name
		container[realOptions.name] = ({ children }) => {
			return (
				<MuiFormFieldOptions.Provider value={{ data: realOptions.options }}>
					{children}
				</MuiFormFieldOptions.Provider>
			);
		};
	}
};

export const MuiBaseContainer = (
	// load,
	Context,
	contextAdditionalData,
	contextFunctions,
	contextTransform
) => {
	const WrappedContainer = ({ data, ...props }) => {
		// useEffect(() => {
		// 	load(props.options, getState, dispatch);
		// 	// eslint-disable-next-line
		// }, [_.isNil(data)]);
		if (_.isNil(data)) return null;
		const context = { data };
		// Apply base functions
		_.merge(context, {
			applyContextFunctions,
			applyContextAdditionalData,
			combineContext,
		});
		// Apply redux functions
		// _.merge(context, { dispatch, getState });
		// Apply filtered props
		_.merge(context, _.omit(props, ['children', 'classes', 'context']));

		// combine context
		context.combineContext(context, {
			contextFunctions: contextFunctions,
			contextAdditionalData: contextAdditionalData,
		});

		// apply context functions
		context.applyContextFunctions(context);
		// transform context
		contextTransform(context);
		// apply context additional data
		context.applyContextAdditionalData(context);

		return (
			<Context.Provider value={context}>{props.children}</Context.Provider>
		);
	};
	WrappedContainer.propTypes = { options: PropTypes.object };
	return WrappedContainer;
};

export const MuiContainer = (ContainerOptions) => {
	const modContainerOptions = _.assign(
		{},
		{
			contextAdditionalData: {},
			contextFunctions: {},
			contextTransform: () => {},
			loadOnce: true,
			shouldLoad: (data) => data === null,
		},
		ContainerOptions
	);
	const { contextAdditionalData, contextFunctions, contextTransform } =
		modContainerOptions;
	//
	// const modWebapiOptions = _.assign({}, WebapiOptions);
	//
	const Context = React.createContext(null);
	// // const ReduxSelector = eswebapi.createSelector(modWebapiOptions);
	// const selector = modContainerOptions.selector;
	// const asyncSelector = !_.has(ContainerOptions, 'selector')
	// 	? async () => null
	// 	: makeAsyncSelector(selector);
	// const action = makeAction(modWebapiOptions);
	//
	// const load = makeLoad(action, asyncSelector);
	//
	const Container = MuiBaseContainer(
		// load,
		Context,
		contextAdditionalData,
		contextFunctions,
		contextTransform
	);

	const ActionComponentCollection = {};
	const addActionComponent = makeAddActionComponent(ActionComponentCollection);
	const ActionComponent = makeActionComponent(ActionComponentCollection);
	// const Container = props => props.children;
	//
	return {
		ActionComponentCollection,
		addActionComponent,
		ActionComponent,
		// load,
		// action,
		Container,
		Context,
	};
};
