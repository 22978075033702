/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import _ from 'lodash';
import { funcWrap } from '../../../core/funcWrap';
import Grid from '@material-ui/core/Grid';
import { MuiChipOption } from '../../../MuiChip';

const Component = (newProps) => {
	const onChange = (val, name) => {
		const newValue = val
			? _.union(newProps.value, [name])
			: _.without(newProps.value, name);
		newProps.onChange(newValue);
	};

	const sorted_options = newProps.disableOptionSorting
		? newProps.options
		: _.orderBy(newProps.options, ['name']);

	const options = sorted_options.map((chipProps) => {
		if (!newProps.displayDisabled && chipProps.disabled) return null;
		if (!newProps.displayUnknown && chipProps.unknown) return null;
		chipProps.disabled =
			chipProps.disabled ||
			newProps.readonly ||
			(chipProps.value && newProps.disable_active);

		chipProps.disabledOn = newProps.disabledOn;
		chipProps.onChange = onChange;

		const vertical = newProps.vertical;
		const xs = vertical ? 12 : null;
		chipProps.fullWidth = newProps.fullWidth;

		return (
			<Grid item key={chipProps.name} xs={xs}>
				<MuiChipOption {...chipProps} />
			</Grid>
		);
	});

	return (
		<Grid
			container
			direction={'row'}
			spacing={newProps.vertical ? 2 : 1}
			alignItems={'center'}
		>
			{options}
		</Grid>
	);
};

const prepareOptions = (newProps) => {
	let newOptionsEnabled = [];
	let newOptionsDisabled = [];

	newProps.options = _.get(newProps, 'options', []);
	newProps.disabledOptions = _.get(newProps, 'disabledOptions', []);
	const dual = !_.isNil(newProps.value2);
	newProps.options.map((i) => {
		const disabled = _.includes(newProps.disabledOptions, i.value);
		const entry = {
			name: i.value,
			label: i.label,
			unknown: false,
			disabled: disabled,
			value: _.includes(newProps.value, i.value),
			value2: dual ? _.includes(newProps.value2, i.value) : null,
		};
		if (!disabled) {
			newOptionsEnabled.push(entry);
		} else {
			newOptionsDisabled.push(entry);
		}
	});

	const newOptionsUnknown = _.difference(
		newProps.value,
		newProps.options.map((i) => i.value)
	).map((i) => {
		const tr = _.get(newProps, `trDataForUnkownOptions.${i}.NAME`, null);
		return {
			name: i,
			label: _.isNil(tr) ? { ALL: i } : tr,
			unknown: true,
			disabled: true,
			value: true,
			value2: dual ? _.includes(newProps.value2, i.value) : null,
		};
	});

	newProps.options = _.concat(
		newOptionsEnabled,
		newOptionsDisabled,
		newOptionsUnknown
	);
};

const getNewProps = (newProps) => {
	newProps.readonly = _.get(newProps, 'readonly', false);
	newProps.disabledOptions = _.get(newProps, 'disabledOptions', []);
	newProps.disableOptionSorting = _.get(
		newProps,
		'disableOptionSorting',
		false
	);
	newProps.value = _.get(newProps, 'value', []);
	newProps.value2 = _.get(newProps, 'value2', null);
	newProps.variant = newProps.styleVariant;
	newProps.onChange = funcWrap(
		newProps.onChange,
		(onChange) => async (value) => {
			onChange(value);
		}
	);

	prepareOptions(newProps);
	return newProps;
};

export const ic_flags = {
	Component,
	getNewProps,
};
