/* eslint-disable */
module.exports = {
	data: {
		STANDARD: {
			AT: [[18.0, 8.99]],
			BE: [[18.0, 8.99]],
			BG: [[18.0, 24.25]],
			CH: [[18.0, 12.45]],
			CZ: [[18.0, 15.6]],
			DE: [[18.0, 8.99]],
			DK: [[18.0, 15.35]],
			EE: [[18.0, 16.95]],
			ES: [[18.0, 16.85]],
			FI: [[18.0, 21.65]],
			FR: [[18.0, 8.99]],
			GB: [[18.0, 14.65]],
			GR: [[18.0, 25.95]],
			HR: [[18.0, 24.25]],
			HU: [[18.0, 15.6]],
			IE: [[18.0, 15.6]],
			IS: [[18.0, 30.85]],
			IT: [[18.0, 19.6]],
			LT: [[18.0, 16.95]],
			LU: [[18.0, 8.99]],
			LV: [[18.0, 16.95]],
			NL: [[18.0, 8.99]],
			NO: [[18.0, 20.2]],
			PL: [[18.0, 15.6]],
			PT: [[18.0, 17.05]],
			RO: [[18.0, 27.5]],
			RS: [[18.0, 23.5]],
			SE: [[18.0, 18.35]],
			SI: [[18.0, 15.6]],
			SK: [[18.0, 15.6]],
		},
		PREMIUM: {
			BE: [
				[2.0, 14.05],
				[5.0, 15.95],
				[10.0, 17.8],
				[10.5, 18.1],
				[11.0, 18.45],
				[11.5, 18.75],
				[12.0, 19.1],
				[12.5, 19.4],
				[13.0, 19.7],
				[13.5, 20.05],
				[14.0, 20.3],
				[14.5, 20.7],
				[15.0, 20.95],
				[15.5, 21.3],
				[16.0, 21.6],
				[16.5, 21.95],
				[17.0, 22.25],
				[17.5, 22.6],
				[18.0, 22.9],
				[18.5, 23.25],
				[19.0, 23.55],
				[19.5, 23.85],
				[20.0, 24.2],
			],
			LU: [
				[2.0, 14.05],
				[5.0, 15.95],
				[10.0, 17.8],
				[10.5, 18.1],
				[11.0, 18.45],
				[11.5, 18.75],
				[12.0, 19.1],
				[12.5, 19.4],
				[13.0, 19.7],
				[13.5, 20.05],
				[14.0, 20.3],
				[14.5, 20.7],
				[15.0, 20.95],
				[15.5, 21.3],
				[16.0, 21.6],
				[16.5, 21.95],
				[17.0, 22.25],
				[17.5, 22.6],
				[18.0, 22.9],
				[18.5, 23.25],
				[19.0, 23.55],
				[19.5, 23.85],
				[20.0, 24.2],
			],
			NL: [
				[2.0, 14.05],
				[5.0, 15.95],
				[10.0, 17.8],
				[10.5, 18.1],
				[11.0, 18.45],
				[11.5, 18.75],
				[12.0, 19.1],
				[12.5, 19.4],
				[13.0, 19.7],
				[13.5, 20.05],
				[14.0, 20.3],
				[14.5, 20.7],
				[15.0, 20.95],
				[15.5, 21.3],
				[16.0, 21.6],
				[16.5, 21.95],
				[17.0, 22.25],
				[17.5, 22.6],
				[18.0, 22.9],
				[18.5, 23.25],
				[19.0, 23.55],
				[19.5, 23.85],
				[20.0, 24.2],
			],
			DE: [
				[2.0, 14.9],
				[5.0, 16.85],
				[10.0, 19.35],
				[10.5, 19.85],
				[11.0, 20.3],
				[11.5, 20.8],
				[12.0, 21.25],
				[12.5, 21.75],
				[13.0, 22.2],
				[13.5, 22.7],
				[14.0, 23.15],
				[14.5, 23.65],
				[15.0, 24.1],
				[15.5, 24.6],
				[16.0, 25.05],
				[16.5, 25.55],
				[17.0, 26.05],
				[17.5, 26.5],
				[18.0, 27.0],
				[18.5, 27.45],
				[19.0, 27.95],
				[19.5, 28.4],
				[20.0, 28.9],
			],
			FR: [
				[2.0, 15.0],
				[5.0, 17.0],
				[10.0, 19.5],
				[10.5, 20.0],
				[11.0, 20.5],
				[11.5, 20.95],
				[12.0, 21.45],
				[12.5, 21.9],
				[13.0, 22.4],
				[13.5, 22.9],
				[14.0, 23.35],
				[14.5, 23.85],
				[15.0, 24.3],
				[15.5, 24.8],
				[16.0, 25.3],
				[16.5, 25.75],
				[17.0, 26.25],
				[17.5, 26.7],
				[18.0, 27.2],
				[18.5, 27.7],
				[19.0, 28.15],
				[19.5, 28.65],
				[20.0, 29.1],
			],
			MC: [
				[2.0, 12.5],
				[5.0, 14.15],
				[10.0, 16.25],
				[10.5, 16.65],
				[11.0, 17.05],
				[11.5, 17.45],
				[12.0, 17.85],
				[12.5, 18.25],
				[13.0, 18.65],
				[13.5, 19.05],
				[14.0, 19.45],
				[14.5, 19.85],
				[15.0, 20.25],
				[15.5, 20.65],
				[16.0, 21.05],
				[16.5, 21.45],
				[17.0, 21.85],
				[17.5, 22.25],
				[18.0, 22.65],
				[18.5, 23.05],
				[19.0, 23.45],
				[19.5, 23.85],
				[20.0, 24.25],
			],
			AT: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],

				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			DK: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			ES: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			IE: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			IT: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			SE: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			BG: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			CY: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			CZ: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			EE: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			FI: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			GR: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			HR: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			HU: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			LT: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			LV: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			MT: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			PL: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			PT: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			RO: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			SI: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			SK: [
				[2.0, 16.15],
				[5.0, 18.7],
				[10.0, 22.7],
				[10.5, 23.85],
				[11.0, 24.9],
				[11.5, 25.95],
				[12.0, 27.0],
				[12.5, 28.1],
				[13.0, 29.15],
				[13.5, 30.2],
				[14.0, 31.3],
				[14.5, 32.4],
				[15.0, 33.45],
				[15.5, 34.5],
				[16.0, 35.6],
				[16.5, 36.65],
				[17.0, 37.7],
				[17.5, 38.75],
				[18.0, 39.85],
				[18.5, 40.9],
				[19.0, 41.95],
				[19.5, 43.0],
				[20.0, 44.15],
			],
			GB: [
				[2.0, 21.45],
				[5.0, 23.3],
				[10.0, 25.75],
				[10.5, 26.25],
				[11.0, 26.7],
				[11.5, 27.15],
				[12.0, 27.65],
				[12.5, 28.1],
				[13.0, 28.55],
				[13.5, 29.05],
				[14.0, 29.5],
				[14.5, 30.0],
				[15.0, 30.45],
				[15.5, 30.9],
				[16.0, 31.4],
				[16.5, 31.85],
				[17.0, 32.3],
				[17.5, 32.8],
				[18.0, 33.25],
				[18.5, 33.7],
				[19.0, 34.2],
				[19.5, 34.65],
				[20.0, 35.1],
			],
			AD: [
				[2.0, 17.0],
				[5.0, 19.15],
				[10.0, 24.9],
				[10.5, 25.9],
				[11.0, 26.9],
				[11.5, 27.9],
				[12.0, 28.9],
				[12.5, 29.9],
				[13.0, 30.85],
				[13.5, 31.85],
				[14.0, 32.85],
				[14.5, 33.85],
				[15.0, 34.85],
				[15.5, 35.85],
				[16.0, 36.85],
				[16.5, 37.85],
				[17.0, 38.85],
				[17.5, 39.85],
				[18.0, 40.85],
				[18.5, 41.85],
				[19.0, 42.85],
				[19.5, 43.85],
				[20.0, 44.85],
			],
			CH: [
				[2.0, 17.0],
				[5.0, 19.15],
				[10.0, 24.9],
				[10.5, 25.9],
				[11.0, 26.9],
				[11.5, 27.9],
				[12.0, 28.9],
				[12.5, 29.9],
				[13.0, 30.85],
				[13.5, 31.85],
				[14.0, 32.85],
				[14.5, 33.85],
				[15.0, 34.85],
				[15.5, 35.85],
				[16.0, 36.85],
				[16.5, 37.85],
				[17.0, 38.85],
				[17.5, 39.85],
				[18.0, 40.85],
				[18.5, 41.85],
				[19.0, 42.85],
				[19.5, 43.85],
				[20.0, 44.85],
			],
			LI: [
				[2.0, 17.0],
				[5.0, 19.15],
				[10.0, 24.9],
				[10.5, 25.9],
				[11.0, 26.9],
				[11.5, 27.9],
				[12.0, 28.9],
				[12.5, 29.9],
				[13.0, 30.85],
				[13.5, 31.85],
				[14.0, 32.85],
				[14.5, 33.85],
				[15.0, 34.85],
				[15.5, 35.85],
				[16.0, 36.85],
				[16.5, 37.85],
				[17.0, 38.85],
				[17.5, 39.85],
				[18.0, 40.85],
				[18.5, 41.85],
				[19.0, 42.85],
				[19.5, 43.85],
				[20.0, 44.85],
			],
			NO: [
				[2.0, 17.0],
				[5.0, 19.15],
				[10.0, 24.9],
				[10.5, 25.9],
				[11.0, 26.9],
				[11.5, 27.9],
				[12.0, 28.9],
				[12.5, 29.9],
				[13.0, 30.85],
				[13.5, 31.85],
				[14.0, 32.85],
				[14.5, 33.85],
				[15.0, 34.85],
				[15.5, 35.85],
				[16.0, 36.85],
				[16.5, 37.85],
				[17.0, 38.85],
				[17.5, 39.85],
				[18.0, 40.85],
				[18.5, 41.85],
				[19.0, 42.85],
				[19.5, 43.85],
				[20.0, 44.85],
			],
			SM: [
				[2.0, 17.0],
				[5.0, 19.15],
				[10.0, 24.9],
				[10.5, 25.9],
				[11.0, 26.9],
				[11.5, 27.9],
				[12.0, 28.9],
				[12.5, 29.9],
				[13.0, 30.85],
				[13.5, 31.85],
				[14.0, 32.85],
				[14.5, 33.85],
				[15.0, 34.85],
				[15.5, 35.85],
				[16.0, 36.85],
				[16.5, 37.85],
				[17.0, 38.85],
				[17.5, 39.85],
				[18.0, 40.85],
				[18.5, 41.85],
				[19.0, 42.85],
				[19.5, 43.85],
				[20.0, 44.85],
			],
			CA: [
				[2.0, 18.55],
				[5.0, 29.9],
				[10.0, 41.2],
				[10.5, 43.05],
				[11.0, 44.85],
				[11.5, 46.7],
				[12.0, 48.5],
				[12.5, 50.35],
				[13.0, 52.2],
				[13.5, 54.0],
				[14.0, 55.85],
				[14.5, 57.65],
				[15.0, 59.5],
				[15.5, 61.3],
				[16.0, 63.15],
				[16.5, 64.95],
				[17.0, 66.8],
				[17.5, 68.6],
				[18.0, 70.45],
				[18.5, 72.3],
				[19.0, 74.1],
				[19.5, 75.95],
				[20.0, 77.75],
			],
			US: [
				[2.0, 18.55],
				[5.0, 29.9],
				[10.0, 41.2],
				[10.5, 43.05],
				[11.0, 44.85],
				[11.5, 46.7],
				[12.0, 48.5],
				[12.5, 50.35],
				[13.0, 52.2],
				[13.5, 54.0],
				[14.0, 55.85],
				[14.5, 57.65],
				[15.0, 59.5],
				[15.5, 61.3],
				[16.0, 63.15],
				[16.5, 64.95],
				[17.0, 66.8],
				[17.5, 68.6],
				[18.0, 70.45],
				[18.5, 72.3],
				[19.0, 74.1],
				[19.5, 75.95],
				[20.0, 77.75],
			],
			AL: [
				[2.0, 31.35],
				[5.0, 48.0],
				[9.5, 64.7],
				[10.0, 64.7],
				[10.5, 66.5],
				[11.0, 68.3],
				[11.5, 70.1],
				[12.0, 71.9],
				[12.5, 73.7],
				[13.0, 75.55],
				[13.5, 77.35],
				[14.0, 79.15],
				[14.5, 80.95],
				[15.0, 82.75],
				[15.5, 84.55],
				[16.0, 86.35],
				[16.5, 88.15],
				[17.0, 90.0],
				[17.5, 91.8],
				[18.0, 93.6],
				[18.5, 95.4],
				[19.0, 97.2],
				[19.5, 99.0],
				[20.0, 100.8],
			],
			BA: [
				[2.0, 31.35],
				[5.0, 48.0],
				[9.5, 64.7],
				[10.0, 64.7],
				[10.5, 66.5],
				[11.0, 68.3],
				[11.5, 70.1],
				[12.0, 71.9],
				[12.5, 73.7],
				[13.0, 75.55],
				[13.5, 77.35],
				[14.0, 79.15],
				[14.5, 80.95],
				[15.0, 82.75],
				[15.5, 84.55],
				[16.0, 86.35],
				[16.5, 88.15],
				[17.0, 90.0],
				[17.5, 91.8],
				[18.0, 93.6],
				[18.5, 95.4],
				[19.0, 97.2],
				[19.5, 99.0],
				[20.0, 100.8],
			],
			BY: [
				[2.0, 31.35],
				[5.0, 48.0],
				[9.5, 64.7],
				[10.0, 64.7],
				[10.5, 66.5],
				[11.0, 68.3],
				[11.5, 70.1],
				[12.0, 71.9],
				[12.5, 73.7],
				[13.0, 75.55],
				[13.5, 77.35],
				[14.0, 79.15],
				[14.5, 80.95],
				[15.0, 82.75],
				[15.5, 84.55],
				[16.0, 86.35],
				[16.5, 88.15],
				[17.0, 90.0],
				[17.5, 91.8],
				[18.0, 93.6],
				[18.5, 95.4],
				[19.0, 97.2],
				[19.5, 99.0],
				[20.0, 100.8],
			],
			FO: [
				[2.0, 31.35],
				[5.0, 48.0],
				[9.5, 64.7],
				[10.0, 64.7],
				[10.5, 66.5],
				[11.0, 68.3],
				[11.5, 70.1],
				[12.0, 71.9],
				[12.5, 73.7],
				[13.0, 75.55],
				[13.5, 77.35],
				[14.0, 79.15],
				[14.5, 80.95],
				[15.0, 82.75],
				[15.5, 84.55],
				[16.0, 86.35],
				[16.5, 88.15],
				[17.0, 90.0],
				[17.5, 91.8],
				[18.0, 93.6],
				[18.5, 95.4],
				[19.0, 97.2],
				[19.5, 99.0],
				[20.0, 100.8],
			],
			IS: [
				[2.0, 31.35],
				[5.0, 48.0],
				[9.5, 64.7],
				[10.0, 64.7],
				[10.5, 66.5],
				[11.0, 68.3],
				[11.5, 70.1],
				[12.0, 71.9],
				[12.5, 73.7],
				[13.0, 75.55],
				[13.5, 77.35],
				[14.0, 79.15],
				[14.5, 80.95],
				[15.0, 82.75],
				[15.5, 84.55],
				[16.0, 86.35],
				[16.5, 88.15],
				[17.0, 90.0],
				[17.5, 91.8],
				[18.0, 93.6],
				[18.5, 95.4],
				[19.0, 97.2],
				[19.5, 99.0],
				[20.0, 100.8],
			],
			MD: [
				[2.0, 31.35],
				[5.0, 48.0],
				[9.5, 64.7],
				[10.0, 64.7],
				[10.5, 66.5],
				[11.0, 68.3],
				[11.5, 70.1],
				[12.0, 71.9],
				[12.5, 73.7],
				[13.0, 75.55],
				[13.5, 77.35],
				[14.0, 79.15],
				[14.5, 80.95],
				[15.0, 82.75],
				[15.5, 84.55],
				[16.0, 86.35],
				[16.5, 88.15],
				[17.0, 90.0],
				[17.5, 91.8],
				[18.0, 93.6],
				[18.5, 95.4],
				[19.0, 97.2],
				[19.5, 99.0],
				[20.0, 100.8],
			],
			ME: [
				[2.0, 31.35],
				[5.0, 48.0],
				[9.5, 64.7],
				[10.0, 64.7],
				[10.5, 66.5],
				[11.0, 68.3],
				[11.5, 70.1],
				[12.0, 71.9],
				[12.5, 73.7],
				[13.0, 75.55],
				[13.5, 77.35],
				[14.0, 79.15],
				[14.5, 80.95],
				[15.0, 82.75],
				[15.5, 84.55],
				[16.0, 86.35],
				[16.5, 88.15],
				[17.0, 90.0],
				[17.5, 91.8],
				[18.0, 93.6],
				[18.5, 95.4],
				[19.0, 97.2],
				[19.5, 99.0],
				[20.0, 100.8],
			],
			MK: [
				[2.0, 31.35],
				[5.0, 48.0],
				[9.5, 64.7],
				[10.0, 64.7],
				[10.5, 66.5],
				[11.0, 68.3],
				[11.5, 70.1],
				[12.0, 71.9],
				[12.5, 73.7],
				[13.0, 75.55],
				[13.5, 77.35],
				[14.0, 79.15],
				[14.5, 80.95],
				[15.0, 82.75],
				[15.5, 84.55],
				[16.0, 86.35],
				[16.5, 88.15],
				[17.0, 90.0],
				[17.5, 91.8],
				[18.0, 93.6],
				[18.5, 95.4],
				[19.0, 97.2],
				[19.5, 99.0],
				[20.0, 100.8],
			],
			RS: [
				[2.0, 31.35],
				[5.0, 48.0],
				[9.5, 64.7],
				[10.0, 64.7],
				[10.5, 66.5],
				[11.0, 68.3],
				[11.5, 70.1],
				[12.0, 71.9],
				[12.5, 73.7],
				[13.0, 75.55],
				[13.5, 77.35],
				[14.0, 79.15],
				[14.5, 80.95],
				[15.0, 82.75],
				[15.5, 84.55],
				[16.0, 86.35],
				[16.5, 88.15],
				[17.0, 90.0],
				[17.5, 91.8],
				[18.0, 93.6],
				[18.5, 95.4],
				[19.0, 97.2],
				[19.5, 99.0],
				[20.0, 100.8],
			],
			CN: [
				[2.0, 26.15],
				[5.0, 39.35],
				[10.0, 50.7],
				[10.5, 54.15],
				[11.0, 57.6],
				[11.5, 61.05],
				[12.0, 64.55],
				[12.5, 68.0],
				[13.0, 71.45],
				[13.5, 74.95],
				[14.0, 78.4],
				[14.5, 81.85],
				[15.0, 85.35],
				[15.5, 88.8],
				[16.0, 92.25],
				[16.5, 95.7],
				[17.0, 99.2],
				[17.5, 102.65],
				[18.0, 106.1],
				[18.5, 109.6],
				[19.0, 113.05],
				[19.5, 116.5],
				[20.0, 120.0],
			],
			JP: [
				[2.0, 26.15],
				[5.0, 39.35],
				[10.0, 50.7],
				[10.5, 54.15],
				[11.0, 57.6],
				[11.5, 61.05],
				[12.0, 64.55],
				[12.5, 68.0],
				[13.0, 71.45],
				[13.5, 74.95],
				[14.0, 78.4],
				[14.5, 81.85],
				[15.0, 85.35],
				[15.5, 88.8],
				[16.0, 92.25],
				[16.5, 95.7],
				[17.0, 99.2],
				[17.5, 102.65],
				[18.0, 106.1],
				[18.5, 109.6],
				[19.0, 113.05],
				[19.5, 116.5],
				[20.0, 120.0],
			],
			KR: [
				[2.0, 26.15],
				[5.0, 39.35],
				[10.0, 50.7],
				[10.5, 54.15],
				[11.0, 57.6],
				[11.5, 61.05],
				[12.0, 64.55],
				[12.5, 68.0],
				[13.0, 71.45],
				[13.5, 74.95],
				[14.0, 78.4],
				[14.5, 81.85],
				[15.0, 85.35],
				[15.5, 88.8],
				[16.0, 92.25],
				[16.5, 95.7],
				[17.0, 99.2],
				[17.5, 102.65],
				[18.0, 106.1],
				[18.5, 109.6],
				[19.0, 113.05],
				[19.5, 116.5],
				[20.0, 120.0],
			],
			MY: [
				[2.0, 26.15],
				[5.0, 39.35],
				[10.0, 50.7],
				[10.5, 54.15],
				[11.0, 57.6],
				[11.5, 61.05],
				[12.0, 64.55],
				[12.5, 68.0],
				[13.0, 71.45],
				[13.5, 74.95],
				[14.0, 78.4],
				[14.5, 81.85],
				[15.0, 85.35],
				[15.5, 88.8],
				[16.0, 92.25],
				[16.5, 95.7],
				[17.0, 99.2],
				[17.5, 102.65],
				[18.0, 106.1],
				[18.5, 109.6],
				[19.0, 113.05],
				[19.5, 116.5],
				[20.0, 120.0],
			],
			SG: [
				[2.0, 26.15],
				[5.0, 39.35],
				[10.0, 50.7],
				[10.5, 54.15],
				[11.0, 57.6],
				[11.5, 61.05],
				[12.0, 64.55],
				[12.5, 68.0],
				[13.0, 71.45],
				[13.5, 74.95],
				[14.0, 78.4],
				[14.5, 81.85],
				[15.0, 85.35],
				[15.5, 88.8],
				[16.0, 92.25],
				[16.5, 95.7],
				[17.0, 99.2],
				[17.5, 102.65],
				[18.0, 106.1],
				[18.5, 109.6],
				[19.0, 113.05],
				[19.5, 116.5],
				[20.0, 120.0],
			],
			TH: [
				[2.0, 26.15],
				[5.0, 39.35],
				[10.0, 50.7],
				[10.5, 54.15],
				[11.0, 57.6],
				[11.5, 61.05],
				[12.0, 64.55],
				[12.5, 68.0],
				[13.0, 71.45],
				[13.5, 74.95],
				[14.0, 78.4],
				[14.5, 81.85],
				[15.0, 85.35],
				[15.5, 88.8],
				[16.0, 92.25],
				[16.5, 95.7],
				[17.0, 99.2],
				[17.5, 102.65],
				[18.0, 106.1],
				[18.5, 109.6],
				[19.0, 113.05],
				[19.5, 116.5],
				[20.0, 120.0],
			],
			TW: [
				[2.0, 26.15],
				[5.0, 39.35],
				[10.0, 50.7],
				[10.5, 54.15],
				[11.0, 57.6],
				[11.5, 61.05],
				[12.0, 64.55],
				[12.5, 68.0],
				[13.0, 71.45],
				[13.5, 74.95],
				[14.0, 78.4],
				[14.5, 81.85],
				[15.0, 85.35],
				[15.5, 88.8],
				[16.0, 92.25],
				[16.5, 95.7],
				[17.0, 99.2],
				[17.5, 102.65],
				[18.0, 106.1],
				[18.5, 109.6],
				[19.0, 113.05],
				[19.5, 116.5],
				[20.0, 120.0],
			],
			AU: [
				[2.0, 31.35],
				[5.0, 48.0],
				[10.0, 68.7],
				[10.5, 72.15],
				[11.0, 75.6],
				[11.5, 79.1],
				[12.0, 82.55],
				[12.5, 86.0],
				[13.0, 89.45],
				[13.5, 92.95],
				[14.0, 96.4],
				[14.5, 99.85],
				[15.0, 103.35],
				[15.5, 106.8],
				[16.0, 110.25],
				[16.5, 113.75],
				[17.0, 117.2],
				[17.5, 120.65],
				[18.0, 124.1],
				[18.5, 127.6],
				[19.0, 131.05],
				[19.5, 134.5],
				[20.0, 138.0],
			],
			IL: [
				[2.0, 31.35],
				[5.0, 48.0],
				[10.0, 68.7],
				[10.5, 72.15],
				[11.0, 75.6],
				[11.5, 79.1],
				[12.0, 82.55],
				[12.5, 86.0],
				[13.0, 89.45],
				[13.5, 92.95],
				[14.0, 96.4],
				[14.5, 99.85],
				[15.0, 103.35],
				[15.5, 106.8],
				[16.0, 110.25],
				[16.5, 113.75],
				[17.0, 117.2],
				[17.5, 120.65],
				[18.0, 124.1],
				[18.5, 127.6],
				[19.0, 131.05],
				[19.5, 134.5],
				[20.0, 138.0],
			],
			NZ: [
				[2.0, 31.35],
				[5.0, 48.0],
				[10.0, 68.7],
				[10.5, 72.15],
				[11.0, 75.6],
				[11.5, 79.1],
				[12.0, 82.55],
				[12.5, 86.0],
				[13.0, 89.45],
				[13.5, 92.95],
				[14.0, 96.4],
				[14.5, 99.85],
				[15.0, 103.35],
				[15.5, 106.8],
				[16.0, 110.25],
				[16.5, 113.75],
				[17.0, 117.2],
				[17.5, 120.65],
				[18.0, 124.1],
				[18.5, 127.6],
				[19.0, 131.05],
				[19.5, 134.5],
				[20.0, 138.0],
			],
			ZA: [
				[2.0, 31.35],
				[5.0, 48.0],
				[10.0, 68.7],
				[10.5, 72.15],
				[11.0, 75.6],
				[11.5, 79.1],
				[12.0, 82.55],
				[12.5, 86.0],
				[13.0, 89.45],
				[13.5, 92.95],
				[14.0, 96.4],
				[14.5, 99.85],
				[15.0, 103.35],
				[15.5, 106.8],
				[16.0, 110.25],
				[16.5, 113.75],
				[17.0, 117.2],
				[17.5, 120.65],
				[18.0, 124.1],
				[18.5, 127.6],
				[19.0, 131.05],
				[19.5, 134.5],
				[20.0, 138.0],
			],
		},
	},
};
