import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { MuiAppBar } from '../MuiApp';
import { MuiGlobalComponent } from '../MuiGlobal'; // eslint-disable-line no-unused-vars

const MuiDefaultPageContent = ({ children }) => {
	return <Fragment>{children}</Fragment>;
};

export const MuiPageContent = MuiGlobalComponent(
	'_pagecontent',
	MuiDefaultPageContent
);
