import React, { Fragment, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import _ from 'lodash';
import { useIsMounted } from '../toolbox/ismounted';

const useStyles = makeStyles((theme) => ({
	buttonProgress: {
		color: theme.palette.primary[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

const MuiLoadableOnClick = (props) => {
	const isMounted = useIsMounted();
	const classes = useStyles(props);
	const [loading, setLoading] = useState(false);
	const firstChild = React.Children.only(props.children);
	const childOnClick = firstChild.props.onClick;
	const forcedLoading = _.get(props, ['loading'], false);

	// eslint-disable-next-line no-unused-vars
	const onClick = async (event) => {
		if (isMounted.current) await setLoading(true);
		await childOnClick();
		if (isMounted.current) await setLoading(false);
	};
	const firstChildPropsWithoutLoading = _.omit(firstChild.props, ['loading']);
	const newFirstChildProps = Object.assign({}, firstChildPropsWithoutLoading, {
		onClick,
		disabled: forcedLoading || loading || firstChild.props.disabled,
	});

	const progress = (
		<Fade
			in={loading || forcedLoading}
			key={'MuiLoadableFadeIn'}
			style={{
				transitionDelay: `${props.transitionDelay}ms`,
			}}
		>
			<CircularProgress
				key={'loading child'}
				size={24}
				className={clsx(classes.buttonProgress)}
			/>
		</Fade>
	);

	const secondLevelChilds = _.concat(firstChild.props.children, progress);
	const clonedChild = React.cloneElement(
		firstChild,
		newFirstChildProps,
		secondLevelChilds
	);
	return <Fragment>{clonedChild}</Fragment>;
};
MuiLoadableOnClick.propTypes = {
	transitionDelay: PropTypes.number,
};
MuiLoadableOnClick.defaultProps = {
	transitionDelay: 200,
};

export { MuiLoadableOnClick };
