import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { makePick, pickDefault } from './input_components';
import { funcWrap } from '../../../core';
import { MuiTranslationContainer, TranslationContainerContext } from '../../MuiTranslationContainer';
import { MuiSplitter, MuiTopCenterBottom } from '@lsoft/mui';
// import 'prismjs/components/prism-clike';
// import 'prismjs/components/prism-javascript';
// import 'prismjs/components/prism-markup';
// import 'prismjs/themes/prism-dark.css';
import { TranslationSelect } from './simple_translation';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { HTMLTranslationStyles } from './html_translation.styles';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
	...HTMLTranslationStyles(theme)
}));

const HTMLTranslationEditor = ({ theme, lc }) => {
	const context = useContext(TranslationContainerContext);
	const text = context.getText(lc);
	const classes = useStyles({ theme });
	(evn) => context.changeTranslationText(lc, evn.target.value)
	return (
		<p>gammel</p>
	);
};
HTMLTranslationEditor.propTypes = {
	lc: PropTypes.string
};

const HTMLTranslationView = ({ theme, lc }) => {
	const classes = useStyles({ theme });
	const context = useContext(TranslationContainerContext);
	const text = context.getText(lc);
	return (
		<div className={clsx(classes.PreviewCard)}>
			<div
				dangerouslySetInnerHTML={{
					__html: text
				}}
			/>
		</div>
	);
};
HTMLTranslationView.propTypes = {
	lc: PropTypes.string
};

const TranslationAddButton = ({ theme }) => {
	const context = useContext(TranslationContainerContext);
	const [lc, setLc] = useState(context.addableLanguages[0]);
	const classes = useStyles({ theme });
	if (context.addableLanguages.length === 0) return null;
	if (!_.includes(context.addableLanguages, lc))
		setLc(context.addableLanguages[0]);
	const handleOnAdd = () => {
		context.changeTranslationText(lc, '');
	};
	return (
		<Grid
			className={clsx(classes.AddButtonBox)}
			container
			direction={'row'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			Gammel
			<Grid item xs>
				<TranslationSelect
					availableLanguages={context.addableLanguages}
					lc={lc}
					onChange={(old_lc, new_lc) => setLc(new_lc)}
					className={classes.AddSelect}
				/>
			</Grid>
			<Grid item xs>
				<IconButton size={'small'} color={'primary'} onClick={handleOnAdd}>
					<AddIcon />
				</IconButton>
			</Grid>
		</Grid>
	);
};

const HTMLTranslationInner = ({ theme }) => {
	const context = useContext(TranslationContainerContext);
	const classes = useStyles({ theme });
	const { usedLanguages } = context;
	let [selectedLanguage, setSelectedLanguage] = useState(
		_.get(usedLanguages, '[0]', '')
	);
	console.log('ul', usedLanguages);
	return (
		<div className={clsx(classes.html_translation_div)}>
			<MuiTopCenterBottom.Container>
				<MuiTopCenterBottom.Top>
					<Grid container direction={'row'} spacing={1}>
						<Grid item xs={12} id={'gammel'} className={clsx(classes.nopadding)}>
							<Grid container direction={'row'}>
								<Grid item>
									<Tabs
										orientation='horizontal'
										value={selectedLanguage}
										className={clsx(classes.tabs)}
										onChange={(comp, value) => {
											setSelectedLanguage(value);
										}}
									>
										{usedLanguages.sort().map((lc) => (
											<Tab key={lc} label={lc} value={lc} />
										))}
									</Tabs>

								</Grid>
								<Grid item>
									<TranslationAddButton />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} style={{ paddingTop: 0 }}>
							<Divider />
						</Grid>
					</Grid>
				</MuiTopCenterBottom.Top>
				<MuiTopCenterBottom.Center>
					<MuiSplitter.Container className={clsx(classes.maxheight, classes.red)}>
						<MuiSplitter.Split>
							<MuiTopCenterBottom.Container>
								<MuiTopCenterBottom.Center className={clsx(classes.maxheight, classes.red)}>

									<HTMLTranslationView lc={selectedLanguage} />
								</MuiTopCenterBottom.Center>
							</MuiTopCenterBottom.Container>
						</MuiSplitter.Split>
						<MuiSplitter.Split>
							<MuiTopCenterBottom.Container>
								<MuiTopCenterBottom.Center className={clsx(classes.maxheight, classes.red)}>
									<HTMLTranslationEditor
										lc={selectedLanguage}
										setSelectedLanguage={setSelectedLanguage} />
								</MuiTopCenterBottom.Center>
							</MuiTopCenterBottom.Container>
						</MuiSplitter.Split>
					</MuiSplitter.Container>
				</MuiTopCenterBottom.Center>
			</MuiTopCenterBottom.Container>
		</div>

	);
};

const HTMLTranslation = ({ value, availableLanguages, onChange }) => {
	console.log(availableLanguages);
	return (
		<MuiTranslationContainer
			tr={value}
			availableLanguages={availableLanguages}
			onChange={onChange}
		>
			<HTMLTranslationInner />
		</MuiTranslationContainer>
	);
};

HTMLTranslation.propTypes = {
	value: PropTypes.object,
	availableLanguages: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func
};
HTMLTranslation.defaultProps = {
	onChange: (value) => {
		console.log('HTMLTranslation onChange:', value);
	}
};

const pick = makePick(['availableLanguages', 'value'], pickDefault);
const Component = (newProps) => {
	return <HTMLTranslation {...pick(newProps)} />;
};

Component.propTypes = {
	availableLanguages: PropTypes.array
};

Component.defaultProps = {
	availableLanguages: ['DE', 'EN', 'FR', 'IT', 'PT']
};

const ic_html_translation = {
	// eslint-disable-next-line react/display-name
	Component,
	getNewProps: (newProps) => {
		newProps.onChange = funcWrap(
			newProps.onChange,
			(onChange) => async (val) => {
				onChange(val);
			}
		);
		return newProps;
	}
};

export { ic_html_translation, HTMLTranslation };
