// eslint-disable-next-line no-unused-vars

export const MuiAssetGalleryStyles = (theme) => ({
	MuiAssetGallery: {
		width: '100%',
		height: '100%',
		// '& div': { border: '1px solid red' },
	},
	MuiAssetGalleryImage: {
		height: '100%',
		userSelect: 'none',
		position: 'relative',
		// '& div': { border: '2px solid red' },
	},
	MuiAssetGallerySelector: {
		height: 69,
		userSelect: 'none',
		marginTop: theme.spacing(1),
		maxWidth: 640,
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
		},

		// border: '2px solid green',
	},
	MuiAssetGallerySelectorContainer: {
		height: '100%',
		display: 'flex',
		overflowX: 'hidden',
		webkitScrollbarDisplay: 'none',
	},
	MuiAssetGallerySelectorItem: {
		minWidth: 88,
		maxWidth: 88,
		minHeight: 68,
		maxHeight: 68,
		padding: theme.spacing(0.5),
	},
	MuiAssetGallerySelectorItemSelected: {
		border: [[2, 'solid', theme.palette.primary[500]]],
		borderRadius: theme.spacing(0.75),
		// '&:hover': {
		// 	borderColor: 'red',
		// },
	},
	MuiAssetGalleryImageButtons: {
		position: 'absolute',
		left: 0,
		top: 0,
		zValue: 100,
		height: '100%',
		// '& div': { border: '2px solid red' },
	},
	MuiAssetGalleryZoomButton: {
		position: 'absolute',
		right: 35,
		bottom: 0,
		zValue: 100,
		'&.MuiButton-root': {
			minWidth: 'unset',
		},
		// '& div': { border: '2px solid red' },
	},
	MuiAssetGalleryZoomCloseButton: {
		position: 'absolute',
		right: 0,
		top: 0,
		zValue: 100,
		'&.MuiButton-root': {
			minWidth: 'unset',
		},
		// '& div': { border: '2px solid red' },
	},
	MuiAssetGalleryTopImageItem: {
		// border: '1px solid green',
		height: '100%',
		width: '100%',
	},
	FullHeightIconButton: {
		'&:hover': {
			// background: theme.palette.primary[400],
			background: 'rgba(0,0,0,0.1)',
			// color: theme.palette.primary.contrastText,
		},
	},
	GridListImage: {
		height: '100%',
		width: '100%',
		border: '1px solid green',
	},
});
