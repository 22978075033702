import { LsDataContainer } from '../../LsContainer';
import React, { useCallback } from 'react';
import _ from 'lodash';
import { ContextDataIterator } from '../../ContextDataIterator/ContextDataIterator';
import { get_promotions } from '../../../functions/shop/promotions';

export const PromotionsContext = React.createContext(null);

const default_promotion_filter_class = 'base';

const load = async ({ view, active, future, without_empty }) => {
	const params = _.assign(
		{},
		{
			view: default_promotion_filter_class,
		},
		{
			view,
			active,
			future,
			without_empty,
		},
	);
	return await get_promotions(params);
};

export const PromotionsContainer = ({
	children,
	view = 'base',
	active,
	future,
	without_empty = false,
	...props
}) => {
	const extendContext = useCallback(({ data, functions }) => {
		if (!_.isNil(data)) {
		}
		if (!_.isNil(functions)) {
		}
		return {
			data,
			functions,
		};
	}, []);

	return (
		<LsDataContainer
			context={PromotionsContext}
			extendContext={extendContext}
			loader={async () => await load({ view, active, future, without_empty })}
			{...props}
		>
			{children}
		</LsDataContainer>
	);
};

PromotionsContainer.propTypes = {
	...LsDataContainer.propTypes,
};

export const PromotionsDataIterator = ({ children }) => {
	return (
		<ContextDataIterator
			context={PromotionsContext}
			data_key={'data'}
			map_key={'name'}
		>
			{children}
		</ContextDataIterator>
	);
};
