// eslint-disable-next-line no-unused-vars
import React, { Fragment } from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { Tr } from '@lsoft/translate';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Switch from '@material-ui/core/Switch';

import _ from 'lodash';

export const halfOnGradient = (
	theme,
	disabled,
	first_color_pos = 20,
	second_color_pos = 30
) => {
	const deg = 115;
	const color1 = theme.palette.grey[300];
	const color2 = theme.palette.primary[100];
	const color3 = theme.palette.primary[200];
	const break1 = disabled
		? `${first_color_pos}px`
		: `${first_color_pos + 30}px`;
	const break2 = disabled
		? `${second_color_pos}px`
		: `${second_color_pos + 30}px`;

	return `linear-gradient(${deg}deg,
		${color1} ${break1},
		${color2} ${break1},
		${color2} ${break2},
		${color3} ${break2},
		${color3} 100%
	)`;
};

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => {
	return {
		InputChip: {
			'& .MuiChip-label': {
				paddingLeft: 0,
				height: 32,
			},
			'& .MuiIconButton-label': {
				height: 15,
				width: 20,
			},
			'& .MuiFormControlLabel-root': {
				marginRight: 0,
			},
			'& .MuiSwitch-root': {
				// verticalAlign: 'middle',
				height: 35,
				paddingTop: 11,
				paddingBottom: 13,
			},
			'& .MuiSwitch-switchBase': {
				color: '#fff',
				top: -1,
			},
			'& .MuiSwitch-track': {
				backgroundColor: '#000',
				opacity: 0.5,
			},
			'& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
				backgroundColor: '#000',
				opacity: 0.5,
			},
		},
		colorOn: {
			backgroundColor: theme.palette.primary[500],
			color: theme.palette.primary.contrastText,
		},
		colorHalfOn: {
			// backgroundColor: theme.palette.primary[100],
			// color: theme.palette.primary.contrastText,
			color: theme.palette.primary.text,
			background: halfOnGradient(theme, false),
		},
		colorHalfOnDisabled: {
			color: theme.palette.primary.text,
			background: halfOnGradient(theme, true),
		},
		colorHalfOff: {
			backgroundColor: theme.palette.warning.light,
			color: theme.palette.warning.contrastText,
		},
		colorOff: {
			backgroundColor: theme.palette.grey[300],
			color: theme.palette.grey.contrastText,
		},
		InputSwitchLabel: {
			// border: '1px solid red',
			// margin: 10,
		},
		fullWidth: {
			width: '100%',
			'& .MuiChip-label': {
				width: '100%',
			},
		},
	};
});

/*
	value		value2	disabled 	color
	0				null						  off
	1				null							on
	0				0									off
	0				1				0					halfOn
	0				1				1					halfOnDisabled ( disabledOn: on )
	1				0				halfOff
	1				1				on
*/

const getColorClass = (classes, value, value2, disabled, disabledOn = true) => {
	let color = null;
	if (_.isNil(value2)) {
		color = value ? classes.colorOn : classes.colorOff;
	} else {
		if (!value && !value2) color = classes.colorOff;
		if (!value && value2)
			color = disabled
				? disabledOn
					? classes.colorOn
					: classes.colorHalfOnDisabled
				: classes.colorHalfOn;
		if (value && !value2) color = classes.colorHalfOff;
		if (value && value2) color = classes.colorOn;
	}
	// console.log(value, value2, disabled, color);
	return color;
};

const MuiChipSwitch = ({
	theme,
	name,
	value,
	value2,
	label,
	onChange,
	fullWidth,
}) => {
	const classes = useStyles({ theme });
	const c = (
		<Fragment>
			<Switch
				checked={value}
				onChange={(e) => {
					onChange(e.target.checked, name);
				}}
				size={'medium'}
				className={clsx(classes.Switch)}
			/>
			<span className={clsx(classes.InputSwitchLabel)}>{label}</span>
		</Fragment>
	);
	const color = getColorClass(classes, value, value2);

	return (
		<Chip
			key={name}
			label={c}
			className={clsx(classes.InputChip, color, {
				[classes.fullWidth]: fullWidth,
			})}
		/>
	);
};

MuiChipSwitch.propTypes = {
	name: PropTypes.string,
	value: PropTypes.bool,
	value2: PropTypes.bool,
	label: PropTypes.any,
	onChange: PropTypes.func,
	fullWidth: PropTypes.bool,
};
MuiChipSwitch.defaultProps = {
	onChange: () => {},
	fullWidth: false,
};

const MuiChipOption = ({
	theme,
	name,
	value,
	value2,
	label,
	disabled,
	disabledOn,
	readonly,
	onChange,
	fullWidth,
}) => {
	const classes = useStyles({ theme });
	const trLabel = <Tr tr={label} />;
	const color = getColorClass(classes, value, value2, disabled, disabledOn);
	if (readonly || disabled) {
		return (
			<Chip
				key={name}
				label={trLabel}
				className={clsx(color, { [classes.fullWidth]: fullWidth })}
			/>
		);
	} else {
		return (
			<MuiChipSwitch
				key={name}
				name={name}
				label={trLabel}
				value={value}
				value2={value2}
				onChange={onChange}
				fullWidth={fullWidth}
			/>
		);
	}
};

MuiChipOption.propTypes = {
	name: PropTypes.string,
	value: PropTypes.bool,
	value2: PropTypes.bool,
	label: PropTypes.any,
	disabled: PropTypes.bool,
	disabledOn: PropTypes.bool,
	readonly: PropTypes.bool,
	onChange: PropTypes.func,
	fullWidth: PropTypes.bool,
};
MuiChipOption.defaultProps = {
	disabled: false,
	disabledOn: false,
	readonly: false,
	onChange: () => {},
	fullWidth: false,
};

export { MuiChipSwitch, MuiChipOption };
