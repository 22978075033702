import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useIsFirstRender } from '../../hooks/useIsFirstRender';
import { useIsMounted } from '../../hooks/useIsMounted';
import { MuiFormFieldOptionsContainer } from '@lsoft/mui/src/MuiForms/MuiFormFieldOptions';

const LsContainerLoadingContext = React.createContext(null);
const LsContainerNoContext = React.createContext(null);

export const LsContainerLoading = ({ context, children }) => {
	const ctx = _.defaultTo(context, LsContainerLoadingContext);
	const { loading } = useContext(ctx);
	return loading ? children : null;
};

export const LsContainerNotLoading = ({ context, children }) => {
	const ctx = _.defaultTo(context, LsContainerLoadingContext);
	const { loading } = useContext(ctx);
	return !loading ? children : null;
};

export const LsContainerLoaded = ({ context, children }) => {
	const ctx = _.defaultTo(context, LsContainerLoadingContext);
	const { loaded } = useContext(ctx);
	return loaded ? children : null;
};

export const LsContainerNotLoaded = ({ context, children }) => {
	const ctx = _.defaultTo(context, LsContainerLoadingContext);
	const { loaded } = useContext(ctx);
	return !loaded ? children : null;
};

export const LsDataContainer = ({
	context: Context,
	parentContext = LsContainerNoContext,
	loader = () => {},
	children,
	data: passedData,
	dataOnly: passedDataOnly = false,
	renderAlways = false,
	child = false,
	childLoader = () => {},
	extendContext = (ctx) => ctx,
	ignoreParentContext = false,
	id,
}) => {
	const isFirstRender = useIsFirstRender();
	const isMounted = useIsMounted();
	const ctx = useContext(Context);
	const parentCtx = useContext(parentContext);
	const dataOnly = passedDataOnly || child;
	const data = child ? childLoader(parentCtx) : passedData;
	const [state, setState] = useState({
		data,
		loading: false,
		loaded: !_.isNil(data),
		identifier: id,
	});

	const higherLevelCtx = ignoreParentContext
		? false
		: !_.isNil(ctx) && _.isEqual(id, ctx.identifier);

	const { loading, loaded, identifier } = state;

	const modState = useCallback(
		async (new_state) => {
			setState(_.assign({}, state, new_state));
		},
		[state]
	);
	const setData = useCallback(async (data) => modState({ data }), [modState]);

	// loading:
	// 	not a child Container
	// 	no data
	//  not dataOnly
	//  only on first render or when changed
	const changed_id = !_.isEqual(id, state.identifier);
	// console.log('changed_id', changed_id, id, state.identifier);

	const shouldLoad =
		!higherLevelCtx &&
		_.isNil(data) &&
		!dataOnly &&
		(isFirstRender || changed_id);

	// useEffect for prop change on data
	useEffect(() => {
		if (!isFirstRender && (!_.isNil(data) || dataOnly)) {
			if (isMounted.current) {
				setState({ data, loading: false, loaded: true });
			}
		}
		// 	next line dependencies should ONLY contain data and not 'isFirstRender' otherwise this useeffect will reset the state unwanted in the second round
	}, [data]);

	const load = useCallback(async () => {
		if (!isMounted.current) return;
		await modState({ loading: true, identifier: id });
		if (!isMounted.current) return;
		const data = await loader();
		if (!isMounted.current) return;
		await modState({ data, loading: false, loaded: true, identifier: id });
	}, [loader, modState, id]);

	const reload = useCallback(() => {
		//TODO: check if child container call reload from parent instead of own load
		load().then(() => {});
	}, [load]);
	// call loader on changed
	useEffect(() => {
		if (shouldLoad && !child) {
			load().then(() => {
				// console.log('loaded');
			});
		}
	}, [child, load, shouldLoad]);

	const valueContext = _.assign(state, {
		functions: {
			reload: child ? parentCtx.functions.reload : reload,
			setState: child ? parentCtx.functions.setState : setState,
			modState: child ? parentCtx.functions.modState : modState,
			setData: child ? parentCtx.functions.setData : setData,
		},
		identifier: id,
	});
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const extendedContext = useMemo(
		() => _.merge({}, valueContext, extendContext(valueContext)),
		[extendContext, valueContext]
	);
	// parent context existing render children
	if (higherLevelCtx) return children;
	// no data return null / children if render_always is set
	const render = renderAlways || !_.isNil(data) || !_.isNil(state.data);
	if (render) {
		return (
			<LsContainerLoadingContext.Provider value={{ loading, loaded }}>
				<Context.Provider value={extendedContext}>{children}</Context.Provider>
			</LsContainerLoadingContext.Provider>
		);
	} else {
		return null;
	}
};

LsDataContainer.propTypes = {
	context: PropTypes.any,
	data: PropTypes.any,
	dataOnly: PropTypes.bool,
	renderAlways: PropTypes.bool,
	loader: PropTypes.func,
	parentContext: PropTypes.any,
	child: PropTypes.bool,
	childLoader: PropTypes.func,
	extendContext: PropTypes.func,
	ignoreParentContext: PropTypes.bool,
	id: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.object,
	]),
};

export const useLsDataContainer = (context, data = true) => {
	const ctx = useContext(context);
	return useMemo(() => {
		if (_.isNil(ctx)) return {};
		return _.assign({}, data ? ctx.data : null, ctx.functions, {
			loading: ctx.loading,
		});
	}, [ctx]);
};

export const withLsDataContainer = ({
	context,
	container: Container,
	containerProps,
}) => {
	// eslint-disable-next-line react/display-name
	return (Component) => (props) => {
		const ctx = useContext(context);
		const c = <Component {...props} />;
		return !_.isNil(ctx) ? c : <Container {...containerProps}>{c}</Container>;
	};
};

export const LsDataContainerOptions = ({ context, generator, children }) => {
	const ctx = useContext(context);
	const options = _.isNil(_.get(ctx, 'data', null) === null)
		? null
		: generator(ctx.data);
	if (options.length === 0) return null;
	return (
		<MuiFormFieldOptionsContainer options={options}>
			{children}
		</MuiFormFieldOptionsContainer>
	);
};
LsDataContainerOptions.propTypes = {
	context: PropTypes.any,
	generator: PropTypes.any,
};
