import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { getTranslation } from '@lsoft/translate';
import { MuiGlobal } from '@lsoft/mui';
import { useRouter } from 'next/router';

const asset_image = {
	sizes: [80, 320, 640, 1200],
};

const asset_types = {
	CDS: {
		apply_mui_asset: (asset, options) => {
			const r = _.cloneDeep(asset);
			const cdsBaseUrl = MuiGlobal.get('cdsBaseUrl');
			if (_.isNil(cdsBaseUrl)) {
				throw 'CDS_BASEURL not set.';
			}
			const o = _.assign(
				{},
				{
					cdsBaseUrl: cdsBaseUrl,
				},
				options,
			);
			const { url } = asset;
			const relativeUrl = url.startsWith('/')
				? url
				: `/catalog/product/${url[0]}/${url[1]}/${url}`;
			const absoluteUrl = `${o.cdsBaseUrl}/image${relativeUrl}`;
			const queryParameter = new URLSearchParams();
			if (!_.isNil(o.width)) queryParameter.set('WIDTH', o.width);
			if (!_.isNil(o.height)) queryParameter.set('HEIGHT', o.height);
			const queryString = queryParameter.toString();
			r.url =
				queryString.length === 0
					? absoluteUrl
					: `${absoluteUrl}?${queryString}`;
			return r;
		},
	},
};

// noinspection DuplicatedCode
const translate_mui_asset = (asset, locale) => {
	const lc = locale.toLowerCase();
	const r = _.cloneDeep(asset);
	if (_.isObject(r.url)) r.url = getTranslation(r.url, lc);
	if (_.isObject(r.caption)) r.caption = getTranslation(r.caption, lc);
	return r;
};

export const apply_mui_asset = (asset, options, locale) => {
	const translatedAsset = translate_mui_asset(asset, locale);
	const assetType = _.get(asset_types, translatedAsset.url_type, null);
	return _.isNil(assetType)
		? translatedAsset
		: assetType.apply_mui_asset(translatedAsset, options);
};

export const NextAssetImage = ({
	asset,
	aspect_ratio = 4 / 3,
	object_fit = 'contain',
	layout = 'responsive',
	placeholder,
	quality = 75,
	width,
	height,
	no_aspect_ratio = false,
	xs = false,
	sm = false,
	md = false,
	lg = false,
	className,
	...props
}) => {
	const { locale: router_locale } = useRouter();
	const locale = !_.isNil(router_locale) ? router_locale : 'EN';

	// 1. Get options
	const options = useMemo(() => {
		const sizes = _.get(asset_image, ['sizes'], [60, 240, 480, 640]);
		const ret = {
			width: null,
			height: null,
		};
		if (!_.isNil(width)) {
			ret.width = width;
		} else {
			if (xs) {
				ret.width = sizes[0];
			} else if (sm) {
				ret.width = sizes[1];
			} else if (md) {
				ret.width = sizes[2];
			} else if (lg) {
				ret.width = sizes[3];
			}
		}
		if (!_.isNil(height)) {
			ret.height = height;
		}
		if (!no_aspect_ratio) {
			if (!_.isNil(ret.width) && _.isNil(height)) {
				ret.height = ret.width / aspect_ratio;
			}
			if (_.isNil(ret.width) && !_.isNil(height)) {
				ret.width = ret.height * aspect_ratio;
			}
		}
		return ret;
	}, [aspect_ratio, width, height, no_aspect_ratio, xs, sm, md, lg]);
	// 2. Apply mui asset
	const { url, caption } = useMemo(
		() => apply_mui_asset(asset, options, locale),
		[asset, options, locale],
	);
	// 3. Collect props for next/image
	const next_image_props = _.merge(
		{},
		{
			alt: caption,
			src: url,
			layout,
			loading: 'lazy',
			objectFit: object_fit,
			quality,
			placeholder,
			className,
			unoptimized: asset?.url_type === 'CDS',
		},
		props,
		{ width: _.isNil(options.width) ? aspect_ratio : options.width },
		{ height: _.isNil(options.height) ? 1 : options.height },
		no_aspect_ratio ? { width: null, height: null, layout: 'intrinsic' } : null,
		// { style: { objectFit: object_fit, layout: layout } }
	);

	// eslint-disable-next-line jsx-a11y/alt-text
	return (
		<Image
			{...next_image_props}
			// style={{ objectFit: 'contain', layout: 'responsive' }}
		/>
	);
};

NextAssetImage.propTypes = {
	aspect_ratio: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.instanceOf(null),
	]),
	asset: PropTypes.object.isRequired,
	className: PropTypes.any,
	object_fit: PropTypes.string,
	layout: PropTypes.oneOf(['fill', 'fixed', 'intrinsic', 'raw', 'responsive']),
	placeholder: PropTypes.string,
	quality: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	no_aspect_ratio: PropTypes.bool,
	xs: PropTypes.bool,
	sm: PropTypes.bool,
	md: PropTypes.bool,
	lg: PropTypes.bool,
};
