import { CircularProgress, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	outerFullHeight: {
		minHeight: '100%',
		height: '100%',
	},
	innerFullHeight: {
		position: 'relative',
		top: '50%',
	},
	root: {},
}));

export const CenteredCircularProgress = ({
	children,
	show = true,
	fullHeight = false,
}) => {
	const classes = useStyles();
	return (
		<div className={clsx({ [classes.outerFullHeight]: fullHeight })}>
			<div className={clsx({ [classes.innerFullHeight]: fullHeight })}>
				<Grid
					container
					direction={'row'}
					justifyContent={'center'}
					className={clsx('CenteredCircularProgress-root')}
				>
					<Grid item style={{ height: 50, top: '50%', position: 'relative' }}>
						<CircularProgress />
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

CenteredCircularProgress.propTypes = {
	show: PropTypes.bool,
	fullHeight: PropTypes.bool,
};
