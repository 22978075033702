import _ from 'lodash';
import { MuiGlobal } from '../../MuiGlobal';

export const CDS = {
	applyMuiAsset: (asset, options) => {
		const r = _.cloneDeep(asset);
		const o = _.assign(
			{},
			{
				cdsBaseUrl: MuiGlobal.get('cdsBaseUrl', 'http://cds.e-s.lu'),
			},
			options
		);
		const { URL } = asset;
		const relativeUrl = URL.startsWith('/')
			? URL
			: `/catalog/product/${URL[0]}/${URL[1]}/${URL}`;
		const absoluteUrl = `${o.cdsBaseUrl}/image${relativeUrl}`;
		const queryParameter = new URLSearchParams();
		if (!_.isNil(o.width)) queryParameter.set('WIDTH', o.width);
		if (!_.isNil(o.height)) queryParameter.set('HEIGHT', o.height);
		const queryString = queryParameter.toString();
		r.URL =
			queryString.length === 0 ? absoluteUrl : `${absoluteUrl}?${queryString}`;
		return r;
	},
};
