import React, { Fragment, useContext } from 'react';
import { MuiAppContext } from '@lsoft/mui';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { HeaderIconPortalButton } from './HeaderIconPortalButton';
import { grey } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { HeaderHamburgerMenuContent } from './HeaderHamburgerMenuContent';

const useStyles = makeStyles((theme) => ({
	IconButton: {
		background: grey[100],
		'&:hover': {
			background: grey[200],
		},
	},
}));

const HeaderHamburgerMenu = ({ children, theme }) => {
	const { buttonRef, isOpen, onButtonClick } = useContext(
		HeaderIconPortalButton.Context
	);

	const classes = useStyles(theme);

	const { loginInfo, userInfo, logout } = useContext(MuiAppContext);

	const { setRenderLogin } = loginInfo;

	return (
		<Fragment>
			<IconButton
				ref={buttonRef}
				color={'inherit'}
				onClick={onButtonClick}
				className={clsx(classes.IconButton)}
			>
				<MenuIcon />
			</IconButton>
			{isOpen && <HeaderHamburgerMenuContent />}
		</Fragment>
	);
};

HeaderHamburgerMenu.propTypes = {};
HeaderHamburgerMenu.defaultProps = {};

export { HeaderHamburgerMenu };
