import React, { useCallback, useContext, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { CenteredCircularProgress } from '../components/CenteredCircularProgress';
import PropTypes from 'prop-types';

export const LoadingOverlayContext = React.createContext({});
export const LoadingOverlayContainer = ({ children, delay = 300 }) => {
	const [loading, real_set_loading] = useState(false);
	const set_loading = useCallback(
		async (state) => {
			//todo: timeout
			await real_set_loading(state);
		},
		[real_set_loading, delay]
	);
	return (
		<LoadingOverlayContext.Provider value={{ loading, set_loading }}>
			{children}
		</LoadingOverlayContext.Provider>
	);
};

const useStyles = makeStyles((theme) => ({
	LoadingOverlay: {
		zIndex: theme.zIndexes.LoadingOverlay,
		height: '100%',
		width: '100%',
		textAlign: 'center',
	},
	relative: {
		position: 'absolute',
		height: '100%',
		width: '100%',
	},
	fullscreen: {
		left: 0,
		top: 0,
		position: 'fixed',
		height: '100%',
		width: '100%',
	},
	backdrop: {
		opacity: 0.7,
		background: 'white',
	},
	inner: {
		height: '100%',
	},
	red: { border: '1px solid red' },
}));

export const LoadingOverlay = ({ fullscreen = false }) => {
	const classes = useStyles();
	const { loading } = useContext(LoadingOverlayContext);
	if (!loading) return null;
	return (
		<div
			className={clsx(classes.LoadingOverlay, {
				[classes.relative]: !fullscreen,
				[classes.fullscreen]: fullscreen,
				[classes.backdrop]: true,
			})}
		>
			<Grid
				container
				direction={'row'}
				justifyContent={'center'}
				alignItems={'center'}
				className={classes.inner}
			>
				<Grid item>
					<CenteredCircularProgress />
				</Grid>
			</Grid>
		</div>
	);
};

LoadingOverlay.propTypes = {
	fullscreen: PropTypes.bool,
};
