import React from 'react';
import { makePick, pickDefault } from './input_components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { funcWrap } from '../../../core';

const pick = makePick(['checked', 'size', 'color'], pickDefault);

const Component = (newProps) => {
	const pickedProps = pick(newProps);
	return (
		<FormControlLabel
			control={<Checkbox {...pickedProps} />}
			label={newProps.label}
			disabled={newProps.disabled}
			labelPlacement={newProps.labelPlacement}
			{...pickedProps}
		/>
	);
};

Component.propTypes = {
	newProps: PropTypes.object,
};

export const ic_checkbox = {
	// eslint-disable-next-line react/display-name
	Component,
	getNewProps: (newProps) => {
		newProps.checked = newProps.value;
		newProps.onChange = funcWrap(newProps.onChange, (onChange) => async (e) =>
			onChange(e.target.checked)
		);
		return newProps;
	},
};
