import styles from '../styles/styles';

export const MuiTopCenterBottomStyles = (theme) => ({
	...styles(theme),
	topCenterBottom: {},
	topCenterBottomScroll: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	topContent: {
		position: 'sticky',
		top: 0,
		zIndex: 10,
	},
	topContentScroll: {
		flex: '0 1 auto',
	},
	centerContent: {},
	centerContentScroll: {
		flex: '1 1 auto',
		overflowY: 'overlay',
		fallbacks: {
			overflowY: 'auto',
		},
	},
	bottomContent: {
		position: 'sticky',
		bottom: 0,
		zIndex: 10,
	},
	bottomContentScroll: {
		flex: '0 1 40px',
	},

	Container: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	Top: {
		flex: '0 1 auto',
	},
	Center: {
		flex: '1 1 auto',
		overflowY: 'overlay',
		fallbacks: {
			overflowY: 'auto',
		},
	},
	Bottom: {
		flex: '0 1 auto',
	},
});
