export const trArticleSuccessFullAddedToCart = {
	DE: `Artikel erfolgreich in den Warenkorb gelegt.`,
	EN: `Article successfully added to shopping cart.`,
	FR: `Article ajouté avec succès au panier.`,
};
export const trYourShoppingCartHasBeenUpdated = {
	DE: 'Ihr Warenkorb wurde aktualisiert.',
	EN: 'Your shopping cart has been updated.',
	FR: 'Votre panier a été mis à jour.',
};
