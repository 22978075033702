import React, { Fragment, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { MuiPaginationError } from './MuiPaginationError';
import Typography from '@material-ui/core/Typography';
import { MuiPaginationInfo } from './MuiPaginationInfo';
import { MuiPaginationIterator } from './MuiPaginationIterator';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { MuiPaginationLoadMore } from './MuiPaginationLoadMore';
import { MuiPaginationPageSelector } from './MuiPaginationPageSelector';
import { MuiPaginationLoadingIndicator } from './MuiPaginationLoadingIndicator';
import { MuiPaginationContext } from './MuiPaginationContext';
import { MuiBasicTable } from '../MuiBasicTable';
import _ from 'lodash';
import { MuiPaginationItemsPerPageSelector } from './MuiPaginationItemsPerPageSelector';
import { MuiPagination } from './MuiPagination';

const MuiPaginationBasicTableView = (props) => {
	const { initialized, DATA } = useContext(MuiPaginationContext);
	const pageSelector = () => {
		if (props.loadMore || props.noPageSelector) return null;
		return (
			<Grid item>
				<MuiPaginationPageSelector />
			</Grid>
		);
	};
	const renderAbove = () => {
		if (!initialized) return <MuiPaginationLoadingIndicator />;
		return (
			<Fragment>
				<Grid container direction={'column'} spacing={0}>
					<MuiPaginationError
						renderError={(error) => {
							return <Grid item>{props.renderError(error)}</Grid>;
						}}
					/>
				</Grid>
				{props.renderAbove()}
				<MuiPaginationLoadingIndicator />
			</Fragment>
		);
	};

	const renderItemsPerPage = () => {
		if (_.get(props, ['options', 'itemsPerPageOptions'], null) == null)
			return null;
		return <MuiPaginationItemsPerPageSelector />;
	};

	const renderRows = () => {
		return (
			<MuiPaginationIterator
				renderRow={(ID) => props.renderRow(ID, _.get(DATA, ID, null))}
			/>
		);
	};
	const renderBelowRows = () => {
		if (!initialized) return null;
		if (!props.loadMore) return null;
		return (
			<Fragment>
				<Grid item>
					<Grid container direction={'column'} alignItems={'center'}>
						<Grid item>
							<MuiPaginationLoadMore />
						</Grid>
					</Grid>
				</Grid>
				<Grid item>&nbsp;</Grid>
			</Fragment>
		);
	};
	const renderBottomInfoArea = () => {
		return (
			<Grid item>
				<Grid
					container
					direction={'row'}
					justifyContent={'flex-end'}
					alignItems={'center'}
					spacing={1}
				>
					{!props.noInfo && (
						<Grid item>
							<Typography variant={'subtitle2'} color={'inherit'}>
								<MuiPaginationInfo />
							</Typography>
						</Grid>
					)}
					{pageSelector()}
				</Grid>
			</Grid>
		);
	};
	const renderHeader = initialized ? props.renderHeader : () => null;
	const renderStaticRows = _.isNil(props.renderStaticRows)
		? () => null
		: props.renderStaticRows;
	return (
		<MuiBasicTable
			renderAbove={renderAbove}
			renderHeader={renderHeader}
			renderStaticRows={renderStaticRows}
			renderRows={renderRows}
			renderBelowRows={renderBelowRows}
			renderBottomButtonArea={props.renderBottomButtonArea}
			renderBottomButtonAreaRight={props.renderBottomButtonAreaRight}
			renderBottomInfoArea={renderBottomInfoArea}
			hideContent={!initialized}
			hideBottom={!initialized}
			className={props.className}
			contentClassName={props.contentClassName}
			renderItemsPerPage={renderItemsPerPage}
		/>
	);
};

const MuiPaginationBasicTable = (props) => {
	const newProps = _.omit(props, ['noContext']);
	if (props.noContext) {
		return <MuiPaginationBasicTableView {...newProps} />;
	} else {
		return (
			<MuiPagination {...newProps}>
				<MuiPaginationBasicTableView {...newProps} />
				{props.children}
			</MuiPagination>
		);
	}
};

MuiPaginationBasicTable.propTypes = MuiPaginationBasicTableView.propTypes = {
	noContext: PropTypes.bool,
	preventInitialLoad: PropTypes.bool,
	options: PropTypes.object,
	renderError: PropTypes.func,
	renderHeader: PropTypes.func.isRequired,
	renderRow: PropTypes.func.isRequired,
	renderAbove: PropTypes.func,
	renderBelow: PropTypes.func,
	renderBottomButtonArea: PropTypes.func,
	renderBottomButtonAreaRight: PropTypes.func,
	loadMore: PropTypes.bool,
	noPageSelector: PropTypes.bool,
	noInfo: PropTypes.bool,
	className: PropTypes.any,
};

MuiPaginationBasicTable.defaultProps =
	MuiPaginationBasicTableView.defaultProps = {
		// eslint-disable-next-line react/display-name
		renderError: (error) => {
			return (
				<Alert severity="error">
					{error.ERROR_CLASS}: {error.ERROR}
				</Alert>
			);
		},
		renderBottomButtonArea: () => null,
		renderBottomButtonAreaRight: () => null,
		renderAbove: () => null,
		loadMore: false,
		noContext: false,
		noPageSelector: false,
	};
export { MuiPaginationBasicTable };
