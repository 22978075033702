import React, { useContext, useState } from 'react';
import { makePick, pickDefault } from './input_components';
import _ from 'lodash';
import { funcWrap } from '../../../core/funcWrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { TrContext } from '@lsoft/translate';

const pick = makePick(['options', 'variant', 'size'], pickDefault);

const defaultPlaceholder = {
	EN: 'Choose an option',
	DE: 'Wählen Sie eine Option',
	FR: 'Choisir une option',
};

const Component = (newProps) => {
	const [isError, setError] = useState(false);
	// console.log(newProps.value);
	// const isFirstRun = useRef(true);
	// useEffect(() => {
	// 	console.log('ue');
	// 	if (isFirstRun.current) {
	// 		isFirstRun.current = false;
	// 		return;
	// 	}
	// 	console.log('ue after');
	// }, [newProps]);

	// console.log('autocomplete value', newProps.value);
	return (
		<Autocomplete
			{...pick(newProps)}
			value={newProps.inputValue}
			onInputChange={(e, value, reason) => {
				// console.log('ic', value, reason);
				const tempError = reason === 'input';
				if (tempError !== isError) setError(tempError);
			}}
			getOptionSelected={(option) => option.value}
			getOptionLabel={(option) => {
				return option.label;
			}}
			onChange={(e, v) => {
				newProps.onChange(_.get(v, 'value', null));
			}}
			// style={{ width: 300 }}
			// autoComplete
			autoHighlight
			// autoSelect
			renderInput={(params) => (
				<TextField
					{...params}
					error={isError || newProps.error}
					helperText={newProps.helperText}
					label={newProps.label}
					variant={newProps.styleVariant}
					inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
					placeholder={newProps.placeholder}
					InputLabelProps={newProps.InputLabelProps}
				/>
			)}
		/>
	);
};

const getNewProps = (newProps) => {
	newProps.variant = newProps.styleVariant;
	newProps.inputValue =
		newProps.value === null
			? null
			: _.get(
					newProps.options.filter((i) => i.value === newProps.value),
					'[0]',
					null
			  );

	newProps.onChange = funcWrap(
		newProps.onChange,
		(onChange) => async (value) => {
			onChange(value);
		}
	);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const trProvider = useContext(TrContext);
	const trPlaceholderData = _.has(newProps, 'trPlaceholder')
		? newProps.trPlaceholder
		: defaultPlaceholder;
	const trPlaceholderString = trProvider.getTranslationFromData(
		trPlaceholderData
	);
	newProps.placeholder = trPlaceholderString;
	if (_.get(newProps, 'selectableNull', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		newProps.options = [{ label: trPlaceholderString, value: null }].concat(
			newProps.options
		);
	}

	return newProps;
};

export const ic_combo = {
	Component,
	getNewProps,
};
