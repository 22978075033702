import React from 'react';
import { useRouter } from 'next/router';
import { TrProvider } from '@lsoft/translate';

const getTrProps = (locale, locales) => {
	const availableLanguages = locales.map((l) => l.toUpperCase());
	const activeLanguage = locale.toUpperCase();
	return { activeLanguage, availableLanguages };
};

export const ShopPageCore = ({ children, ...pageProps }) => {
	const { locale, locales } = useRouter();
	const trProps = getTrProps(locale, locales);
	return (
		// <DataStoreContainer.Container>
			<TrProvider {...trProps}>{children}</TrProvider>
		// </DataStoreContainer.Container>
	);
};
