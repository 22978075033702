export const SimpleTranslationStyles = (theme) => ({
	GridSelect: {
		paddingLeft: 0,
	},
	GridInput: {},
	GridButton: {
		width: 30,
	},
	AddButton: {
		width: 95,
	},
	Select: {
		width: 55,
		background: theme.palette.grey[200],
		borderRadius: 4,
		marginRight: theme.spacing(1),
		fontSize: '0.8rem',
	},
	Input: {
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),
		minWidth: '100%',
	},
	FormControlOutlined: {
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.grey[400],
		borderRadius: 4,
		'&:hover': {
			borderColor: theme.palette.grey[800],
		},
		'&:focus-within': {
			borderColor: theme.palette.primary[600],
			borderWidth: 1,
			boxShadow: [[0, 0, 0, 1, theme.palette.primary[600]]],
		},
	},
	FormControl: {
		paddingLeft: theme.spacing(0),
		paddingRight: theme.spacing(0),
	},
	FormControlInner: {
		'& .MuiSelect-icon': { left: 0, paddingLeft: 0 },
		'& .MuiSelect-select.MuiSelect-select': {
			paddingRight: theme.spacing(0.5),
			paddingLeft: theme.spacing(3),
		},
		// border: [[1, 'solid', 'red']],
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 0,
		},
		'& .MuiInput-underline:before': {
			borderBottom: 0,
		},
		'& .MuiInput-underline:after': {
			borderBottom: 0,
		},
		'& .MuiFormControl-root': {
			border: 0,
		},
		'& .MuiIconButton-root': {
			// border: '1px solid purple',
			marginRight: 0,
		},
	},
});
