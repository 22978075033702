import React, { useCallback } from 'react';
import { LsDataContainer } from '../../components/LsContainer';
import {
	application_modules_modules_get,
	application_modules_modules_put,
} from '../../backend/application_modules/application_modules';
import _ from 'lodash';

const debug = false;

const application_modules_load = async ({ app_name, modules }) => {
	const application_module_paths_activated =
		await application_modules_modules_get({
			app_name,
		});

	const application_module_paths_all = _.union(
		_.concat(
			application_module_paths_activated,
			['menu.admin.application_modules', 'application_modules'],
			modules
		)
	);

	const application_modules = application_module_paths_all.reduce(
		(ret, application_module) => {
			ret[application_module] = {
				path: application_module,
				enabled:
					application_module_paths_activated.includes(application_module),
			};
			return ret;
		},
		{}
	);

	if (debug) console.log('test', { application_modules });

	return {
		application_modules,
	};
};

export const ApplicationModulesContext = React.createContext(null);
export const ApplicationModulesContainer = ({
	children,
	app_name,
	menu_entries,
	modules,
	...props
}) => {
	const extend_context = useCallback(async ({ data, functions }) => {
		if (!_.isNil(data)) {
			const { application_modules } = data;
			const real_application_modules = _.get(data, ['application_modules'], {});
			if (data) {
				data.menu_entries_available = menu_entries;
				data.menu_entries_enabled = Object.values(real_application_modules)
					.filter((i) => i.path.startsWith('menu.') && i.enabled)
					.map((i) => i.path);
				data.application_modules_enabled = Object.values(
					real_application_modules
				)
					.filter((i) => i.enabled)
					.map((i) => i.path);
			}
			if (functions) {
				functions.application_modules_save = async (application_modules) => {
					return await application_modules_modules_put({
						app_name,
						application_modules: Object.values(application_modules)
							.filter((i) => i.enabled)
							.map((i) => i.path),
					});
				};
				functions.application_modules_enabled = (paths) => {
					return _.isEmpty(
						paths.filter(
							(path) => !_.get(application_modules, [path, 'enabled'])
						)
					);
				};
			}
		}
		return { data, functions };
	}, []);

	return (
		<LsDataContainer
			context={ApplicationModulesContext}
			loader={async () => {
				return await application_modules_load({
					app_name,
					modules,
					menu_entries,
				});
			}}
			extendContext={extend_context}
			{...props}
		>
			{children}
		</LsDataContainer>
	);
};

ApplicationModulesContainer.propTypes = LsDataContainer.propTypes;
ApplicationModulesContainer.propTypes = {
	...LsDataContainer.propTypes,
};
