import { makeOptionsProvider, MuiContainer } from '../MuiWebapi';
import _ from 'lodash';
import { getTranslation } from '@lsoft/translate';

// eslint-disable-next-line no-unused-vars
const contextFunctions = {
	countryName: (ctx, countryCode, locale) => {
		const countryName = _.get(ctx.data, [countryCode, 'NAME'], null);
		return _.isNil(countryName) ? '' : getTranslation(countryName, locale);
	},
};

export const Countries = MuiContainer({
	contextFunctions,
});

makeOptionsProvider(Countries, {
	includeData: false,
	makeOption: (entry) => ({
		value: entry.COUNTRY_CODE,
		label: entry.NAME,
	}),
	filterEntries: (entries) => entries.sort(),
});

makeOptionsProvider(Countries, {
	name: 'OptionsEuCountries',
	includeData: false,
	makeOption: (entry) => ({
		value: entry.COUNTRY_CODE,
		label: entry.NAME,
	}),
	filterEntries: (entries) => entries.filter((i) => i.EU_COUNTRY),
});
