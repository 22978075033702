import PropTypes from 'prop-types';

const MuiFormatNumber = (props) => {
	const placeSeparator = (val) => {
		let string_val = val.toString();
		if (props.decimals === 0) {
			string_val = string_val.replace('.', '');
			string_val = string_val.replace(',', '');
			return string_val;
		}
		if (string_val.length > props.decimals) {
			let separator_position = string_val.length - props.decimals;
			string_val =
				string_val.slice(0, separator_position) +
				props.decimalSeparator +
				string_val.slice(separator_position);
			if (string_val.indexOf('-,') !== -1 || string_val.indexOf('-.') !== -1) {
				string_val = string_val.replace('-.', '-0.');
				string_val = string_val.replace('-,', '-0,');
			}
			return string_val;
		} else if (string_val.length <= props.decimals) {
			let zeros = '';
			for (let i = string_val.length; i < props.decimals; i++) {
				zeros = zeros + '0';
			}
			return '0' + props.decimalSeparator + zeros + val;
		}
	};

	const formatToString = (val) => {
		let val_string = placeSeparator(val);
		if (props.showSign) {
			if (val > 0) {
				val_string = '+' + val_string;
			}
		}
		return val_string;
	};
	let decimals = props.decimals;
	let value = props.value * 10 ** decimals;

	if (props.round === 'UP') {
		value = Math.ceil(value);
	} else if (props.round === 'DOWN') {
		value = Math.floor(value);
	} else if (props.round === 'HALF_UP') {
		value = Math.round(value);
	}

	if (value === 0) {
		if (props.decimals === 0) {
			return 0;
		}
		let zeros = '0'.repeat(decimals);
		return '0,' + zeros;
	}

	return formatToString(value);
};

MuiFormatNumber.propTypes = {
	decimals: PropTypes.number,
	value: PropTypes.number,
	round: PropTypes.string,
	decimalSeparator: PropTypes.string,
	showSign: PropTypes.bool,
};
MuiFormatNumber.defaultProps = {
	decimals: 2,
	value: 0,
	round: 'HALF_UP',
	decimalSeparator: ',',
	showSign: false,
};

export { MuiFormatNumber };
