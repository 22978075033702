import React, { useContext } from 'react';
import { MuiSearchBar } from '../MuiSearch/MuiSearchBar';
import { MuiSearchBarContainer } from '../MuiSearch/MuiSearchBarContainer';
import { MuiAppContext } from '@lsoft/mui';
import { ShopSearchAutocompleteResult } from './ShopSearchAutocompleteResult';
import { useRouter } from 'next/router';
import { getTranslation } from '@lsoft/translate';
import _ from 'lodash';
import { trSearchApiNotReachable } from './ShopSearch.tr';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import { trSomethingWentWrongPleaseTryAgainLater } from '../CustomerCard/CustomerCardView.tr';
import { shop_search_query } from '@lsoft/shared/functions/search/search_query';
import { apiRenderProductsSearch } from '@lsoft/shared/api/render_products';

//keep this delay outside!!! otherwise the clear wont work
let delay;

export const ShopSearchBar = () => {
	const { scrollTop } = useContext(MuiAppContext);
	const { locale, push } = useRouter();
	const throwSnackbar = useShopSnackbar();

	const autocomplete = async (search_string) => {
		try {
			const query = shop_search_query(search_string, {});
			const ret = await apiRenderProductsSearch({
				skip: 0,
				limit: 12,
				query,
				sort: {},
				view: 'shop_autocomplete',
			});
			const result = ret.ids.map((sku) => _.get(ret.data, sku));
			return result;
		} catch (e) {
			const error_message = _.get(e, ['message'], '');
			if (error_message.includes('504')) {
				throwSnackbar({
					exception: e,
					msg: getTranslation(trSearchApiNotReachable, locale),
					variant: 'error',
				});
			} else {
				throwSnackbar({
					exception: e,
					msg: getTranslation(trSomethingWentWrongPleaseTryAgainLater, locale),
					variant: 'error',
				});
			}
		}
	};

	const handle_delay = async (ms) => {
		await clearTimeout(delay);
		await new Promise((resolve) => {
			delay = setTimeout(resolve, ms);
		});
	};

	const delayed_autocomplete = async (search_string) => {
		await handle_delay(500);
		return await autocomplete(search_string);
	};

	const search = (search_string) => {
		// noinspection JSIgnoredPromiseFromCall
		push({ pathname: '/search', query: { q: search_string } });
	};

	const onResultClick = (data) => {
		scrollTop();
		// noinspection JSIgnoredPromiseFromCall
		push(`/product/${data.sku}`);
	};

	return (
		<MuiSearchBarContainer
			autocomplete={delayed_autocomplete}
			search={search}
			onResultClick={onResultClick}
		>
			<MuiSearchBar
				AutocompleteResultComponent={ShopSearchAutocompleteResult}
			/>
		</MuiSearchBarContainer>
	);
};
