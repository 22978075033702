export const free_shipping_thresholds = {
	BE: 100,
	// BG: 100,
	// DK: 100,
	DE: 100,
	// EE: 100,
	// FI: 100,
	FR: 100,
	// GR: 100,
	// IE: 100,
	// IT: 100,
	// HR: 100,
	// LV: 100,
	// LT: 100,
	LU: 100,
	NL: 100,
	AT: 100,
	// PL: 100,
	// PT: 100,
	// RO: 100,
	// SE: 100,
	// SK: 100,
	// SI: 100,
	// ES: 100,
	// CZ: 100,
	// HU: 100,
};

export const minimum_good_values = {
	BE: 10,
	BG: 10,
	DK: 10,
	DE: 10,
	EE: 10,
	FI: 10,
	FR: 10,
	GR: 10,
	IE: 10,
	IT: 10,
	HR: 10,
	LV: 10,
	LT: 10,
	LU: 10,
	NL: 10,
	AT: 10,
	PL: 10,
	PT: 10,
	RO: 10,
	SE: 10,
	SK: 10,
	SI: 10,
	ES: 10,
	CZ: 10,
	HU: 10,
};
