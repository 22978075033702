import {
	inputProps,
	makePick,
	pickDefault,
	validateShortcuts,
} from './input_components';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { funcWrap } from '../../../core/funcWrap';
import _ from 'lodash';
import clsx from 'clsx';
import { validateKeyEventCharacters } from '../../../core';

const pick = makePick(
	[
		'fullWidth',
		'label',
		'value',
		'onKeyDown',
		'onKeyUp',
		'InputProps',
		'inputProps',
		'variant',
		'placeholder',
		'size',
		'helperText',
		'error',
		'onFocus',
		'type',
	],
	pickDefault
);

/*
 * Base Input Functions - Text */

export const textInputOnKeyDown = (e, props) => {
	if (!validateShortcuts(e, props)) {
		e.preventDefault();
		return;
	}
	if (!validateKeyEventCharacters(e, props)) {
		e.preventDefault();
		return;
	}
	if (e.keyCode === 13) {
		// 13 === ENTER
		props.onEnter();
	}
};

const textInputOnKeyUp = (e, props) => {
	if (!validateShortcuts(e, props)) {
		e.preventDefault();
		return;
	}

	if (!validateKeyEventCharacters(e, props)) {
		e.preventDefault();
		return;
	}
};

const Component = (newProps) => {
	return (
		<TextField InputLabelProps={newProps.InputLabelProps} {...pick(newProps)} />
	);
};

Component.propTypes = {
	newProps: PropTypes.object,
};

export const ic_text = {
	// eslint-disable-next-line react/display-name
	Component,
	getNewProps: (newProps) => {
		newProps.onEnter = !_.isNull(_.get(newProps, 'onEnter', null))
			? newProps.onEnter
			: () => {};
		newProps.InputProps = inputProps(newProps);
		if (_.get(newProps, 'onFocusSelectAll', true)) {
			newProps.onFocus = (e) => {
				e.target.select();
			};
		}
		newProps.type = newProps.password ? 'password' : 'text';
		if (newProps.styleVariant === 'tablecell') {
			newProps.styleVariant = 'filled';
			newProps.size = 'small';
			newProps.className = clsx(
				newProps.className,
				newProps.sharedClasses.MuiInputTableCell
			);
			newProps.helperText = null;
			newProps.label = null;
		}
		newProps.variant = newProps.styleVariant;
		newProps.onKeyDown = (e) => textInputOnKeyDown(e, newProps);
		newProps.onKeyUp = (e) => textInputOnKeyUp(e, newProps);
		newProps.onChange = funcWrap(newProps.onChange, (onChange) => async (e) => {
			let val = e.target.value;
			if (newProps.upperCase) {
				val = e.target.value.toUpperCase();
			}
			if (newProps.lowerCase) {
				val = e.target.value.toLowerCase();
			}
			await onChange(val);
		});

		if (_.has(newProps, 'maxLength')) {
			newProps.inputProps = { maxLength: newProps.maxLength };
		}
		newProps.placeholder = newProps.trPlaceholderString;
		return newProps;

		//	übergebene props abändern
	},
};
