import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { MuiLoadableOnClick } from './MuiLoadableOnClick';
import PropTypes from 'prop-types';
import _ from 'lodash';

const MuiLoadableIconButton = (props) => {
	return (
		<MuiLoadableOnClick loading={props.loading}>
			<IconButton {..._.omit(props, ['loading'])}>{props.children}</IconButton>
		</MuiLoadableOnClick>
	);
};
MuiLoadableIconButton.propTypes = {
	loading: PropTypes.bool,
};
MuiLoadableIconButton.defaultProps = {
	loading: false,
};

export { MuiLoadableIconButton };
