export const trAccount = { DE: 'Konto', EN: 'Account', FR: 'Compte' };
export const trMyAccount = {
	DE: 'Mein Konto',
	EN: 'My account',
	FR: 'Mon compte',
};
export const trSignOut = { DE: 'Abmelden', EN: 'Sign out', FR: 'Déconnexion' };
export const trSignIn = { DE: 'Anmelden', EN: 'Sign in', FR: 'Connexion' };
export const trCreateAccount = {
	DE: 'Konto anlegen',
	EN: 'Create account',
	FR: 'Créer un compte',
};
export const trYouHaveSuccessfullyLoggedOut = {
	DE: 'Sie haben sich erfolgreich abgemeldet',
	EN: 'You have successfully logged out',
	FR: 'Vous avez réussi à vous déconnecter',
};
export const trYouHaveSuccessfullyLoggedIn = {
	DE: 'Sie haben sich erfolgreich angemeldet',
	EN: 'You have successfully logged in',
	FR: 'Vous avez réussi à vous connecter',
};
export const trShoppingCart = {
	DE: 'Warenkorb',
	EN: 'Shopping Cart',
	// prettier-ignore
	FR: 'Panier d\'achat',
};
export const trValueOfGoods = {
	DE: 'Warenwert',
	EN: 'Value of Goods',
	FR: 'Valeur des biens',
};
export const trEditShoppingCart = {
	DE: 'Warenkorb bearbeiten',
	EN: 'Edit shopping cart',
	FR: 'Modifier le panier',
};
export const trProceedToCheckout = {
	DE: 'Zur Kasse',
	EN: 'Proceed to Checkout',
	FR: 'Passer à la caisse',
};
export const trLanguage = {
	DE: 'Sprache',
	EN: 'Language',
	FR: 'Langue',
};
export const trChangeLanguage = {
	DE: 'Sprache anpassen',
	EN: 'Change language',
	FR: 'Changer de langue',
};
