import React, { useCallback } from 'react';
import { render_product_load } from '../backend/render/product';
import { LsDataContainer } from '../components/LsContainer';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const loadProduct = async ({ sku, view }) => {
	return await render_product_load({
		sku,
		view,
	});
};

export const ProductContext = React.createContext(null);

export const ProductContainer = ({ children, ...props }) => {
	const extendContext = useCallback(({ data, functions }) => {
		if (!_.isNil(data)) {
			data.openProductsLink = `/product/${data.sku}`;
		}
		return { data, functions };
	}, []);

	return (
		<LsDataContainer
			id={{ sku: props.sku }}
			context={ProductContext}
			loader={() =>
				loadProduct({
					sku: props.sku,
					view: props.view,
				})
			}
			extendContext={extendContext}
			{...props}
		>
			{children}
		</LsDataContainer>
	);
};
ProductContainer.propTypes = {
	...LsDataContainer.propTypes,
	sku: PropTypes.number.isRequired,
	view: PropTypes.string,
};
