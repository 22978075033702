import React, { useContext } from 'react';
import { MuiAppLoginView } from './MuiAppLoginView';
import { AmContext } from '@lsoft/shared/components/am/AmContext';

export const WithMuiAppLogin = ({ children }) => {
	const amProvider = useContext(AmContext);
	if (!amProvider.loggedIn) {
		return <MuiAppLoginView />;
	} else {
		return children;
	}
};

WithMuiAppLogin.propTypes = {};

WithMuiAppLogin.defaultProps = {};
