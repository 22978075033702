const _ = require('lodash');

const global = (0, eval)('this');
global._lsoft_mui_globals = {};

const MuiGlobal = {};

MuiGlobal.set = (path, value) => {
	_.set(global._lsoft_mui_globals, path, value);
};
MuiGlobal.get = (path, value_default = null) => {
	return _.get(global._lsoft_mui_globals, path, value_default);
};

MuiGlobal.setMultiple = (globals) => {
	Object.entries(globals).map(([path, value]) => {
		MuiGlobal.set(path, value);
	});
};

MuiGlobal.getAll = () => global._lsoft_mui_globals;

const useMuiGlobals = () => MuiGlobal.getAll();

module.exports = { MuiGlobal, useMuiGlobals };
