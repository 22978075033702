import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import PropTypes from 'prop-types';

const MuiLoadableContext = React.createContext({});
MuiLoadableContext.displayName = 'MuiLoadableContext';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		// zIndex: theme.zIndex.drawer + 1,
		zIndex: theme.zIndexes.MuiLoadable,
		color: '#fff',
	},
}));

const MuiLoadableContainer = ({ children, theme, initialLoading }) => {
	const classes = useStyles(theme);
	const [loading, setLoading] = useState(initialLoading);
	const contextData = {
		loading,
		setLoading,
	};
	return (
		<MuiLoadableContext.Provider value={contextData}>
			{children}
			<Backdrop open={loading} className={clsx(classes.backdrop)}>
				<CircularProgress color={'inherit'} />
			</Backdrop>
		</MuiLoadableContext.Provider>
	);
};

MuiLoadableContainer.propTypes = {
	initialLoading: PropTypes.bool,
};
MuiLoadableContainer.defaultProps = {
	initialLoading: false,
};

export { MuiLoadableContainer, MuiLoadableContext };
