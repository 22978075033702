import { createTheme } from '@material-ui/core/styles';
import { amber, grey, red } from '@material-ui/core/colors';
import { MuiDefaultTheme } from '@lsoft/mui';
import _ from 'lodash';

const theme = createTheme(
	_.merge({}, MuiDefaultTheme, {
		palette: {
			primary: red,
			secondary: amber,
			background: {
				default: '#f2f2f2',
			},
		},
		sizes: {
			sideBarWidth: 180,
			appBarHeight: 66,
		},
		zIndexes: {
			Header: 10000,
			HeaderIconPortal: 10000,
			LoadingOverlay: 10002,
			CookieNotification: 10001,
			SearchAutocomplete: 10000,
		},
		zIndex: {
			snackbar: 10001,
		},
		breakpoints: {
			CSSContainerMaxWidth: 'lg',
			CSSContainerDisableGutters: 'md',
			values: {
				xs: 0,
				sm: 600,
				md: 960,
				lg: 1280,
			},
		},
		overrides: {
			MuiCssBaseline: {
				'@global': {
					'.MuiTab-root.Mui-selected': {
						background: grey[200],
					},
				},
			},
		},
	})
);

export default theme;
