// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import { ApplicationModulesContext } from '../../container/application-modules/ApplicationModules';

const ApplicationModulesEnabled = (
	props = {
		paths: [],
	}
) => {
	const { data, functions } = useContext(ApplicationModulesContext);
	const { children, paths } = props;

	if (paths.length === 0) return children;
	const enabled = functions.application_modules_enabled(paths);
	if (!enabled) return null;

	// const unfulfilled_modules = paths.filter(
	// 	(path) => !_.get(data, ['application_modules', path, 'enabled'], false)
	// );
	// if (unfulfilled_modules.length !== 0) return null;
	return children;
};

ApplicationModulesEnabled.propTypes = {
	paths: PropTypes.array,
};

export { ApplicationModulesEnabled };
