import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	aspectRatio: {
		position: 'relative',
		width: '100%',
		// border: '1px solid red',
	},
	aspectRatioInner: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},
}));

export const MuiAspectRatio = ({ theme, aspectRatio, children }) => {
	const classes = useStyles({ theme });

	return (
		<Box
			className={classes.aspectRatio}
			style={{ paddingTop: 100 / aspectRatio + '%' }}
		>
			<Box className={classes.aspectRatioInner}>{children}</Box>
		</Box>
	);
};

MuiAspectRatio.propTypes = {
	aspectRatio: PropTypes.any,
};

MuiAspectRatio.defaultProps = {
	aspectRatio: 1 / 1,
};
