import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import React from 'react';
import { makePick, pickDefault } from './input_components';
import PropTypes from 'prop-types';
import { funcWrap } from '../../../core/funcWrap';

const pick = makePick(['checked'], pickDefault);

const Component = (newProps) => {
	return (
		<FormControlLabel
			control={<Switch {...pick(newProps)} />}
			label={newProps.label}
			disabled={newProps.disabled}
			labelPlacement={newProps.labelPlacement}
		/>
	);
};

Component.propTypes = {
	newProps: PropTypes.object,
};

export const ic_switch = {
	// eslint-disable-next-line react/display-name
	Component,
	getNewProps: (newProps) => {
		newProps.checked = newProps.value;
		newProps.onChange = funcWrap(newProps.onChange, (onChange) => async (e) =>
			onChange(e.target.checked)
		);
		return newProps;

		//	übergebene props abändern
	},
};
