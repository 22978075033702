/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import _ from 'lodash';
import { funcWrap } from '../../../core/funcWrap';
import Grid from '@material-ui/core/Grid';
import { IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import { Tr } from '@lsoft/translate';
import { makePick, pickDefault } from './input_components';
import InputAdornment from '@material-ui/core/InputAdornment';

const pick = makePick(
	[
		'fullWidth',
		'label',
		'value',
		'onKeyDown',
		'onKeyUp',
		'InputProps',
		'inputProps',
		'variant',
		'placeholder',
		'size',
		'helperText',
		'error',
		'onFocus',
		'type',
	],
	pickDefault
);

const Component = (newProps) => {
	const onChange = (val, index) => {
		let list = newProps.value;
		if (_.isNil(val)) {
			_.pullAt(list, index);
		} else {
			list[index] = val;
		}
		newProps.onChange(list);
	};

	const pickedProps = pick(newProps);
	const inputs = newProps.value.map((text, i) => {
		const realProps = _.merge({}, pickedProps, {
			value: text,
			onChange: (e) => {
				onChange(e.target.value, i);
			},
		});
		return (
			<Grid key={i} item xs={12}>
				<TextField
					InputLabelProps={newProps.InputLabelProps}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									size={'small'}
									color={'primary'}
									onClick={() => {
										onChange(null, i);
									}}
								>
									<RemoveIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
					{...realProps}
				/>
			</Grid>
		);
	});
	const list_length = newProps.value.length;
	return (
		<Grid container direction={'row'} spacing={1} alignItems={'center'}>
			{inputs}
			<Grid item xs={12}>
				<Grid
					container
					direction={'row'}
					justifyContent={newProps.addButtonPositionJustify}
				>
					<Grid item>
						<Button
							size={'small'}
							variant={'contained'}
							color={'primary'}
							startIcon={<AddIcon />}
							onClick={() => {
								onChange('', list_length);
							}}
						>
							<Tr tr={newProps.addTextTranslation} />
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
const getNewProps = (newProps) => {
	newProps.variant = _.get(newProps, 'variant', 'outlined');
	newProps.readonly = _.get(newProps, 'readonly', false);
	newProps.disabledOptions = _.get(newProps, 'disabledOptions', []);
	// newProps.label = _.get(newProps, 'label', { DE: 'list' });
	newProps.value = _.get(newProps, 'value', []);
	newProps.addButtonPositionJustify = _.get(
		newProps,
		'addButtonPositionJustify',
		'flex-start'
	);
	newProps.addTextTranslation = _.get(newProps, 'addTextTranslation', {
		DE: 'Hinzufügen',
		EN: 'Add',
	});
	newProps.variant = newProps.styleVariant;
	newProps.onChange = funcWrap(
		newProps.onChange,
		(onChange) => async (value) => {
			onChange(value);
		}
	);

	return newProps;
};
export const ic_list = {
	Component,
	getNewProps,
};
