import React, { useContext } from 'react';
import { MuiAppContext, MuiLink } from '@lsoft/mui';
import _ from 'lodash';
import clsx from 'clsx';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	LogoLink: {
		lineHeight: 0,
	},
}));

export const HeaderLinkLogo = (props) => {
	const classes = useStyles();
	const { loginInfo } = useContext(MuiAppContext);
	const setRenderLogin = _.get(loginInfo, 'setRenderLogin', () => {});
	return (
		<MuiLink
			href={'/'}
			fullWidth
			linkprops={{
				className: clsx(classes.LogoLink),
				onClick: () => setRenderLogin(false),
			}}
		>
			<Image
				src={'/images/logo_ltoys.png'}
				alt={'LToys - Bricks, Figures & More'}
				width={48}
				height={48}
				layout={'intrinsic'}
				priority
				loading={'eager'}
			/>
		</MuiLink>
	);
};

HeaderLinkLogo.propTypes = {};
