import _ from 'lodash';
import React, { useContext } from 'react';
import {
	applyContextAdditionalData,
	applyContextFunctions,
	combineContext,
	makeActionComponent,
	makeAddActionComponent,
} from './MuiWebapi';
import PropTypes from 'prop-types';

const getParentContextData = (parentContextData, options) => {
	const id = _.get(options, 'ID', null);
	if (_.isNil(id)) return null;
	return _.get(parentContextData, id, null);
};

export const MuiBaseContextConsumerContainer = (
	Context,
	contextAdditionalData,
	contextFunctions,
	contextTransform,
	ContextOptions
) => {
	const WrappedContainer = ({ options, ...props }) => {
		const parentContext = useContext(ContextOptions.Context);
		const overWriteData = _.get(options, 'data', null);
		const parentContextData = ContextOptions.selector(parentContext, options);

		if (_.isNil(parentContextData) && _.isNil(overWriteData)) return null;

		const innerContext = {
			data: _.isNil(overWriteData) ? parentContextData : overWriteData,
			parentContext,
		};

		// Apply base functions
		_.merge(innerContext, {
			applyContextFunctions,
			applyContextAdditionalData,
			combineContext,
		});

		_.merge(innerContext, _.omit(props, ['children', 'classes', 'context']));

		// combine context
		innerContext.combineContext(innerContext, {
			contextFunctions: contextFunctions,
			contextAdditionalData: contextAdditionalData,
		});

		// apply context functions
		innerContext.applyContextFunctions(innerContext);
		// transform context
		contextTransform(innerContext);
		// apply context additional data
		innerContext.applyContextAdditionalData(innerContext);

		return (
			<Context.Provider value={innerContext}>{props.children}</Context.Provider>
		);
	};
	WrappedContainer.propTypes = { options: PropTypes.object };
	return WrappedContainer;
};

export const MuiContextConsumerContainer = (
	ContextOptions,
	ContainerOptions
) => {
	const modContainerOptions = _.assign(
		{},
		{
			contextAdditionalData: {},
			contextFunctions: {},
			contextTransform: () => {},
			loadOnce: true,
			shouldLoad: (data) => data === null,
		},
		ContainerOptions
	);
	const {
		contextAdditionalData,
		contextFunctions,
		contextTransform,
	} = modContainerOptions;

	const Context = React.createContext(null);

	const Container = MuiBaseContextConsumerContainer(
		// load,
		Context,
		contextAdditionalData,
		contextFunctions,
		contextTransform,
		ContextOptions
	);

	const ActionComponentCollection = {};
	const addActionComponent = makeAddActionComponent(ActionComponentCollection);
	const ActionComponent = makeActionComponent(ActionComponentCollection);
	// const Container = props => props.children;
	//
	return {
		ActionComponentCollection,
		addActionComponent,
		ActionComponent,
		// load,
		// action,
		Container,
		Context,
	};
};
