import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { getTranslation } from '@lsoft/translate';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { trCountingBricks } from './LoadingOverlay.tr';

const useStyles = makeStyles((theme) => ({
	LoadingOverlay: {
		zIndex: theme.zIndexes.LoadingOverlay,
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
	},
	Progress: {
		textAlign: 'center',
		position: 'fixed',
		top: '50%',
		left: '50%',
		/* bring your own prefixes */
		transform: 'translate(-50%, -50%)',
		zIndex: theme.zIndexes.LoadingOverlay + 1,
	},
	ProgressInner: {
		padding: theme.spacing(13),
		color: 'black',
		borderRadius: theme.spacing(13),
	},
}));

const debug = false;

export const MuiLoadingOverlayContext = React.createContext(null);
MuiLoadingOverlayContext.displayName = 'MuiLoadingOverlayContext';

const MuiLoadingOverlayComponent = ({ theme }) => {
	const classes = useStyles({ theme });
	const { locale } = useRouter();

	const text = getTranslation(trCountingBricks, locale);

	return (
		<div className={clsx(classes.LoadingOverlay)}>
			<div className={clsx(classes.Progress)}>
				<div className={clsx(classes.ProgressInner)}>
					<CircularProgress color={'inherit'} />
					<Typography component={'div'} variant={'body2'}>
						{text}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export const MuiLoadingOverlay = ({ children }) => {
	const [show, realSetShow] = useState(false);
	const timer = useRef(null);
	const bufferedShowState = useRef(null);

	const { events } = useRouter();

	const setShow = useCallback(
		async (val, immediately = false) => {
			if (debug) console.log('setShow() called', val, immediately);
			if (val) {
				bufferedShowState.current = true;
				if (immediately) {
					realSetShow(true);
				} else {
					timer.current = setTimeout(
						() => realSetShow(bufferedShowState.current),
						200
					);
				}
			} else {
				bufferedShowState.current = false;
				clearTimeout(timer.current);
				realSetShow(bufferedShowState.current);
			}
		},
		[bufferedShowState, events, realSetShow, show, timer]
	);

	useEffect(() => {
		let isSubscribed = true;
		events.on('routeChangeStart', (e) => {
			if (debug) console.log('routeChangeStart', e);
			if (isSubscribed) {
				setShow(true).then();
			}
		});
		events.on('routeChangeComplete', () => {
			if (debug) console.log('routeChangeComplete');
			if (isSubscribed) {
				setShow(false).then();
			}
		});
		events.on('routeChangeError', () => {
			if (debug) console.log('routeChangeError');
			if (isSubscribed) {
				setShow(false).then();
			}
		});
		return () => (isSubscribed = false);
	}, [events, setShow]);

	const contextValue = {
		setShow,
	};

	return (
		<MuiLoadingOverlayContext.Provider value={contextValue}>
			{show && <MuiLoadingOverlayComponent />}
			{children}
		</MuiLoadingOverlayContext.Provider>
	);
};
