import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import Card from '@material-ui/core/Card';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import { MuiCentered } from '../MuiCentered';
import { useTheme } from '@material-ui/core/styles';
import { TrContext } from '@lsoft/translate';
import { createStyles, makeStyles } from '@material-ui/core';
import { MuiAssetImageStyles } from './MuiAssetImage.styles';
import clsx from 'clsx';
import { applyMuiAsset } from './MuiAsset';
import { MuiGlobal } from '../MuiGlobal';

const imageAssetTypes = [
	'PRODUCT_MAIN_PICTURE',
	'MANUFACTURER_LOGO',
	'PRODUCT_PICTURE',
	'LINK_PICTURE',
	'SLIDER_FULL',
	'SLIDER_SLIM',
];

const useStyles = makeStyles((theme) =>
	createStyles({
		...MuiAssetImageStyles(theme),
	})
);

const getAssetContentByLC = (data, lc) => {
	if (typeof data === 'string') {
		return data;
	}
	let ret = _.get(data, lc, null);
	if (ret != null) {
		return ret;
	}
	const givenLanguages = ['ALL', 'EN', 'DE', 'FR'];
	const result = givenLanguages.filter((lc) => _.get(data, lc, null) != null);
	return result.length !== 0 ? data[result[0]] : '';
};

const getAssetUrl = ({ URL_TYPE, URL }, width, height) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const trProvider = useContext(TrContext);

	// console.log('getAssetUrl', URL_TYPE, URL, width, height);

	const url = getAssetContentByLC(
		URL,
		_.get(trProvider, 'activeLanguage', 'DE')
	);
	if (URL_TYPE !== 'CDS') return url;
	const relativeUrl = url.startsWith('/')
		? url
		: `/catalog/product/${url[0]}/${url[1]}/${url}`;

	const cds_base_url = MuiGlobal.get('CDS_BASEURL', null);
	// console.log('cds_base_url', cds_base_url);
	const absoluteUrl = `${cds_base_url}/image${relativeUrl}`;

	const queryParameter = new URLSearchParams();
	if (!_.isNil(width) && !_.isNil(height)) {
		queryParameter.set('WIDTH', width);
		queryParameter.set('HEIGHT', height);
	}
	const queryString = queryParameter.toString();
	return `${absoluteUrl}?${queryString}`;
};

const Error = ({ children }) => {
	return (
		<MuiCentered>
			<div style={{ textAlign: 'center' }}>
				<Typography variant={'caption'}>{children}</Typography>
			</div>
		</MuiCentered>
	);
};

const getSrcDimensions = (padding, width, height, srcWidth, srcHeight) => {
	const realSrcWidth = !_.isNil(srcWidth)
		? srcWidth
		: _.isNumber(width)
		? width
		: null;
	const realSrcHeight = !_.isNil(srcHeight)
		? srcHeight
		: _.isNumber(height)
		? height
		: null;

	return {
		width: !_.isNil(realSrcWidth) ? realSrcWidth - padding * 2 : null,
		height: !_.isNil(realSrcHeight) ? realSrcHeight - padding * 2 : null,
	};
};

const MuiAssetImage = ({
	asset,
	width,
	srcWidth,
	height,
	srcHeight,
	variant,
	noPadding,
	className,
}) => {
	const theme = useTheme();
	const trCtx = useContext(TrContext);
	const locale = _.isNil(trCtx)
		? 'en'
		: _.get(trCtx, 'activeLanguage', 'en').toLowerCase();
	const classes = useStyles({ theme });
	const dispDimensions = {
		width,
		height,
	};

	const padding = noPadding ? 0 : theme.spacing(0.5);
	const srcDimensions = getSrcDimensions(
		padding,
		width,
		height,
		srcWidth,
		srcHeight
	);
	let error = null;
	// No asset
	if (_.isNil(asset)) return null;
	// No image asset
	if (!_.includes(imageAssetTypes, asset.ASSET_TYPE)) return null;

	// apply asset data
	const a = applyMuiAsset(
		asset,
		{ width: srcDimensions.width, height: srcDimensions.height },
		locale
	);
	const url = a.URL;

	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useMemo(() => {
		const imgProps = { src: url };
		const wrapperProps = { style: dispDimensions };
		switch (variant) {
			case 'card':
				wrapperProps.square = true;
				wrapperProps.elevation = 4;
				wrapperProps.className = clsx(classes.MuiAssetImageStretch, className);
				return (
					<Card {...wrapperProps}>
						<img {...imgProps} />
					</Card>
				);
			case 'div':
				wrapperProps.className = clsx(classes.MuiAssetImageStretch, className);
				return (
					<div {...wrapperProps}>
						<img {...imgProps} />
					</div>
				);
			default:
				imgProps.width = width;
				imgProps.height = height;
				return <img {...imgProps} className={className} />;
		}
	}, [srcWidth, srcHeight, error, url, variant, className]);
};

MuiAssetImage.propTypes = {
	asset: PropTypes.object,
	height: PropTypes.any,
	width: PropTypes.any,
	srcHeight: PropTypes.number,
	srcWidth: PropTypes.number,
	variant: PropTypes.string,
	noPadding: PropTypes.bool,
	className: PropTypes.any,
};
MuiAssetImage.defaultProps = {
	variant: 'img',
	noPadding: false,
};

export { MuiAssetImage };
