import PropTypes from 'prop-types';
import _ from 'lodash';

const MuiFormatDateTime = (props) => {
	if (_.get(props, 'date') === null) return null;
	const date = new Date(props.date);
	const dateString = date.toLocaleDateString('de-DE', {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	});

	return `${dateString}`.replace(',', ' ');
};

MuiFormatDateTime.propTypes = {
	date: PropTypes.string,
};
MuiFormatDateTime.defaultProps = {
	date: null,
};

export { MuiFormatDateTime };
