import React from 'react';
import Typography from '@material-ui/core/Typography';

// eslint-disable-next-line no-unused-vars
const MuiLoremIpsum = (props) => {
	return (
		<Typography variant={'body1'}>
			Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
			eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
			voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
			clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
			amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
			nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
			diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
			Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
			sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
			diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
			erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
			rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
			dolor sit amet. Duis autem vel eum iriure dolor in hendrerit in vulputate
			velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis
			at vero eros et accumsan et iusto odio dignissim qui blandit praesent
			luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem
			ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
			euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
			enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
			lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum
			iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel
			illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto
			odio dignissim qui blandit praesent luptatum zzril delenit augue duis
			dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis
			eleifend option congue nihil imperdiet doming id quod mazim placerat facer
			possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
			sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
			erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
			ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
			Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse
			molestie consequat, vel illum dolore eu feugiat nulla facilisis. At vero
			eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
			gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
			ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
			tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
			voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
			clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
			amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, At accusam
			aliquyam diam diam dolore dolores duo eirmod eos erat, et nonumy sed
			tempor et et invidunt justo labore Stet clita ea et gubergren, kasd magna
			no rebum. sanctus sea sed takimata ut vero voluptua. est Lorem ipsum dolor
			sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
			diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
			erat. Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
			ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
			accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
			sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
			amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
			ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
			accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
			sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
			amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
			ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
			accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
			sea takimata sanctus. Lorem ipsum dolor sit amet, consetetur sadipscing
			elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
			aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
			dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
			est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
			sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
			dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
			justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
			sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
			consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
			labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
			accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
			sea takimata sanctus est Lorem ipsum dolor sit amet. Duis autem vel eum
			iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel
			illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto
			odio dignissim qui blandit praesent luptatum zzril delenit augue duis
			dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer
			adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore
			magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
			exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
			consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit
			esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at
			vero eros et accumsan et iusto odio dignissim qui blandit praesent
			luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam
			liber tempor cum soluta nobis eleifend option congue nihil imperdiet
			doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit
			amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
			tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
			minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
			nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
			in hendrerit in vulputate velit esse molestie consequat, vel illum dolore
			eu feugiat nulla facilisis. At vero eos et accusam et justo duo dolores et
			ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
			ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
			elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
			aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
			dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
			est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
			sadipscing elitr, At accusam aliquyam diam diam dolore dolores duo eirmod
			eos erat, et nonumy sed tempor et et invidunt justo labore Stet clita ea
			et gubergren, kasd magna no rebum. sanctus sea sed takimata ut vero
			voluptua. est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
			consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
			labore et dolore magna aliquyam erat. Consetetur sadipscing elitr, sed
			diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
			erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
			rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
			dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
			sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
			erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
			rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
			dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
			sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
			erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
			rebum. Stet clita kasd gubergren, no sea takimata sanctus. Lorem ipsum
			dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
			invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
			vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
			gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
			ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
			tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
			voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
			clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
			amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
			nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
			diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
			Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
			sit amet. Duis autem vel eum iriure dolor in hendrerit in vulputate velit
			esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at
			vero eros et accumsan et iusto odio dignissim qui blandit praesent
			luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem
			ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
			euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
			enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
			lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum
			iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel
			illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto
			odio dignissim qui blandit praesent luptatum zzril delenit augue duis
			dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis
			eleifend option congue nihil imperdiet doming id quod mazim placerat facer
			possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
			sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
			erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
			ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
			Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse
			molestie consequat, vel illum dolore eu feugiat nulla facilisis. At vero
			eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
			gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
			ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
			tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
			voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
			clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
			amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, At accusam
			aliquyam diam diam dolore dolores duo eirmod eos erat, et nonumy sed
			tempor et et invidunt justo labore Stet clita ea et gubergren, kasd magna
			no rebum. sanctus sea sed takimata ut vero voluptua. est Lorem ipsum dolor
			sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
			diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
			erat.
		</Typography>
	);
};

export { MuiLoremIpsum };
