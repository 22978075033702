import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { TrContext } from './TrProvider';

export const WithTr = ({ children, trms }) => {
	const trProvider = useContext(TrContext);
	trProvider.registerTrms(trms);
	return <Fragment>{children}</Fragment>;
};

WithTr.propTypes = {
	trms: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export const withTr = (trms) => (WrappedComponent) => {
	console.log('call ', trms);

	return class WithTrWrapper extends React.Component {
		render() {
			return (
				<WithTr trms={trms}>
					<WrappedComponent {...this.props} />
				</WithTr>
			);
		}
	};
};
