import _ from 'lodash';

export const MuiPaginationServerSideProps = (Component) => (ctx) => {
	const SKIP = _.get(ctx, 'query.SKIP', null);
	const LIMIT = _.get(ctx, 'query.LIMIT', null);
	const SORT = _.get(ctx, 'query.SORT', null);
	const OPTIONS = _.get(ctx, 'query.OPTIONS', null);
	const paginationViewParams = _.assign(
		{},
		_.isNil(SKIP) ? {} : { SKIP: Number(SKIP) },
		_.isNil(LIMIT) ? {} : { LIMIT: Number(LIMIT) },
		// _.isNil(SORT) ? {} : { SORT: JSON.parse(SORT) },
		_.isNil(OPTIONS) ? {} : { OPTIONS: JSON.parse(OPTIONS) }
	);
	const newProps = _.assign({}, ctx, {
		paginationViewParams: paginationViewParams,
	});
	return Component(newProps);
};
