import PropTypes from 'prop-types';
import React from 'react';

export const MuiScrollArea = ({ height, direction, children }) => {
	if (direction === 'column') {
		return (
			<div
				style={{
					height: height,
					overflowY: 'overlay',
					fallbacks: {
						overflowY: 'auto',
					},
				}}
			>
				{children}
			</div>
		);
	}
};
MuiScrollArea.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	direction: PropTypes.oneOf(['column', 'row']),
};
