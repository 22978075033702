import React, {
	Fragment,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Container, createStyles, Dialog, makeStyles } from '@material-ui/core';
import { MuiAssetGalleryStyles } from './MuiAssetGallery.styles';
import clsx from 'clsx';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import CloseIcon from '@material-ui/icons/Close';
import { NextAssetImage } from '@lsoft/shared/components/ui/NextAssetImage';

const useStyles = makeStyles((theme) =>
	createStyles({
		...MuiAssetGalleryStyles(theme),
	})
);

const FullHeightIconButton = ({ children, className, onClick }) => {
	return (
		<div
			onClick={onClick}
			className={className}
			style={{ height: '100%', cursor: 'pointer' }}
		>
			<Grid
				container
				direction={'row'}
				alignItems={'center'}
				style={{ height: '100%' }}
			>
				<Grid item>{children}</Grid>
			</Grid>
		</div>
	);
};
FullHeightIconButton.propTypes = {
	className: PropTypes.any,
	onClick: PropTypes.func,
};
FullHeightIconButton.defaultProps = {};

const ZoomButton = () => {
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const { selectedAsset: asset } = useContext(Context);
	const onClick = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<Fragment>
			<Button
				className={clsx(classes.MuiAssetGalleryZoomButton)}
				onClick={onClick}
			>
				<ZoomInIcon />
			</Button>
			<Dialog fullScreen open={open} onClose={handleClose}>
				<div
					className={clsx(classes.MuiAssetGalleryImage)}
					onClick={handleClose}
				>
					<Container maxWidth="lg" style={{ height: '100%' }}>
						<Grid
							container
							direction={'row'}
							justifyContent={'center'}
							alignItems={'center'}
							style={{ height: '100%' }}
						>
							<Grid item xs={12}>
								<NextAssetImage asset={asset} lg />
							</Grid>
						</Grid>

						<Button
							className={classes.MuiAssetGalleryZoomCloseButton}
							onClick={handleClose}
						>
							<CloseIcon />
						</Button>
					</Container>
				</div>
			</Dialog>
		</Fragment>
	);
};

const Context = React.createContext(null);
Context.displayName = 'MuiAssetGallery.Context';

const Buttons = ({ onLeft, onRight }) => {
	const classes = useStyles();
	return (
		<Grid
			container
			direction={'row'}
			justifyContent={'space-between'}
			alignItems={'stretch'}
			className={clsx(classes.MuiAssetGalleryImageButtons)}
		>
			<Grid item>
				<FullHeightIconButton
					className={clsx(classes.FullHeightIconButton)}
					onClick={onLeft}
				>
					<ChevronLeft fontSize={'large'} />
				</FullHeightIconButton>
			</Grid>
			<Grid item>
				<FullHeightIconButton
					className={clsx(classes.FullHeightIconButton)}
					onClick={onRight}
				>
					<ChevronRight fontSize={'large'} />
				</FullHeightIconButton>
			</Grid>
		</Grid>
	);
};
Buttons.propTypes = {
	onLeft: PropTypes.func,
	onRight: PropTypes.func,
};

const Image = ({ magnify, xs, sm, md, lg }) => {
	const classes = useStyles();
	const {
		selectedAsset: asset,
		nextAsset,
		previousAsset,
		numAssets,
	} = useContext(Context);
	return (
		<div className={clsx(classes.MuiAssetGalleryImage)}>
			<NextAssetImage
				asset={asset}
				xs={xs}
				sm={sm}
				md={md}
				lg={lg}
				loading={'eager'}
				priority
			/>
			{numAssets > 1 && <Buttons onLeft={previousAsset} onRight={nextAsset} />}
			{magnify && <ZoomButton />}
		</div>
	);
};
Image.propTypes = {
	magnify: PropTypes.bool,
	xs: PropTypes.bool,
	sm: PropTypes.bool,
	md: PropTypes.bool,
	lg: PropTypes.bool,
};
Image.defaultProps = {
	magnify: false,
	xs: false,
	sm: false,
	md: true,
	lg: false,
};

const Selector = ({ xs, sm, md, lg }) => {
	const classes = useStyles();
	const selectedItemRef = useRef(null);
	const { selectAsset, selectedAssetIndex, assets } = useContext(Context);
	React.useEffect(() => {
		if (!_.isNil(selectedItemRef)) {
			selectedItemRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
		}
	}, [selectedItemRef, selectedAssetIndex]);
	return (
		<div className={clsx(classes.MuiAssetGallerySelector)}>
			<div className={clsx(classes.MuiAssetGallerySelectorContainer)}>
				{assets.map((asset, idx) => {
					const selected = idx === selectedAssetIndex;
					return (
						<div
							key={idx}
							className={clsx(
								classes.MuiAssetGallerySelectorItem,
								selected ? classes.MuiAssetGallerySelectorItemSelected : null
							)}
							ref={selected ? selectedItemRef : null}
							onClick={() => selectAsset(idx)}
						>
							<NextAssetImage asset={asset} xs={xs} sm={sm} md={md} lg={lg} />
						</div>
					);
				})}
			</div>
		</div>
	);
};
Selector.propTypes = {
	xs: PropTypes.bool,
	sm: PropTypes.bool,
	md: PropTypes.bool,
	lg: PropTypes.bool,
};
Selector.defaultProps = {
	xs: true,
	sm: false,
	md: false,
	lg: false,
};

export const MuiAssetGallery = ({ children, assets }) => {
	const numAssets = assets.length;
	const [selectedAssetIndex, setSelectedAssetIndex] = useState(
		numAssets === 0 ? null : 0
	);
	const nextAsset = useCallback(() => {
		setSelectedAssetIndex((selectedAssetIndex + 1) % numAssets);
	}, [selectedAssetIndex, numAssets]);

	const previousAsset = useCallback(() => {
		setSelectedAssetIndex(
			selectedAssetIndex === 0 ? numAssets - 1 : selectedAssetIndex - 1
		);
	}, [selectedAssetIndex, numAssets]);

	const selectAsset = useCallback((idx) => {
		setSelectedAssetIndex(idx);
	}, []);

	const value = useMemo(() => {
		return {
			assets,
			numAssets,
			selectedAssetIndex,
			selectedAsset: _.isNil(selectedAssetIndex)
				? null
				: assets[selectedAssetIndex],
			nextAsset,
			previousAsset,
			selectAsset,
		};
	}, [
		assets,
		nextAsset,
		numAssets,
		previousAsset,
		selectAsset,
		selectedAssetIndex,
	]);
	return <Context.Provider value={value}>{children}</Context.Provider>;
};
MuiAssetGallery.propTypes = {
	assets: PropTypes.arrayOf(PropTypes.object).isRequired,
};
MuiAssetGallery.defaultProps = {};
MuiAssetGallery.Image = Image;
MuiAssetGallery.Selector = Selector;
