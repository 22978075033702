import PropTypes from 'prop-types';
import _ from 'lodash';

const MuiFormatSinceDate = (props) => {
	if (_.get(props, 'date') === null) return null;
	const date = new Date(props.date);
	date.setHours(0);
	date.setMinutes(0);
	date.setSeconds(0);
	const now = new Date();
	const since = Math.abs(now - date);
	const result = Math.ceil(since / (1000 * 60 * 60 * 24)) - 1;
	console.log(result);
	return result;
};

MuiFormatSinceDate.propTypes = {
	date: PropTypes.string,
};
MuiFormatSinceDate.defaultProps = {
	date: null,
};

export { MuiFormatSinceDate };
