import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import {
	Collapse,
	Divider,
	ListItemText,
	makeStyles,
	MenuItem,
	MenuList,
	Typography,
} from '@material-ui/core';
import { Tr } from '@lsoft/translate';
import { MuiLink } from '@lsoft/mui';
import _ from 'lodash';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HeaderContext } from '../Header/Header';
import { trCurrentSales } from './TagMenuHorizontal.tr';
import {
	PromotionContainer,
	PromotionContext,
} from '@lsoft/shared/components/Promotion/container/Promotion';
import {
	PromotionsContainer,
	PromotionsContext,
	PromotionsDataIterator,
} from '@lsoft/shared/components/Promotion/container/Promotions';

const useStyles = makeStyles((theme) => ({
	ShopMenu: {
		'& ul': {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
			marginBottom: theme.spacing(1),
		},
		'& a': {
			color: 'inherit',
		},
		'& li': {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			cursor: 'pointer',
			lineHeight: 1.2,
		},
		'& li:hover': {
			color: theme.palette.primary[500],
		},
		'& h2': {
			fontWeight: 700,
			marginBottom: theme.spacing(1),
		},
	},
}));

const TagMenuVerticalPromotion = ({ setOpen }) => {
	const { data } = useContext(PromotionContext);
	const { setSelectedIconPortal } = useContext(HeaderContext);
	const link = `/promotion?name=${data.name}`;
	return (
		<MuiLink
			fullWidth
			href={link}
			linkprops={{
				color: 'textPrimary',
				onClick: () => {
					setOpen(false);
					setSelectedIconPortal(null);
				},
			}}
			scrollToTop
		>
			<MenuItem>
				<Typography noWrap>
					<Tr tr={data.name_text} html />
				</Typography>
			</MenuItem>
		</MuiLink>
	);
};

export const TagMenuVerticalPromotions = ({ theme }) => {
	const [open, setOpen] = React.useState(false);

	const { data } = useContext(PromotionsContext);

	if (_.isEmpty(data)) return null;

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<Fragment>
			<MenuItem onClick={handleClick} button>
				<ListItemText primary={<Tr tr={trCurrentSales} html />} />
				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</MenuItem>
			<Collapse in={open} unmountOnExit>
				<Divider />
				<MenuList component={'div'}>
					<PromotionsDataIterator>
						<PromotionContainer>
							<TagMenuVerticalPromotion setOpen={setOpen} />
						</PromotionContainer>
					</PromotionsDataIterator>
				</MenuList>
			</Collapse>
			<Divider />
		</Fragment>
	);
};

export const TagMenuVertical = ({ data, theme }) => {
	const { setSelectedIconPortal } = useContext(HeaderContext);

	const { title, items } = data;

	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<Fragment>
			<MenuItem onClick={handleClick} button>
				<ListItemText primary={<Tr tr={title} html />} />
				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</MenuItem>
			<Collapse in={open} unmountOnExit>
				<Divider />
				<MenuList component={'div'}>
					{items
						.filter((item) => _.get(item, 'grouptags', []).length !== 0)
						.map((item, idx) => {
							const tags = item.grouptags.join(',');
							const link = `/group?grouptags=${tags}`;
							return (
								<MuiLink
									fullWidth
									href={link}
									key={idx}
									linkprops={{
										color: 'textPrimary',
										onClick: () => {
											setOpen(false);
											setSelectedIconPortal(null);
										},
									}}
									scrollToTop
								>
									<MenuItem>
										<Typography noWrap>
											<Tr tr={item.title} html />
										</Typography>
									</MenuItem>
								</MuiLink>
							);
						})}
				</MenuList>
			</Collapse>
		</Fragment>
	);
};
TagMenuVertical.propTypes = {
	data: PropTypes.object,
	variant: PropTypes.string,
};

TagMenuVertical.defaultProps = {
	data: {
		title: {},
		items: [],
	},
	variant: 'sidebar',
};
