import React from 'react';
// noinspection ES6UnusedImports
import PropTypes from 'prop-types'; // eslint-disable-line no-unused-vars
// noinspection ES6UnusedImports
import clsx from 'clsx'; // eslint-disable-line no-unused-vars
import MuiInfoAreaButtons from './MuiInfoAreaButtons';
import MuiInfoAreaDrawer from './MuiInfoAreaDrawer';
import { MuiInfoAreaContext } from './MuiInfoAreaContext';

class MuiInfoArea extends React.Component {
	constructor(props) {
		super(props);
		this.items = {};
		this.state = {
			drawerOpen: false,
			selectedId: null,
		};
	}

	clickItem = (id) => {
		this.setState({ drawerOpen: true, selectedId: id });
	};

	closeDrawer = () => {
		this.setState({ drawerOpen: false, selectedId: null });
	};

	getContextData = () => {
		return Object.assign({}, this.state, {
			items: this.items,
			clickItem: this.clickItem,
			closeDrawer: this.closeDrawer,
		});
	};

	render() {
		const children = React.Children.toArray(this.props.children);

		this.items = children.reduce((dict, child) => {
			dict[child.props.id] = child;
			return dict;
		}, {});

		return (
			<MuiInfoAreaContext.Provider value={this.getContextData()}>
				<MuiInfoAreaButtons />
				<MuiInfoAreaDrawer />
			</MuiInfoAreaContext.Provider>
		);
	}
}

export default MuiInfoArea;
