import React from 'react';
import PropTypes from 'prop-types';
import { Tr } from '@lsoft/translate';
import { MuiLink } from '@lsoft/mui';
import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { FooterMenuText } from '../../constants/FooterMenuEntries';

export const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
}));

const TextFooter = ({ typographyColor }) => {
	const classes = useStyles();

	return (
		<Grid
			container
			direction={'row'}
			justifyContent={'center'}
			spacing={2}
			className={clsx(classes.root)}
		>
			{Object.values(FooterMenuText.items).map((menu_item, index) => {
				return (
					<Grid item key={index}>
						<MuiLink href={menu_item.link}>
							<Typography variant={'caption'} color={typographyColor}>
								<Tr tr={menu_item.title} />
							</Typography>
						</MuiLink>
					</Grid>
				);
			})}
		</Grid>
	);
};

TextFooter.propTypes = {
	typographyColor: PropTypes.string,
};
TextFooter.defaultProps = {
	typographyColor: 'textSecondary',
};

export { TextFooter };
