import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	button: {
		minWidth: 'auto',
		padding: theme.spacing(1),
		borderStyle: 'solid',
		borderWidth: [[1, 0]],
		borderColor: theme.palette.grey[400],
	},
	buttonLeft: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		borderWidth: [[1, 0, 1, 1]],
	},
	buttonRight: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		borderWidth: [[1, 1, 1, 0]],
	},
	value: {
		background: 'white',
		borderStyle: 'solid',
		borderWidth: [[1, 0]],
		borderColor: theme.palette.grey[400],
		textAlign: 'center',
		userSelect: 'none',
		height: 42,
		width: 50,
		padding: theme.spacing(1),
	},
}));

export const MuiCountInput = ({
	theme,
	value,
	buttonProps,
	minusButtonProps,
	plusButtonProps,
}) => {
	const classes = useStyles(theme);

	return (
		<Grid
			alignItems={'center'}
			container
			direction={'row'}
			justifyContent={'flex-start'}
		>
			<Grid item>
				<Button
					className={clsx(classes.button, classes.buttonLeft)}
					disableElevation
					size={'small'}
					variant={'contained'}
					color={'default'}
					{...buttonProps}
					{...minusButtonProps}
				>
					<RemoveIcon />
				</Button>
			</Grid>
			<Grid item>
				<div className={clsx(classes.value)}>
					<Typography align={'center'}>{value}</Typography>
				</div>
			</Grid>
			<Grid item>
				<Button
					className={clsx(classes.button, classes.buttonRight)}
					disableElevation
					size={'small'}
					variant={'contained'}
					color={'default'}
					{...buttonProps}
					{...plusButtonProps}
				>
					<AddIcon />
				</Button>
			</Grid>
		</Grid>
	);
};

MuiCountInput.propTypes = {
	value: PropTypes.number.isRequired,
	buttonProps: PropTypes.object,
	minusButtonProps: PropTypes.object,
	plusButtonProps: PropTypes.object,
};
