import PropTypes from 'prop-types';

const MuiFormatCurrency = (props) => {
	let value;
	switch (props.value) {
		case 'EUR':
			value = '€';
			break;

		case 'GBP':
			value = '£';
			break;
		case 'USD':
			value = '$';
			break;

		default:
			value = '€';
			break;
	}

	return value;
};

MuiFormatCurrency.propTypes = {
	value: PropTypes.string,
};
MuiFormatCurrency.defaultProps = {
	value: null,
};

export { MuiFormatCurrency };
