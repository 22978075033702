import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { MuiInput } from '../MuiForms/Inputs';
import { MuiFormField } from '../MuiForms';

const CODE_LENGTH = new Array(6).fill(0);

const useStyles = makeStyles((theme) => ({
	root: {
		border: '1px solid grey',
		// display: 'inline-block',
		position: 'relative',
		display: 'flex',
	},
	MuiInput: {
		position: 'absolute',
		border: 'none',
		// fontSize: 32,
		// textAlign: 'center',
		backgroundColor: 'red',
		// backgroundColor: 'transparent',
		outline: 'none',
		width: theme.spacing(5),
		top: 0,
		bottom: 0,
	},
	input: {
		position: 'absolute',
		border: 'none',
		fontSize: 32,
		textAlign: 'center',
		backgroundColor: 'transparent',
		outline: 'none',
		width: theme.spacing(5),
		top: 0,
		bottom: 0,
	},
	display: {
		borderRight: '1px solid grey',
		width: theme.spacing(5),
		height: theme.spacing(7),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 32,
		position: 'relative',
	},
	shadows: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		boxShadow: '0 0 0 4px rgba(58, 151, 212, 0.28)',
	},
}));

export const MuiPinInput = ({
	theme,
	value,
	// buttonProps,
	// minusButtonProps,
	// plusButtonProps,
}) => {
	const classes = useStyles(theme);

	const [stateValue, setStateValue] = useState('');
	const [focused, setFocused] = useState(false);

	const inputRef = useRef();

	const handleClick = () => {
		inputRef.current.focus();
	};
	const handleFocus = () => {
		setFocused(true);
	};
	const handleBlur = () => {
		setFocused(false);
	};
	const handleKeyUp = (e) => {
		if (e.key === 'Backspace') {
			setStateValue(stateValue.slice(0, stateValue.length - 1));
		}
	};
	const handleChange = (e) => {
		const eventValue = e.target.value;
		// if (eventValue.length > CODE_LENGTH.length) return null;
		setStateValue((stateValue + eventValue).slice(0, CODE_LENGTH.length));
	};

	const handleButtonClick = () => {
		setStateValue('');
	};

	const values = stateValue.split('');

	const selectedIndex =
		stateValue.length < CODE_LENGTH.length
			? values.length
			: CODE_LENGTH.length - 1;

	const hideInput = !(values.length < CODE_LENGTH.length);

	return (
		<div className={clsx(classes.root)} onClick={handleClick}>
			<input
				variant={'text'}
				value={''}
				ref={inputRef}
				onChange={handleChange}
				onKeyUp={handleKeyUp}
				onFocus={handleFocus}
				onBlur={handleBlur}
				className={clsx(classes.input)}
				style={{
					left: selectedIndex * 40,
					opacity: hideInput ? 0 : 1,
				}}
			/>
			{CODE_LENGTH.map((v, index) => {
				const selected = values.length === index;
				const filled =
					values.length === CODE_LENGTH.length &&
					index === CODE_LENGTH.length - 1;

				return (
					<div className={clsx(classes.display)} key={index}>
						{values[index]}
						{(selected || filled) && focused && (
							<div className={clsx(classes.shadows)} />
						)}
					</div>
				);
			})}
			<IconButton onClick={handleButtonClick}>
				<CloseIcon />
			</IconButton>
		</div>
	);
};

MuiPinInput.propTypes = {
	value: PropTypes.number.isRequired,
	buttonProps: PropTypes.object,
	minusButtonProps: PropTypes.object,
	plusButtonProps: PropTypes.object,
};
