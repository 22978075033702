export const MuiReducer = (...defs) => {
	const reducer = () => (state, action) => {
		state = defs.reduce((state, def) => {
			if (def.type === action.type) {
				state = def.reducer(state, action);
			}
			return state;
		}, state);
		return state;
	};
	// eslint-disable-next-line no-unused-vars
	const getActions = (dispatch) => {
		const reduce = defs.reduce((ret, def) => {
			ret[def.type] = (...args) => {
				// console.log(def.type, ...args);
				dispatch({ type: def.type, ...def.action(...args) });
			};
			return ret;
		}, {});
		// console.log(reduce);
		return reduce;
	};
	return [reducer, getActions];
};
export const MuiReducerAction = (type, action, reducer) => ({
	type,
	action,
	reducer,
});
