import React, { useContext } from 'react';
import { CartPosition } from '../../container/shop/CartPosition';
import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Tr } from '@lsoft/translate';
import { MuiFormatPrice } from '@lsoft/mui';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import { HeaderContext } from '../Header/Header';
import {
	ShopProductContext,
	ShopProductLink,
} from '../../container/shop/ShopProduct';

const useStyles = makeStyles((theme) => ({
	HeaderCartPositionContainer: {
		padding: theme.spacing(1),
	},
	HeaderCartPosition: {
		cursor: 'default',
	},
	HeaderCartPositionAmount: {
		color: theme.palette.text.primary,
		display: 'inline-block',
		minWidth: theme.spacing(3.5),
	},
	txtEllipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: '100%',
	},
}));

export const CartPositionSmall = ({ theme }) => {
	const classes = useStyles(theme);
	const { setSelectedIconPortal } = useContext(HeaderContext);
	const { AMOUNT, PRICE } = useContext(CartPosition.Context);
	const {
		data: { currency, name_text },
	} = useContext(ShopProductContext);

	return (
		<MenuItem
			className={clsx(classes.HeaderCartPosition)}
			style={{ display: 'block' }}
		>
			<ShopProductLink
				linkprops={{
					onClick: () => setSelectedIconPortal(null),
				}}
				fullWidth
			>
				<Grid container direction={'row'}>
					<Grid item xs={9}>
						<Typography
							variant={'subtitle1'}
							component={'div'}
							className={clsx(classes.txtEllipsis)}
						>
							<Box className={clsx(classes.HeaderCartPositionAmount)}>
								{AMOUNT}x
							</Box>
							&nbsp;
							<Tr tr={name_text} />
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography
							variant={'subtitle1'}
							color={'textPrimary'}
							align={'right'}
						>
							<MuiFormatPrice currency={currency} value={PRICE} />
						</Typography>
					</Grid>
				</Grid>
			</ShopProductLink>
		</MenuItem>
	);
};
