import React from 'react';
import PropTypes from 'prop-types';
import { MuiForm } from './MuiForm';
import { MuiFormField } from './MuiFormField';
import _ from 'lodash';

const MuiFormSingleField = ({
	variant,
	field,
	onChange,
	onChangeIfValid,
	onValidChange,
	value,
	inputProps,
}) => {
	const fields = {
		single: _.assign({}, _.isNil(variant) ? null : { variant: variant }, field),
	};
	const values = { single: value };
	return (
		<MuiForm
			fields={fields}
			values={values}
			onChange={(d) => onChange(d.single)}
			onChangeIfValid={(d) => onChangeIfValid(d.single)}
			onValidChange={onValidChange}
		>
			<MuiFormField {...inputProps} name={'single'} />
		</MuiForm>
	);
};

MuiFormSingleField.propTypes = {
	variant: PropTypes.string,
	field: PropTypes.object,
	onChange: PropTypes.func,
	onChangeIfValid: PropTypes.func,
	onValidChange: PropTypes.func,
	value: PropTypes.any,
	inputProps: PropTypes.object,
};

MuiFormSingleField.defaultProps = {
	onChange: () => {},
	onChangeIfValid: () => {},
	onValidChange: () => {},
};

export { MuiFormSingleField };
