import IconButton from '@material-ui/core/IconButton';
import Image from 'next/image';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from '@lsoft/translate';
import { tr_visit_our_letzshop_profile } from '@lsoft/shared/translations/links';
import { useRouter } from 'next/router';
import Link from 'next/link';

export const HeaderLinkLetzshop = (props) => {
	const { locale } = useRouter();
	const title = useMemo(() => {
		return getTranslation(tr_visit_our_letzshop_profile, locale);
	}, [locale]);

	const letzshop_link_lc = locale !== 'fr' ? 'de' : locale;
	const utm_source = 'www.ltoys.lu';
	const href = `https://letzshop.lu/${letzshop_link_lc}/vendors/ltoys?utm_source=${utm_source}&utm_medium=referral&utm_campaign=stickyButton`;

	return (
		<Link href={href} target={'_blank'} rel={'noreferrer'} title={title}>
			<IconButton
				color={'inherit'}
				style={{
					padding: 0,
				}}
			>
				<Image
					src={'/images/icons/partner_icons/letzshop_sticky_button.png'}
					alt={'Letzshop'}
					width={48}
					height={48}
					layout={'intrinsic'}
					priority
					loading={'eager'}
				/>
			</IconButton>
		</Link>
	);
};

HeaderLinkLetzshop.propTypes = {};
