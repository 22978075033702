/* eslint-disable-line */
import { data } from '../../constants/shipping_method.data';
import _ from 'lodash';
import React from 'react';
import { free_shipping_thresholds } from '../../constants/shipping_additional.data';

/**
 * Returns available shipping methods for specified destination country
 * @param destCountryCode {string}
 * @returns {string[]}
 */
export const getShippingMethods = (destCountryCode) => {
	return _.compact(
		_.concat(
			Object.entries(data).map(([k, v]) => {
				return _.get(v, destCountryCode, null) === null ? null : k;
			}),
			['PICKUP']
		)
	);
};

/**
 * gets package price
 * @param countryData {string} country code
 * @param weight {number} in kg
 * @returns {null/number} either null if not applicable or the price in euro
 */
const getPackagePrice = (countryData, weight) => {
	let price = null;
	countryData.some((d) => {
		if (d[0] >= weight) {
			price = d[1];
			return true;
		}
		return false;
	});
	return price;
};

/**
 * defines the weight , when packages are split into multiple
 * @type {number} in kg
 */
const packageSplit = 14;

/**
 * Calculates the price for a shipping according
 * to shippingmethod, weight and destination country.
 * It splits the shipment into packages if the total weight
 * exceeds the packageSplit size.
 *
 * @param shippingMethod {string} 'STANDARD' or 'PREMIUM',
 * @param weight {number} in kg
 * @param destCountryCode {string} 2-letter iso code of destination country
 * @returns {null|number} null if not applicable otherwise price in euro
 */
export const getShippingCosts = (
	shippingMethod,
	weight,
	destCountryCode,
	goodsValue
) => {
	// Check if shipping method is available for destination country, if not return null
	if (!_.includes(getShippingMethods(destCountryCode), shippingMethod))
		return null;

	// Check if shipping method is PICKUP, if true return 0
	if (shippingMethod === 'PICKUP') return 0;

	// Check if free shipping is available, if true return 0
	if (isFreeShippingAvailable(shippingMethod, destCountryCode, goodsValue))
		return 0;

	// Default shipping cost calculation
	const countryData = _.get(data, [shippingMethod, destCountryCode], null);

	let restWeight = weight;
	let price = 0;

	// eslint-disable-next-line no-constant-condition
	while (true) {
		if (restWeight > packageSplit) {
			price += getPackagePrice(countryData, packageSplit);
			restWeight -= packageSplit;
		} else {
			price += getPackagePrice(countryData, restWeight);
			break;
		}
	}
	return Math.round(price * 100) / 100;
};

export const isFreeShippingAvailable = (
	shippingMethod,
	destCountryCode,
	goodsValue
) => {
	const free_standard_shipping_treshold = _.get(
		free_shipping_thresholds,
		destCountryCode,
		null
	);

	return (
		!_.isNil(free_standard_shipping_treshold) &&
		shippingMethod === 'STANDARD' &&
		free_standard_shipping_treshold <= goodsValue
	);
};

const Context = React.createContext(null);
Context.displayName = 'ShippingMethods.Context';

const Container = ({ children }) => {
	return (
		<Context.Provider
			value={{
				data,
				packageSplit,
				getShippingMethods,
				getShippingCosts,
				isFreeShippingAvailable,
			}}
		>
			{children}
		</Context.Provider>
	);
};

Container.displayName = 'ShippingMethods.Container';

export const ShippingMethods = {
	Container,
	Context,
};
