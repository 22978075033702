import PropTypes from 'prop-types';
import _ from 'lodash';

const MuiFormatDate = (props) => {
	if (_.get(props, 'date') === null) return null;
	const date = new Date(props.date);
	return date.toLocaleDateString('de-DE', {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
	});
};

MuiFormatDate.propTypes = {
	date: PropTypes.string,
};

export { MuiFormatDate };
