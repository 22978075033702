import PropTypes from 'prop-types';
import {
	inputProps,
	makePick,
	pickDefault,
	validateShortcuts,
} from './input_components';
import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import { funcWrap, validateKeyEventCharacters } from '../../../core';

/*
 * Base Input Functions - Number */

const removeUnusedCharacter = (value, decimalSeparator, decimals) => {
	const indexDecimalSeparator = value.indexOf(decimalSeparator);
	if (indexDecimalSeparator >= 0) {
		const decimalLength = indexDecimalSeparator + 1 + decimals;
		return value.slice(0, decimalLength);
	}
	return value;
};

const toDisplayValueFunc = (value, props) => {
	const allow_null = _.get(props, 'allow_null', false);

	if (allow_null && value === '') {
		return value;
	}

	// Prepare value: Replaces comma separator with point separator
	value = value.toString().replace(',', '.');
	value = removeUnusedCharacter(value, props.decimalSeparator, props.decimals);
	// Convert to float and add missing decimals
	value = parseFloat(value).toFixed(props.decimals);
	// Convert back to string, replace decimal separator with prop.decimalSeparator
	value = value.toString().replace('.', props.decimalSeparator);
	return value;
};

const toRealValueFunc = (value, props) => {
	const allow_null = _.get(props, 'allow_null', false);
	if (allow_null && value === '') {
		return null;
	}
	// Convert value to string and replace comma separator with float compatible dot separator
	value = value.toString().replace(',', '.');
	value = removeUnusedCharacter(value, '.', props.decimals);

	return parseFloat(value);
};

// Returns cursor position, based on selection, special keys and more
const getCursorPosition = (selectionStart, selectionEnd, e) => {
	// Prepare cursor position (setCaretPosition)
	let cursorPosition = selectionStart;
	const cursorLeftKeys = ['ArrowLeft'];
	const cursorRightKeys = ['ArrowRight'];
	const cursorHomeKeys = ['Home', 'ArrowUp'];
	const cursorEndKeys = ['End', 'ArrowDown'];
	if (selectionStart !== selectionEnd) {
		// Selection detected
		if (cursorLeftKeys.includes(e.key)) {
			cursorPosition = 0;
		} else if (cursorRightKeys.includes(e.key)) {
			cursorPosition = e.target.value.length;
		}
	} else if (cursorLeftKeys.includes(e.key)) {
		// Cursor the left
		cursorPosition = selectionStart - 1;
	} else if (cursorRightKeys.includes(e.key)) {
		// Cursor to the right
		cursorPosition = selectionStart + 1;
	} else if (cursorHomeKeys.includes(e.key)) {
		// Cursor to start
		cursorPosition = 0;
		// Cursor to end
	} else if (cursorEndKeys.includes(e.key)) {
		cursorPosition = e.target.value.length;
	}
	if (cursorPosition < 0) {
		cursorPosition = 0;
	}

	//Todo --- Sometimes caret  position is set twice (onChange)

	if (cursorPosition >= e.target.value.length) {
		cursorPosition = e.target.value.length;
	}
	return cursorPosition;
};

// Sets selectionRange from input (Helper class to map the longer call)
const setSelectionRange = (ref, start, end) => {
	// Todo Always jumps to the end. Maybe value is not ready yet? To short?
	return ref.current.setSelectionRange(start, end);
	// return ref.current.setSelectionRange(start, end);
};

const handlePaste = async (e, props) => {
	e.preventDefault();
	let temp = e.clipboardData.getData('text');
	if (!_.isNaN(parseFloat(temp))) {
		temp = toRealValueFunc(temp, props);
		await props.onChange(temp);
	}
};
const numOnKeyDown = (
	e,
	props,
	ref,
	caretPosition,
	setCaretPosition,
	onChange,
) => {
	if (!validateShortcuts(e, props)) {
		e.preventDefault();
		return;
	}

	if (!validateKeyEventCharacters(e, props)) {
		e.preventDefault();
		return;
	}
	// Set cursor position to current caret position
	const selectionStart = e.target.selectionStart;
	const selectionEnd = e.target.selectionEnd;
	setCaretPosition(getCursorPosition(selectionStart, selectionEnd, e));
	// Decimal Separators Keycodes - 190 = point - 188 = Comma - 110 = numpad point
	const decimalSeparatorKeyCodes = [190, 188, 110];
	// If: Input in decimal separators
	if (decimalSeparatorKeyCodes.includes(e.keyCode)) {
		e.preventDefault();
		// If: Decimal is set
		if (props.decimals > 0) {
			const oldValue = props.value.toString();

			if (_.indexOf(oldValue, props.decimalSeparator)) {
				const newDecimalSeparatorPosition =
					_.indexOf(oldValue, props.decimalSeparator) + 1;
				setCaretPosition(newDecimalSeparatorPosition);
				setSelectionRange(
					ref,
					newDecimalSeparatorPosition,
					newDecimalSeparatorPosition,
				);
			}
		}
	} else if (e.ctrlKey && e.keyCode === 86) {
		//	CTRL + V (Paste)
		// e.preventDefault();
		return;
	} else if (e.ctrlKey && e.keyCode === 67) {
		//	CTRL + C
		return;
	} else if (e.key === 'Backspace') {
		let valueToDelete = Array.from(e.target.value)[selectionStart - 1];
		if (_.isUndefined(caretPosition)) {
			e.preventDefault();
			setCaretPosition(1);
			setSelectionRange(ref, 1, 1);
		} else if (valueToDelete === props.decimalSeparator) {
			e.preventDefault();
			const newCaretPosition = selectionStart - 1;
			setSelectionRange(ref, newCaretPosition, newCaretPosition);
			setCaretPosition(newCaretPosition);
		} else if (
			selectionStart === 1 &&
			e.target.value.replace(',', '.')[1] === '.'
		) {
			//fix caret when backspace 5|.12-> 0|.12   setFlag on Target. gets Handled in Onchange
			e.target.setFirstCharacterNull = true;
		} else {
			setCaretPosition(selectionStart - 1);
		}
	} else if (e.key === 'Delete') {
		if (e.target.value[selectionStart] === props.decimalSeparator) {
			e.preventDefault();
			e.target.handleDeleteSeparator = true;
			onChange(e);
		} else if (selectionStart === 0 && selectionEnd === 0) {
			//delete when |0.12
			e.target.handleDeleteFirstCharBeforeDecimalSeparator = true;
		}
	} else if (e.keyCode === 109) {
		//	109 === Minus
		// Todo: Implement allowNegative
	} else if (e.keyCode === 13) {
		// 13 === ENTER
		props.onEnter();
	}
};

const numberInputOnKeyUp = (e, props) => {
	if (!validateShortcuts(e, props)) {
		e.preventDefault();
	}

	if (!validateKeyEventCharacters(e, props)) {
		e.preventDefault();
	}
};

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	NumberInputAlignRight: {
		'& input': {
			textAlign: 'right',
		},
	},
}));

const pick = makePick(
	[
		'fullWidth',
		'label',
		'value',
		'prefix',
		'suffix	',
		'onKeyDown',
		'onKeyUp',
		'variant',
		'onFocus',
		'onBlur',
		'decimals',
		'InputProps',
		'size',
		'error',
		'helperText',
		'hideZeroIfNoFocus',
		'currency',
	],
	pickDefault,
);

const Component = (newProps) => {
	const classes = useStyles(newProps);
	const [focus, setFocus] = useState(false);
	const inputRef = useRef();
	const [caretPosition, setCaretPosition] = useState(0);

	// if (newProps.textAlign === 'right') {
	// 	newProps.className = clsx(classes.NumberInputAlignRight);
	// }

	const onChange = funcWrap(newProps.onChange, (onChange) => async (e) => {
		let val = e.target.value;
		let selectionStart = e.target.selectionStart;
		let newCaretPosition = caretPosition + 1;

		const allow_null = _.get(newProps, 'allow_null', false);

		if (val === '' && !allow_null) {
			val = '0';
			selectionStart = 1;
			newCaretPosition = 1;
		}
		//next condition fixes 1|.12 ->Backspace 0|.12  setFirstCharacterNull is set in onKeyDown
		if (_.get(e, 'target.setFirstCharacterNull', false)) {
			e.target.setFirstCharacterNull = false;
			val = 0 + newProps.decimalSeparator + val.slice(1);
			selectionStart = 1;
			newCaretPosition = 1;
		}

		//next condition fixes	delete when 0|.12 ---> 0|.20
		if (_.get(e, 'target.handleDeleteSeparator', false)) {
			e.target.handleDeleteSeparator = false;
			const shiftetStringBehindSeparator = e.target.value.slice(
				selectionStart + 2,
			);
			const stringBeforeSeparator = e.target.value.slice(0, selectionStart);
			val =
				stringBeforeSeparator +
				newProps.decimalSeparator +
				shiftetStringBehindSeparator;
		}

		//next condition fixes	delete when |0.12 ---> |1.20
		if (_.get(e, 'target.handleDeleteFirstCharBeforeDecimalSeparator', false)) {
			e.target.handleDeleteFirstCharBeforeDecimalSeparator = false;
			if (val[0].replace(',', '.') === '.') {
				val = val.slice(1, 2) + newProps.decimalSeparator + val.slice(2);
			}
			if (parseFloat(val) === 0) {
				selectionStart = 1;
				newCaretPosition = 1;
			}
		}

		//next condition Fixes "0|.00"  problem. to deny 1.23 instead of 123.00
		if (
			newProps.value.toString().replace(',', '.').startsWith('0.') &&
			caretPosition === 1
		) {
			newCaretPosition = 1;
			selectionStart = 1;
		}

		val = toRealValueFunc(val, newProps);

		await onChange(val);
		setSelectionRange(inputRef, selectionStart, selectionStart);
		setCaretPosition(newCaretPosition);
	});

	const oldOnfocus = newProps.onFocus;
	newProps = _.assign({}, newProps, {
		onFocus: (e) => {
			if (_.get(newProps, 'onFocusSelectAll', true)) {
				e.target.select();
			}
			setFocus(true);
			oldOnfocus();
		},
	});

	const oldOnBlur = newProps.onBlur;
	newProps = _.assign({}, newProps, {
		onBlur: () => {
			setFocus(false);
			oldOnBlur();
		},
	});

	if (newProps.hideZeroIfNoFocus && !focus) {
		const temp = newProps.value.replace(',', '.');
		const new_props_value_is_number_and_0 = !isNaN(temp) && Number(temp) === 0;
		if (newProps.value === '0' || new_props_value_is_number_and_0) {
			newProps = _.assign({}, newProps, { value: '' });
		}
	}
	delete newProps['hideZeroIfNoFocus'];
	return (
		<TextField
			{...pick(newProps)}
			className={clsx(newProps.className, {
				[classes.NumberInputAlignRight]: newProps.textAlign === 'right',
			})}
			inputRef={inputRef}
			onChange={(e) => onChange(e)}
			// onChange={(e) => {
			// 	return newOnChange(e);
			// }}
			onKeyUp={(e) => numberInputOnKeyUp(e, newProps)}
			onPaste={(e) => {
				handlePaste(e, newProps);
			}}
			onKeyDown={(e) =>
				numOnKeyDown(
					e,
					newProps,
					inputRef,
					caretPosition,
					setCaretPosition,
					onChange,
				)
			}
			InputLabelProps={newProps.InputLabelProps}
		/>
	);
};

Component.propTypes = {
	inputRef: PropTypes.any,
	newProps: PropTypes.object,
};

const getNewProps = (newProps) => {
	newProps.onEnter = !_.isNull(_.get(newProps, 'onEnter', null))
		? newProps.onEnter
		: () => {};

	newProps.allowedCharacters = '[0-9.,-]';

	newProps.hideZeroIfNoFocus = _.get(newProps, 'hideZeroIfNoFocus', false);
	newProps.InputProps = inputProps(newProps);
	newProps.type = 'text';
	if (newProps.value === 'NaN') {
		newProps.value = 0;
	}

	newProps.value = toDisplayValueFunc(newProps.value, newProps);
	if (newProps.styleVariant === 'tablecell') {
		newProps.styleVariant = 'filled';
		newProps.size = 'small';
		newProps.className = clsx(
			newProps.className,
			newProps.sharedClasses.MuiInputTableCell,
		);
		newProps.helperText = null;
		newProps.label = null;
	}
	newProps.variant = newProps.styleVariant;
	return newProps;
	//	übergebene props abändern
};

export const ic_number = {
	Component,
	getNewProps,
};
