import React, { useContext } from 'react';
// noinspection ES6UnusedImports
import PropTypes from 'prop-types'; // eslint-disable-line no-unused-vars
// noinspection ES6UnusedImports
import clsx from 'clsx'; // eslint-disable-line no-unused-vars
import { MuiInfoAreaContext } from './MuiInfoAreaContext';
import Drawer from '@material-ui/core/Drawer';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
	createStyles({
		drawerPaper: {
			width: theme.sizes.drawerWidth,
		},
		drawerCloseButton: {
			position: 'absolute',
			top: theme.spacing(8.5),
			right: theme.spacing(0.5),
		},
		drawerAction: {
			borderLeft: theme.palette.divider,
			flexShrink: 1,
			width: theme.sizes.drawerWidth,
		},
		itemCard: {
			padding: theme.spacing(2),
		},
		itemCardHeader: theme.mixins.toolbar,
	})
);

const MuiInfoAreaDrawer = (props) => {
	const classes = useStyles(props);
	const muiInfoArea = useContext(MuiInfoAreaContext);

	const open = _.get(muiInfoArea, 'drawerOpen', false);
	if (!open) return null;

	const drawerItems = Object.entries(muiInfoArea.items).map(([id, item]) => {
		return (
			<Box
				hidden={muiInfoArea.selectedId !== id}
				key={id}
				className={clsx(classes.itemCard)}
			>
				<Box className={clsx(classes.itemCardHeader)}>
					<Typography component={'h1'} variant={'h6'} color={'inherit'}>
						{item.props.title}
					</Typography>
				</Box>
				{item.props.children}
			</Box>
		);
	});
	return (
		<Drawer
			anchor={'right'}
			classes={{
				paper: classes.drawerPaper,
			}}
			className={clsx(classes.drawerAction)}
			variant={'permanent'}
		>
			<div className={clsx(classes.itemCardHeader)} />
			<IconButton
				aria-label="delete"
				className={classes.drawerCloseButton}
				size="small"
				onClick={() => {
					muiInfoArea.closeDrawer();
				}}
			>
				<CloseIcon fontSize="inherit" />
			</IconButton>
			{drawerItems}
		</Drawer>
	);
};

export default MuiInfoAreaDrawer;
