import * as assetTypes from './assetTypes';
import { getTranslation } from '@lsoft/translate';
import _ from 'lodash';

export const translateMuiAsset = (asset, locale) => {
	const lc = locale.toLowerCase();
	const r = _.cloneDeep(asset);
	if (_.isObject(r.URL)) r.URL = getTranslation(r.URL, lc);
	if (_.isObject(r.CAPTION)) r.CAPTION = getTranslation(r.CAPTION, lc);
	return r;
};

export const applyMuiAsset = (asset, options, locale = 'en') => {
	const translatedAsset = translateMuiAsset(asset, locale);
	const assetType = _.get(assetTypes, translatedAsset.URL_TYPE, null);
	return _.isNil(assetType)
		? translatedAsset
		: assetType.applyMuiAsset(translatedAsset, options);
};
