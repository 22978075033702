// eslint-disable-next-line no-unused-vars
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AmContext } from './AmContext';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useUniqueId } from '@lsoft/mui';

const Restricted = ({
	children,
	scopes,
	renderOnRestriction,
	noLogin,
	renderRestricted,
}) => {
	const id = useUniqueId('Restricted');

	const {
		changeLoginSubscription,
		scopesMatching,
		changeRestrictedSubscription,
		unfulfilledScopes,
		loggedIn,
		renderOnRestriction: ctxRenderOnRestriction,
	} = useContext(AmContext);

	const scopesMatched = scopesMatching(scopes);

	const callRenderOnRestriction = useCallback(
		(scopes) => {
			const realCall = isNil(renderOnRestriction)
				? ctxRenderOnRestriction
				: renderOnRestriction;
			return realCall(scopes);
		},
		[renderOnRestriction, scopes, ctxRenderOnRestriction]
	);

	useEffect(() => {
		if (loggedIn && renderRestricted) {
			if (!scopesMatched) {
				changeRestrictedSubscription(id, true, unfulfilledScopes(scopes));
				return () => changeRestrictedSubscription(id, false);
			}
		}
	}, [
		id,
		loggedIn,
		changeRestrictedSubscription,
		scopesMatched,
		renderRestricted,
	]);

	useEffect(() => {
		if (!noLogin) {
			changeLoginSubscription(id, true);
			return () => changeLoginSubscription(id, false);
		}
	}, [id, loggedIn, changeLoginSubscription]);

	if (scopes.length === 0 && noLogin) {
		return children;
	}

	if (scopesMatched && loggedIn) {
		if (isNil(children)) return null;
		return children;
	}

	if (!isNil(renderOnRestriction)) {
		return callRenderOnRestriction(scopes);
	}
	return null;
};

Restricted.propTypes = {
	scopes: PropTypes.array,
	renderOnRestriction: PropTypes.func,
	noLogin: PropTypes.bool,
	renderRestricted: PropTypes.bool,
};

Restricted.defaultProps = {
	scopes: [],
	renderOnRestriction: null,
	noLogin: false,
	renderRestricted: false,
};

export { Restricted };
