import { MuiGlobalComponent } from '../MuiGlobal';
import React from 'react';
import PropTypes from 'prop-types';
import { Tr } from '@lsoft/translate';
import Grid from '@material-ui/core/Grid';

const MuiDefaultRestrictedError = ({ scopes }) => {
	return (
		<Grid container>
			<Grid item>
				<h2>
					<Tr
						tr={{
							EN: 'Access denied.',
							DE: 'Zugriff verweigert.',
						}}
					/>
				</h2>
				<Tr tr={{ EN: 'Missing permissions', DE: 'Fehlende Berechtigung' }} />
				<ul>
					{scopes.map((i) => (
						<li key={i}>{i}</li>
					))}
				</ul>
			</Grid>
		</Grid>
	);
};
MuiDefaultRestrictedError.propTypes = {
	scopes: PropTypes.array,
};

MuiDefaultRestrictedError.defaultProps = {
	scopes: [],
};

export const MuiRestrictedError = MuiGlobalComponent(
	'_restrictederror',
	MuiDefaultRestrictedError
);
