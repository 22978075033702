export const trNew = { DE: 'Neu', EN: 'New', FR: 'Nouveau' };
export const trRare = { DE: 'Selten', EN: 'Rare', FR: 'Rare' };
export const trSearchApiNotReachable = {
	DE: 'Der Suchserver ist gerade nicht erreichbar.',
	EN: 'The search server is currently not reachable',
	// prettier-ignore
	FR: "Le serveur de recherche n\'est actuellement pas joignable,",
};
export const trYourSearchFor = {
	DE: 'Ihre Suche nach "${q}"',
	EN: 'Your search for "${q}"',
	FR: 'Votre recherche pour "${q}" ',
};
export const trSearch = {
	DE: 'Suche',
	EN: 'Search',
	FR: 'Recherche',
};
