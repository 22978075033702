// ES5 file only
function flatLsConfigServer(config) {
	const { hybrid, server } = config;
	return { ...hybrid, ...server };
}

function flatLsConfigClient(config) {
	const { hybrid, client } = config;
	return { ...hybrid, ...client };
}

module.exports = {
	flatLsConfigServer,
	flatLsConfigClient,
};
