import React, { useContext, useEffect, useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { MuiSearchInput } from './MuiSearchInput';
import { MuiSearchAutocomplete } from './MuiSearchAutocomplete';
import PropTypes from 'prop-types';
import { MuiSearchBarContext } from './MuiSearchBarContainer';
import _ from 'lodash';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	MuiSearchBar: {
		position: 'relative',
	},
	MuiSearchInputWrap: {
		background: 'white',
		padding: [
			[theme.spacing(1), theme.spacing(2), theme.spacing(1), theme.spacing(2)],
		],
		marginRight: -Math.abs(theme.spacing(1)),
		position: 'fixed',
		right: theme.spacing(1),
		left: 0,
		zIndex: 10,

		[theme.breakpoints.up('md')]: {
			padding: 0,
			position: 'inherit',
			right: 'auto',
			left: 'auto',
		},
	},
	MuiSearchAutocompleteWrap: {
		paddingTop: theme.spacing(8),
		maxWidth: '100%',

		[theme.breakpoints.up('md')]: {
			position: 'relative',
			width: 600,
			padding: 0,
		},
		[theme.breakpoints.up('lg')]: {
			width: 800,
		},
	},
}));

const MuiSearchBar = ({ noAutocomplete, AutocompleteResultComponent }) => {
	const classes = useStyles();

	const { autocompleteResults, setAutocompleteResults } =
		useContext(MuiSearchBarContext);

	const searchRef = useRef();

	const handleClickOutside = (e) => {
		if (!_.isNil(searchRef.current)) {
			if (searchRef.current.contains(e.target)) {
				return;
			}
			setAutocompleteResults(null);
		}
	};

	useEffect(() => {
		if (autocompleteResults !== null) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [autocompleteResults]);

	return (
		<Box ref={searchRef} className={clsx(classes.MuiSearchBar)}>
			<Box className={clsx(classes.MuiSearchInputWrap)}>
				<MuiSearchInput />
			</Box>
			<Box className={clsx(classes.MuiSearchAutocompleteWrap)}>
				<MuiSearchAutocomplete ResultComponent={AutocompleteResultComponent} />
			</Box>
		</Box>
	);
};

MuiSearchBar.propTypes = {
	noAutocomplete: PropTypes.bool,
	AutocompleteResultComponent: PropTypes.any,
};

MuiSearchBar.defaultProps = {
	noAutocomplete: false,
};

export { MuiSearchBar };
