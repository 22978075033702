import React, { useContext } from 'react';
import { TrContext } from './TrProvider';

const TrSelect = () => {
	const trProvider = useContext(TrContext);
	const options = trProvider.availableLanguages.map((LC) => {
		return (
			<option key={LC} value={LC}>
				{LC}
			</option>
		);
	});
	return (
		<select
			defaultValue={trProvider.activeLanguage}
			onChange={(e) => trProvider.setActiveLanguage(e.target.value)}
		>
			{options}
		</select>
	);
};

export { TrSelect };
