import { MuiWebapi } from '@lsoft/mui';
import _ from 'lodash';

const debug_log = true;

export const erp_promotions_put = async ({
	filter_class,
	ids_merge_mode,
	limit,
	options,
	reset,
	skip,
	sort,
	view,
	active,
	future,
}) => {
	const res = await MuiWebapi.get().lsfastapi.erp.paths['/promotions'].put(
		{
			filter_class,
			ids_merge_mode,
			limit,
			options,
			reset,
			skip,
			sort,
			view,
		},
		{
			active,
			future,
		},
	);
	return _.get(res, ['data', 'data'], null);
};
