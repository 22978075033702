import { trSubscribeToOurNewsletter, trTermsOfUse } from './fields.tr';

export const terms_of_use = {
	inputComponent: 'switch',
	inputProps: {
		label: trTermsOfUse,
	},
};

export const newsletter_subscription = {
	inputComponent: 'switch',
	inputProps: {
		label: trSubscribeToOurNewsletter,
	},
	optional: true,
};
