// noinspection ES6CheckImport
import { MuiWebapi } from '@lsoft/mui';
import _ from 'lodash';

const debug = false;

const get_cookie_header = (cookies) => {
	if (debug) console.log('shop.js get_cookie_header', cookies);
	if (_.isNil(cookies)) return null;

	const cookieStr = Object.entries(cookies)
		// eslint-disable-next-line no-unused-vars
		.filter(([k, v]) => !_.isNil(v))
		.map(([k, v]) => `${k}=${v} `)
		.join(';');
	return { Cookie: cookieStr };
};

// works lol
export const extract_session_from_dict = async (data) => {
	if (debug) console.log('shop.js extract_session_from_dict');

	const sessions = Object.values(_.get(data, 'SESSIONS', {})).map(
		(session) => session
	);
	return _.get(sessions, '[0]', null);
};

// works
export const shop_customer_register = async ({ app_name, form_data }) => {
	const webapi = MuiWebapi.get();

	if (debug) console.log('shop.js shop_customer_register');

	const res = await webapi.lsfastapi.shop.paths[
		'/customer/register/app/{app_name}'
	].post({ app_name }, form_data, {
		headers: get_cookie_header(webapi.cookies),
	});
	return {
		session: _.get(res, 'data.session', null),
		am_auth_token: _.get(res, 'data.access_token', null),
	};
};

// works
export const shop_customer_contact_verify = async ({
	app_name,
	account_verification_code,
	email,
}) => {
	const webapi = MuiWebapi.get();

	if (debug) console.log('shop.js shop_customer_contact_verify');

	const res = await webapi.lsfastapi.shop.paths['/customer/contact/verify'].get(
		{
			email,
			account_verification_code,
			app_name,
		},
		{},
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);

	return {
		session: _.get(res, 'data.session'),
		am_auth_token: _.get(res, 'data.access_token', null),
	};
};

export const shop_customer_contact_request_verification_signup = async ({
	email,
	password,
}) => {
	const webapi = MuiWebapi.get();

	if (debug)
		console.log('shop.js shop_customer_contact_request_verification_signup');

	const res = await webapi.lsfastapi.shop.paths[
		'/customer/contact/request_verification'
	].post(
		{
			email,
		},
		{
			PASSWORD: password,
		},
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);
	return _.get(res, 'data');
};

// works
export const shop_customer_contact_password_request = async ({ email }) => {
	const webapi = MuiWebapi.get();

	if (debug) console.log('shop.js shop_customer_contact_password_request');

	const res = await webapi.lsfastapi.shop.paths[
		'/customer/contact/password_reset_request'
	].get(
		{
			email,
		},
		{},
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);
	return _.get(res, 'data');
};

// works
export const shop_customer_contact_request_verification_resend = async ({
	email,
	password,
}) => {
	const webapi = MuiWebapi.get();

	if (debug)
		console.log('shop.js shop_customer_contact_request_verification_resend');

	const res = await webapi.lsfastapi.shop.paths[
		'/customer/contact/request_verification'
	].post(
		{ email },
		{ PASSWORD: password },
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);

	if (debug) console.log('shop.js res', res);

	return res;
};

export const shop_customer_contact_exists = async ({ email }) => {
	const webapi = MuiWebapi.get();

	if (debug) console.log('shop.js shop_customer_contact_exists');

	const res = await webapi.lsfastapi.shop.paths['/customer/contact/exists'].get(
		{ email },
		{},
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);
	return {
		exists: _.get(res, 'data.EXISTS', false),
		account_verified: _.get(res, 'data.ACCOUNT_VERIFIED', false),
	};
};
export const shop_customer_contact_password_reset_put = async ({
	password,
	token,
}) => {
	const webapi = MuiWebapi.get();

	if (debug) console.log('shop.js shop_customer_contact_password_reset_put');
	// noinspection JSUnresolvedFunction
	const res = await webapi.lsfastapi.shop.paths[
		'/customer/contact/password_reset'
	].put(
		{},
		{
			TOKEN: token,
			PASSWORD: password,
		},
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);

	return _.get(res, 'data');
};

export const shop_session_app_get = async ({ app_name }) => {
	const webapi = MuiWebapi.get();
	if (debug) console.log('shop.js shop_session_app_get');
	// noinspection JSUnresolvedFunction
	const res = await webapi.lsfastapi.shop.paths[
		'/shop/session/app/{app_name}'
	].get(
		{ app_name },
		{},
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);
	return _.get(res, 'data');
};

export const shop_session_archive_get = async ({ session_id }) => {
	const webapi = MuiWebapi.get();

	if (debug) console.log('shop.js shop_session_archive_get');
	// noinspection JSUnresolvedFunction

	const res = await webapi.lsfastapi.shop.paths[
		'/shop/session/{session_id}/archive'
	].get(
		{ session_id },
		{},
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);

	return _.get(res, 'data');
};

// works
export const shop_session_login_post = async ({
	app_name,
	password,
	username,
}) => {
	const webapi = MuiWebapi.get();

	if (debug) console.log('shop.js shop_session_login_post');

	// noinspection JSUnresolvedFunction,UnnecessaryLocalVariableJS
	const res = await webapi.lsfastapi.shop.paths[
		'/shop/session/app/{app_name}/login'
	].post(
		{
			app_name,
		},
		{
			username,
			password,
		},
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);

	return _.get(res, 'data');
};

// works
export const shop_session_logout_get = async ({ app_name }) => {
	const webapi = MuiWebapi.get();

	if (debug) console.log('shop.js shop_session_logout_get');

	// noinspection JSUnresolvedFunction,UnnecessaryLocalVariableJS
	const res = await webapi.lsfastapi.shop.paths[
		'/shop/session/app/{app_name}/logout'
	].get(
		{
			app_name,
		},
		{},
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);

	return _.get(res, 'data');
};

// works
export const shop_session_put = async ({ session_id, session }) => {
	const webapi = MuiWebapi.get();

	if (debug) console.log('shop.js shop_session_put');

	const res = await webapi.lsfastapi.shop.paths[
		'/shop/session/{session_id}'
	].put(
		{
			session_id,
		},
		session,
		{
			headers: get_cookie_header(webapi.cookies),
		}
	);
	return {
		session: _.get(res, 'data'),
	};
};
