import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';

class MuiInfoAreaItem extends React.Component {
	static defaultProps = {};

	static propTypes = {
		children: PropTypes.any,
		icon: PropTypes.node.isRequired,
		id: PropTypes.string,
		muiInfoArea: PropTypes.object.isRequired,
		title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
	};

	constructor(props, context) {
		super(props, context);
	}

	render() {
		return (
			<div>
				<h1>{this.props.title}</h1>
				{this.props.children}
			</div>
		);
	}
}

export default MuiInfoAreaItem;
