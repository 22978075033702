import React, { useContext } from 'react';
import { MuiPaginationContext } from './MuiPaginationContext';
import _ from 'lodash';
import { MuiFormSingleField } from '../MuiForms/MuiFormSingleField';
import Grid from '@material-ui/core/Grid';
import { Tr } from '@lsoft/translate';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	MuiPaginationItemsPerPageSelector: {
		'& .MuiOutlinedInput-inputMarginDense': {
			paddingTop: theme.spacing(0.75),
			paddingBottom: theme.spacing(0.75),
		},
		fontSize: '14px',
	},
}));

// eslint-disable-next-line no-unused-vars
const MuiPaginationItemsPerPageSelector = ({ theme }) => {
	const {
		initialized,
		itemsPerPageOptions,
		itemsPerPage,
		loadCurrentPageWithNewItemsPerPage,
	} = useContext(MuiPaginationContext);
	const classes = useStyles({ theme });
	if (_.isNil(initialized) || !initialized) {
		return null;
	}
	let itemsOptions = itemsPerPageOptions;
	if (_.indexOf(itemsOptions, itemsPerPage) < 0) {
		itemsOptions = _.concat(itemsPerPageOptions, [itemsPerPage]);
	}
	return (
		<Grid container alignItems={'center'} spacing={1}>
			<Grid item>
				<Typography variant={'body2'} component={'span'} color={'inherit'}>
					<Tr
						tr={{
							DE: 'Einträge pro Seite',
							EN: 'Entries per page',
						}}
					/>
				</Typography>
			</Grid>
			<Grid item>
				<MuiFormSingleField
					key={itemsPerPage}
					field={{
						inputProps: {
							size: 'small',
							options: itemsOptions.map((entry) => {
								return {
									label: entry,
									value: entry,
								};
							}),
							className: clsx(classes.MuiPaginationItemsPerPageSelector),
						},
					}}
					variant={'items_per_page'}
					value={itemsPerPage}
					onChange={(val) => {
						if (val !== itemsPerPage) {
							loadCurrentPageWithNewItemsPerPage(val);
						}
					}}
					onChangeIfValid={() => {}}
				/>
			</Grid>
		</Grid>
	);
};

export { MuiPaginationItemsPerPageSelector };
