/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, useState } from 'react';
import { makePick, pickDefault } from './input_components';
import { funcWrap } from '../../../core';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MuiInput } from '../MuiInput';
import { MuiFormFieldOptions } from '../../MuiFormFieldOptions';

const pick = makePick(
	['fullWidth', 'classes', 'label', 'noLabel'],
	pickDefault
);

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	ListSelect: {
		height: 200,
	},
	Prefix: {
		width: 30,
		fontSize: 10,
		lineHeight: 2.1,
	},
}));

const trSearch = {
	DE: 'Suche',
	EN: 'Search',
};

export const compareSearchAndOption = (
	options_string,
	search_string,
	same_length = false
) => {
	const pattern = /[^a-zA-Z0-9]/g;
	options_string = _.replace(options_string, pattern, '').toLowerCase();
	const search = _.replace(search_string.toLowerCase(), pattern, '');

	if (same_length) {
		return options_string === search;
	}
	return options_string.search(search) !== -1;
};

const filterOptions = (
	options,
	search_string,
	selected_option_value,
	limit = 12
) => {
	const filtered_options = options.filter((option) => {
		if (search_string === '') return true;
		return compareSearchAndOption(option.label, search_string);
	});
	let filtered_sorted_options = _.sortBy(
		filtered_options,
		(entry) => entry.label
	);

	filtered_sorted_options = _.slice(filtered_sorted_options, 0, limit);
	return filtered_sorted_options;
};

const Component = (newProps) => {
	const [search_string, set_search_string] = useState('');

	const options = filterOptions(
		newProps.options,
		search_string,
		newProps.value,
		8
	);
	return (
		<Fragment>
			<MuiInput
				variant={'text'}
				value={search_string}
				label={trSearch}
				onChange={(val) => {
					newProps.onChange(null);
					set_search_string(val);
				}}
				{...newProps.SearchProps}
			/>
			<MuiFormFieldOptions.Provider value={{ data: options }}>
				<MuiInput
					variant={'list_select'}
					label={newProps.trLabel}
					value={newProps.value}
					onChange={(val) => {
						newProps.onChange(val);
					}}
					{...newProps.SelectProps}
				/>
			</MuiFormFieldOptions.Provider>
		</Fragment>
	);
};

const getNewProps = (newProps) => {
	if (_.isNil(newProps.options))
		throw new Error(`Missing options for ic_select`);
	newProps.onChange = funcWrap(
		newProps.onChange,
		(onChange) => async (value) => {
			onChange(value);
		}
	);
	return newProps;
};

Component.propTypes = {
	newProps: PropTypes.object,
};

export const ic_list_select_search = {
	Component,
	getNewProps,
};
