import { MuiGlobal } from '../MuiGlobal';
import React, { useCallback, useContext, useReducer, useState } from 'react';
import _, { isNil } from 'lodash';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { MuiCookie } from '../MuiCookie';

export const MuiAppContext = React.createContext({});
MuiAppContext.displayName = 'MuiAppContext';

const getAmToken = (webapi, am_auth_token) => {
	if (!_.isNil(am_auth_token)) {
		webapi.setAmTokenCookie(am_auth_token);
	} else {
		webapi.loadAmTokenFromCookie();
	}
	return webapi.access_token;
};

const getLoginInfoMode = (cookie) => {
	const appDefaultLoginMode = MuiGlobal.get('appDefaultLoginMode', 'username');
	const loginInfoMode = cookie.get('loginInfo_mode');
	return _.isNil(loginInfoMode) ? appDefaultLoginMode : loginInfoMode;
};

const muiAppReducer = (state, action) => {
	switch (action.type) {
		case 'setLoginMode':
			return _.assign({}, state, { loginMode: action.payload.loginMode });
		case 'changeLoginSubscriptions':
			return _.assign({}, state, {
				loginSubscriptions: action.payload.subscribed
					? _.union(state.loginSubscriptions, [action.payload.id])
					: _.without(state.loginSubscriptions, action.payload.id),
			});
		case 'logout':
			return _.assign({}, state, { token: null });
		case 'login':
			return _.assign({}, state, {
				token: action.payload.token,
				loginSubscriptions: [],
			});
		default:
			throw new Error(`no reducer for action '${action.type}'`);
	}
};

export const MuiAppContainer = ({ children, am_auth_token, currentUser }) => {
	const {
		webapi,
		availableApplicationModules,
		availableMenuEntries,
		appTitle,
		appVersion,
		appName,
		orgId,
	} = MuiGlobal.getAll();
	const cookie = new Cookies();
	const cookieCtx = useContext(MuiCookie.Context);
	const [state, dispatch] = useReducer(muiAppReducer, {
		token: getAmToken(webapi, am_auth_token),
		loginMode: getLoginInfoMode(cookie),
		loginSubscriptions: [],
	});

	const setLoginInfoMode = (mode) => {
		const oldmode = getLoginInfoMode(cookie);
		if (oldmode !== mode) {
			let expires = new Date();
			expires.setDate(new Date().getDate() + 1825);
			cookie.set('loginInfo_mode', mode, {
				expires,
				path: '/',
				sameSite: 'strict',
			});
			dispatch({ type: 'setLoginMode', payload: { loginMode: mode } });
		}
	};

	const logout = async () => {
		cookieCtx.deleteCookie('_am_auth_token');
		await webapi.logout();
		dispatch({
			type: 'logout',
		});
	};
	const login = async (options) => {
		const ret = await webapi.login({
			jsonBody: Object.assign({}, options, {
				ORG_ID: orgId,
			}),
		});
		const token = _.get(ret, ['access_token'], null);
		if (!isNil(token)) {
			cookieCtx.setCookie('_am_auth_token', token);
		}
	};

	const changeLoginSubscription = useCallback(
		(id, subscribed) => {
			// console.log('changeLoginSubscription called ', id, subscribed);
			dispatch({
				type: 'changeLoginSubscriptions',
				payload: { id, subscribed },
			});
		},
		[dispatch]
	);
	const value = {
		appName,
		appInfo: {
			title: appTitle,
			version: appVersion,
		},
		loginInfo: {
			mode: state.loginMode,
			token: state.token,
			hasLoginSubscriptions: state.loginSubscriptions.length !== 0,
			changeLoginSubscription,
		},
		userInfo: {
			firstname: _.get(currentUser, ['data', 'FIRSTNAME'], ''),
			lastname: _.get(currentUser, ['data', 'LASTNAME'], ''),
		},
		availableApplicationModules,
		availableMenuEntries,
		setLoginInfoMode,
		login,
		logout,
	};
	// console.log(value);
	return (
		<MuiAppContext.Provider value={value}>{children}</MuiAppContext.Provider>
	);
};

MuiAppContainer.propTypes = {
	currentUser: PropTypes.any,
	am_auth_token: PropTypes.string,
};
