import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import clsx from 'clsx';
import { MuiDrawerMenuItem } from './MuiDrawerMenuItem';
import { getMuiDrawerMenuTree } from './MuiDrawerHelper/MuiDrawerMenuHelper';
import _ from 'lodash';
import { ApplicationModulesContext } from '@lsoft/shared/container/application-modules/ApplicationModules';
import { AmContext } from '@lsoft/shared/components/am/AmContext';

const useStyles = makeStyles((theme) =>
	createStyles({
		drawerMenuItemsRoot: {
			width: '100%',
			maxWidth: theme.sizes.drawerWidth,
		},
	}),
);

const MuiDrawerMenu = (props) => {
	const classes = useStyles(props);
	const { scopes } = useContext(AmContext);
	const {
		data: { menu_entries_available, menu_entries_enabled },
	} = useContext(ApplicationModulesContext);

	const menu_object = getMuiDrawerMenuTree(
		menu_entries_available,
		menu_entries_enabled,
		!_.isNil(scopes) ? scopes : {},
	);

	return (
		<List component={'nav'} className={clsx(classes.drawerMenuItemsRoot)}>
			<MuiDrawerMenuItems menuObj={menu_object} />
		</List>
	);
};

const MuiDrawerMenuItems = ({ menuObj }) => {
	return menuObj.items.map((item, index) => {
		return <MuiDrawerMenuItem item={item} key={index} />;
	});
};

MuiDrawerMenuItems.propTypes = {
	menuObj: PropTypes.object,
};

export { MuiDrawerMenu, MuiDrawerMenuItems };
