import _ from 'lodash';

export const extract_data_for_mui_pagination_view = (res) => {
	const data = _.get(res, 'data', {});

	// Todo Seems sometimes, max_results is in res, sometimes in data
	const max_results = _.defaultTo(
		_.isNil(_.get(data, ['max_results']))
			? _.get(res, ['max_results'])
			: _.get(data, ['max_results']),
		0,
	);

	return {
		IDS: _.get(res, ['ids'], []),
		DATA: _.get(res, ['data'], {}),
		MAX_RESULTS: max_results,
	};
};
