import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const MuiFormFieldOptions = React.createContext(null);
export const MuiFormFieldOptionsContext = MuiFormFieldOptions;

export const MuiFormFieldOptionsContainer = ({ options: data, children }) => {
	const label_by_value = useCallback(
		(value) => {
			const found = _.get(
				data.filter((e) => e.value === value),
				'[0]',
				null
			);
			// console.log('label_by_value', value, found, data);
			return _.isNil(found) ? null : found.label;
		},
		[data]
	);
	return (
		<MuiFormFieldOptionsContext.Provider value={{ data, label_by_value }}>
			{children}
		</MuiFormFieldOptionsContext.Provider>
	);
};

MuiFormFieldOptionsContainer.propTypes = {
	options: PropTypes.any,
};
