import React from 'react';

// const path = require('path');
//
// const RealLogin = require('/mui/_login.js').default;
// console.log('RealLogin', RealLogin);
// const importPath = path.resolve();
// console.log(process.cwd());
// console.log(require.main);
// console.log(require.main.filename);

export const MuiLogin = (loginProps) => {
	// console.log('--->', path.resolve(__dirname));

	// try {
	// const RealLogin = require('../../../mui/_login.js');
	return <div>MuiLogin Component in Mui</div>;
	// return <RealLogin {...loginProps} />;
	// } catch (error) {
	// 	return <MuiAppLoginView {...loginProps} />;
	// }
};
