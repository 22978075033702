import React, { useContext } from 'react';
import { Cart } from './Cart';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Context = React.createContext(null);
Context.displayName = 'CartPosition.Context';

const Container = ({ children, SKU }) => {
	const cartCtx = useContext(Cart.Context);

	const positions = _.get(cartCtx, 'POSITIONS', null);
	if (_.isNil(positions)) return null;

	const realPos = _.get(
		Object.values(positions).filter((pos) => pos.SKU === SKU),
		'[0]'
	);
	const value = {
		SKU: SKU,
		AMOUNT: realPos.AMOUNT,
		ACTIVE: realPos.ACTIVE,
		PRICE: realPos.PRICE,
	};
	return <Context.Provider value={value}> {children}</Context.Provider>;
};
Container.propTypes = {
	SKU: PropTypes.number.isRequired,
};
Container.defaultProps = {};

export const CartPosition = {
	Container,
	Context,
};
