import React from 'react';
// noinspection ES6UnusedImports
import PropTypes from 'prop-types'; // eslint-disable-line no-unused-vars
// noinspection ES6UnusedImports
import clsx from 'clsx'; // eslint-disable-line no-unused-vars
import { createStyles, makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
	createStyles({
		contentContainer: {
			padding: [
				[
					theme.spacing(0),
					theme.spacing(0),
					theme.spacing(11),
					theme.spacing(0),
				],
			],
		},
	})
);

const MuiPageContentContainer = (props) => {
	const classes = useStyles(props);

	return (
		<Container
			maxWidth={props.maxWidth}
			className={clsx(classes.contentContainer)}
		>
			{props.children}
		</Container>
	);
};

MuiPageContentContainer.propTypes = {
	maxWidth: PropTypes.string,
};

MuiPageContentContainer.defaultProps = {
	maxWidth: 'md',
};

export { MuiPageContentContainer };
