import React, { useContext, useEffect } from 'react';
import { MuiActionContext } from './MuiActionContext';
import PropTypes from 'prop-types';

const MuiActionContainerComponent = (props) => {
	const actionContainer = useContext(MuiActionContext);
	useEffect(() => {
		actionContainer.setActionComponent(props.index, props.position, () => {
			const childProps = Object.assign({}, props.children.props, {
				onClick: props.onClick,
				href: props.href,
			});
			return React.cloneElement(props.children, childProps);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.children.props]);
	return null;
};

MuiActionContainerComponent.propTypes = {
	index: PropTypes.number.isRequired,
	position: PropTypes.string,
	onClick: PropTypes.func,
};

MuiActionContainerComponent.defaultProps = {
	position: 'right',
	onClick: () => {},
	href: () => {},
};

export { MuiActionContainerComponent };
