import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { MuiPortal } from '@lsoft/mui';
import { createStyles, makeStyles } from '@material-ui/core';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { HeaderContext } from './Header';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { MuiCSSContainer } from '../MuiCSSContainer';

const useStyles = makeStyles((theme) =>
	createStyles({
		HeaderIconPortal: {
			position: 'absolute',
			top: 1,
			right: 0,
			zIndex: theme.zIndexes.HeaderIconPortal,
			[theme.breakpoints.up('lg')]: {
				right: theme.spacing(3),
			},
		},
		HeaderIconPortalPaper: {
			borderRadius: 0,
			width: '100vw',
			maxHeight: `calc(100vh - ${theme.sizes.appBarHeight}px)`,
			overflowY: 'overlay',
			[theme.breakpoints.up('md')]: {
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0,
				width: 250,
			},
		},
		HeaderIconPortalPlacer: {
			position: 'relative',
			margin: [[0, 'auto']],
		},
	})
);
const HeaderIconPortal = ({ children }) => {
	return (
		<MuiPortal id={'HeaderIconPortal'}>
			<NoSsr>{children}</NoSsr>
		</MuiPortal>
	);
};

const HeaderIconPortalPlacer = ({ theme, children }) => {
	const classes = useStyles({ theme });
	const headerIconPortalRef = useRef(null);
	const {
		activeIconRef,
		selectedIconPortal,
		setSelectedIconPortal,
	} = useContext(HeaderContext);
	useEffect(() => {
		if (!_.isNil(selectedIconPortal)) {
			document.addEventListener('mousedown', handleOutsideClick);
			return () => {
				document.removeEventListener('mousedown', handleOutsideClick);
			};
		}
	}, [selectedIconPortal]);

	const handleOutsideClick = useCallback(
		(e) => {
			const outside = !headerIconPortalRef.current.contains(e.target);
			const notIcon = !activeIconRef.current.contains(e.target);
			if (outside && notIcon) setSelectedIconPortal(null);
		},
		[headerIconPortalRef, setSelectedIconPortal, activeIconRef]
	);

	return (
		<MuiCSSContainer className={clsx(classes.HeaderIconPortalPlacer)}>
			<div
				className={clsx(classes.HeaderIconPortal)}
				id={'HeaderIconPortal'}
				ref={headerIconPortalRef}
			>
				{children}
			</div>
		</MuiCSSContainer>
	);
};

const HeaderIconPortalPaper = ({ theme, children, className }) => {
	const classes = useStyles(theme);
	return (
		<Paper
			className={clsx(classes.HeaderIconPortalPaper, className)}
			elevation={4}
		>
			{children}
		</Paper>
	);
};

HeaderIconPortalPaper.propTypes = {
	className: PropTypes.string,
};

export { HeaderIconPortal, HeaderIconPortalPlacer, HeaderIconPortalPaper };
