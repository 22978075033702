import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { MuiFormatNumber } from './MuiFormatNumber';
import { MuiFormatCurrency } from './MuiFormatCurrency';

const MuiFormatPrice = ({
	value = 0,
	decimals = 2,
	currency = 'EUR',
	round = 'HALF_UP',
	showSign = false,
}) => {
	return (
		<Fragment>
			<MuiFormatNumber
				value={value}
				decimals={decimals}
				round={round}
				showSign={showSign}
			/>
			&nbsp;
			<MuiFormatCurrency value={currency} />
		</Fragment>
	);
};

MuiFormatPrice.propTypes = {
	decimals: PropTypes.number,
	value: PropTypes.number,
	currency: PropTypes.string,
	round: PropTypes.string,
	showSign: PropTypes.bool,
};
export { MuiFormatPrice };
