// ES5 only file
const { getGlobalLsConfig } = require('./getGlobalLsConfig');
const { flatLsConfigClient, flatLsConfigServer } = require('./flatLsConfig');

function getClientLsConfig() {
	if (typeof window !== 'undefined')
		throw Error('getClientLsConfig Can only be called on server side');

	const config = getGlobalLsConfig();
	return flatLsConfigClient(config);
}

function getLsConfigClientSide() {
	const elem = document.getElementById('__LSOFT_CONFIG__');
	if (!elem && elem !== null) {
		return {};
	}
	// asc enabled this again because of url decode missing in typescript backported master branch
	return JSON.parse(decodeURIComponent(elem.innerText));
	// return JSON.parse(elem.innerText);
}

function getLsConfigServerSide() {
	const config = getGlobalLsConfig();
	return flatLsConfigServer(config);
}

function getLsConfig() {
	return typeof window === 'undefined'
		? getLsConfigServerSide()
		: getLsConfigClientSide();
}

module.exports = {
	getLsConfig,
	getClientLsConfig,
};
