import React, { useContext } from 'react';
import { createStyles, makeStyles, MenuItem, Paper } from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import { Tr } from '@lsoft/translate';
import ListSubheader from '@material-ui/core/ListSubheader';
import { MuiSearchBarContext } from './MuiSearchBarContainer';
import PropTypes from 'prop-types';
import { trProducts } from './MuiSearch.tr';

const useStyles = makeStyles((theme) =>
	createStyles({
		HeaderSearchAutocomplete: {
			borderRadius: 0,
			[theme.breakpoints.up('md')]: {
				position: 'absolute',
				top: 0,
				right: 0,
				left: 0,
				zIndex: theme.zIndexes.SearchAutocomplete,
				borderBottomLeftRadius: theme.spacing(0.5),
				borderBottomRightRadius: theme.spacing(0.5),
				maxHeight: '32vh',
				overflowY: 'overlay',
			},
			[theme.breakpoints.up('lg')]: {
				// minHeight: '120px',
				maxHeight: '32vh',
			},
		},
		HeaderSearchAutocompleteList: {
			padding: 0,
		},
		HeaderSearchSubHeader: {
			'&.MuiListSubheader-root': {
				lineHeight: '24px',
			},
		},
		listCartPositions: {
			width: '100%',
		},
		listCartPosition: {
			padding: 0,
		},
	})
);

export const MuiSearchAutocomplete = ({ ResultComponent }) => {
	const { autocompleteResults, onResultClick, autocompleteRef } =
		useContext(MuiSearchBarContext);
	const classes = useStyles();

	if (autocompleteResults === null) return null;

	const items = autocompleteResults.map((item, idx) => {
		return (
			<MenuItem
				key={idx}
				onClick={() => onResultClick(item)}
				className={clsx(classes.listCartPosition)}
				ref={idx === 0 ? autocompleteRef : null}
			>
				<ResultComponent data={item} />
			</MenuItem>
		);
	});
	return (
		<Paper className={clsx(classes.HeaderSearchAutocomplete)}>
			<List
				subheader={
					<ListSubheader
						disableSticky
						className={clsx(classes.HeaderSearchSubHeader)}
					>
						<Tr tr={trProducts} />
					</ListSubheader>
				}
				className={clsx(classes.HeaderSearchAutocompleteList)}
			>
				<ListItem disableGutters style={{ padding: 0 }}>
					<MenuList disablePadding className={clsx(classes.listCartPositions)}>
						{items}
					</MenuList>
				</ListItem>
			</List>
		</Paper>
	);
};

MuiSearchAutocomplete.propTypes = {
	ResultComponent: PropTypes.any.isRequired,
};
