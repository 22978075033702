import jwt from 'jsonwebtoken';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
// import { createWrapper } from 'next-redux-wrapper';
import { HYDRATE } from 'next-redux-wrapper';
import _ from 'lodash';
import { MuiGlobal } from '../MuiGlobal';

const composeEnhancers =
	process.env.MODE === 'production' ? compose() : composeWithDevTools({});

const defaultStoreSecret = 'AreYouALegalUserOrNot';

const MuiStore = {};

MuiStore.serialize = (data) => {
	const storeSecret = MuiGlobal.get('storeSecret', defaultStoreSecret);
	const sign = jwt.sign(data, storeSecret, { noTimestamp: true });
	return sign;
};

MuiStore.deserialize = (data) => {
	if (data === undefined) return {};
	const verify = jwt.decode(data);
	return verify;
};

MuiStore.makeStore = (webapi, rootReducer, middlewares = []) => (
	initialState
) => {
	// console.log('makeStore', initialState);
	// Todo: hack to avoid problems introduced by next-redux-wrapper.
	//  Has to be removed / changed when problem is solved in lib
	const newInitState = _.omit(initialState, [
		'paginationViewParams',
		'AppTree',
		'ctx',
		'Component',
		'router',
		'req',
		'res',
		'query',
		'resolvedUrl',
		'params',
		'locales',
		'locale',
		'defaultLocale',
	]);

	const combinedReducer = combineReducers({
		webapi: webapi.reducer,
		rootReducer,
	});
	const reducer = (state, action) => {
		if (action.type === HYDRATE) {
			const nextState = {
				...state, // use previous state
				...action.payload, // apply delta from hydration
			};
			return nextState;
		} else {
			return combinedReducer(state, action);
		}
	};
	return createStore(
		reducer,
		newInitState,
		composeEnhancers(
			applyMiddleware(
				...middlewares,
				thunk // needed for redux
			)
		)
	);
};

export { MuiStore };
