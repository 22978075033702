const _ = require('lodash');
const { MuiGlobal } = require('../MuiGlobal');

const MuiWebapi = {};
MuiWebapi.set = (webapi) => {
	MuiGlobal.set('webapi', webapi);
};
MuiWebapi.get = () => {
	return MuiGlobal.get('webapi', null);
};
MuiWebapi.isSet = () => !_.isNil(_.get(MuiWebapi.get(), 'action', null));

const useWebapi = () => {
	return MuiWebapi.get();
};

module.exports = { MuiWebapi, useWebapi };
