export const trTermsOfUse = {
	DE: 'Nutzungsbedingungen',
	EN: 'Terms of Use',
	// prettier-ignore
	FR: 'Conditions d\'utilisation',
};
export const trSubscribeToOurNewsletter = {
	DE: 'Newsletter abonnieren',
	EN: 'Subscribe to our newsletter',
	// prettier-ignore
	FR: 'S\'abonner à la newsletter',
};
