// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import _ from 'lodash';
import { MuiPaginationContext } from './MuiPaginationContext';
import { MuiInput } from '../MuiForms/Inputs';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { Tr } from '@lsoft/translate';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	MuiPaginationSort: {
		'& .MuiOutlinedInput-inputMarginDense': {
			paddingTop: theme.spacing(0.75),
			paddingBottom: theme.spacing(0.75),
		},
		fontSize: '14px',
	},
}));

const MuiPaginationSort = ({ theme, style }) => {
	const {
		initialized,
		sortOptions,
		loadCurrentPageWithNewSort,
		sortStringValue,
	} = useContext(MuiPaginationContext);
	const classes = useStyles({ theme });

	if (
		_.isNil(initialized) ||
		!initialized ||
		_.isUndefined(sortOptions) ||
		sortOptions.length === 0
	) {
		return null;
	}

	return (
		<Grid container alignItems={'center'} spacing={1} style={style}>
			<Grid item>
				<Typography variant={'body2'} component={'span'} color={'inherit'}>
					<Tr
						tr={{
							DE: 'Sortierung',
							EN: 'Sorting',
						}}
					/>
				</Typography>
			</Grid>
			<Grid item>
				<MuiInput
					noLabel
					size={'small'}
					variant={'select'}
					options={sortOptions}
					className={clsx(classes.MuiPaginationSort)}
					value={sortStringValue}
					onChange={(val) => {
						const newSortFilter = sortOptions.filter(
							(entry) => entry.value === val
						);
						if (newSortFilter.length !== 0) {
							loadCurrentPageWithNewSort(_.get(newSortFilter, '[0]'));
						}
					}}
				/>
			</Grid>
		</Grid>
	);
};
MuiPaginationSort.propTypes = {};
MuiPaginationSort.defaultProps = {};

export { MuiPaginationSort };
