import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import _ from 'lodash';
import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
	createStyles({
		cellX: {
			textAlign: 'right',
			paddingLeft: theme.spacing(1),
			paddingRight: [[0], '!important'],
		},
		cellOf: {
			width: 10,
			textAlign: 'center',
			paddingLeft: [[0], '!important'],
			paddingRight: [[0], '!important'],
		},
		cellY: {
			textAlign: 'left',
			paddingLeft: [[0], '!important'],
			paddingRight: theme.spacing(1),
		},
	})
);

const convertValue = (value, zeroEmpty) => {
	// console.log(value, zeroEmpty, isNaN(parseFloat(value)));
	if (_.isNil(value)) return '';
	if (!zeroEmpty) return value;
	if (isNaN(parseFloat(value))) return value;
	if (value === 0) return '';
	return value;
};

const MuiTableCellsXofY = ({
	hideEmpty,
	zeroEmpty,
	divider: propsDivider,
	x: propsX,
	y: propsY,
	theme,
	classNameLeft,
	classNameMiddle,
	classNameRight,
}) => {
	const classes = useStyles({ theme });
	const x = convertValue(propsX, zeroEmpty);
	const y = convertValue(propsY, zeroEmpty);
	const divider = x === '' && y === '' && hideEmpty ? '' : propsDivider;

	return (
		<Fragment>
			<TableCell className={clsx(classes.cellX, classNameLeft)}>{x}</TableCell>
			<TableCell className={clsx(classes.cellOf, classNameMiddle)}>
				{divider}
			</TableCell>
			<TableCell className={clsx(classes.cellY, classNameRight)}>{y}</TableCell>
		</Fragment>
	);
};
MuiTableCellsXofY.propTypes = {
	divider: PropTypes.any,
	x: PropTypes.any,
	y: PropTypes.any,
	hideEmpty: PropTypes.bool,
	zeroEmpty: PropTypes.bool,
	classNameLeft: PropTypes.any,
	classNameRight: PropTypes.any,
	classNameMiddle: PropTypes.any,
};

MuiTableCellsXofY.defaultProps = {
	divider: '/',
	hideEmpty: false,
	zeroEmpty: false,
};

export { MuiTableCellsXofY };
