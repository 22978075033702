import { useEffect, useRef } from 'react';

// Todo remove and use the the usePrevious from /shared/hooks/usePrevious
export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};
