export const trCookiePolicy = {
	DE: 'Cookie-Hinweis',
	EN: 'Cookie Policy',
	FR: 'Politique sur les cookies',
};
export const trPrivacyPolicy = {
	DE: 'Datenschutzerklärung',
	EN: 'Privacy Policy',
	FR: 'Politique de confidentialité',
};
export const trAcceptNecessaryCookies = {
	DE: 'Notwendige Cookies',
	EN: 'Necessary cookies',
	FR: 'Cookies nécessaires',
};
export const trAcceptAllCookies = {
	DE: 'Alle Cookies',
	EN: 'All cookies',
	FR: 'Tous les cookies',
};
export const trYourCookieSettings = {
	DE: 'Ihre Cookie-Einstellungen',
	EN: 'Your cookie settings',
	FR: 'Vos paramètres de cookies',
};
export const trWeUseCookiesAndTechnology = {
	DE:
		'Wir verwenden Cookies und ähnliche Technologien, um Inhalte zu personalisieren und Ihr Erlebnis zu verbessern. Außerdem nutzen sie um grundlegende Webseitenfunktionen anzubieten und zu verstehen wie Kunden unsere Webseite nutzen und mit diesen Daten die die Webseite zu optimieren.',
	EN:
		'We use cookies and similar technologies to personalise content and improve your experience. They are also used to provide basic website functionality and to understand how customers use our website and to use this information to improve the website.',
	// prettier-ignore
	FR:
		'Nous utilisons des cookies et des technologies similaires pour personnaliser le contenu et améliorer votre expérience. Ils sont également utilisés pour fournir des fonctionnalités de base du site web et pour comprendre comment les clients utilisent notre site web et pour utiliser ces informations afin d\'améliorer le site web.',
};
export const trToContinueAcceptCookies = {
	DE:
		'Um fortzufahren, wählen Sie bitte ein der beiden Optionen aus. Sie können Ihre getätigten Einstellung jederzeit anpassen.',
	EN:
		'To continue, please select one of the two options. You can adjust your settings at any time.',
	// prettier-ignore
	FR:
		'Pour continuer, veuillez sélectionner l\'une des deux options. Vous pouvez modifier vos paramètres à tout moment.',
};
export const trWhichCookieSettingsProceed = {
	DE: 'Mit welchen Cookie-Einstellungen möchten Sie fortfahren?',
	EN: 'With which cookie settings do you want to proceed?',
	FR:	'Quels sont les paramètres des cookies que vous voulez continuer à utiliser ?',
};
