/* eslint-disable-line */
import React, { useCallback, useContext, useMemo } from 'react';
import { GTMProviderContext } from './GTMProvider';
import { Session } from './Session';
import _ from 'lodash';
import { getTranslation } from '@lsoft/translate';
import { remove_empty_keys } from '@lsoft/shared/functions/dict';

const debug_log = false;

export const GTMCheckoutProviderContext = React.createContext(null);
GTMCheckoutProviderContext.displayName = 'GTMCheckoutProviderContext';

export const GTMCheckoutProvider = ({ children }) => {
	const { push_to_data_layer } = useContext(GTMProviderContext);
	const session_context = useContext(Session.Context);

	const get_gtm_position = useCallback((data, list_name) => {
		if (_.isNil(data)) return null;
		return remove_empty_keys({
			item_id: data.SKU.toString(),
			item_name: !_.isNil(_.get(data, 'NAME_TEXT'))
				? getTranslation(_.get(data, 'NAME_TEXT'), 'en')
				: null,
			item_brand: _.get(data, 'MANUFACTURER_NAME'),
			item_list_name: list_name,
			price: _.get(data, 'PRICE'),
			currency: _.get(data, 'CURRENCY', 'EUR'), // TODO - CURRENCY is not part of the provided data, see also issue #177
			quantity: data.AMOUNT,
		});
	}, []);

	const product_data = useMemo(() => {
		if (_.isNil(session_context)) return null;
		const positions = _.get(session_context, ['DATA', 'CART', 'POSITIONS'], []);
		return Object.values(positions).map((position) =>
			get_gtm_position(position)
		);
	}, [session_context]);

	const session_transaction_data = useMemo(() => {
		return remove_empty_keys({
			value: _.get(session_context, ['DATA', 'CART', 'TOTAL_PRICE'], null),
			currency: 'EUR', // TODO - CURRENCY is not part of the provided data, see also issue #177
			transaction_id: _.get(
				session_context,
				['DATA', 'CART', 'ORDER_NUMBER'],
				null
			),
			shipping: _.get(
				session_context,
				['DATA', 'CART', 'SHIPPING_PRICE'],
				null
			),
		});
	}, [session_context]);

	const checkout_add_shipping_info = () => {
		const dataLayer = {
			event: 'add_shipping_info',
			ecommerce: _.merge(
				{},
				{
					items: product_data,
				},
				session_transaction_data
			),
		};
		if (debug_log) console.log('checkout_add_shipping_info', dataLayer);
		push_to_data_layer(dataLayer);
	};

	const checkout_purchase = (step, payment_order_transaction_data) => {
		const dataLayer = {
			event: step,
			ecommerce: payment_order_transaction_data,
		};
		if (debug_log) console.log('checkout_purchase', dataLayer);
		push_to_data_layer(dataLayer);
	};

	const checkout_custom_event = (event_name) => {
		const dataLayer = {
			event: event_name,
			ecommerce: _.merge(
				{},
				{
					items: product_data,
				},
				session_transaction_data
			),
		};
		if (debug_log) console.log('checkout_custom_step', event_name, dataLayer);
		push_to_data_layer(dataLayer);
	};

	const value = {
		// checkout_begin_checkout,
		// checkout_add_shipping_info,
		checkout_purchase,
		checkout_custom_event,
	};

	return (
		<GTMCheckoutProviderContext.Provider value={value}>
			{children}
		</GTMCheckoutProviderContext.Provider>
	);
};
