import { controlKeys } from './controlKeys';

const keyEventIsAllowedCharacter = (e, allowedCharacters) => {
	if (controlKeys.indexOf(e.key) !== -1) return true;
	const reg = RegExp(allowedCharacters);
	if (e.ctrlKey) {
		return true;
	} else {
		return !(allowedCharacters != null && !reg.test(e.key));
	}
};
export const validateKeyEventCharacters = (e, { allowedCharacters }) => {
	return keyEventIsAllowedCharacter(e, allowedCharacters);
};
