import React from 'react';
import {
	Button,
	Card,
	CardContent,
	Container,
	makeStyles,
	Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { TextFooter } from '../Footer/TextFooter';
import { MuiLink } from '@lsoft/mui';
import { ShopPageBasicHeader } from '../../app/ShopPageBasicHeader';
import { Tr } from '@lsoft/translate';
import { trGoBackToHomepage } from '../CheckoutPaymentReturn/CheckoutPaymentReturn.tr';
import {
	trPageNotFound,
	trSorryThePageYouRequestedCouldNotBeFound,
} from './ErrorView.tr';

const useStyles = makeStyles((theme) => ({
	Container: {
		paddingTop: theme.spacing(2),
	},
}));
export const ErrorView = ({ ...pageProps }) => {
	const classes = useStyles();
	return (
		<ShopPageBasicHeader loginPage {...pageProps}>
			<Container maxWidth={'lg'} className={clsx(classes.Container)}>
				<Grid
					container
					justifyContent={'center'}
					direction={'row'}
					alignItems={'center'}
				>
					<Grid item xs={12} md={8}>
						<Card>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant={'h3'} gutterBottom>
											<Tr tr={trPageNotFound} />
										</Typography>
										<Typography>
											<Tr tr={trSorryThePageYouRequestedCouldNotBeFound} />
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<MuiLink href={'/'} fullWidth>
											<Button variant={'contained'} color={'primary'} fullWidth>
												<Tr tr={trGoBackToHomepage} />
											</Button>
										</MuiLink>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={8}>
						<TextFooter />
					</Grid>
				</Grid>
			</Container>
		</ShopPageBasicHeader>
	);
};
