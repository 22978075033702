import React, { useContext } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { MuiGlobalComponent, useMuiGlobals } from '../../MuiGlobal';
import Grid from '@material-ui/core/Grid';
import { MuiSearchWidgetStyles } from './MuiSearchWidget.styles';
import clsx from 'clsx';
import { MuiSearchInput } from '../../MuiSearch/MuiSearchInput';
import { MuiSearch, MuiSearchContext } from '../../MuiSearch/MuiSearch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTranslation } from '@lsoft/translate';
import { useRouter } from 'next/router';

const useStyles = makeStyles((theme) =>
	createStyles({
		...MuiSearchWidgetStyles(theme),
	}),
);

const MuiSearchWidgetInner = ({ theme }) => {
	const context = useContext(MuiSearchContext);
	const { locale } = useRouter();
	const classes = useStyles(theme);

	const renderIcon = () => {
		if (context.loading) {
			return (
				<div style={{ height: 18, paddingRight: 6, paddingLeft: 6 }}>
					<CircularProgress size={18} color={'inherit'} />
				</div>
			);
		} else {
			return (
				<IconButton
					onClick={() => {
						console.log('onclick');
						context.search();
					}}
					className={classes.searchIcon}
					size={'small'}
				>
					<SearchIcon />
				</IconButton>
			);
		}
	};

	return (
		<Grid
			container
			direction={'row'}
			className={classes.searchInput}
			justifyContent={'flex-end'}
			alignItems={'center'}
		>
			<Grid item>
				<MuiSearchInput
					classes={clsx(classes.SearchInputField)}
					placeholder={getTranslation(
						{
							DE: 'SKU / MPN eingeben',
							EN: 'Type SKU / MPN',
						},
						locale,
					)}
				/>
			</Grid>
			<Grid item>{renderIcon()}</Grid>
		</Grid>
	);
};

export const MuiDefaultSearchWidget = () => {
	const { searchPlugin } = useMuiGlobals();
	return (
		<MuiSearch plugin={searchPlugin}>
			<MuiSearchWidgetInner />
		</MuiSearch>
	);
};

export const MuiSearchWidget = MuiGlobalComponent(
	'_appsearchwidget',
	MuiDefaultSearchWidget,
);
