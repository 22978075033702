import React from 'react';
import {
	MenuBooks,
	MenuLegoAge,
	MenuLegoSeries,
	MenuLighting,
	MenuOtherBrands,
	MenuStorageBoxes,
} from '../../constants/MenuEntries';

import { Divider, MenuList } from '@material-ui/core';
import {
	TagMenuVertical,
	TagMenuVerticalPromotions,
} from '../TagMenu/TagMenuVertical';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Tr } from '@lsoft/translate';
import { trBrowseCategories } from './TagNavVertical.tr';
import { PromotionsContainer } from '@lsoft/shared/components/Promotion/container/Promotions';

const TagNavVertical = () => {
	return (
		<MenuList
			subheader={
				<ListSubheader disableSticky>
					<Tr tr={trBrowseCategories} />
				</ListSubheader>
			}
			component={'nav'}
		>
			<PromotionsContainer active={true} without_empty>
				<TagMenuVerticalPromotions />
			</PromotionsContainer>

			<TagMenuVertical data={MenuLegoAge} />
			<Divider />
			<TagMenuVertical data={MenuLegoSeries} />
			<Divider />
			{/*<TagMenuVertical data={MenuLegoEducationStem} />*/}
			{/*<Divider />*/}
			{/*<TagMenuVertical data={MenuLegoEducationSeries} />*/}
			{/*<Divider />*/}
			<TagMenuVertical data={MenuLighting} />
			<Divider />
			<TagMenuVertical data={MenuStorageBoxes} />
			<Divider />
			<TagMenuVertical data={MenuOtherBrands} />
			<Divider />
			<TagMenuVertical data={MenuBooks} />
		</MenuList>
	);
};

TagNavVertical.propTypes = {};
TagNavVertical.defaultProps = {};

export { TagNavVertical };
