import { MuiWebapiContainer } from '../MuiWebapi';
import _ from 'lodash';

// eslint-disable-next-line no-unused-vars
const selector = (state, options) =>
	_.get(state.webapi.data, 'TYPE_MAPPINGS', null);

const TypeMappings = MuiWebapiContainer(
	{
		endpointPath: 'type_mapping.typeMappings_get',
		dataPaths: { data: ['TYPE_MAPPINGS'] },
	},
	{
		selector: selector,
	}
);
export { TypeMappings };
