import { appConfig } from '../../../app.config';
import { useRouter } from 'next/router';
import { ThemeProvider } from '@material-ui/styles';
import _ from 'lodash';
import { MuiDefaultTheme } from '@lsoft/mui';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Image from 'next/image';
import { Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { getTranslation } from '@lsoft/translate';
import React from 'react';

export const MaintenanceModeView = () => {
	const { theme } = appConfig;
	const { locale } = useRouter;

	// Allows to block the access by .env variable (MAINTENANCE_MODE) and a cookie (_allow_access) #69
	// const maintenance_mode = MuiGlobal.get('MAINTENANCE_MODE', null);
	// const allowAccessCookieVal = _.get(appProps.cookies, '_allow_access', null);
	// if (maintenance_mode !== 'false') {
	// 	// if (allowAccessCookieVal !== maintenance_mode) {
	// 	// 	if (!ssrRender) {
	// 	// 		// noinspection JSIgnoredPromiseFromCall
	// 	// 		push('/onepage');
	// 	// 		return null;
	// 	// 	}
	// 	// } else {
	// 	return <MaintenanceView />;
	// 	// }
	// }

	return (
		<ThemeProvider theme={!_.isNil(theme) ? theme : MuiDefaultTheme}>
			<CssBaseline />
			<Grid
				container
				spacing={2}
				justifyContent={'center'}
				alignItems={'center'}
				style={{ marginTop: 20 }}
			>
				<Grid item>
					<Card>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Grid
										container
										spacing={2}
										justifyContent={'center'}
										alignItems={'center'}
									>
										<Grid item>
											<Image
												src={'/images/logo_ltoys.png'}
												alt={'LToys - Bricks, Figures & More'}
												width={48}
												height={48}
												layout={'intrinsic'}
												priority
												loading={'eager'}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<Typography align={'center'} gutterBottom variant={'h6'}>
										{getTranslation(
											{
												DE: 'Wartungsmodus',
												EN: 'Maintenance Mode',
												FR: 'Mode Maintenance',
											},
											locale
										)}
									</Typography>
									<Typography align={'center'} gutterBottom>
										{getTranslation(
											{
												DE: 'Unsere Onlineshop ist im Moment im Wartungsmodus. Bald sind wir wieder für Sie da!',
												EN: 'Our online shop is currently in maintenance mode. We will be back for you soon!',
												FR: 'Notre boutique en ligne est actuellement en mode maintenance. Nous serons bientôt de retour pour vous !',
											},
											locale
										)}
									</Typography>
									<Typography align={'center'}>
										{getTranslation(
											{
												DE: 'Du möchtest uns kontaktieren? Schreib usn einfach an: support[at]ltoys.lu',
												EN: 'You want to contact us? Just write to us at: support[at]ltoys.lu',
												FR: 'Vous souhaitez nous contacter ? Écrivez-nous à : support[at]ltoys.lu',
											},
											locale
										)}
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
};
