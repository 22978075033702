import React from 'react';
import { Tr } from './Tr';

const TextOrTr = (props) => {
	const { children } = props;
	if (children == null || !children.startsWith('_tr:')) return children;
	const path = children.split('_tr:')[1];
	const newProps = { ...props, path: path };
	return <Tr {...newProps} />;
};

export { TextOrTr };
