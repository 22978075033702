// eslint-disable-next-line no-unused-vars
import React from 'react';
import _ from 'lodash';

export const validate = (value, validators) => {
	const ret = validators.reduce(
		(valObj, validator) => {
			if (!valObj.bypass) {
				const newValObj = validator(value);
				if (newValObj.errors !== null)
					valObj.errors = valObj.errors.concat(newValObj.errors);
				valObj.valid = newValObj.valid;
				valObj.bypass = newValObj.bypass;
			}
			return valObj;
		},
		//initial value must be true!
		{ valid: true, bypass: false, errors: [] }
	);
	return ret;
};

export const makeReturn = (valid, errorMessage, overrides) => {
	return _.assign(
		{},
		{ valid, bypass: !valid, errors: valid ? null : errorMessage },
		{ ...overrides }
	);
};

export const max = (maxValue) => (value) =>
	makeReturn(value <= maxValue, { ALL: `Max. ${maxValue}` });

export const min = (minValue) => (value) =>
	makeReturn(value >= minValue, { ALL: `Min. ${minValue}` });

export const notEqual = (neq) => (value) =>
	makeReturn(value !== neq, {
		EN: `${value} not allowed`,
		DE: `${value} nicht erlaubt`,
	});

export const maxLength = (length) => (value) =>
	makeReturn(value !== null && value.length <= length, {
		EN: `Max. ${length} characters`,
		DE: `Max. ${length} Zeichen`,
	});

export const minLength = (length) => (value) =>
	makeReturn(value !== null && value.length >= length, {
		EN: `Min. ${length} characters`,
		DE: `Min. ${length} Zeichen`,
	});

export const contentLength = (length) => (value) =>
	makeReturn(value !== null && value.length === length, {
		EN: `Not ${length} characters`,
		DE: `Nicht ${length} Zeichen lang`,
	});

export const validEmail = () => (value) => {
	const re = new RegExp(
		'^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z0-9-]+\\.[A-Za-z]{2,6}$'
	);
	return makeReturn(value !== null && re.test(value), {
		EN: `No valid email address`,
		DE: `Keine valide E-Mail-Adresse`,
	});
};

export const notEmptyString = () => (value) =>
	makeReturn(value !== null && value.length !== 0, {
		EN: `Empty`,
		DE: `Leer`,
	});

export const optional = () => (value) => {
	if (value === null) return { valid: true, bypass: true, errors: [] };
	return { valid: true, bypass: false, errors: [] };
};

export const optionalEmpty = (optionalValue) => (value) => {
	if (value === optionalValue) return { valid: true, bypass: true, errors: [] };
	return { valid: true, bypass: false, errors: [] };
};

// const equalValues = (values) => (value) => {
// 	const valid = _.includes(values, value);
// 	return {
// 		valid: valid,
// 		bypass: false,
// 		errors: valid
// 			? []
// 			: [
// 					<Tr
// 						key={'lengthvalue'}
// 						tr={{ ALL: `Value not matching one of given values` }}
// 					/>,
// 			  ],
// 	};
// };

// const optionalValue = () => (value) => {
// 	if (value === null) return { valid: true, bypass: true, errors: [] };
// 	return { valid: true, bypass: false, errors: [] };
// };
//
// const notNull = () => (value) => {
// 	return { valid: value !== null, bypass: false, errors: [] };
// };
