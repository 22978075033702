import { ShopPageCore } from './ShopPageCore';
import { MuiTopCenterBottom } from '@lsoft/mui';
import { Header } from '../components/Header/Header';
import { CookieNotification } from '../components/CookieNotification/CookieNotification';
import React from 'react';
import PropTypes from 'prop-types';
import { Cart } from '../container/shop/Cart';
import { Customer } from '../container/shop/Customer';
import { Hidden } from '@material-ui/core';
import { OrdersDisabledAlert } from '../components/ShopMode/OrdersDisabledAlert';

export const ShopPageBasicHeader = ({ children, loginPage, ...pageProps }) => {
	return (
		<ShopPageCore {...pageProps}>
			<Cart.Container>
				<Customer.Container>
					<MuiTopCenterBottom.Container>
						<MuiTopCenterBottom.Top>
							<Header loginPage={loginPage} />
						</MuiTopCenterBottom.Top>
						<MuiTopCenterBottom.Center isScrollRef>
							<OrdersDisabledAlert />
							{children}
							<CookieNotification />
						</MuiTopCenterBottom.Center>
					</MuiTopCenterBottom.Container>
				</Customer.Container>
			</Cart.Container>
		</ShopPageCore>
	);

	return (
		<ShopPageCore {...pageProps}>
			<Cart.Container>
				<Customer.Container>
					<MuiTopCenterBottom.Container>
						<Hidden smDown initialWidth={'md'}>
							<MuiTopCenterBottom.Top>
								<Header loginPage={loginPage} />
							</MuiTopCenterBottom.Top>
						</Hidden>
						<MuiTopCenterBottom.Center isScrollRef>
							<Hidden mdUp initialWidth={'xs'}>
								<Header loginPage={loginPage} />
							</Hidden>
							{children}
							<CookieNotification />
						</MuiTopCenterBottom.Center>
					</MuiTopCenterBottom.Container>
				</Customer.Container>
			</Cart.Container>
		</ShopPageCore>
	);
};

ShopPageBasicHeader.propTypes = {
	loginPage: PropTypes.bool,
};

ShopPageBasicHeader.defaultProps = {
	loginPage: false,
};
