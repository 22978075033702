import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import _, { isNil } from 'lodash';
import { createStyles, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { MuiGlobal, MuiGlobalComponent } from '../MuiGlobal';
import { MuiAppBar } from './MuiAppBar/MuiAppBar';
import clsx from 'clsx';
import { MuiAppMenu } from './MuiAppMenu';
import { MuiTopCenterBottom } from '../MuiTopCenterBottomView';
import { ErrorBoundary } from 'react-error-boundary';
import { WithMuiAppLogin } from './WithMuiAppLogin';
import { Restricted } from '@lsoft/shared/components/am/Restricted';

const red = {
	border: '1px solid red',
};

const useStyles = makeStyles((theme) =>
	createStyles({
		flex: {
			display: 'flex',
			position: 'relative',
		},
		drawer: {
			flexShrink: 0,
			width: theme.sizes.drawerWidth,
		},
		drawerPaper: {
			background: theme.palette.grey[100],
			borderRight: [[1, 'solid', theme.palette.grey[300]]],
			width: theme.sizes.drawerWidth,
		},
		toolbar: theme.mixins.toolbar,
		containerBox: {
			// ...red,
			display: 'flex',
			height: '100%',
		},
		drawerBox: {
			// ...red,
			width: theme.sizes.drawerWidth,
			height: '100%',
			display: 'flex',
		},
		pageBox: {
			// ...red,
			// width: theme.sizes.drawerWidth,
			height: '100%',
			width: '100%',
			display: 'flex',
		},
		error: {
			margin: theme.spacing(1),
			'& p': {
				fontSize: 20,
				fontWeight: 700,
			},
			'& .errorMessage': {
				border: '2px solid red',
				padding: theme.spacing(2),
			},
			'& .stackTrace': {
				border: '2px solid red',
			},
		},
	}),
);

function ErrorFallback({ error, componentStack, resetErrorBoundary }) {
	const classes = useStyles();
	console.error(error);
	return (
		<div role="alert" className={clsx(classes.error)}>
			<p>Oops: Please welcome the failwhale...</p>
			<pre className={clsx('errorMessage')}>{error.message}</pre>
			<div className={clsx('stackTrace')}>
				<code>{error.stack}</code>
			</div>
			{/*<button onClick={resetErrorBoundary}>Try again</button>*/}
		</div>
	);
}

const MuiDefaultAppView = ({ pageInfo, children, theme }) => {
	if (isNil(pageInfo)) return null;
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const classes = useStyles({ theme });
	const menuExists = true;
	const { availableMenuEntries } = MuiGlobal.getAll();
	return (
		<Fragment>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<WithMuiAppLogin>
					<Restricted>
						<MuiTopCenterBottom.Container>
							<MuiTopCenterBottom.Top>
								<MuiAppBar
									title={pageInfo.title}
									documentName={_.get(pageInfo, 'documentName', null)}
								/>
							</MuiTopCenterBottom.Top>
							<MuiTopCenterBottom.Center>
								<Box className={clsx(classes.containerBox)}>
									{!isNil(availableMenuEntries) && (
										<Box className={clsx(classes.drawerBox)}>
											<MuiAppMenu />
										</Box>
									)}
									<Box className={clsx(classes.pageBox)}>{children}</Box>
								</Box>
							</MuiTopCenterBottom.Center>
						</MuiTopCenterBottom.Container>
					</Restricted>
				</WithMuiAppLogin>
			</ErrorBoundary>
		</Fragment>
	);
};

MuiDefaultAppView.propTypes = {
	pageInfo: PropTypes.object,
};

export const MuiAppView = MuiGlobalComponent('_appview', MuiDefaultAppView);
