import React, { useEffect, useReducer, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

export const TranslationContainerContext = React.createContext(null);
TranslationContainerContext.displayName = 'TranslationContainerContext';

const TranslationContainerReducer = (state, action) => {
	const newState = _.assign({}, state);
	switch (action.type) {
		case 'changeTranslationLanguage':
			newState[action.payload.new_lc] = _.clone(
				newState[action.payload.old_lc]
			);
			delete newState[action.payload.old_lc];
			break;

		case 'deleteTranslation':
			delete newState[action.payload.lc];
			break;

		case 'changeTranslationText':
			newState[action.payload.lc] = action.payload.text;
			break;
	}
	return newState;
};

export const MuiTranslationContainer = ({
	children,
	tr,
	availableLanguages,
	onChange,
}) => {
	const [state, dispatch] = useReducer(TranslationContainerReducer, tr);
	const isFirstRun = useRef(true);
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		onChange(state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	const usedLanguages = Object.keys(state);

	const selectableLanguages = (lc) => {
		// if there is no or only one translation, add ALL to the selection
		const ret = _.uniq(
			_.concat(
				usedLanguages.length <= 1 ? 'ALL' : [],
				_.without(availableLanguages, ...usedLanguages),
				lc
			)
		).sort();
		return ret;
	};
	const allExists = _.includes(usedLanguages, 'ALL');

	const addableLanguages = allExists
		? []
		: _.uniq(
				_.concat(
					usedLanguages.length == 0 ? 'ALL' : [],
					_.without(availableLanguages, ...usedLanguages)
				)
		  ).sort();

	const changeTranslationLanguage = (old_lc, new_lc) => {
		dispatch({
			type: 'changeTranslationLanguage',
			payload: { old_lc, new_lc },
		});
	};

	const changeTranslationText = (lc, text) => {
		dispatch({
			type: 'changeTranslationText',
			payload: { lc, text },
		});
	};

	const deleteTranslation = async (lc) => {
		dispatch({
			type: 'deleteTranslation',
			payload: { lc },
		});
	};

	const value = {
		usedLanguages,
		availableLanguages,
		addableLanguages,
		selectableLanguages,
		changeTranslationLanguage,
		deleteTranslation,
		changeTranslationText,
		getText: (lc) => state[lc],
	};
	return (
		<TranslationContainerContext.Provider value={value}>
			{children}
		</TranslationContainerContext.Provider>
	);
};
MuiTranslationContainer.propTypes = {
	tr: PropTypes.object,
	availableLanguages: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func,
};
MuiTranslationContainer.defaultProps = {
	onChange: () => {},
};
