// eslint-disable-next-line no-unused-vars
export const MuiAssetImageStyles = (theme) => ({
	MuiAssetImage: {
		// padding: theme.spacing(1),
		// maxWidth: '100%',
		// maxHeight: '100%',
		'& img': {
			objectFit: 'contain',
			// width: '100%',
			// height: '100%',
		},
	},
	MuiAssetImageStretch: {
		'& img': {
			objectFit: 'contain',
			width: '100%',
			height: '100%',
		},
	},
});
