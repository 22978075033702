import IconButton from '@material-ui/core/IconButton';
import Image from 'next/image';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getTranslation } from '@lsoft/translate';
import { tr_visit_our_letzshop_profile } from '@lsoft/shared/translations/links';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { tr_find_the_perfect_gift } from '../../translations/pages';

export const HeaderLinkGiftfinder = (props) => {
	const { locale } = useRouter();
	const title = useMemo(() => {
		return getTranslation(tr_find_the_perfect_gift, locale);
	}, [locale]);

	return (
		<Link href={'/gift_finder'} title={title}>
			<IconButton
				color={'inherit'}
				style={{
					padding: 0,
					width: 48,
					height: 48,
				}}
			>
				<Image
					src={'/images/gift_finder/shop_giftfinder_icon.png'}
					// src={'/images/logo_ltoys.png'}
					alt={'Letzshop'}
					width={32}
					height={32}
					// layout={'intrinsic'}
					priority
					loading={'eager'}
					style={{ objectFit: 'contain' }}
				/>
			</IconButton>
		</Link>
	);
};

HeaderLinkGiftfinder.propTypes = {};
