import React, { useContext } from 'react';
import { MuiCookie } from '@lsoft/mui';
import { Tr } from '@lsoft/translate';
import Tooltip from '@material-ui/core/Tooltip';

export const MuiCookieTooltip = ({ children }) => {
	const { technicalCookies } = useContext(MuiCookie.Context);
	if (technicalCookies) return children;

	const realChildren = React.Children.only(children);
	const newChildren = React.cloneElement(
		realChildren,
		{
			disabled: true,
			href: null,
			style: {
				pointerEvents: 'none',
			},
			onClick: () => {},
		},
		realChildren.props.children
	);

	return (
		<Tooltip
			title={
				<Tr
					tr={{
						DE: 'Um diese Funktion zu nutzen, stimmen Sie den notwendigen Cookies zu.',
						EN: 'To use this feature agree to the necessary cookies.',
						FR: 'Pour utiliser cette fonction, vous devez accepter les cookies nécessaires.',
					}}
				/>
			}
		>
			<div style={{ cursor: 'not-allowed' }}>{newChildren}</div>
		</Tooltip>
	);
};
