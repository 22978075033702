export const HTMLTranslationStyles = (theme) => ({
	html_translation_div: {
		// border: "1px solid red",
		height: '100%',
		minHeight: '100%',
		width: '100%'
	},
	tabs: {
		// width: 100,
		'& .MuiTab-root': {
			minWidth: 0
			// minHeight: 34,
			// height: 34,
			// fontSize: '0.875rem',
		},
		'& .MuiButtonBase-root': {
			width: 100
		},
		'& .Mui-selected': {
			background: theme.palette.grey[200],
			fontColor: theme.palette.black,
			fontWeight: theme.typography.fontWeightRegular
		},
		'& .MuiTabs-indicator': {
			height: 3
		}
	},
	Divider: {
		height: 2,
		background: theme.palette.primary[300]
	},
	AddButtonBox: {
		marginTop: theme.spacing(1),
		width: 80
	},
	AddSelect: {
		width: 50,
		background: theme.palette.grey[200],
		borderRadius: 4,
		marginRight: theme.spacing(1),
		fontSize: '0.875rem'
	},
	PreviewCard: {
		fontFamily: 'Roboto',
		padding: theme.spacing(2),
		wordBreak: 'break-word'
	},
	EditorContainer: {
		height: '700px',
		overflowY: 'scroll',
		marginBottom: theme.spacing(2)
	},
	Editor: {
		width: '100%',
		borderRadius: 5,
		minHeight: '100%',
		// fontFamily: '"Fira code", "Fira Mono", monospace',
		background: '#2b2b2b',
		color: '#FFF',
		fontSize: '14',
		'& pre': {
			wordBreak: 'break-word!important'
		}
	},
	nopadding: {
		padding: [0, '!important']
		// "&.MuiGrid-spacing-xs-1 > .MuiGrid-item": {
		// padding: [[0, '!important']]
		// }
	},
	maxheight: {
		height: '100%',
		minHeight: '100%'
	},
	red: {
		border: '1px solid red'
	},
	editor: {
		fontSize: 12,
		minHeight: '100%',
		borderLeft: '1px solid rgb(224 224 224)',
		backgroundColor: '#f5f5f5',
		fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
	}

});
