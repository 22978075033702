import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import clsx from 'clsx';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import { MuiTopCenterBottom } from '../MuiTopCenterBottomView';
import { makeStyles } from '@material-ui/core';
//Todo: remove import (styles are imported from outside mui lib)
import styles from '../styles/styles';

const useStyles = makeStyles((theme) => ({
	...styles(theme),
	topPaper: {
		minHeight: theme.spacing(0.75),
	},
	headerFirstLine: {
		'& th': {
			paddingTop: 0,
		},
	},
	bottomPaper: {
		background: theme.palette.grey[200],
		padding: theme.spacing(1),
	},
}));

//Todo: adapt to new MuiTopCenterBottom
const MuiBasicTable = (props) => {
	const classes = useStyles(props);

	const tableHeader = () => {
		return (
			<Table
				size={'small'}
				className={clsx(
					classes.MuiTable,
					props.className,
					// classes.headerFirstLine
					// classes.MuiTableStickyHeader
				)}
				// style={{ borderBottom: '1px solid red' }}
			>
				<TableHead>{props.renderHeader()}</TableHead>
			</Table>
		);
	};
	const topContent = () => {
		return (
			<Paper square elevation={2} className={clsx(classes.topPaper)}>
				{props.renderAbove()}
				{tableHeader()}
			</Paper>
		);
	};
	const centerContent = () => {
		return (
			<Paper square elevation={0}>
				<Table
					size={'small'}
					className={clsx(classes.MuiTable, props.contentClassName)}
				>
					<TableBody>
						{props.renderStaticRows()}
						{props.renderRows()}
					</TableBody>
				</Table>
				<Grid
					container
					className={clsx(classes.GirdSpacingFix)}
					direction={'column'}
					spacing={1}
				>
					{props.renderBelowRows()}
				</Grid>
			</Paper>
		);
	};
	const bottomContent = () => {
		return (
			<Paper
				// variant="outlined"
				square
				elevation={4}
				className={clsx(classes.bottomPaper)}
			>
				<Grid container direction={'column'} alignItems={'stretch'} spacing={0}>
					<Grid item>
						<Grid
							container
							direction={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<Grid item>
								<Grid
									container
									direction={'row'}
									justifyContent={'flex-end'}
									alignItems={'center'}
									spacing={1}
								>
									{props.renderBottomButtonArea()}
									{props.renderItemsPerPage()}
								</Grid>
							</Grid>
							<Grid item>
								<Grid
									container
									direction={'row'}
									justifyContent={'flex-end'}
									alignItems={'center'}
									spacing={1}
								>
									{props.renderBottomInfoArea()}
									{props.renderBottomButtonAreaRight()}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		);
	};

	return (
		<MuiTopCenterBottom.Container>
			<MuiTopCenterBottom.Top>{topContent()}</MuiTopCenterBottom.Top>
			<MuiTopCenterBottom.Center>{centerContent()}</MuiTopCenterBottom.Center>
			{!props.hideBottom && (
				<MuiTopCenterBottom.Bottom>{bottomContent()}</MuiTopCenterBottom.Bottom>
			)}
		</MuiTopCenterBottom.Container>
	);
};

MuiBasicTable.propTypes = {
	renderError: PropTypes.func,
	renderHeader: PropTypes.func.isRequired,
	renderStaticRows: PropTypes.func,
	renderRows: PropTypes.func.isRequired,
	renderBelowRows: PropTypes.func,
	renderAbove: PropTypes.func,
	renderBelow: PropTypes.func,
	renderLoadMore: PropTypes.func,
	renderBottomInfoArea: PropTypes.func,
	renderLoadingIndicator: PropTypes.func,
	renderBottomButtonArea: PropTypes.func,
	renderBottomButtonAreaRight: PropTypes.func,
	renderItemsPerPage: PropTypes.func,
	loadMore: PropTypes.bool,
	className: PropTypes.any,
	hideTop: PropTypes.bool,
	hideContent: PropTypes.bool,
	hideBottom: PropTypes.bool,
};
MuiBasicTable.defaultProps = {
	// eslint-disable-next-line react/display-name
	renderError: () => null,
	renderStaticRows: () => null,
	renderLoadingIndicator: () => null,
	renderAbove: () => null,
	renderBottomButtonArea: () => null,
	renderBottomButtonAreaRight: () => null,
	renderBottomInfoArea: () => null,
	renderItemsPerPage: () => null,
	renderRows: () => null,
	renderBelowRows: () => null,
	loadMore: false,
	hideTop: false,
	hideContent: false,
	hideBottom: false,
};
export { MuiBasicTable };
