import React, { useContext, useEffect, useReducer } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
	MuiActionContainerTableCell,
	MuiActionContainerTableRow,
} from './MuiActionContainerTableCell';

const Context = React.createContext(null);
Context.displayName = "MuiAction.Context"

const RealActionContext = React.createContext(null);
RealActionContext.displayName = "RealActionContext"

const indexReducer = (state, action) => {
	switch (action.type) {
		case 'add_section':
			return _.assign({}, state, { [action.section]: action.components });
		default:
			throw new Error();
	}
};

const MuiActionContainer = (props) => {
	const [state, dispatch] = useReducer(
		indexReducer,
		{ left: [], right: [] },
		(x) => x
	);

	const getActionSection = (section) => {
		return state[section];
	};
	const setActionSection = (section, components) => {
		dispatch({
			type: 'add_section',
			section: section,
			components: components,
		});
	};
	// console.log(state);
	const getActionComponents = () => {
		return state;
	};
	const getActionComponent = (action_index, ID) => {
		const action = _.get(state, action_index, null);
		if (action === null) return null;
		const comp = state[action_index].comp();
		const newCompProps = Object.assign({}, comp.props, {
			key: `${action_index}${ID}`,
			onClick: () => comp.props.onClick(ID),
			href: comp.props.href(ID),
		});
		return React.cloneElement(comp, newCompProps);
	};

	return (
		<Context.Provider
			value={{
				getActionComponent,
				getActionSection,
				setActionSection,
				getActionComponents,
			}}
		>
			{props.children}
		</Context.Provider>
	);
};

MuiActionContainer.propTypes = {};
MuiActionContainer.defaultProps = {};

const Section = (props) => {
	const actionContainer = useContext(Context);
	useEffect(() => {
		const components = React.Children.map(props.children, (child) => {
			return child;
		});
		actionContainer.setActionSection(props.section, components);
		// eslint-disable-next-line
	}, []);
	return null;
};
Section.propTypes = {
	section: PropTypes.string,
};
Section.defaultProps = {
	section: 'right',
};

const Content = (props) => {
	return props.children;
};

const RenderSections = (props) => {
	const context = useContext(Context);
	const actionContext = useContext(RealActionContext);
	if (context === null) return null;
	const actions = _.remove(
		_.spread(_.concat)(
			props.sections.map((section) => context.getActionSection(section))
		),
		undefined
	);
	const renderActions = actions.map((action, idx) => {
		const newProps = _.assign({}, action.props, { actionContext });
		const clonedAction = React.cloneElement(action, newProps);
		// console.log('cap',clonedAction.props)
		const Container = props.container;

		return <Container key={idx}>{clonedAction}</Container>;
	});

	// console.log(renderActions);
	return renderActions;
};
RenderSections.propTypes = {
	sections: PropTypes.arrayOf(PropTypes.string),
	container: PropTypes.any,
};

MuiActionContainer.Context = Context;

const ActionContext = {
	Provider: (props) => {
		// eslint-disable-next-line react/prop-types
		const newValue = _.assign({}, props.value, { actionId: props.actionId });
		return (
			<RealActionContext.Provider value={newValue}>
				{props.children}
			</RealActionContext.Provider>
		);
	},
	Consumer: RealActionContext.Consumer,
};
ActionContext.Provider.propTyes = {
	value: PropTypes.any.isRequired,
	actionId: PropTypes.any,
};

const ActionComponent = (props) => {
	return props.children;
};

MuiActionContainer.ActionComponent = ActionComponent;
MuiActionContainer.ActionContext = ActionContext;
MuiActionContainer.Section = Section;
MuiActionContainer.Content = Content;
MuiActionContainer.RenderSections = RenderSections;
MuiActionContainer.TableCell = MuiActionContainerTableCell;
MuiActionContainer.TableRow = MuiActionContainerTableRow;

export { MuiActionContainer };
