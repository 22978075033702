// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import { AmContext } from './AmContext';

const NotLoggedIn = (props) => {
	const am = useContext(AmContext);
	if (am.loggedIn) return null;
	return props.children;
};

export { NotLoggedIn };
