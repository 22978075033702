import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { TrContext } from '@lsoft/translate';
import PropTypes from 'prop-types';
import { MuiLink } from '../MuiLink';
import _ from 'lodash';

const withId = (func) => {
	return ({ children: realChildren, ...props }) => {
		let children;
		let id = null;

		const first_child = _.get(
			realChildren,
			'[0].props.children.[0].props.children'
		);
		if (_.isString(first_child)) {
			if (_.startsWith(first_child, '{#') && _.endsWith(first_child, '}')) {
				children = _.slice(realChildren, 1);
				id = first_child.substring(2, first_child.length - 1);
			}
		} else {
			children = realChildren;
		}

		return func({ children, id, ...props });
	};
};

const renderers = {
	/* eslint-disable-next-line react/prop-types,react/display-name */
	heading: withId(({ level, children, id, ...props }) => {
		let variant;
		let component;

		switch (level) {
			case 1:
				variant = 'h4';
				component = 'h1';
				break;
			case 2:
				variant = 'h5';
				component = 'h2';
				break;
			case 3:
				variant = 'h6';
				component = 'h3';
				break;
			default:
				variant = 'body1';
				component = 'p';
				break;
		}

		return (
			<Typography
				{...props}
				gutterBottom
				variant={variant}
				component={component}
				id={id}
			>
				{children}
			</Typography>
		);
	}),

	// Todo - Add check if external or internal href
	// eslint-disable-next-line react/display-name,react/prop-types
	link: ({ href, children }) => {
		return <MuiLink href={href}>{children}</MuiLink>;
	},
	html: () => null,
	// eslint-disable-next-line react/display-name,no-unused-vars,react/prop-types
	listItem: ({ tight, ...props }) => {
		let newProps = props;
		delete newProps.ordered;
		return <Typography component={'li'} {...newProps} />;
	},
	// listItem: withStyles(styles)(({classes, tight, ...props}) => (
	// 	<li className={classes.listItem}>
	// 		<Typography component="span" {...props} />
	// 	</li>
	// )),
	// eslint-disable-next-line react/display-name
	paragraph: (props) => <Typography {...props} paragraph />,
	// eslint-disable-next-line no-unused-vars,react/display-name
	thematicBreak: (props) => <Divider style={{ margin: '2em 0' }} />,
};

export const MuiMarkdown = ({ tr, ...props }) => {
	const ctx = useContext(TrContext);
	const text = ctx.getTranslationFromData(tr);
	return (
		<ReactMarkdown renderers={renderers} {...props}>
			{text}
		</ReactMarkdown>
	);
};
MuiMarkdown.propTypes = {
	tr: PropTypes.object.isRequired,
};
