import _ from 'lodash';

type AmTokenType = {
  exp: number;
  iss: string;
  accountId: object;
  accountName: string;
  accountOrgIds: string;
  scopesOrgId: string;
  scopes: object;
  originOrgId: string;
  payload: object;
};

const demo_object = {
  exp: '124',
  iss: '124',
  accountId: '124',
  accountName: '124',
  accountOrgIds: '124',
  scopesOrgId: '124',
  scopes: {},
  originOrgId: '124',
  payload: {},
};

export const isAmTokenType = (token: any) => {
  if (_.isNil(token)) return false;
  return (
    typeof token.exp === 'number' &&
    typeof token.iss === 'string' &&
    typeof token.accountId === 'string' &&
    typeof token.accountName === 'string' &&
    typeof token.accountOrgIds === 'object' &&
    typeof token.scopesOrgId === 'string' &&
    typeof token.originOrgId === 'string' &&
    typeof token.scopes === 'object' &&
    typeof token.payload === 'object'
  );
};
