import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../styles/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
	...styles(theme),
}));

const MuiAccordion = ({ children, theme, id, expandedId, setExpandedId }) => {
	const classes = useStyles({ theme });
	const expanded = _.isNil(id) ? null : expandedId === id;
	const onChange = _.isNil(id)
		? () => {}
		: (event, isExpanded) => {
				setExpandedId(isExpanded ? id : null);
		  };
	return (
		<Accordion
			square
			className={clsx(classes.MuiAccordion)}
			expanded={expanded}
			onChange={onChange}
		>
			{children}
		</Accordion>
	);
};
MuiAccordion.propTypes = {
	id: PropTypes.any,
	expandedId: PropTypes.any,
	setExpandedId: PropTypes.func,
};
MuiAccordion.defaultProps = {
	id: null,
};

export { MuiAccordion };
