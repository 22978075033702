import _ from 'lodash';
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useReducer,
} from 'react';
// noinspection ES6CheckImport
import { usePrevious } from '@lsoft/mui';

const debugLog = false;

export const makeSubContainer =
	(Context, initialDataPath, reducer) => (Component) => {
		const subContextName = `SubContext.${Context.displayName}.${initialDataPath}`;

		const realReducer = (state, action) => {
			if (action.type === 'replaceInitialData') {
				if (debugLog)
					console.log(
						`${subContextName}->replaceInitialData->session:`,
						action.payload.sessionId
					);
				return _.assign({}, action.payload.data);
			} else {
				if (!_.isNil(state)) {
					return reducer(state, action);
				}
			}
		};

		// eslint-disable-next-line react/display-name
		const Wrapper = React.memo(({ ...props }) => {
			const { DATA, updatePath, SESSION_ID: sessionId } = useContext(Context);

			// SESSION_ID
			const prevSessionId = usePrevious(sessionId);
			const sessionIdChanged = useMemo(
				() => sessionId !== prevSessionId,
				[sessionId, prevSessionId]
			);

			if (debugLog)
				console.log(
					`${subContextName}->render: ${sessionId} ${
						sessionIdChanged ? `(changed) before ${prevSessionId}` : ''
					}`
				);

			// initialData
			const initialData = _.get(DATA, initialDataPath, null);
			const prevInitialData = usePrevious(initialData);

			const initialDataChanged = useMemo(
				() => !_.isEqual(initialData, prevInitialData),
				[initialData, prevInitialData]
			);

			if (debugLog && initialDataChanged)
				console.log(`${subContextName}->render: init changed.`);

			// state
			const [state, dispatch] = useReducer(realReducer, initialData, (x) => x);
			const prevState = usePrevious(initialData);
			const nilState = _.isNil(state);
			const stateChanged = useMemo(
				() => !_.isEqual(state, prevState),
				[state, prevState]
			);

			if (debugLog && stateChanged)
				console.log(`${subContextName}->render: state changed.`);

			useEffect(() => {
				const dispatchReplaceCartData = async () => {
					if (debugLog)
						console.log(`${subContextName}->useEffect(replaceInitialData)`);
					await dispatch({
						type: 'replaceInitialData',
						payload: { data: initialData, sessionId },
					});
				};

				if (!_.isNil(initialData) && initialDataChanged) {
					dispatchReplaceCartData();
				}
			}, [initialData, initialDataChanged]);

			useEffect(() => {
				if (!nilState && !initialDataChanged && stateChanged) {
					if (debugLog) console.log(`${subContextName}->useEffect(updatePath)`);
					updatePath(initialDataPath, state);
				}
			}, [state]);

			const makeValues = useCallback(
				(values) => {
					return nilState
						? {}
						: {
								...values,
						  };
				},
				[state]
			);

			const componentProps = { ...props, state, dispatch, makeValues };
			return useMemo(() => {
				if (debugLog)
					console.log(`${subContextName}->render->useMemo->Component render`);
				return <Component {...componentProps} />;
			}, [state, props.children]);
		});

		Component.displayName = subContextName;
		return Wrapper;
	};
