/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { funcWrap, validateKeyEventCharacters } from '../../../core';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	root: {},
	pin_code: {
		position: 'relative',
		display: 'inline-flex',
		border: [[1, 'solid', theme.palette.grey[400]]],
		borderRadius: theme.spacing(0.5),
	},
	input: {
		position: 'absolute',
		border: 'none',
		fontSize: 32,
		textAlign: 'center',
		outline: 'none',
		width: 40,
		top: 0,
		bottom: 0,
	},
	digit: {
		borderRight: [[1, 'solid', theme.palette.grey[400]]],
		width: 40,
		height: theme.spacing(7),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 32,
		position: 'relative',
	},
	digit_shadow: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		boxShadow: [['0 0 0 2px', blue[100]]],
	},
	digit_shadow_left: {
		borderBottomLeftRadius: theme.spacing(0.5),
		borderTopLeftRadius: theme.spacing(0.5),
	},
	button: {
		borderRadius: 0,
		width: 40,
	},
}));

const Component = (newProps) => {
	const CODE_LENGTH = new Array(_.get(newProps, 'digits', 6)).fill(0);

	const classes = useStyles();

	const [focused, setFocused] = useState(false);

	const inputRef = useRef();

	const handleClick = () => {
		inputRef.current.focus();
	};

	const handleFocus = () => {
		setFocused(true);
	};

	const handleBlur = () => {
		setFocused(false);
	};

	const handleKeyUp = (e) => {
		if (e.key === 'Backspace') {
			newProps.onChange(newProps.value.slice(0, newProps.value.length - 1));
		}
	};

	const handleKeyDown = (e) => {
		if (e.ctrlKey && e.key === 'v') return; // Allow paste

		const reg = new RegExp(newProps.allowedCharacters);
		if (!reg.test(e.key)) {
			e.preventDefault();
			return;
		}
	};

	const handleChange = (e) => {
		const eventValue = e.target.value;
		newProps.onChange(
			(newProps.value + eventValue).slice(0, CODE_LENGTH.length)
		);
	};

	const handleButtonClick = () => {
		newProps.onChange('');
	};

	const values = newProps.value.split('');

	const selectedIndex =
		newProps.value.length < CODE_LENGTH.length
			? values.length
			: CODE_LENGTH.length - 1;

	const hideInput = !(values.length < CODE_LENGTH.length);

	return (
		<div className={clsx(classes.root)}>
			<div className={clsx(classes.pin_code)} onClick={handleClick}>
				<input
					variant={'text'}
					ref={inputRef}
					value={''}
					onChange={handleChange}
					onKeyUp={handleKeyUp}
					onKeyDown={handleKeyDown}
					onFocus={handleFocus}
					onBlur={handleBlur}
					className={clsx(classes.input)}
					style={{
						left: selectedIndex * 40,
						opacity: hideInput ? 0 : 1,
					}}
				/>
				{CODE_LENGTH.map((v, index) => {
					const selected = values.length === index;
					const filled =
						values.length === CODE_LENGTH.length &&
						index === CODE_LENGTH.length - 1;

					return (
						<div className={clsx(classes.digit)} key={index}>
							{values[index]}
							{(selected || filled) && focused && (
								<div
									className={clsx(classes.digit_shadow, {
										[classes.digit_shadow_left]: index == 0,
									})}
								/>
							)}
						</div>
					);
				})}
				<IconButton
					onClick={handleButtonClick}
					className={clsx(classes.button)}
				>
					<CloseIcon />
				</IconButton>
			</div>
		</div>
	);
};

const getNewProps = (newProps) => {
	newProps.readonly = _.get(newProps, 'readonly', false);
	newProps.disabledOptions = _.get(newProps, 'disabledOptions', []);

	newProps.value = _.isNil(newProps.value) ? '' : newProps.value;

	newProps.allowedCharacters = '^\\d+$';
	newProps.variant = newProps.styleVariant;
	newProps.onChange = funcWrap(
		newProps.onChange,
		(onChange) => async (value) => {
			onChange(value);
		}
	);

	return newProps;
};

export const ic_pin_code = {
	Component,
	getNewProps,
};
