import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { GTMProviderContext } from './GTMProvider';
import _ from 'lodash';
import { getTranslation } from '@lsoft/translate';
import { remove_empty_keys } from '@lsoft/shared/functions/dict';

const debug_log = false;

export const GTMProductListContext = React.createContext(null);
GTMProductListContext.displayName = 'GTMProductListContext';

export const GTMProductList = ({ list, productDetailView, children }) => {
	// noinspection JSCheckFunctionSignatures
	const gtmProviderContext = useContext(GTMProviderContext);
	const { push_to_data_layer } = gtmProviderContext;

	const get_gtm_product = useCallback((data, list_name) => {
		if (_.isNil(data)) return null;
		return remove_empty_keys({
			item_id: data.sku.toString(),
			item_name: !_.isNil(_.get(data, 'name_text', null))
				? getTranslation(_.get(data, 'name_text'), 'en')
				: null,
			item_brand: _.get(data, 'manufacturer_name', null),
			item_list_name: list_name,
			price: _.get(data, 'brutto_sp', null),
			currency: _.get(data, 'currency', 'EUR'),
			quantity: data.AMOUNT,
		});
	}, []);

	const product_impression = useCallback(
		async (product) => {
			if (debug_log) console.log('impression', list, product);
			const dataLayer = {
				event: 'view_item_list',
				ecommerce: {
					items: [product],
				},
			};
			push_to_data_layer(dataLayer);
		},
		[list, push_to_data_layer]
	);

	const product_click = useCallback(
		async (product) => {
			if (debug_log) console.log('product_click', list, product);
			const dataLayer = {
				event: 'select_item',
				ecommerce: {
					items: [product],
				},
			};
			push_to_data_layer(dataLayer);
		},
		[list, push_to_data_layer]
	);

	const product_detail = useCallback(
		async (product) => {
			if (debug_log) console.log('product_detail', list, product);
			const dataLayer = {
				event: 'view_item',
				ecommerce: {
					items: [product],
				},
			};
			push_to_data_layer(dataLayer);
		},
		[list, push_to_data_layer]
	);

	const product_add_to_cart = useCallback(
		async (product) => {
			const dataLayer = {
				event: 'add_to_cart',
				ecommerce: {
					currency: product.CURRENCY,
					items: [product],
					value: product.price,
				},
			};
			if (debug_log) console.log('product_add_to_cart', dataLayer);
			push_to_data_layer(dataLayer);
		},
		[list, push_to_data_layer]
	);

	const product_remove_from_cart = useCallback(
		async (product) => {
			const dataLayer = {
				event: 'remove_from_cart',
				ecommerce: {
					currency: product.CURRENCY,
					items: [product],
					value: product.price,
				},
			};
			if (debug_log) console.log('product_remove_from_cart', dataLayer);
			push_to_data_layer(dataLayer);
		},
		[list, push_to_data_layer]
	);

	const product_increment_cart_position = useCallback(
		async (product) => {
			if (debug_log)
				console.log('product_increment_cart_position', list, product);
			const dataLayer = {
				event: 'add_to_cart',
				ecommerce: {
					items: [product],
				},
			};
			push_to_data_layer(dataLayer);
		},
		[list, push_to_data_layer]
	);

	const product_decrement_cart_position = useCallback(
		async (product) => {
			if (debug_log)
				console.log('product_decrement_cart_position', list, product);
			const dataLayer = {
				event: 'remove_from_cart',
				ecommerce: {
					items: [product],
				},
			};
			push_to_data_layer(dataLayer);
		},
		[list, push_to_data_layer]
	);

	const value = {
		list,
		productDetailView,
		get_gtm_product,
		product_impression,
		product_click,
		product_detail,
		product_add_to_cart,
		product_remove_from_cart,
		product_increment_cart_position,
		product_decrement_cart_position,
		...gtmProviderContext,
	};
	return (
		<GTMProductListContext.Provider value={value}>
			{children}
		</GTMProductListContext.Provider>
	);
};

GTMProductList.propTypes = {
	list: PropTypes.string.isRequired,
	productDetailView: PropTypes.bool,
};
GTMProductList.defaultProps = {
	productDetailView: false,
};
