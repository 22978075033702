import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import _ from 'lodash';
import { MuiPaginationContext } from './MuiPaginationContext';

const md5 = require('md5');
const trs = {
	priceDescending: {
		FR: 'Prix : décroissant',
		DE: 'Preis: absteigend',
		EN: 'Price, descending',
	},
	priceAscending: {
		FR: 'Prix : croissant',
		DE: 'Preis: aufsteigend',
		EN: 'Price, ascending',
	},
};

const getPageData = (viewData, loadMore) => {
	if (_.isNil(viewData))
		return {
			start: 0,
			end: 0,
			page: 0,
			pages: 0,
			isLastPage: true,
		};

	const idsLength = _.get(viewData, 'IDS', []).length;
	let ret = {};

	ret.page = Math.floor(viewData.SKIP / viewData.LIMIT);
	ret.pages = Math.ceil(viewData.MAX_RESULTS / viewData.LIMIT);
	ret.isLastPage = ret.page >= ret.pages;
	if (loadMore) {
		ret.start = 1;
		ret.end = idsLength;
	} else {
		ret.start = viewData.SKIP + 1;
		ret.end = viewData.SKIP + viewData.LIMIT;
		if (ret.end > viewData.MAX_RESULTS) ret.end = viewData.MAX_RESULTS;
	}
	return ret;
};

const PaginationReducer = (state, action) => {
	switch (action.type) {
		case 'setError':
			return _.assign({}, state, { LOADING: false, ERROR: action.payload });
		case 'setLoading':
			return _.assign({}, state, { LOADING: action.payload });
		case 'replaceOptions':
			return action.payload;
		case 'replaceOptionsAndData':
			return _.assign({}, state, action.payload, { LOADING: false });
		case 'loadMore':
			return _.assign({}, state, action.payload, {
				IDS: _.concat(state.IDS, action.payload.IDS),
				LOADING: false,
			});
		default:
			throw new Error(`no reducer for action '${action.type}'`);
	}
};
const MuiPagination = ({
	loadData: realLoadData,
	options,
	sortOptions,
	children,
	loadMore,
	defaultSortOption,
	onPageChange,
	router,
	triggerUseffect,
}) => {
	const initialOptions = _.merge({}, options, { IDS: [], OPTIONS: {} });
	const [state, dispatch] = useReducer(
		PaginationReducer,
		initialOptions,
		(x) => x
	);
	const routerIsDefined = !_.isNil(router);
	const sortOptionByOptionProps = sortOptions.filter((so) => {
		return _.isEqual(so.realValue, options.SORT);
	});
	const sortOptionString =
		sortOptionByOptionProps.length === 1
			? sortOptionByOptionProps[0].value
			: defaultSortOption;

	const [sortStringValue, setSortStringValue] = useState(sortOptionString);
	const [initialized, setInititalized] = useState(false);

	useEffect(() => {
		const as = async () => {
			await loadInit();
			setInititalized(true);
		};
		as();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [triggerUseffect]);

	const pageData = getPageData(state, loadMore);

	const replaceOptionsAndData = useCallback(
		(newOptions) => {
			dispatch({
				type: 'replaceOptionsAndData',
				payload: newOptions,
			});
		},
		[dispatch]
	);

	const mergeData = useCallback(
		(newData) => {
			if (!_.isNil(newData)) {
				dispatch({
					type: 'mergeData',
					payload: newData,
				});
			}
		},
		[dispatch]
	);

	const loadMoreAction = useCallback(
		(options) => {
			dispatch({
				type: 'loadMore',
				payload: options,
			});
		},
		[dispatch]
	);
	const setLoading = useCallback(async (val) => {
		await dispatch({
			type: 'setLoading',
			payload: val,
		});
	}, []);

	const setError = useCallback((e) => {
		dispatch({
			type: 'setError',
			payload: e,
		});
	}, []);

	const loadData = async (SKIP, LIMIT, SORT, OPTIONS) => {
		await setLoading(true);
		try {
			const res = await realLoadData(SKIP, LIMIT, SORT, OPTIONS);
			const { DATA, IDS, MAX_RESULTS } = res;
			const newOptions = _.assign({}, state, {
				SKIP,
				LIMIT,
				SORT,
				OPTIONS,
				IDS,
				MAX_RESULTS,
				DATA,
			});
			await replaceOptionsAndData(newOptions);

			if (routerIsDefined) {
				let newQueryParams = _.assign({}, router.query, {
					SKIP,
					LIMIT,
				});
				if (!_.isNil(SORT)) {
					newQueryParams['SORT'] = JSON.stringify(SORT);
				}
				if (!_.isNil(OPTIONS)) {
					newQueryParams['OPTIONS'] = JSON.stringify(OPTIONS);
				}
				router.replace(
					{
						pathname: router.pathname,
						query: newQueryParams,
					},
					{
						pathname: router.pathname,
						query: newQueryParams,
					},
					{ shallow: true }
				);
			}
		} catch (e) {
			console.log(e);
			setError(e);
		}
	};

	const loadInit = async () => {
		const { SKIP, LIMIT, SORT, OPTIONS } = state;
		const real_skip = _.defaultTo(SKIP, 0);
		return await loadData(real_skip, LIMIT, SORT, OPTIONS);
	};
	//Todo: needs to be adapted to DATA injection.
	const loadMoreData = useCallback(
		async (SKIP, LIMIT, SORT, FILTER) => {
			setLoading(true);
			try {
				const data = await realLoadData(SKIP, LIMIT, SORT, FILTER);
				const newOptions = _.assign({}, state, data, {
					SKIP,
					LIMIT,
					SORT,
					FILTER,
				});
				loadMoreAction(newOptions);
			} catch (e) {
				setError(e);
			}
		},
		[setLoading, state, realLoadData]
	);
	const realLoadMore = async () => {
		let newOptions = _.merge(state, {
			SKIP: state.SKIP + state.LIMIT,
		});

		const { SKIP, LIMIT, SORT, FILTER } = newOptions;
		await loadMoreData(SKIP, LIMIT, SORT, FILTER);
	};

	const loadCurrentPage = () => {
		loadPage(pageData.page);
	};
	const loadCurrentPageWithNewItemsPerPage = (itemsPerPage) => {
		loadPage(pageData.page, itemsPerPage);
	};

	const loadCurrentPageWithNewSort = (sort) => {
		loadPage(pageData.page, null, sort.realValue);
		setSortStringValue(sort.value);
	};

	const loadCurrentPageWithNewFilter = (filter, activated) => {
		let newFilter = _.assign({}, state.OPTIONS.FILTER);
		if (activated) {
			newFilter = _.merge({}, newFilter, filter);
		} else {
			Object.keys(filter.match.fields).map((fieldName) => {
				delete newFilter.match.fields[fieldName];
			});
		}
		loadPage(0, null, null, newFilter);
	};

	const loadPage = async (page, itemsPerPage, sort, filter) => {
		let newOptions = _.merge(state, {
			SKIP: page * state.LIMIT,
		});
		newOptions = _.merge(
			{},
			newOptions,
			!_.isNil(itemsPerPage) ? { LIMIT: itemsPerPage } : {},
			!_.isNil(filter) ? { OPTIONS: { FILTER: filter } } : {}
		);
		if (!_.isNil(sort)) {
			newOptions['SORT'] = sort;
		}

		await loadData(
			newOptions.SKIP,
			newOptions.LIMIT,
			newOptions.SORT,
			newOptions.OPTIONS
		);
		onPageChange();
	};
	const temp =
		_.get(state, 'LIMIT', null) === null
			? options.itemsPerPageDefault
			: state.LIMIT;

	const getContextValues = () => {
		return {
			DATA: state.DATA,
			error: state.ERROR,
			loading: state.LOADING,
			initialized,
			view: state,
			loadInit,
			loadMore: realLoadMore,
			loadPage,
			loadCurrentPage,
			loadCurrentPageWithNewFilter,
			activeFilter: _.get(state, 'OPTIONS.FILTER.match.fields', {}),
			sortOptions: sortOptions,
			sortStringValue: sortStringValue,
			loadCurrentPageWithNewSort,
			itemsPerPage: temp,
			loadCurrentPageWithNewItemsPerPage,
			// setItemsPerPage,
			itemsPerPageOptions: _.get(options, 'itemsPerPageOptions', null),
			...pageData,
		};
	};
	if (!initialized) {
		return null;
	}

	return (
		<MuiPaginationContext.Provider value={getContextValues()}>
			{children}
		</MuiPaginationContext.Provider>
	);
};

MuiPagination.propTypes = {
	preventInitialLoad: PropTypes.bool,
	loadData: PropTypes.func.isRequired,
	options: PropTypes.shape({
		itemsPerPageDefault: PropTypes.number,
		itemsPerPageOptions: PropTypes.array,
		SKIP: PropTypes.number,
		LIMIT: PropTypes.number,
		OPTIONS: PropTypes.object,
	}).isRequired,
	loadMore: PropTypes.bool,
	sortOptions: PropTypes.array,
	defaultSortOption: PropTypes.string,
	onPageChange: PropTypes.func,
	router: PropTypes.object,
	triggerUseffect: PropTypes.any,
};

MuiPagination.defaultProps = {
	preventInitialLoad: true,
	router: null,
	options: {
		itemsPerPageDefault: 20,
	},
	sortOptions: [],
	loadMore: false,
	onPageChange: () => {},
};

export { MuiPagination };
