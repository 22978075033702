import { MuiWebapi } from '@lsoft/mui';
import _ from 'lodash';

const debug_log = true;

export const render_promotions_get = async ({
	org_id,
	render_product_class,
	render_product_version,
}) => {
	const res = await MuiWebapi.get().lsfastapi.render.paths[
		'/render/promotion'
	].get({
		org_id,
		render_class: render_product_class,
		version: render_product_version,
	});
	return _.get(res, ['data'], null);
};
