import React, { Fragment, useEffect, useRef, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { inputProps, makePick, pickDefault } from './input_components';
import _ from 'lodash';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import { funcWrap } from '../../../core/funcWrap';
import { textInputOnKeyDown } from './text';

const pick = makePick(
	[
		'fullWidth',
		'label',
		'value',
		'onKeyDown',
		'onKeyUp',
		// 'InputProps',
		'variant',
		'placeholder',
		'size',
		// 'helperText',
		'onFocus',
		'error',
	],
	pickDefault
);

const Component = (newProps) => {
	const inputLabel = useRef(null);
	const [labelWidth, setLabelWidth] = React.useState(0);
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, [inputLabel]);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const inputProps = {
		...pick(newProps),
		type: showPassword ? 'text' : 'password',
		endAdornment: (
			<InputAdornment position="end">
				<IconButton
					onClick={() => setShowPassword(!showPassword)}
					onMouseDown={handleMouseDownPassword}
					size={'small'}
				>
					{showPassword ? <Visibility /> : <VisibilityOff />}
				</IconButton>
			</InputAdornment>
		),
	};
	return (
		<Fragment>
			<FormControl {..._.pick(newProps, ['fullWidth', 'size'])}>
				<InputLabel ref={inputLabel} variant={newProps.styleVariant} shrink>
					{newProps.label}
				</InputLabel>

				{newProps.variant === 'outlined' ? (
					<OutlinedInput
						labelWidth={labelWidth}
						notched={Boolean(newProps.label)}
						{...inputProps}
					/>
				) : (
					<Input {...inputProps} />
				)}
			</FormControl>
			<FormHelperText
				variant={'outlined'}
				error
				className={clsx(newProps.sharedClasses.MuiFormHelperText)}
			>
				{newProps.helperText}
			</FormHelperText>
		</Fragment>
	);
};
export const ic_password = {
	Component,
	getNewProps: (newProps) => {
		newProps.onEnter = !_.isNull(_.get(newProps, 'onEnter', null))
			? newProps.onEnter
			: () => {};
		newProps.onKeyDown = (e) => textInputOnKeyDown(e, newProps);

		newProps.InputProps = inputProps(newProps);
		if (_.get(newProps, 'onFocusSelectAll', true)) {
			newProps.onFocus = (e) => {
				e.target.select();
			};
		}
		newProps.variant = newProps.styleVariant;
		newProps.onChange = funcWrap(newProps.onChange, (onChange) => async (e) => {
			let val = e.target.value;
			await onChange(val);
		});
		return newProps;

		//	übergebene props abändern
	},
};
