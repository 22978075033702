import React, { useContext } from 'react';
import { MuiGlobalComponent } from '../MuiGlobal';
import { MuiRestrictedError } from '../MuiError';
import { MuiPageContent } from './MuiPageContent';
import { AmContext } from '@lsoft/shared/components/am/AmContext';

const MuiDefaultRestrictedPage = () => {
	const { restrictedScopes } = useContext(AmContext);
	return (
		<MuiPageContent>
			{/*123 test*/}
			<MuiRestrictedError scopes={restrictedScopes} />
		</MuiPageContent>
	);
};

MuiDefaultRestrictedPage.propTypes = {};

MuiDefaultRestrictedPage.defaultProps = {};

export const MuiRestrictedPage = MuiGlobalComponent(
	'_restrcited',
	MuiDefaultRestrictedPage,
);
