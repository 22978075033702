import _ from 'lodash';
import { MuiWebapi } from '@lsoft/mui/src/MuiWebapi';

const debug = false;

export const application_modules_modules_get = async ({ app_name }) => {
	const res = await MuiWebapi.get().lsfastapi.application_modules.paths[
		'/{app_name}/modules'
	].get(app_name);
	if (debug) console.log('application_modules_modules_get res', res);
	if (debug)
		console.log(
			'application_modules_modules_get ret',
			_.get(res, ['data', 'APPLICATION_MODULES'])
		);
	return _.get(res, ['data', 'APPLICATION_MODULES']);
};

export const application_modules_modules_put = async ({
	app_name,
	application_modules,
}) => {
	const res = await MuiWebapi.get().lsfastapi.application_modules.paths[
		'/{app_name}/modules'
	].put({ app_name }, { APPLICATION_MODULES: application_modules });
	if (debug) console.log('application_modules_modules_put res', res);
	return _.get(res, ['data', 'APPLICATION_MODULES']);
};
