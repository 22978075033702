import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { MuiPortal } from '../MuiPortal';

const useStyles = makeStyles((theme) => ({
	MuiFullScreenDialogPortal: {
		width: '100%',
		height: 0,
	},
	MuiFullScreenDialog: {
		background: theme.palette.background.default,
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: theme.zIndexes.MuiFullScreenDialog,
	},
}));

export const MuiFullScreenDialogPortal = ({ children }) => {
	const classes = useStyles();
	return (
		<div
			id={'MuiFullScreenDialogPortal'}
			className={clsx(classes.MuiFullScreenDialogPortal)}
		>
			{children}
		</div>
	);
};

export const MuiFullScreenDialog = ({ children }) => {
	const classes = useStyles();
	return (
		<MuiPortal id={'MuiFullScreenDialogPortal'}>
			<div className={clsx(classes.MuiFullScreenDialog)}>{children}</div>
		</MuiPortal>
	);
};
