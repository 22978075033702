import React, { Fragment, useContext } from 'react';
import { HeaderIconPortalButton } from './HeaderIconPortalButton';
import { createStyles, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';
import SearchIcon from '@material-ui/icons/Search';
import { HeaderIconPortal, HeaderIconPortalPaper } from './HeaderIconPortal';
import { ShopSearchBar } from '../ShopSearch/ShopSearchBar';

const useStyles = makeStyles((theme) =>
	createStyles({
		HeaderIconPortalPaper: {
			background: 'white',
		},
		IconButton: {
			background: grey[100],
			'&:hover': {
				background: grey[200],
			},
		},
	})
);

const HeaderSearchIcon = () => {
	const classes = useStyles();

	const { buttonRef, isOpen, onButtonClick } = useContext(
		HeaderIconPortalButton.Context
	);

	return (
		<Fragment>
			<IconButton
				color={'inherit'}
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={onButtonClick}
				ref={buttonRef}
				className={clsx(classes.IconButton)}
			>
				<SearchIcon />
			</IconButton>
			{isOpen && (
				<HeaderIconPortal>
					<HeaderIconPortalPaper
						className={clsx(classes.HeaderIconPortalPaper)}
					>
						<ShopSearchBar />
					</HeaderIconPortalPaper>
				</HeaderIconPortal>
			)}
		</Fragment>
	);
};

HeaderSearchIcon.propTypes = {};
HeaderSearchIcon.defaultProps = {};

export { HeaderSearchIcon };
