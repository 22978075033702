module.exports = {
	...require('./MuiPagination'),
	...require('./MuiPaginationBasicTable'),
	...require('./MuiPaginationContext'),
	...require('./MuiPaginationIterator'),
	...require('./MuiPaginationPageSelector'),
	...require('./MuiPaginationItemsPerPageSelector'),
	...require('./MuiPaginationInfo'),
	...require('./MuiPaginationLoadingIndicator'),
	...require('./MuiPaginationSort'),
	...require('./MuiPaginationFilter'),
	...require('./MuiPaginationServerSideProps'),
};
