import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { AmContext } from './AmContext';
import { PropTypes } from 'prop-types';
import jwt from 'jsonwebtoken';
import _ from 'lodash';
import { isAmTokenType } from './AmTypeCheck';

const AmReducer = (state, action) => {
	switch (action.type) {
		case 'changeLoginSubscriptions':
			return _.assign({}, state, {
				loginSubscriptions: action.payload.subscribed
					? _.union(state.loginSubscriptions, [action.payload.id])
					: _.without(state.loginSubscriptions, action.payload.id),
			});
		case 'changeRestrictedSubscription':
			return _.assign({}, state, {
				restrictedScopesData: action.payload.subscribed
					? _.assign({}, state.restrictedScopesData, {
							[action.payload.id]: action.payload.scopes,
						})
					: _.omit(state.restrictedScopesData, [action.payload.id]),
			});
		default:
			throw new Error(`no reducer for action '${action.type}'`);
	}
};

const AmProvider = ({
	am_auth_token,
	children,
	deleteToken = () => {},
	renderLogin = () => {},
	renderOnRestriction = () => null,
}) => {
	const [state, dispatch] = useReducer(AmReducer, {
		loginSubscriptions: [],
		restrictedScopesData: {},
	});
	const restrictedScopes = _.union(
		...Object.values(state.restrictedScopesData),
	);
	const restrictedRender = restrictedScopes.length !== 0;
	const loginNeeded = state.loginSubscriptions.length !== 0;
	const token = jwt.decode(am_auth_token);
	// verify needs the public key.
	// const verify = jwt.verify(am_auth_token);
	const is_am_token_type = isAmTokenType(token);
	const scopes = token != null ? token.scopes : null;
	const loggedIn =
		token != null && token.exp > Date.now() / 1000 && is_am_token_type;

	useEffect(() => {
		if (!loggedIn) {
			deleteToken('_am_auth_token');
		}
	}, [am_auth_token]);

	useEffect(() => {
		renderLogin(loginNeeded);
	}, [loginNeeded]);

	const changeLoginSubscription = useCallback(
		async (id, subscribed) => {
			if (!loggedIn) {
				dispatch({
					type: 'changeLoginSubscriptions',
					payload: { id, subscribed },
				});
			}
		},
		[loggedIn],
	);

	const changeRestrictedSubscription = useCallback(
		(id, subscribed, scopes) => {
			if (loggedIn) {
				dispatch({
					type: 'changeRestrictedSubscription',
					payload: {
						id,
						subscribed,
						scopes,
					},
				});
			}
		},
		[loggedIn],
	);

	const getTokenValue = useCallback(
		(name) => {
			if (token == null) return null;
			return _.get(token, name, 'undefined');
		},
		[token],
	);

	const unfulfilledScopes = useCallback(
		(demandedScopes) => {
			//no scopes demanded? access!
			if (demandedScopes.length === 0) return [];
			if (_.get(scopes, 'admin', '') === 'rwcd') return [];
			return demandedScopes.filter((ds) => {
				if (!ds.includes(':')) ds = ds + ':r';
				const [ar, mode] = ds.split(':');
				const scopeNotMatch = !(ar in scopes);
				if (scopeNotMatch) return true;
				const modeNotMatch = !scopes[ar].startsWith(mode);
				if (modeNotMatch) return true;
				return false;
			});
		},
		[token, scopes],
	);
	const scopesMatching = useCallback(
		(demandedScopes) => {
			//demandedScopes but no token? no access!
			if (token == null) return false;
			const unfulfilled = unfulfilledScopes(demandedScopes);
			return unfulfilled.length === 0;
		},
		[token],
	);

	const value = useMemo(() => {
		return {
			currentUser: token != null ? token.accountName : null,
			loggedIn,
			scopes,
			scopesMatching,
			getTokenValue,
			loginNeeded,
			changeLoginSubscription,
			restrictedRender,
			restrictedScopes,
			am_auth_token,
			changeRestrictedSubscription,
			unfulfilledScopes,
			renderOnRestriction,
		};
	}, [
		token,
		loggedIn,
		scopes,
		scopesMatching,
		getTokenValue,
		loginNeeded,
		changeLoginSubscription,
		restrictedRender,
		restrictedScopes,
		am_auth_token,
		changeRestrictedSubscription,
		unfulfilledScopes,
		renderOnRestriction,
	]);

	return <AmContext.Provider value={value}>{children}</AmContext.Provider>;
};

AmProvider.propTypes = {
	am_auth_token: PropTypes.string,
	renderOnRestriction: PropTypes.func,
	renderLogin: PropTypes.func,
};

AmProvider.defaultProps = {
	am_auth_token: null,
	renderOnRestriction: () => null,
	renderLogin: () => {},
};

export { AmProvider };
