import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const MuiSelectionContext = React.createContext(null);
MuiSelectionContext.displayName = 'MuiSelectionContext';

const MuiSelection = ({ children, payloadCheck }) => {
	const [data, setData] = useState({ ids: [], payload: null });
	const clearSelection = () => {
		setData({ ids: [], payload: null });
	};
	const isSelected = (id) => _.includes(data.ids, id);
	const setSelected = (id, state, payload = null) => {
		const newIds = state ? _.union(data.ids, [id]) : _.pull(data.ids, id);
		// console.log(state, data.ids, newIds);
		const newData = {
			ids: newIds,
			payload: newIds.length === 0 ? null : payload
		};
		setData(newData);
	};
	const isSelectable = (payload) =>
		_.isNil(data.payload) || payloadCheck(data.payload, payload);

	const getValues = () => {
		return {
			ids: _.get(data, 'ids', []),
			isSelectable,
			isSelected,
			setSelected,
			clearSelection
		};
	};
	return (
		<MuiSelectionContext.Provider value={getValues()}>
			{children}
		</MuiSelectionContext.Provider>
	);
};
MuiSelection.propTypes = {
	payloadCheck: PropTypes.func
};
MuiSelection.defaultProps = {
	payloadCheck: _.isEqual
};

export { MuiSelection, MuiSelectionContext };
