import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import { MuiGlobalComponent } from '../MuiGlobal';
import { MuiAppContext } from '../MuiApp';
import { TrContext } from '@lsoft/translate';
import Head from 'next/head';
import _ from 'lodash';
import { ApplicationModulesEnabled } from '@lsoft/shared/components/ApplicationModules/ApplicationModulesEnabled';
import { MuiRestrictedPage } from './MuiRestrictedPage';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { MuiFullScreenDialogPortal } from '../MuiFullScreenDialog/MuiFullScreenDialog';
import {
	LoadingOverlay,
	LoadingOverlayContainer,
} from '@lsoft/shared/LoadingOverlay';
import { Restricted } from '@lsoft/shared/components/am/Restricted';
import { RestrictedRender } from '@lsoft/shared/components/am/RestrictedRender';

const useStyles = makeStyles((theme) => ({
	// ...styles(theme),
	RestrictedRenderDiv: {
		zIndex: 2000,
		position: 'fixed',
		width: '100%',
		height: '100%',
	},
	MuiPage: {
		backgroundColor: theme.palette.background.default,
		flex: [[1, 0]],
		height: `calc(100vh - ${theme.sizes.appBarHeight}px)`, // Todo: Is there a variable height for the toolbar?
		position: 'relative',
	},
	pageScroll: {
		flexGrow: 1,
		overflowY: 'overlay',
	},
	contentPadding: {
		padding: theme.spacing(3),
	},
	contentHidden: {
		display: 'none',
	},
}));

export const MuiDefaultPage = ({
	children,
	theme,
	application_modules,
	noLogin,
	noPadding,
	noPageScroll,
	scopes,
	...pageProps
}) => {
	const classes = useStyles({ theme });
	const {
		appInfo: { title },
		scrollRef,
	} = useContext(MuiAppContext);
	const { activeLanguage, getTranslationFromData } = useContext(TrContext);
	const restrictedProps = {
		noLogin,
		scopes,
	};
	const pageTitle = getTranslationFromData(
		_.get(pageProps, 'pageInfo.title', {}),
	);
	return (
		<Fragment>
			<Head>
				<title>
					{title}&nbsp;|&nbsp;{pageTitle}
				</title>
				<meta httpEquiv={'Content-type'} content={'text/html; charset=utf-8'} />
				<meta httpEquiv={'content-language'} content={activeLanguage} />
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<link rel={'icon'} type={'image/png'} href={'/images/favicon.png'} />
			</Head>
			<ApplicationModulesEnabled paths={application_modules}>
				<Restricted {...restrictedProps} renderRestricted>
					<LoadingOverlayContainer>
						<LoadingOverlay />
						<div
							className={clsx(classes.MuiPage, {
								[classes.pageScroll]: !noPageScroll,
								[classes.contentPadding]: !noPadding,
								[classes.contentHidden]: false,
							})}
							ref={scrollRef}
						>
							<MuiFullScreenDialogPortal />
							{children}
						</div>
					</LoadingOverlayContainer>
				</Restricted>
				<RestrictedRender>
					<div className={clsx(classes.RestrictedRenderDiv)}>
						<div
							className={clsx(classes.MuiPage, {
								[classes.pageScroll]: !noPageScroll,
								[classes.contentPadding]: !noPadding,
							})}
						>
							<MuiRestrictedPage />
						</div>
					</div>
				</RestrictedRender>
			</ApplicationModulesEnabled>
		</Fragment>
	);
};

MuiDefaultPage.propTypes = {
	noLogin: PropTypes.bool,
	scopes: PropTypes.array,
	application_modules: PropTypes.array,
	documentName: PropTypes.any,
	noPadding: PropTypes.bool,
	noPageScroll: PropTypes.bool,
};

MuiDefaultPage.defaultProps = {
	noLogin: false,
	scopes: [],
	application_modules: [],
	noPadding: false,
	noPageScroll: false,
};

export const MuiPage = MuiGlobalComponent('_page', MuiDefaultPage);
