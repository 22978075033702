import { createTheme } from '@material-ui/core/styles';
import { blueGrey, teal, red } from '@material-ui/core/colors';

const theme = createTheme();

export const MuiDefaultTheme = {
	// Basic Theme Parameters
	palette: {
		// type: 'dark',
		primary: teal,
		secondary: blueGrey,
		tableError: {
			light: red[100],
			dark: red[300],
		},
	},
	// Custom Theme Parameters
	sizes: {
		drawerWidth: 240,
		appBarHeight: 64,
		sideBarWidth: 240,
	},
	// typography: {
	// 	fontWeightRegular: 300,
	// 	fontWeightMedium: 300,
	// 	fontWeightBold: 300,
	// },
	zIndexes: {
		Header: 100,
		MuiFullScreenDialog: 105,
		LoadingOverlay: 200,
		SearchAutocomplete: 110,
		MuiLoadable: 200,
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				// html: {
				// 	WebkitFontSmoothing: 'auto',
				// },
				'#__next': {
					height: '100%',
				},
				'*::-webkit-scrollbar': {
					width: theme.spacing(1),
				},
				'*::-webkit-scrollbar-track': {
					boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
					webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
				},
				'*::-webkit-scrollbar-thumb': {
					backgroundColor: 'rgba(0,0,0,.25)',
					// borderRadius: theme.spacing(0.5),
					// backgroundColor: theme.palette.secondary[200],
					// outline: [[1, 'solid', 'rgba(0,0,0,.5)']],
				},
				'.MuiLink-root': {
					cursor: 'pointer',
				},
				':focus': {
					outline: 0,
				},
			},
		},
	},
};
