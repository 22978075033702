// noinspection ES6CheckImport
import { MuiAppContext, MuiCookie, MuiGlobal } from '@lsoft/mui';
import React, {
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import _, { isNil } from 'lodash';
// noinspection ES6CheckImport
import { Session } from '../container/shop/Session';
import { customerContactVerify } from '../tools/customerContactVerify';
import { shop_customer_register } from '@lsoft/shared/backend/shop/shop';
import { useRouter } from 'next/router';
import { AmContext } from '@lsoft/shared/components/am/AmContext';

const muiAppReducer = (state, action) => {
	switch (action.type) {
		case 'setRenderLogin':
			return _.assign({}, state, { renderLogin: action.payload.renderLogin });
		case 'logout':
			return _.assign({}, state, { token: null });
		case 'login':
			return _.assign({}, state, {
				token: action.payload.token,
				loginSubscriptions: [],
			});
		default:
			throw new Error(`no reducer for action '${action.type}'`);
	}
};

export const ShopAppContainer = ({
	children,
	loginFunc,
	logoutFunc,
	...appProps
}) => {
	const [scrollRef, setScrollRef] = useState(null);
	const { push } = useRouter();

	const { currentUser, loginNeeded } = useContext(AmContext);
	const { replaceSession } = useContext(Session.Context);
	const { setCookie, deleteCookie, _am_auth_token, technicalCookies } =
		useContext(MuiCookie.Context);

	const {
		availableApplicationModules,
		availableMenuEntries,
		appTitle,
		appVersion,
		appName,
		webapi,
	} = MuiGlobal.getAll();

	useEffect(() => {
		setRenderLogin(loginNeeded);
	}, [loginNeeded]);

	const scrollTop = () => {
		if (!_.isNil(scrollRef) && !_.isNil(scrollRef.current)) {
			scrollRef.current.scrollTop = 0;
		}
	};

	const [state, dispatch] = useReducer(muiAppReducer, {
		token: _am_auth_token,
		loginSubscriptions: [],
	});

	const setRenderLogin = useCallback(
		(renderLogin) => {
			dispatch({
				type: 'setRenderLogin',
				payload: { renderLogin },
			});
		},
		[technicalCookies],
	);

	const logout = async () => {
		/* Ticket #248
		 * Do not change order without testing.
		 * Removing cookie after logout results in 422 on some pages
		 * e.g. /account/orders
		 * */
		deleteCookie('_am_auth_token');
		await logoutFunc();
		await dispatch({
			type: 'logout',
		});
		webapi.access_token = null;
	};

	const login = async ({ password, username }) => {
		const webapiOptions = {
			jsonBody: {
				PASSWORD: password,
				USERNAME: username,
			},
		};

		const ret = _.isNil(loginFunc)
			? await webapi.login(webapiOptions)
			: await loginFunc({ password, username });

		const token = _.get(ret, ['access_token'], null);

		if (!isNil(token)) {
			webapi.access_token = token;
			setCookie('_am_auth_token', token);
			dispatch({
				type: 'login',
				payload: {
					token: token,
				},
			});
		}
		return ret;
	};

	const replaceSessionAndSetCookie = useCallback(
		async (session, am_auth_token) => {
			webapi.access_token = am_auth_token;
			await setCookie('_am_auth_token', am_auth_token);
			await dispatch({
				type: 'login',
				payload: {
					token: am_auth_token,
				},
			});
			await replaceSession(session);
		},
		[webapi, dispatch, replaceSession, setCookie],
	);

	const register_and_replace_session_and_set_cookie = useCallback(
		async (formdata) => {
			const { session, am_auth_token } = await shop_customer_register({
				app_name: appName,
				form_data: formdata,
			});
			await replaceSessionAndSetCookie(session, am_auth_token);
		},
		[appName, replaceSessionAndSetCookie],
	);

	const verifyAndReplaceSessionAndSetCookie = useCallback(
		async (data) => {
			const result = await customerContactVerify(
				webapi,
				appName,
				data.EMAIL,
				data.ACCOUNT_VERIFICATION_CODE,
			);

			if (!result.successful) {
				throw result.error;
			}

			const session = _.get(result, 'appProps.session', null);
			const am_auth_token = _.get(result, 'appProps.am_auth_token', null);
			if (!_.isNil(session) && !_.isNil(am_auth_token)) {
				await replaceSessionAndSetCookie(session, am_auth_token);
			}
		},
		[appName, webapi, replaceSessionAndSetCookie],
	);

	const value = _.assign({}, appProps, {
		appName,
		appInfo: {
			title: appTitle,
			version: appVersion,
		},
		loginInfo: {
			token: state.token,
			renderLogin: state.renderLogin,
			// renderLogin: state.renderLogin,
			setRenderLogin: setRenderLogin,
			// renderOnRestriction: (scopes) => <MuiRestrictedError scopes={scopes} />,
		},
		userInfo: {
			accountName: currentUser,
		},
		availableApplicationModules,
		availableMenuEntries,
		login,
		logout,
		register_and_replace_session_and_set_cookie,
		verifyAndReplaceSessionAndSetCookie,
		scrollTop,
		scrollRef,
		setScrollRef: setScrollRef,
	});
	return (
		<MuiAppContext.Provider value={value}>{children}</MuiAppContext.Provider>
	);
};

ShopAppContainer.propTypes = {
	currentUser: PropTypes.any,
	am_auth_token: PropTypes.string,
	loginFunc: PropTypes.func,
	logoutFunc: PropTypes.func,
};
