export const trDoYouHaveAnyQuestions = {
	DE: 'Sie haben Fragen?',
	EN: 'Do you have any questions?',
	FR: 'Vous avez des questions ?',
};
export const trCurrentSales = {
	DE: 'Aktuelle Angebote',
	EN: 'Current Sales',
	FR: 'Offres actuelles',
};
