export const MuiSearchWidgetStyles = (theme) => ({
	search: {
		position: 'relative',
	},
	searchInput: {
		borderRadius: theme.shape.borderRadius,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.primary[600],
		zIndex: 400,
		backgroundColor: theme.palette.primary[400],
	},
	SearchInputField: {
		'& .MuiInputBase-root': {
			color: theme.palette.primary.contrastText,
		},
		'& fieldset': {
			border: 'none',
		},
	},
	searchIcon: {
		color: 'inherit',
		zIndex: 500,
	},
	inputInput: {
		padding: theme.spacing(1.75),
		paddingLeft: theme.spacing(1),
		width: '100%',
		color: theme.palette.primary.contrastText,
	},
});
