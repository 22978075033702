// noinspection ES6CheckImport
import { MuiWebapi } from '@lsoft/mui';
import _ from 'lodash';

export const erp_promotion_get = async ({ name }) => {
	// noinspection JSUnresolvedVariable
	const res = await MuiWebapi.get().lsfastapi.erp.paths[
		'/promotion/{name}'
	].get({
		name,
	});
	return _.get(res, `data`, null);
};
