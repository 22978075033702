import React, { useContext } from 'react';
import { MuiPaginationContext } from './MuiPaginationContext';
import _ from 'lodash';
import Pagination from '@material-ui/lab/Pagination';

// eslint-disable-next-line no-unused-vars
const MuiPaginationPageSelector = (props) => {
	const { page, pages, loadPage, initialized, loading } = useContext(
		MuiPaginationContext
	);
	if (_.isNil(initialized) || !initialized) {
		return null;
	}

	return (
		<Pagination
			count={pages}
			page={page + 1}
			color={'primary'}
			size="small"
			showFirstButton
			showLastButton
			onChange={(d, page) => {
				// console.log(page);
				if (!loading) {
					// console.log('debounce');
					loadPage(page - 1);
				}
			}}
			// disabled={loading}
		/>
	);
};

export { MuiPaginationPageSelector };
