import { MuiStyles } from '../MuiStyles';
// import { CommonTableColumnsStyles } from './CommonTableColumns.styles';

const menuWidth = 160;
const styles = (theme) => ({
	...MuiStyles(theme),
	// ...CommonTableColumnsStyles(theme),
	padding: {
		padding: theme.spacing(1),
	},
	taRight: {
		textAlign: 'right',
	},
	taCenter: {
		textAlign: 'center',
	},
	redBorder: {
		border: '1px solid red',
	},
	AppBar: {
		height: theme.spacing(8),
		paddingLeft: menuWidth,
		// zIndex: 2000
	},
	PageContainer: {
		padding: 0,
		paddingLeft: menuWidth,
		'& .MuiPaper-root': {
			padding: theme.spacing(1),
		},
	},
	Drawer: {
		'& .MuiPaper-root': {
			color: theme.palette.primary.contrastText,
			background: theme.palette.primary.dark,
		},
		'& .MuiDivider-root': {
			background: theme.palette.primary.main,
		},
	},
	Menu: {
		minWidth: menuWidth,
		width: menuWidth,
		'& .MuiListItemText-root': {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	},
	MenuTitleVersion: {
		height: theme.spacing(8),
		color: theme.palette.primary.contrastText,
		background: theme.palette.primary.dark,
	},
	/** GirdSpacingFix - Workaround for a @material-ui behaviour
	 * Grid spacing prop adds extra width which could lead to a horizontal scrollbar
	 * Use if container should be 100% width (like MuiBasicTable)
	 * Issue: https://github.com/mui-org/material-ui/issues/7466 */
	GirdSpacingFix: {
		margin: 0,
		width: '100%',
	},
});

export default styles;
