import React, { useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MuiPaginationContext } from './MuiPaginationContext';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import styles from '../styles/styles';

const useStyles = makeStyles((theme) => ({
	...styles(theme),
	loaderWrapper: {
		position: 'relative',
		height: 0,
		width: '100%',
	},
	loader: {
		height: theme.spacing(0.75),
		position: 'absolute',
		right: 0,
		bottom: '-' + theme.spacing(0.75),
		left: 0,
		zIndex: 200,
	},
}));

const MuiPaginationLoadingIndicator = () => {
	const { initialized, loading } = useContext(MuiPaginationContext);
	const classes = useStyles({});
	if (_.isNil(initialized) || _.isNil(loading)) return null;
	const show = !(initialized && !loading);
	if (!show) return null;
	return (
		<Fade
			in={show}
			timeout={{
				enter: 350,
				exit: 0,
			}}
			unmountOnExit
		>
			<div className={clsx(classes.loaderWrapper)}>
				<LinearProgress variant={'query'} className={clsx(classes.loader)} />
			</div>
		</Fade>
	);
};

export { MuiPaginationLoadingIndicator };
