import React, { Fragment, useContext } from 'react';
import {
	Box,
	Collapse,
	createStyles,
	Grid,
	makeStyles,
	MenuItem,
} from '@material-ui/core';
import { MuiAppContext, MuiLink, trmLanguages } from '@lsoft/mui';
import { HeaderIconPortal, HeaderIconPortalPaper } from './HeaderIconPortal';
import MenuList from '@material-ui/core/MenuList';
import clsx from 'clsx';
import { getTranslation, Tr } from '@lsoft/translate';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import { HeaderIconPortalButton } from './HeaderIconPortalButton';
import { TagNavVertical } from '../TagNavigation/TagNavVertical';
import { useRouter } from 'next/router';
import _ from 'lodash';
import { Customer } from '../../container/shop/Customer';
import Typography from '@material-ui/core/Typography';
import LanguageIcon from '@material-ui/icons/Language';
import AccountIcon from '@material-ui/icons/Person';
import {
	trAccount,
	trChangeLanguage,
	trCreateAccount,
	trLanguage,
	trMyAccount,
	trSignIn,
	trSignOut,
	trYouHaveSuccessfullyLoggedOut,
} from './Header.tr';
import { grey } from '@material-ui/core/colors';
import { MuiCookieTooltip } from '../../../libs/mui/MuiCookieTooltip';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import { LoggedIn } from '@lsoft/shared/components/am/LoggedIn';
import { NotLoggedIn } from '@lsoft/shared/components/am/NotLoggedIn';

const useStyles = makeStyles((theme) =>
	createStyles({
		headerIconPortalPaper: {
			[theme.breakpoints.up('sm')]: {
				minWidth: 444,
			},
		},
		listCartPositions: {
			width: '100%',
		},
		secondaryMenuList: {
			padding: [[theme.spacing(1), 0, theme.spacing(1), 0]],
		},
		listHeaderDense: {
			lineHeight: 1.48,
		},
		CustomerMenu: {
			width: '100%',
			background: grey['200'],
		},
	}),
);

const Account = () => {
	const { locale } = useRouter();
	const { close } = useContext(HeaderIconPortalButton.Context);
	const classes = useStyles();

	const throwSnackbar = useShopSnackbar();

	const { loginInfo, logout } = useContext(MuiAppContext);

	const { setRenderLogin } = loginInfo;

	const handleLogin = () => {
		setRenderLogin(true);
	};

	const handleLogout = async () => {
		await logout();
		throwSnackbar({
			msg: getTranslation(trYouHaveSuccessfullyLoggedOut, locale),
			variant: 'success',
		});
		close();
	};

	return (
		<MenuList
			dense
			className={clsx(classes.secondaryMenuList)}
			subheader={
				<ListSubheader disableSticky className={clsx(classes.listHeaderDense)}>
					<Grid
						container
						direction={'row'}
						justifyContent={'flex-start'}
						spacing={2}
					>
						<Grid item>
							<AccountIcon fontSize={'small'} />
						</Grid>
						<Grid item>
							<Typography variant={'inherit'}>
								<Tr tr={trAccount} />
							</Typography>
						</Grid>
					</Grid>
				</ListSubheader>
			}
			component={'nav'}
		>
			<LoggedIn>
				<MenuItem>
					<MuiLink
						fullWidth
						href={'/account/profile'}
						linkprops={{
							onClick: close,
							color: 'textPrimary',
						}}
					>
						<Typography variant={'inherit'}>
							<Tr tr={trMyAccount} />
						</Typography>
					</MuiLink>
				</MenuItem>
				<MenuItem onClick={handleLogout}>
					<Typography variant={'inherit'}>
						<Tr tr={trSignOut} />
					</Typography>
				</MenuItem>
			</LoggedIn>
			<NotLoggedIn>
				<MuiCookieTooltip>
					<MenuItem onClick={handleLogin}>
						<Tr tr={trSignIn} />
					</MenuItem>
				</MuiCookieTooltip>
				<MuiCookieTooltip>
					<MenuItem>
						<MuiLink
							fullWidth
							href={'/register/sign_up'}
							linkprops={{
								onClick: close,
								color: 'textPrimary',
							}}
						>
							<Tr tr={trCreateAccount} />
						</MuiLink>
					</MenuItem>
				</MuiCookieTooltip>
			</NotLoggedIn>
		</MenuList>
	);
};

const Translation = () => {
	const classes = useStyles();

	const [translationOpen, setTranslationOpen] = React.useState(false);

	const handleClick = () => {
		setTranslationOpen(!translationOpen);
	};

	const handleClose = () => {
		setTranslationOpen(false);
	};

	const { locale, locales, asPath, push } = useRouter();
	const tr = _.get(trmLanguages, 'tr.language');
	const { setLanguageCode } = useContext(Customer.Context);

	const menuItemOnClick = (lc) => () => {
		// noinspection JSIgnoredPromiseFromCall
		push(asPath, asPath, { locale: lc });
		if (_.isFunction(setLanguageCode)) setLanguageCode(lc);
		handleClose();
	};

	const options = locales
		.filter((lc) => lc !== locale)
		.map((lc) => {
			return (
				<MenuItem key={lc} onClick={menuItemOnClick(lc)}>
					<Typography>
						<Tr tr={tr.Languages[lc.toUpperCase()]} />
					</Typography>
				</MenuItem>
			);
		});

	return (
		<Fragment>
			<MenuList
				dense
				className={clsx(classes.secondaryMenuList)}
				subheader={
					<ListSubheader
						disableSticky
						className={clsx(classes.listHeaderDense)}
					>
						<Grid container direction={'row'} spacing={2}>
							<Grid item>
								<LanguageIcon className={clsx(classes.translateIcon)} />
							</Grid>
							<Grid item>
								<Tr tr={trLanguage} />
							</Grid>
						</Grid>
					</ListSubheader>
				}
				component={'nav'}
			>
				<MenuItem onClick={handleClick}>
					<Tr tr={trChangeLanguage} />
				</MenuItem>
			</MenuList>
			<Collapse in={translationOpen}>
				<Divider />
				<MenuList>{options}</MenuList>
			</Collapse>
		</Fragment>
	);
};

const CustomerMenu = () => {
	const classes = useStyles();
	return (
		<Box className={clsx(classes.CustomerMenu)}>
			<Grid container spacing={0}>
				<Grid item xs={6} md={6}>
					<Account />
				</Grid>
				<Grid item xs={6} md={6}>
					<Translation />
				</Grid>
			</Grid>
		</Box>
	);
};

export const HeaderHamburgerMenuContent = ({ theme }) => {
	const classes = useStyles(theme);
	return (
		<HeaderIconPortal>
			<HeaderIconPortalPaper className={clsx(classes.headerIconPortalPaper)}>
				<Grid container>
					<Grid item xs={12}>
						<CustomerMenu />
					</Grid>
					<Grid item xs={12}>
						<TagNavVertical />
					</Grid>
				</Grid>
			</HeaderIconPortalPaper>
		</HeaderIconPortal>
	);
};
