import React, { useCallback, useContext, useRef } from 'react';
import { HeaderContext } from './Header';
import _ from 'lodash';
import PropTypes from 'prop-types';

export const HeaderIconPortalButtonContext = React.createContext(null);
HeaderIconPortalButtonContext.displayName = "HeaderIconPortalButtonContext";

export const HeaderIconPortalButton = ({ children, name }) => {
	const {
		selectedIconPortal,
		setSelectedIconPortal,
		activeIconRef,
	} = useContext(HeaderContext);

	const buttonRef = useRef(null);
	const isOpen = selectedIconPortal === name;
	const close = useCallback(() => {
		setSelectedIconPortal(null);
	}, [setSelectedIconPortal]);
	const onButtonClick = useCallback(() => {
		if (_.isNil(selectedIconPortal) || selectedIconPortal !== name) {
			activeIconRef.current = buttonRef.current;
			setSelectedIconPortal(name);
		} else {
			setSelectedIconPortal(null);
		}
	}, [
		selectedIconPortal,
		setSelectedIconPortal,
		activeIconRef,
		buttonRef,
		name,
	]);

	const value = {
		buttonRef,
		isOpen,
		close,
		onButtonClick,
	};

	return (
		<HeaderIconPortalButtonContext.Provider value={value}>
			{children}
		</HeaderIconPortalButtonContext.Provider>
	);
};
HeaderIconPortalButton.propTypes = {
	name: PropTypes.string.isRequired,
};
HeaderIconPortalButton.Context = HeaderIconPortalButtonContext;
