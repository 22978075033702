import _ from 'lodash';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { applyConverter } from '../../Converter';
import { controlKeys } from '../../../core';

export const pickDefault = [
	'disabled',
	'id',
	'className',
	'onChange',
	'type',
	'autoFocus',
];

export const makePick = (...args) => {
	return (props) => _.pick(props, _.union(_.concat(pickDefault, ...args)));
};

export const validateShortcuts = (e, props) => {
	const reg = RegExp(props.disabledShortcuts);
	let ret = true;

	if (props.disabledShortcuts != null) {
		if (e.ctrlKey) {
			if (reg.test(e.keyCode)) {
				ret = false;
			}
		}
	}

	return ret;
};

export const inputProps = (props) => {
	return Object.assign(
		{},
		props.InputProps,
		!_.has(props, 'prefix')
			? null
			: {
					startAdornment: (
						<InputAdornment position="start">{props.prefix}</InputAdornment>
					),
			  },
		!_.has(props, 'suffix')
			? null
			: {
					endAdornment: (
						<InputAdornment position="end">{props.suffix}</InputAdornment>
					),
			  },
		!_.has(props, 'readOnly')
			? null
			: {
					readOnly: props.readOnly,
			  }
	);
};

export const fromValue = (props) => {
	let value = props.value;
	if (_.isNil(value)) {
		if (props._inputVariant === 'text' || props._inputVariant === 'password')
			value = '';
		if (props._inputVariant === 'number') {
			if (props.allow_null) {
				value = '';
			} else {
				value = 0;
			}
		}
	}
	return value;
};

export const toValue = (value, props) => {
	value = applyConverter(value, props.converter, 'TO', props);
	if (value === props.optionalEmpty && props.optionalReturnEmptyNull) {
		value = null;
	}
	return value;
};
