export const trThankYouForYourOrder = {
	DE: 'Vielen Dank für Ihre Bestellung',
	EN: 'Thank you for your order',
	FR: 'Nous vous remercions de votre commande',
};

export const trThankYou = {
	DE: 'Vielen Dank!',
	EN: 'Thank you!',
	FR: 'Merci!',
};

export const trThankYouForBuying = {
	DE: 'Vielen Dank für Ihren Einkauf',
	EN: 'Thank you for shopping',
	FR: 'Merci de vos achats',
};

export const trWeHaveReceivedYourOrder = {
	DE: 'Ihre Bestellung ist bei uns eingegangen und wird zur Zeit bearbeitet. Sie erhalten eine Bestätigung per E-Mail. Die Auftragsbestätigung erfolgt separat.',
	EN: 'We have received your order and are currently processing it. You will receive a notification by e-mail. The order confirmation will be sent separately.',
	FR: 'Nous avons reçu votre commande et nous sommes en train de la traiter. Vous recevrez une notification par courrier électronique. La confirmation de la commande sera envoyée séparément.',
};

export const trYourOrderNumber = {
	DE: 'Ihre Bestellnummer',
	EN: 'Your order number',
	FR: 'Votre numéro de commande',
};

export const trOrderNumber = {
	DE: 'Bestellnummer',
	EN: 'Order number',
	FR: 'Numéro de commande',
};

export const trGoBackToHomepage = {
	DE: 'Zurück zur Startseite',
	EN: 'Go back to homepage',
	// prettier-ignore
	FR: 'Revenir à l\'accueil ',
};

export const trContinueShopping = {
	DE: 'Weiter einkaufen',
	EN: 'Continue shopping',
	FR: 'Continuer les achats',
};

export const trPaymentOpen = {
	DE: 'Zahlung offen',
	EN: 'Payment open',
	FR: 'Paiement ouvert',
};

export const trPaymentProcessCancelled = {
	DE: 'Zahlungsvorgang abgebrochen',
	EN: 'Payment process cancelled',
	FR: 'Paiement annulé',
};

export const trYouHaveCancelledThePaymentProcess = {
	DE: 'Sie haben den Bezahlvorgang abgebrochen, die Bestellung ist somit noch nicht abgeschlossen. Um wieder zur Zahlung zu gelangen, klicken Sie bitte auf den untenstehenden Button.',
	EN: 'You have cancelled the payment process, so the order is not yet completed. To return to the payment process, please click on the button below.',
	// prettier-ignore
	FR:
		'Vous avez annulé le processus de paiement, la commande n\'est donc pas encore terminée. Pour revenir au processus de paiement, veuillez cliquer ici :',
};

export const trBackToPayment = {
	DE: 'Zurück zur Bezahlung',
	EN: 'Back to payment ',
	FR: 'Retour au paiement',
};

export const trIfYouWantToCancelThePaymentProcess = {
	DE: 'Wenn Sie den Bestellvorgang abbrechen möchten, klicken Sie auf die untenstehende Schaltfläche. Bitte beachten Sie, dass dabei Ihr Warenkorb und andere erhobenen Bestelldaten zurückgesetzt werden.',
	EN: 'If you wish to cancel the order process, simply use the button below. Please note that this will reset your shopping cart and other order data that you have entered.',
	// prettier-ignore
	FR:
		'Si vous souhaitez annuler le processus de commande, utilisez simplement le bouton ci-dessous. Veuillez noter que cette opération réinitialisera votre panier et les autres données de commande que vous avez saisies.',
};

export const trBackToHomeAndCancelOrder = {
	DE: 'Bestellung abbrechen',
	EN: 'Cancel order',
	// prettier-ignore
	FR: 'Annuler la commande',
};

export const trYouWillBeRedirectedToHomePage = {
	DE: '(Sie werden zur Startseite weitergeleitet)',
	EN: '(You will be redirected to our home page)',
	// prettier-ignore
	FR: '( Vous serez redirigé vers la page d\'accueil. )',
};
export const trYouHaveSelectedBankTransfer = {
	DE: 'Sie haben als Zahlungsmethode Banküberweisung gewählt. Um Ihren Kauf abzuschließen, bezahlen Sie bitte den offenen Betrag an die unterstehende Bankverbindung.',
	EN: 'You have selected bank transfer as payment method. To complete the purchase, please pay the outstanding amount to the bank account below.',
	// prettier-ignore
	FR:
		'Vous avez choisi le virement bancaire comme mode de paiement. Pour finaliser l\'achat, veuillez verser le montant restant sur le compte bancaire ci-dessous.',
};
export const trBeneficiary = {
	DE: 'Begünstigter',
	EN: 'Beneficiary',
	FR: 'Bénéficiaire',
};
export const trBankAccountNumber = {
	DE: 'IBAN',
	EN: 'Bank account number',
	FR: 'Numéro de compte',
};
export const trBic = { ALL: 'BIC' };
export const trAmount = { DE: 'Betrag', EN: 'Amount', FR: 'Montant' };
export const trReference = {
	DE: 'Verwendungszweck',
	EN: 'Reference',
	FR: 'Référence du paiement',
};
export const trPaymentTerm = {
	DE: 'Zahlungsfrist',
	EN: 'Payment term',
	FR: 'Délai de paiement',
};
export const trImportantNote = {
	DE: 'Wichtiger Hinweis',
	EN: 'Important note',
	FR: 'Note importante',
};
export const trOrderIsProcessedAfterPayment = {
	DE: 'Ihre Bestellung wird nach Zahlungseingang verarbeitet.',
	EN: 'Your order will be processed after we have received your payment.',
	FR: 'Votre commande sera traitée après réception du paiement.',
};
export const trYouCanChangePaymentMethod = {
	DE: 'Sie können die Zahlung auch mit einer anderen Zahlungsmethode vornehmen.',
	EN: 'You can also still change the payment method.',
	FR: 'Vous pouvez encore modifier le mode de paiement',
};
export const trChangePaymentMethod = {
	DE: 'Zahlungsmethode ändern',
	EN: 'Change payment method',
	FR: 'Modifier le paiement des factures',
};
export const trOrderReceived = {
	DE: 'Bestellung ist eingegangen',
	EN: 'We have received your order',
	FR: 'Nous avons reçu votre commande',
};
