import React, { useMemo } from 'react';
import { MaintenanceModeView } from './MaintenanceModeView';
import _ from 'lodash';

export const ShopModeContext = React.createContext(null);

export const ShopMode = ({ shop_mode, children }) => {
	const value = useMemo(() => {
		return {
			shop_mode,
			disable_customer_order: _.isEqual(shop_mode, 'catalog'),
		};
	}, [shop_mode]);

	if (_.isEqual(shop_mode, 'maintenance')) return <MaintenanceModeView />;

	return (
		<ShopModeContext.Provider value={value}>
			{children}
		</ShopModeContext.Provider>
	);
};
