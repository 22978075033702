import { makeSubContainer } from '../../tools/containerTools';
import { Session } from './Session';
import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const Context = React.createContext(null);
Context.displayName = 'Customer.Context';

const setNewsletter = (state, ACTIVE) => {
	const temp = _.assign({}, state, { NEWSLETTER: ACTIVE });
	return temp;
};

const CustomerReducer = (state, action) => {
	switch (action.type) {
		case 'setEmail':
			return _.assign({}, state, { EMAIL: action.payload.data.EMAIL });

		case 'setNewsletter':
			return setNewsletter(state, action.payload.data.ACTIVE);
		case 'setLanguageCode':
			return _.assign({}, state, {
				LANGUAGE_CODE: action.payload.data.LANGUAGE_CODE.toUpperCase(),
			});
	}
	throw new Error(`no reducer for action '${action.type}'`);
};
const CustomerContainer = ({ state, dispatch, makeValues, children }) => {
	const setLanguageCode = useCallback((LANGUAGE_CODE) => {
		dispatch({
			type: 'setLanguageCode',
			payload: { data: { LANGUAGE_CODE } },
		});
	}, []);

	const setNewsletter = useCallback(
		(ACTIVE) => {
			dispatch({
				type: 'setNewsletter',
				payload: { data: { ACTIVE } },
			});
		},
		[dispatch]
	);

	const setEmail = useCallback(
		(EMAIL) => {
			dispatch({
				type: 'setEmail',
				payload: { data: { EMAIL } },
			});
		},
		[dispatch]
	);

	const value = makeValues({
		...state,
		setNewsletter,
		setLanguageCode,
		setEmail,
	});
	return <Context.Provider value={value}>{children}</Context.Provider>;
};

CustomerContainer.propTypes = {
	state: PropTypes.object,
	makeValues: PropTypes.func,
};

const Container = makeSubContainer(
	Session.Context,
	'CUSTOMER',
	CustomerReducer
)(CustomerContainer);
Container.displayName = 'Customer.Container';

export const Customer = {
	Container,
	Context,
};
