// import React, { useContext } from 'react';
// import { MuiAppContext } from './MuiAppContainer';
// import { get } from 'lodash';
// import { AmProvider } from '@lsoft/am';
//
// export const MuiAppContextAmProvider = ({ children }) => {
// 	const {
// 		loginInfo: { token, setRenderLogin },
// 		renderOnRestriction,
// 	} = useContext(MuiAppContext);
// 	console.log('setRenderLogin', setRenderLogin);
// 	console.log('-->', useContext(MuiAppContext));
// 	return (
// 		<AmProvider
// 			am_auth_token={token}
// 			renderLogin={setRenderLogin}
// 			renderOnRestriction={renderOnRestriction}
// 		>
// 			{children}
// 		</AmProvider>
// 	);
// };
