import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const MuiSearchBarContext = React.createContext(null);
MuiSearchBarContext.displayName = "MuiSearchBarContext"

export const MuiSearchBarContainer = ({
	children,
	autocomplete: passed_autocomplete,
	onResultClick: passed_onResultClick,
	search: passed_search,
}) => {
	const [search_string, setSearchString] = useState('');
	const [autocompleteResults, setAutocompleteResults] = useState(null);
	const autocompleteRef = useRef();

	useEffect(() => {
		autocomplete();
	}, [search_string]);

	const autocomplete = async () => {
		// result has to be a dictionary , so that the selected index can be inserted.

		if (search_string.length < 3) {
			// empty autocomplete results
			if (autocompleteResults !== null) setAutocompleteResults(null);
		} else {
			const data = await passed_autocomplete(search_string);
			setAutocompleteResults(_.isNil(data) || data.length === 0 ? null : data);
		}
	};

	const onFocus = () => {
		autocomplete();
	};

	const search = () => {
		const call_passedsearch = async (data) => {
			if (!_.isNil(autocompleteResults) && autocompleteResults.length === 1) {
				onResultClick(autocompleteResults[0]);
			} else {
				passed_search(data);
			}
		};
		call_passedsearch(search_string);
		setSearchString('');
	};

	const onResultClick = (data) => {
		const call_onResultClick = async (data) => {
			passed_onResultClick(data);
		};
		call_onResultClick(data);
		setSearchString('');
	};

	const focusAutocomplete = () => {
		autocompleteRef.current.focus();
	};
	const value = {
		autocompleteResults,
		setAutocompleteResults,
		autocomplete,
		search,
		search_string,
		setSearchString,
		onResultClick,
		autocompleteRef,
		focusAutocomplete,
		onFocus,
	};

	return (
		<MuiSearchBarContext.Provider value={value}>
			{children}
		</MuiSearchBarContext.Provider>
	);
};

MuiSearchBarContainer.propTypes = {
	autocomplete: PropTypes.func,
	search: PropTypes.func,
	onResultClick: PropTypes.func,
};
