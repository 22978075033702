// eslint-disable-next-line no-unused-vars
import React from 'react';
import { ic_number } from './number';
import _ from 'lodash';

export const ic_price = {
	// eslint-disable-next-line react/display-name
	Component: ic_number.Component,
	getNewProps: (newProps) => {
		newProps.suffix = _.get(newProps, 'currency', '€');
		const value2 = _.get(newProps, 'value2', null);

		if (value2 != null) newProps.suffix = value2;

		newProps.decimals = 2;
		newProps.decimalSeparator = ',';
		newProps.textAlign = 'right';
		newProps = ic_number.getNewProps(newProps);
		return newProps;
	},
};
