import React, { Fragment, useContext } from 'react';
import { Tr } from '@lsoft/translate';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { trmLanguages } from '@lsoft/mui';
import IconButton from '@material-ui/core/IconButton';
import LanguageIcon from '@material-ui/icons/Language';
import { createStyles, makeStyles } from '@material-ui/core';
import { useRouter } from 'next/router';
import _ from 'lodash';
import MenuList from '@material-ui/core/MenuList';
import { HeaderIconPortal, HeaderIconPortalPaper } from './HeaderIconPortal';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Customer } from '../../container/shop/Customer';
import clsx from 'clsx';
import { HeaderIconPortalButton } from './HeaderIconPortalButton';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) =>
	createStyles({
		HeaderIconPortalPaper: {},
		IconButton: {
			background: grey[100],
			'&:hover': {
				background: grey[200],
			},
		},
	})
);

const HeaderTranslation = ({ theme }) => {
	const { buttonRef, close, isOpen, onButtonClick } = useContext(
		HeaderIconPortalButton.Context
	);

	const classes = useStyles({ theme });
	const { locale, locales, asPath, push } = useRouter();
	const tr = _.get(trmLanguages, 'tr.language');
	const { setLanguageCode } = useContext(Customer.Context);

	const menuItemOnClick = (lc) => () => {
		push(asPath, asPath, { locale: lc });
		if (_.isFunction(setLanguageCode)) setLanguageCode(lc);
		close();
	};

	const options = locales
		.filter((lc) => lc !== locale)
		.map((lc) => {
			return (
				<MenuItem key={lc} onClick={menuItemOnClick(lc)}>
					<Typography>
						<Tr tr={tr.Languages[lc.toUpperCase()]} />
					</Typography>
				</MenuItem>
			);
		});

	return (
		<Fragment>
			<IconButton
				color={'inherit'}
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={onButtonClick}
				ref={buttonRef}
				className={clsx(classes.IconButton)}
			>
				<LanguageIcon className={clsx(classes.translateIcon)} />
			</IconButton>
			{isOpen && (
				<HeaderIconPortal>
					<HeaderIconPortalPaper
						className={clsx(classes.HeaderIconPortalPaper)}
					>
						<MenuList
							subheader={
								<ListSubheader color={'primary'} disableSticky>
									<Tr tr={tr.ChangeLanguage} />
								</ListSubheader>
							}
						>
							{options}
						</MenuList>
					</HeaderIconPortalPaper>
				</HeaderIconPortal>
			)}
		</Fragment>
	);
};
HeaderTranslation.propTypes = {};
HeaderTranslation.defaultProps = {};

export { HeaderTranslation };
